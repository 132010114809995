<dx-popup
  [width]="600"
  [height]="'auto'"
  [title]="dialogTitle"
  [showTitle]="true"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="false"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Site <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <dx-lookup
          [items]="getSitesLocaux()"
          formControlName="site"
          placeholder="Sélectionner un site"
          valueExpr="id"
          displayExpr="libelle"
          searchExpr="libelle"
        >
        </dx-lookup>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Libellé <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="input-group-sm">
          <dx-text-box placeholder="Nom du plan de production" formControlName="libelle"></dx-text-box>
        </div>
      </div>
    </div>

    <div class="p-2 d-flex justify-content-end">
      <button
        class="btn p-button-success"
        type="submit"
        pButton
        [disabled]="!canCreate()"
        [class.disable]="!canCreate()">
        <i class="fas fa-save mg-r-5"></i> Enregistrer
      </button>
      <button
        class="ml-3 btn cta-inner-delete"
        type="button"
        (click)="closeDialog()"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </form>

</dx-popup>
