<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <p-calendar
        firstDayOfWeek="1"
        [locale]="fr"
        (onSelect)="onChangeFilterDateLivraison()"
        (onTodayClick)="onChangeFilterDateLivraison()"
        (onClearClick)="onChangeFilterDateLivraison()"
        placeholder="Filtrer par dates de livraison..."
        pTooltip="Filtrer par dates de livraison"
        showDelay="500"
        appendTo="body"
        showButtonBar="true"
        class="mg-r-5"
        selectionMode="range"
        [(ngModel)]="filterDatesLivraisons"
        dateFormat="dd/mm/yy"
        readonlyInput="true"
        [showWeek]="true">
      </p-calendar>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
        <dx-tag-box
          [width]="370"
          [multiline]="false"
          [dataSource]="filterItemsFournisseur"
          placeholder="Filtrer par fournisseur..."
          displayExpr="value"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeFilterFournisseurs($event)"
        >
        </dx-tag-box>
      </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="370"
        [multiline]="false"
        [dataSource]="filterItemsUdp"
        placeholder="Filtrer par unité de production..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        (onValueChanged)="onChangeFilterUdps($event)"
      >
      </dx-tag-box>
    </span>

      <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="450"
        [dataSource]="filterItemsListeBesoin"
        placeholder="Filtrer par liste de besoins..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [multiline]="false"
        (onValueChanged)="onChangeFilterListesBesoins($event)"
      >
      </dx-tag-box>
    </span>
    </div>
  </dxi-item>

  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
    <span class="mg-r-5">
      <button
        pButton
        icon="fas fa-print"
        (click)="printPDFCommandes()"
        [disabled]="isActionDisabled"
        pTooltip="Imprimer les propositions sélectionnées au format pdf">
      </button>
    </span>

      <span class="mg-r-5">
      <button
        pButton
        icon="fa fa-trash"
        class="cta-inner-delete"
        (click)="deleteBonCfs()"
        [disabled]="isActionDisabled"
        pTooltip="Supprimer les propositions sélectionnées">
      </button>
    </span>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  (onSelectionChanged)="checkDisabledButtons()"
  [allowColumnResizing]="true"
  #grid>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="numeroBcf" caption="Bon de commande" alignement="left"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande"
              alignement="left"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a pTooltip="Aller sur la liste de besoins" showDelay="500"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} commande(s) refusée(s)">
  </dxo-pager>

</dx-data-grid>
