<div class="search-panel">

  <div class="row">

    <div class="col-md-12">
      <form [formGroup]="form">
        <p-table [columns]="cols"
                 [value]="produitsDeclinaisons"
                 [scrollable]="true"
                 styleClass="p-datatable-sm"
                 [style]="{'height':'445px', 'min-height':'445px', 'max-height':'445px'}"
                 scrollHeight="330px"
                 (onSort)="onSort($event)"
                 (onRowSelect)="onRowSelect($event)"
                 (onRowUnselect)="onRowUnselect($event)"
                 [(selection)]="selectedProduitsDeclinaisons"
                 dataKey="id"
                 #dt
        >

          <!--EMPTY MESSAGE-->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="vertical-align: top; ">
                <i class="fas fa-exclamation-triangle mg-r-5 danger-color"></i> Aucun composant. Modifiez votre recherche.
              </td>
            </tr>

          </ng-template>

          <!--COLGROUP-->
          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">


                <col *ngSwitchCase="'selection'" [style.width]="'40px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'libelle'" [style.width]="'200px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'declinaison'" [style.width]="'150px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'familleProduitLibelle'" [style.width]="'150px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'typeProduitLibelle'" [style.width]="'150px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'allergene'" [style.width]="'150px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'appellation'" [style.width]="'150px'" [style.text-align]="'left'">
                <col *ngSwitchCase="'prixAchat'" [style.width]="'150px'" [style.text-align]="'right'">
                <col *ngSwitchCase="'prixAchatUniversel'" [style.width]="'150px'" [style.text-align]="'right'">

              </ng-container>

            </colgroup>
          </ng-template>

          <!--CAPTION-->
          <!--<ng-template pTemplate="caption">-->
          <!--<div class="text-right">-->
          <!--&lt;!&ndash;selection vue dietetique / economat &ndash;&gt;-->
          <!--&lt;!&ndash;<p-selectButton class="mg-r-20" [options]="views" [(ngModel)]="selectedView">&ndash;&gt;-->
          <!--&lt;!&ndash;</p-selectButton>&ndash;&gt;-->
          <!--</div>-->
          <!--</ng-template>-->


          <!--HEADER-->
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">

                <ng-container *ngSwitchCase="'selection'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'libelle'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'declinaison'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'familleProduitLibelle'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'typeProduitLibelle'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'allergene'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'appellation'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'prixAchat'">
                  <th>
                    {{col.header}}
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'prixAchatUniversel'">
                  <th [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </ng-container>

              </ng-container>
            </tr>
            <tr>
              <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">


                <ng-container *ngSwitchCase="'libelle'">
                  <th>
                    <input type="text" formControlName="libelle" placeholder="Libellé...">
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'declinaison'">
                  <th>
                    <p-multiSelect
                      formControlName="declinaisons"
                      appendTo="body"
                      [options]="declinaisons"
                      optionLabel="libelle"
                      defaultLabel="Déclinaisons..."
                      selectedItemsLabel="{0} déclinaison(s)"
                      [maxSelectedLabels]="2"
                    ></p-multiSelect>
                  </th>
                </ng-container>


                <ng-container *ngSwitchCase="'familleProduitLibelle'">
                  <th>
                    <p-multiSelect
                      formControlName="familles"
                      appendTo="body"
                      [options]="familles"
                      optionLabel="libelle"
                      defaultLabel="Familles..."
                      selectedItemsLabel="{0} familles(s)"
                      [maxSelectedLabels]="2"
                    ></p-multiSelect>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'typeProduitLibelle'">
                  <th>
                    <p-multiSelect
                      formControlName="types"
                      appendTo="body"
                      [options]="types"
                      optionLabel="libelle"
                      defaultLabel="Types..."
                      selectedItemsLabel="{0} type(s)"
                      [maxSelectedLabels]="2"
                    ></p-multiSelect>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'allergene'">
                  <th>
                    <p-multiSelect
                      formControlName="allergenes"
                      appendTo="body"
                      [options]="allergenes"
                      optionLabel="libelle"
                      defaultLabel="Allergènes..."
                      selectedItemsLabel="{0} allergene(s)"
                      [maxSelectedLabels]="2"
                    ></p-multiSelect>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'appellation'">
                  <th>
                    <p-multiSelect
                      formControlName="appellations"
                      appendTo="body"
                      [options]="appellations"
                      optionLabel="libelle"
                      defaultLabel="Appellation..."
                      selectedItemsLabel="{0} appellation(s)"
                      [maxSelectedLabels]="2"
                    ></p-multiSelect>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'prixAchat'">
                  <th>
                    <label> Prix entre {{form.controls['prixAchatRange'].value[0] | currency:'EUR':'symbol':'1.0-0'}}
                      et {{form.controls['prixAchatRange'].value[1]  | currency:'EUR':'symbol':'1.0-0' }}</label>
                    <p-slider formControlName="prixAchatRange" [animate]="true" [min]="minPrixAchat"
                              [max]="maxPrixAchat" [step]="0.01" [range]="true"></p-slider>
                  </th>
                </ng-container>

                <ng-container *ngSwitchCase="'prixAchatUniversel'">
                  <th>

                  </th>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <th>

                  </th>
                </ng-container>

              </ng-container>
            </tr>
          </ng-template>

          <!--BODY-->
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData">
              <td *ngFor="let col of columns;  trackBy:utils.trackByFn">

                <ng-container [ngSwitch]="col.field">

                  <ng-container *ngSwitchCase="'selection'">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </ng-container>

                  <ng-container *ngSwitchCase="'libelle'">
                    <yo-icon-produitfab [fabrique]="rowData.fabrique"></yo-icon-produitfab>
                    <i *ngIf="rowData.erreurPrix"
                       class="fas fa-exclamation-triangle danger-color mg-r-5 mg-l-5 align-self-center"
                       [pTooltip]="rowData.messageErreurPrix">
                    </i>
                    {{rowData.libelle}}
                  </ng-container>

                  <ng-container *ngSwitchCase="'declinaison'">
                    {{rowData.declinaison.libelle}}
                  </ng-container>

                  <ng-container *ngSwitchCase="'familleProduitLibelle'">
                    {{rowData.familleProduitLibelle}}
                  </ng-container>

                  <ng-container *ngSwitchCase="'typeProduitLibelle'">
                    {{rowData.typeProduitLibelle}}
                  </ng-container>

                  <ng-container *ngSwitchCase="'appellation'">
                    <ng-container *ngFor="let pa of rowData.produitAppellationList">
                      <ng-container *ngIf="pa.actif">
                        <yo-img-entity class="mg-r-5"
                                       [displayNoImage]="false"
                                       [ordre]="1"
                                       [entityName]="appellationsSvc.getEntityName()"
                                       [height]="48"
                                       [refresh]="1"
                                       [openable]="true"
                                       format="thumb"
                                       [entityId]="pa?.appellationId"
                                       [title]="pa?.appellationLibelle"></yo-img-entity>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'allergene'">

                    {{getActifs(rowData.produitAllergeneList, 'allergeneLibelle')}}
                  </ng-container>

                  <ng-container *ngSwitchCase="'prixAchat'">
                    <div style="text-align: right;">{{rowData.udpPrixAchat   | currency:'EUR':'symbol':'1.4-4'}}
                      / {{rowData.uniteDeMesure.libelle}}</div>
                  </ng-container>

                  <ng-container *ngSwitchCase="'prixAchatUniversel'">
                    <div style="text-align: right;">
                      {{ rowData.udpPrixAchatUniversel | currency:'EUR':'symbol':'1.4-4' }} / KILO
                    </div>
                  </ng-container>

                </ng-container>

              </td>
            </tr>
          </ng-template>

          <!--SUMMARY-->
          <!--<ng-template pTemplate="summary">-->

            <!--<table>-->
              <!--<tr>-->
                <!--<td style="text-align: left;">-->

                <!---->

                <!--</td>-->
                <!--<td style="text-align: right;">-->


                <!--</td>-->
              <!--</tr>-->
            <!--</table>-->
          <!--</ng-template>-->

        </p-table>
      </form>
    </div>


  </div>


  <div class="row">
    <div class="col-md-4 text-left">
    </div>

    <div class="col-md-8 text-right">

      <!--voir mon panier-->
      <button pButton (click)="showCart()" class="mg-r-20 "
              [disabled]="utils.isCollectionNullOrEmpty(produitsDeclinaisonsInCart)"
              label="{{produitsDeclinaisonsInCart.length}}" icon="fas fa-shopping-basket"></button>

      <!--ajouter mon panier à la recette-->
      <button [disabled]="utils.isCollectionNullOrEmpty(produitsDeclinaisonsInCart)" pButton
              label="Ajouter mon panier à la recette" icon="fas fa-plus"
              class=" mg-r-20 "
              (click)="addProduitDecliInCartToRecette()"></button>

      <!--fermer-->
      <button pButton (click)="onHideAjoutComposants()" icon="fas fa-times"
              class="cta-inner-delete"
              label="Fermer"></button>
    </div>
  </div>

</div>

<!--DIALOG PANIER-->
<p-dialog [(visible)]="displayCart"
          appendTo="body"
          [contentStyle]="{'overflow':'visible'}"
          [modal]="true"
          (onHide)="onHideCart()"
          [style]="{width: '550px', minWidth: '550px'}"
          header="Mon panier"
          [dismissableMask]="false">

  <div *ngFor="let pd of produitsDeclinaisonsInCart">
    <i class="fas fa-times danger-color mg-r-20 cursor" pTooltip="Enlever" showDelay="500"
       (click)="removeProduitDeclinaisonInCart(pd)"></i>
    <yo-icon-produitfab [fabrique]="pd.fabrique"></yo-icon-produitfab>
    <span class="mg-r-20">{{pd.libelle}}</span>
    <em class="genlabel-color" style="font-size: 12px;"> {{pd.declinaison.libelle}}</em>
  </div>

  <ng-template pTemplate="footer">
    <button pButton (click)="onHideCart()" icon="fas fa-times" class="cta-inner-delete" label="Fermer"></button>
    <button pButton (click)="eraseCart()" class="cta-inner-delete" icon="fas fa-trash" label="Vider le panier"></button>
  </ng-template>


</p-dialog>
