<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Libellé <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <input pInputText formControlName="libelle" />
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Code <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <input pInputText formControlName="code" />
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'code',label:'Code'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Actif <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <p-checkbox formControlName="actif" binary="true"></p-checkbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'actif',label:'Actif'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Type de tâche <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="typesTachesList"
                     [style.width]="'100%'"
                     checkbox="true"
                     formControlName="idTypeTache"
                     optionLabel="libelle">
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'idTypeTache',label:'Type de tâche'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        class="p-button-success"
        label="Enregistrer">
      </button>
      <button
        class="mg-l-5 cta-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
