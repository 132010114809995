<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <strong>
        <span class="mg-r-25 ">Articles préférés</span>
      </strong>

      <label class="genlabel mg-r-5 line-height-24 height-16">Sélectionner les articles préférés par</label>
      <p-dropdown
        [options]="upAps.reglesPreferes"
        pTooltip="Mode de sélection de l'article préféré"
        tooltipPosition="bottom"
        showDelay="500"
        [style]="{'width':'300px'}"
        appendTo="body"
        [showClear]="false"
        filter="true"
        (onChange)="changeReglePrefere($event)"
        optionLabel="libelle"
        [(ngModel)]="upAps.selectedReglePrefere">
      </p-dropdown>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <p-menu appendTo="body" #computeMenu [popup]="true" [model]="itemsComputeMenu"></p-menu>
      <button type="button"
              pButton
              icon="fas fa-cog rotate-1-turn"
              class="mg-r-5"
              [disabled]="!gds.canModify(uniteDeProduction)"
              (click)="computeMenu.toggle($event)"
              pTooltip="Traitement et mise à jour"
              tooltipPosition="bottom"
              showDelay="500">
      </button>

      <span class="mg-r-5">
          <button pButton icon="fas fa-truck rotate-1-turn" [@animateFilialesButton]="currentStateRegle"
                  [disabled]="!gds.canModify(uniteDeProduction)"
                  pTooltip="Voir/Sélectionner les filiales préférées."
                  (click)="displayFilialesPreferees($event)">
          </button>
        </span>
    </div>
  </dxi-item>
</dx-toolbar>


<router-outlet></router-outlet>


<!--DIALOG DE SÉLECTION DES FILIALES PRÉFÉRÉES-->
<yo-dialog-filiales-preferees></yo-dialog-filiales-preferees>
