<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'width':'80vw'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="mg-r-5 fas fa-utensils yoni-color"></i>Plats qui utilisent la denrée {{produitUtilise.libelle}}
    </div>
  </ng-template>

  <div class="d-flex justify-content-end mg-b-5">
    <p-menu appendTo="body" #computeMenu [popup]="true" [model]="itemsActionMenu"></p-menu>
    <button type="button"
            pButton
            icon="fas fa-cog rotate-1-turn"
            class="mg-r-5"
            (click)="computeMenu.toggle($event)"
            pTooltip="Actions"
            [disabled]="isDisabledActionButton"
            tooltipPosition="bottom"
            showDelay="500">
    </button>
  </div>

  <!--  BODY-->
  <div class="mg-t-5" [style.max-height]="'calc(80vh)'" style=" overflow-y:auto;overflow-x: hidden">
    <dx-data-grid
      [dataSource]="platList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(250)"
      [width]="'calc(75vw)'"
      columnResizingMode="widget"
      [remoteOperations]="true"
      [cacheEnabled]="false"
      [allowColumnReordering]="true"
      (onSelectionChanged)="onSelectionChanged($event)"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="site.libelle" alignment="left" caption="Site" [width]="200"
                  [allowFiltering]="false"
                  [allowSorting]="false"
                  cellTemplate="siteCellTemplate">
      </dxi-column>
      <dxi-column dataField="libelle" alignment="center" caption="Libelle" [width]="400"
                  [allowSorting]="false"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="code" alignment="center" caption="Code" [width]="300"
                  [allowSorting]="false"
                  [allowFiltering]="true">
      </dxi-column>


      <div *dxTemplate="let cell of 'siteCellTemplate'">
        <yo-site [siteLibelle]="cell.data.site.libelle"
                 [siteId]="cell.data.site.id">
        </yo-site>
      </div>

      <dxo-selection
        [selectAllMode]="'page'"
        [showCheckBoxesMode]="'always'"
        mode="multiple">
      </dxo-selection>
      <dxo-paging [pageSize]="50"></dxo-paging>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100,200]"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Plats">
      </dxo-pager>

      <dxo-filter-row
        [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>

    </dx-data-grid>
  </div>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <!--      <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>-->
      <!--      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Completez les 3 onglets pour avoir-->
      <!--        accés à l'onglet suivant "Articles" </label></div>-->
    </div>
    <div>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="cta-inner-delete">
        </button>
      </span>
    </div>
  </div>
</p-dialog>

<yo-dialog-replace-denree></yo-dialog-replace-denree>
