<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [style]="{'width':'1000px'}"
  [modal]="true"
  appendTo="body"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt mg-r-5"></i> Modifier le mouvement
    </div>
  </ng-template>

  <ng-container *ngIf="!utils.isNullOrEmpty(selectedStockMvt)">


    <div class="row">
      <!-- Fiche identité -->
      <div class="col-md-6">
        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Nom Denrée</div>
          <div class="col-md-8"><strong>{{selectedStockMvt.denominationArticle}}</strong></div>
        </div>
        <div class="row mg-t-10">
          <div class="col-md-4 genlabel">Motif</div>
          <div class="col-md-1">
            <ng-container *ngTemplateOutlet="tplEntreeSortie;context:{rowData:this.selectedStockMvt}"></ng-container>
          </div>
          <div class="col-md-7"><strong>{{selectedStockMvt.stockMouvementStatutLibelle}}</strong></div>
        </div>
      </div>

      <!--actions-->
      <div class="col-md-6">
        <form [formGroup]="form">
          <div class="row mg-t-10">
            <div class="col-md-4 genlabel required" title="Quantité">Quantité</div>
            <div class="col-md-8">
              <div class="p-inputgroup input-group-sm ">
                <input pInputText pKeyFilter="pnum"
                       [style.width.px]="80"
                       (keydown)="utils.keydownNumber($event,form,'quantite',true)"
                       formControlName="quantite"
                       class=" text-right bold">
                <span id="qte" class="p-inputgroup-addon"
                      *ngIf="selectedStockMvt.uniteDeStockage && selectedStockMvt.uniteDeStockage != ''">{{selectedStockMvt.uniteDeStockage}}</span>
                <ng-container
                  *ngTemplateOutlet="tplError;context:{form:form,property:'quantite',label:'quantité'}"></ng-container>
              </div>
            </div>
          </div>

          <div class="row mg-t-10" *ngIf="selectedStockMvt.entreeSortie">
            <div class="col-md-4 genlabel required" title="Prix">Prix unitaire</div>
            <div class="col-md-8">
              <div class="p-inputgroup">
                <input pInputText pKeyFilter="pnum"
                       [style.width.px]="80"
                       min="0"
                       (keydown)="utils.keydownNumber($event,form,'prixUnitaire',true)"
                       formControlName="prixUnitaire"
                       class=" text-right bold">
                <ng-container
                  *ngTemplateOutlet="tplError;context:{form:form,property:'prixUnitaire',label:'prix unitaire'}"></ng-container>
              </div>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-4 genlabel required">Date de mouvement</div>
            <div class="col-md-8">
              <p-calendar
                firstDayOfWeek="1"
                dateFormat="DD d MM yy"
                tooltipPosition="bottom"
                showDelay="500"
                [style]="{'font-weight':'bold'}"
                [readonlyInput]="true"
                formControlName="dateMouvement"
                [locale]="localeFr"
                [showIcon]="true"
                [showWeek]="true">
              </p-calendar>
              <ng-container
                *ngTemplateOutlet="tplError;context:{form:form,property:'dateMouvement',label:'date de mouvement'}"></ng-container>
            </div>
          </div>

        </form>
      </div>

    </div>


  </ng-container>

  <ng-template pTemplate="footer">



    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-sign-out-alt" (click)="modifierMouvement()"
              title="Modifier le mouvement"
              label="Modifier le mouvement"
              showDelay="500"
      ></button>
    </span>
    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-times"
              class="cta-inner-delete"
              (click)="closeDialog()" title="Annuler" label="Annuler"
              showDelay="500"></button>
    </span>


  </ng-template>

</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label" let-site="site">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      {{startError(label)}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      {{startError(label)}} ne peut avoir moins de {{form.controls[property].errors.minlength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('aucunparamzds')">
      {{startError(label)}} est obligatoire. Veuillez paramétrer les zones de stockage de l'unité de
      production {{site.libelle | uppercase}}.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      {{startError(label)}} ne peut dépasser {{form.controls[property].errors.maxlength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('min')">
      {{startError(label)}} doit être supérieur ou égal à {{form.controls[property].errors.min.min}}.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('max')">
      {{startError(label)}} doit être inférieur ou égal à {{form.controls[property].errors.max.max}}.
    </div>

  </div>

</ng-template>

<!--TEMPLATE ENTREE / SORTIE-->
<ng-template #tplEntreeSortie let-rowData="rowData">

  <ng-container *ngIf="rowData.entreeSortie">
    <i class="fas fa-arrow-right bold mg-l-5 mg-r-5 new-color" title="Entrée de stock"
       showDelay="500"></i>
  </ng-container>

  <ng-container *ngIf="!rowData.entreeSortie">
    <i class="fas fa-arrow-left bold  mg-l-5 mg-r-5 danger-color" title="Sortie de stock"
       showDelay="500"></i>
  </ng-container>

</ng-template>
