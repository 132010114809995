<p-card [style]="{'width': '100%',
'min-height':'calc(100vh - 180px)',
'max-height':'calc(100vh - 180px)',
'overflow-y': 'auto',
'overflow-x':'hidden',
'margin-right': '15px'}">
  <ng-template pTemplate="header">

    <i class="fas fa-search mg-r-5 mg-t-10 mg-l-15"></i> FILTRES DE RECHERCHE
  </ng-template>

    <form [formGroup]="formSearch">
      <div class="row">
        <div class="col-md-6 no-padding-right">
          <input type="text"
                 formControlName="referenceInterneArticle"
                 placeholder="Réf. article"
                 class="mg-b-15 full-width no-padding-right font-14">
        </div>
        <div class="col-md-6 no-padding-right">
          <input type="text"
                 pInputText
                 formControlName="denominationArticle"
                 placeholder="Nom Article"
                 class="mg-b-15 full-width no-padding-right font-14">
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 no-padding-right">
          <input type="text"
                 pInputText
                 formControlName="lotArticle"
                 placeholder="Lot article"
                 class="mg-b-15 full-width no-padding-right font-14">
        </div>
        <div class="col-md-6 no-padding-right">
          <input type="text"
                 pInputText
                 formControlName="lotArticleFournisseur"
                 placeholder="Lot fournisseur"
                 class="mg-b-15 full-width no-padding-right font-14">
        </div>
      </div>

      <p-calendar
        firstDayOfWeek="1"
        appendTo="body"
        class="mg-r-40"
        dateFormat="dd/mm/yy"
        placeholder="Période"
        title="Sélection d'une période"
        tooltipPosition="bottom"
        showDelay="500"
        [style]="{'width':'100%'}"
        selectionMode="range"
        formControlName="periode"
        [readonlyInput]="true"
        [showButtonBar]="false"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true">
      </p-calendar>

      <h3 class="first">Mouvements</h3>
      <div class="row mg-b-15">
        <div class="col-md-6">
          <dx-check-box name="filterEntree" (onValueChanged)="filterTypeMouvements($event, 'E')"></dx-check-box> <i class="fas fa-arrow-right bold mg-l-5 mg-r-5 new-color"></i>Entrées
        </div>
        <div class="col-md-6">
          <dx-check-box name="filterSortie" (onValueChanged)="filterTypeMouvements($event, 'S')"></dx-check-box> <i class="fas fa-arrow-left bold  mg-l-5 mg-r-5 danger-color"></i>Sorties
        </div>
      </div>
      <dx-list
        #listTypesMoves
        [dataSource]="mouvementsStatut"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        keyExpr="id"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        searchExpr="libelle"
        searchMode="contains"
        [selectedItemKeys]="idsTypesMouvSelected"
        (onSelectionChanged)="onChangeTypeMouv($event)"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par type de mouvement..."
        >
        </dxo-search-editor-options>
      </dx-list>

      <dx-list
        #listSites
        [dataSource]="sites"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        keyExpr="id"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        [selectedItemKeys]="idsSitesSelected"
        (onSelectionChanged)="onChangeSite($event)"
        searchExpr="libelle"
        searchMode="contains"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par site..."
        >
        </dxo-search-editor-options>
      </dx-list>

      <dx-tree-view
        #treeViewZonesStockages
        [dataSource]="zonesStockages"
        keyExpr="id"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        [selectNodesRecursive]="true"
        [selectByClick]="true"
        [searchEnabled]="true"
        (onItemSelectionChanged)="changeZoneStockage($event)"
        searchExpr="label"
        searchMode="contains"
        itemTemplate="itemTemplate"
      >
        <div *dxTemplate="let item of 'itemTemplate'">
          <i class="{{ item.icon }}" ></i> {{ item.label }}
        </div>
      </dx-tree-view>

    </form>

</p-card>

