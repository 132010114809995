<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'min-height':'700px', 'max-height':'700px', 'width':'500px'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="far fa-lg fa-file-pdf mg-r-5"></i>Impression des menus
    </div>
  </ng-template>

  <div class="d-flex flex-row mg-b-20 mg-t-20">
    <div class="">
      <label class=" mg-r-42">Du : </label>
      <p-calendar pTooltip="Selectionner la date de début"
                  placeholder="date de début"
                  [showOnFocus]="false"
                  [(ngModel)]="startDate"
                  [showIcon]="true"
                  firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"
                  [readonlyInput]="false"
                  [showWeek]="true"
                  [locale]="fr"
                  [inputStyle]="{'width':'120px'}"
                  appendTo="body">
      </p-calendar>
    </div>
    <div class="">
      <label class="mg-l-30 mg-r-18 "> au : </label>
      <p-calendar pTooltip="Selectionner la date de fin"
                  placeholder="date de fin"
                  [showOnFocus]="false"
                  [(ngModel)]="endDate"
                  [showIcon]="true"
                  firstDayOfWeek="1"
                  dateFormat="dd/mm/yy"
                  [readonlyInput]="false"
                  [showWeek]="true"
                  [locale]="fr"
                  [inputStyle]="{'width':'120px'}"
                  appendTo="body">
      </p-calendar>
    </div>
  </div>

  <div class="d-flex flex-row mg-b-20">
    <div class="d-flex align-items-center">
      <label class="mg-b-0 mg-r-5">Régimes :</label>
      <p-dropdown
        [options]="regimeOptionList"
        [(ngModel)]="selectedRegimeId"
        placeholder="Choisir un régime"
        appendTo="body"
        filter="true"
        [style]="{'width':'150px', 'minWidth':'150px'}"
        [showClear]="true">
      </p-dropdown>
    </div>
    <div class="d-flex align-items-center">
      <label class="mg-b-0 mg-r-5 mg-l-30">Jours :</label>
      <p-multiSelect
        [options]="dayOfWeekOptionList"
        [(ngModel)]="selectedDayOfWeek"
        optionLabel="viewValue"
        [displaySelectedLabel]="true"
        maxSelectedLabels="7"
        [defaultLabel]="'sélection...'"
        [filterPlaceHolder]="'sélection...'"
        [style]="{'width':'150px'}"
        appendTo="body">
      </p-multiSelect>
    </div>
  </div>


  <div class="d-flex flex-column mg-b-20">
    <label>Message personnalisé :</label>
    <textarea rows="2" cols="53" maxlength="470" [(ngModel)]="messagePerso">
      </textarea>
  </div>

  <div class="d-flex flex-row mg-b-20">
    <p-fileUpload name="demo[]"
                  customUpload="true"
                  (uploadHandler)="uploadMenuLogo($event)"
                  accept="image/*"
                  [auto]="true"
                  mode="basic"
                  [disabled]="!isLogo"
                  chooseLabel="Image logo"
                  maxFileSize="1000000">
    </p-fileUpload>
    <div *ngIf="isLogo" class="image-logo-menu">
      <img src="dolrest/utils/parametre-image?imageName=menuLogo&date={{refresh}}" height="32">
    </div>
  </div>

  <div class="d-flex flex-column mg-b-20">
    <label>Options d'affichage : </label>
    <div class="mg-l-44">
      <div class="d-flex flex-row">
        <div class="d-flex flex-column mg-r-18 ">
          <p-checkbox binary="true" [(ngModel)]="isContratPrestationRegime"
                      label="Offre, Prestation, Régime"></p-checkbox>
          <p-checkbox binary="true" [(ngModel)]="isLibelleLong" label="Plat libellé long"></p-checkbox>
          <p-checkbox binary="true" [(ngModel)]="isCouleur" label="Code couleur des aliments"></p-checkbox>
        </div>
        <div class="d-flex flex-column ">
          <p-checkbox binary="true" [(ngModel)]="isPrice" label="Coût matière plat"></p-checkbox>
          <p-checkbox binary="true" [(ngModel)]="isCoutRepas" label="Coût matière repas"></p-checkbox>
          <p-checkbox binary="true" [(ngModel)]="isLogo" label="Logo"></p-checkbox>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex justify-content-end">
    <button type="button" pButton label="Imprimer" [disabled]="this.isValidForm()" icon="fas fa-print"
            (click)="downloadMenu()">
    </button>

    <button type="button" pButton class="close-button cta-inner-delete" icon="fas fa-times" (click)="closeDialog()">
    </button>
  </div>
</p-dialog>
