<div class="container-fluid">
  <div class="header">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <i class="fas fa-calendar-check mg-r-5"></i>{{ planProduction?.libelle }} Sélection des filtres | <yo-site [siteLibelle]="planProduction?.site?.libelle" [siteId]="planProduction?.site?.id"></yo-site>

    <span class="floatright">
      <span class="mg-r-5">
      <button pButton
              (click)="saveCurrentStep()"
              class="p-button-success"
              type="submit"
              tabindex="-1"
              title="Enregistrer l'étape courante"
              icon="fas fa-save">
      </button>
      </span>
    </span>

  </div>

  <div class="row">
    <div class="col" id="select-plcs">
      <h5><label class="badge badge-secondary mg-b-10"><i class="fa-map-pin fas mg-r-2"></i> Sélection des points de livraison client</label></h5>
      <dx-tree-view
        #treeView
        [dataSource]="treeFilters"
        [height]="450"
        keyExpr="id"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        [selectNodesRecursive]="true"
        [selectByClick]="true"
        [searchEnabled]="false"
        (onItemSelectionChanged)="updateMenuPlanning($event)"
        searchExpr="label"
        searchMode="contains"
        itemTemplate="itemTemplate"
      >
        <div *dxTemplate="let item of 'itemTemplate'">
          <i class="{{ item.icon }}" ></i> {{ item.label }}
        </div>
      </dx-tree-view>
    </div>
    <div class="col" id="calendars">
      <h5><label class="badge badge-secondary mg-b-10"><i class="fas fa-calendar mg-r-2"></i> Sélection de la période</label></h5>
      <div style="overflow-y: auto">
        <p-calendar
          #calendar
        firstDayOfWeek="1"
        inline="true"
        class="mg-r-40 p-calendar-sm"
        dateFormat="dd/mm/yy"
        showDelay="800"
        selectionMode="range"
        (onMonthChange)="updateStartMonth($event)"
        [numberOfMonths]="2"
        [readonlyInput]="true"
        [(ngModel)]="selectedDates"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true">
          <ng-template pTemplate="date" let-date>
            <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date, validDatesFromMenu)"></yo-date-dispo>
          </ng-template>
      </p-calendar>
        <div class="d-flex">
          <div class="mg-r-10">
            <i class="fas fa-square checked-color mg-r-5 "></i>Menus validés
          </div>
          <div>
            <i class="fas fa-square save-color mg-r-5 "></i>Période sélectionnée
          </div>
        </div>
    </div>
  </div>

  </div>
  <div class="row">
    <div class="col-lg-12" id="effectifs">
      <h5><label class="badge badge-secondary mg-b-10"><i class="fas fa-users mg-r-2"></i> Sélection du type d'effectifs</label></h5>
      <strong class="mg-b-5">Choisir l'effectif sur lequel sera fait le calcul :</strong>
      <div class="dx-field">
        <div class="dx-field-value">
          <dx-radio-group [items]="effectifs" [value]="currentEffectif" (onValueChanged)="onEffectifChanged($event)"></dx-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div class="row" id="footer-step">
    <div class="col-lg-12 text-center">
      <button
        (click)="generatePlanProduction()"
        pButton
        iconPos="right"
        type="button"
        class="p-button-primary"
        label="Suivant"
        title="Génération du plan de production"
        showDelay="800"
        icon="fas fa-angle-right">
      </button>
    </div>
  </div>
</div>

<dx-popup
  [showTitle]="true"
  title="Plats non configurés"
  [(visible)]="displayPopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <ng-container *ngIf="dishesWithoutConfigurationTeams.length">
    <label>Veuillez vérifier la configuration des équipes des plats affichés ci-dessous : </label>
    <dx-data-grid
      [dataSource]="dishesWithoutConfigurationTeams"
      keyExpr="dish"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column dataField="dish" caption="Plat non configuré" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxi-column dataField="tache" caption="Type de fabrication" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxi-column caption="Actions" [allowFiltering]="false" cellTemplate="templateActions"></dxi-column>

      <div class="actions" *dxTemplate="let cell of 'templateActions'">
        <a class="mg-r-15" target="_blank" [href]="cell.row.data.urlUdpEquipe" title="Contrôler la configuration des équipes au niveau de l'unité de production"><i class="fas fa-users"></i></a>
        <a target="_blank" [href]="cell.row.data.urlOffreAlimPresta" title="Contrôler la configuration des équipes au niveau des prestations"><i class="fas fa-handshake"></i></a>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} plat(s) non configuré(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="teamsDisabled.length">
    <label>Veuillez vérifier l'activité des équipes affichées ci-dessous (non terminé, jours non exhaustifs) : </label>
    <dx-data-grid
      [dataSource]="teamsDisabled"
      keyExpr="team"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column dataField="team" caption="Equipe" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxi-column dataField="days" caption="Jour(s) à activer" [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <dxi-column caption="Actions" [allowFiltering]="false" cellTemplate="templateActions"></dxi-column>
      <div class="actions" *dxTemplate="let cell of 'templateActions'">
        <a class="mg-r-15" target="_blank" [href]="cell.row.data.urlUdpEquipe" title="Contrôler la configuration des équipes au niveau de l'unité de production"><i class="fas fa-users"></i></a>
      </div>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} équipe(s) non configurée(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>


