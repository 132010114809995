import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionClientsRoutingModule} from './gestion-clients-routing.module';
import {SharedModule} from '../shared/shared.module';
import {ClientFicheIdentiteComponent} from './clients/client/fiche-identite/client-fiche-identite.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {SlideMenuModule} from 'primeng/slidemenu';
import {StepsModule} from 'primeng/steps';
import {GridClientsComponent} from "./grille-clients/grid-clients.component";
import {ClientComponent} from "./clients/client.component";
import {PointDeLivraisonComponent} from "./points-de-livraison/point-de-livraison/point-de-livraison.component";
import {PointDeLivraisonFicheIdentiteComponent} from "./points-de-livraison/point-de-livraison/fiche-identite/point-de-livraison-fiche-identite.component";
import {PointDeLivraisonConditionnementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/point-de-livraison-conditionnement.component";
import {DxDataGridModule, DxLookupModule, DxPopupModule, DxToolbarModule} from "devextreme-angular";
import {LiaisonPlcMcPlcComponent} from "./points-de-livraison/point-de-livraison/conditionnement/liaisons/dialog/liaison-plc-mc-plc.component";
import {LiaisonsComponent} from "./points-de-livraison/point-de-livraison/conditionnement/liaisons/liaisons.component";
import {ReglesRegroupementComponent} from "./points-de-livraison/point-de-livraison/conditionnement/regles/regles-regroupement.component";

@NgModule({
    imports: [
        CommonModule,
        GestionClientsRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,

        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ButtonModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        SelectButtonModule,
        MultiSelectModule,
        SlideMenuModule,
        StepsModule,
        DxDataGridModule,
        DxPopupModule,
        DxLookupModule,
        DxToolbarModule,

    ],
  declarations: [
    ClientComponent,
    ClientFicheIdentiteComponent,
    PointDeLivraisonComponent,
    PointDeLivraisonFicheIdentiteComponent,
    PointDeLivraisonConditionnementComponent,
    LiaisonPlcMcPlcComponent,
    GridClientsComponent,
    LiaisonsComponent,
    ReglesRegroupementComponent
  ]
})
export class GestionClientsModule { }
