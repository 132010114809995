<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button pButton
              label="CRÉER"
              icon="fa fa-plus"
              (click)="openEditObject(undefined)"
              [disabled]="!canCreate()"
              pTooltip="Créer un client">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<p-table
  [scrollable]="true"
  [scrollHeight]="utils.scrollHeightContent(285)"
  styleClass="p-datatable-sm"
  [rows]="50"
  [rowsPerPageOptions]="[5,10,15,20,50,100]"
  [paginator]="true"
  [value]="clientsList"
  (onFilter)="onFilterdata($event)"
  [columns]="cols"
  #dt>

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

        <col *ngSwitchCase="'actions'" [style.width]="'80px'" [style.text-align]="'left'">
        <col *ngSwitchCase="'site'" [style.text-align]="'left'">
        <col *ngSwitchCase="'libelle'" [style.text-align]="'left'">
        <col *ngSwitchCase="'adresse'" [style.text-align]="'left'">
        <col *ngSwitchCase="'plcList'" [style.text-align]="'left'">
        <col *ngSwitchCase="'actif'" [style.width]="'70px'" [style.text-align]="'left'">

      </ng-container>
    </colgroup>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">

        <ng-container *ngSwitchCase="'actions'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'site'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'libelle'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'adresse'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'plcList'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'actif'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

      </ng-container>

    </tr>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">

        <ng-container *ngSwitchCase="'site'">
          <th>
            <input pInputText placeholder="Tous les sites" type="text"
                   (input)="dt.filter($event.target.value, 'site.libelle', 'contains')"
                   style="width: 100% !important; ">
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'libelle'">
          <th>
            <input pInputText placeholder="Tous les clients" type="text"
                   (input)="dt.filter($event.target.value, col.field, 'contains')" style="width: 100% !important; ">
          </th>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <th>
          </th>
        </ng-container>


      </ng-container>

    </tr>
  </ng-template>


  <!--BODY-->
  <ng-template pTemplate="body" let-columns="columns" let-rowData let-rowIndex="rowIndex">
    <tr>
      <td *ngFor="let col of columns;  trackBy:utils.trackByFn">

        <ng-container [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'actions'">
            <yo-cell-button (yoNavigation)="openEditObject(rowData)"
                            [yoViewMode]="!gds.canModify(rowData)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="45"
                            yoTextAlign="center"
                            yoIconClass="fa fa-edit"
                            yoTooltip="Modifier le client"
            ></yo-cell-button>
            <yo-cell-button (yoNavigation)="openDeleteObject(rowData)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="45"
                            [yoDisabled]="!gds.canModify(rowData)"
                            yoTextAlign="center"
                            yoIconClass="fa fa-trash"
                            yoTooltip="Supprimer le client"
            ></yo-cell-button>
          </ng-container>


          <ng-container *ngSwitchCase="'site'">
            <yo-site [siteLibelle]="rowData.site.libelle" [siteId]="rowData.site.id"></yo-site>
          </ng-container>


          <ng-container *ngSwitchCase="'libelle'">
            {{rowData.libelle | uppercase}}
          </ng-container>

          <ng-container *ngSwitchCase="'adresse'">
            {{rowData.adresse.codePostal}} {{rowData.adresse.ville}}
          </ng-container>

          <ng-container *ngSwitchCase="'plcList'">
            <table>
              <tr>
                <td [style.width.px]="60" style="vertical-align: top;padding:4px;"
                    pTooltip="Ajouter un point de livraison"
                    showDelay="500"
                    tooltipPosition="left">

                  <yo-cell-button (yoNavigation)="openPlc(rowData, undefined)"
                                  [yoTooltipShowDelay]="1500"
                                  [yoMaxWidth]="true"
                                  [yoWidthPercent]="45"
                                  yoTextAlign="center"
                                  yoIconClass="fa fa-plus"
                  ></yo-cell-button>
                </td>
                <td style="vertical-align: top;padding:4px;">
                  <ng-container *ngFor="let plc of rowData.plcList">
                    <p-menu appendTo="body" #menuActions [model]="plcActions" [popup]="true"></p-menu>
                    <div (click)="choosePlcAction(menuActions,$event,plc)" class="plc" showDelay="500">
                      <span class="genlabel cursor">{{plc.libelle | uppercase}}</span>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </table>
          </ng-container>

          <ng-container *ngSwitchCase="'actif'">
            <p-checkbox [disabled]="true" [(ngModel)]="rowData.actif"
                        binary="true"></p-checkbox>
          </ng-container>

        </ng-container>
      </td>
    </tr>
  </ng-template>

  <!--SUMMARY-->
  <ng-template pTemplate="summary">
    <tr>
      <td style="text-align:left;">
        {{utils.getTotalRecordsLabel('client(s)', clientsList.length)}}
      </td>
    </tr>
  </ng-template>

</p-table>
