import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {StocksSupplier} from '../stocks-resolver.service';
import {FormControl, FormGroup} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {TreeNode} from 'primeng/api';
import {DATEPICKER_FR, STOCKS_FEATURES} from '../../../core/constants';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {
  ProduitDeclinaisonService,
  SearchStockSupplier
} from '../../../core/services/entities/produit-declinaison.service';
import {DxTreeViewComponent} from "devextreme-angular";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subFeature: Subscription;
  subPaginationSearchStock: Subscription;
  subFormSearch: Subscription;
  subLotsDeleted: Subscription;

  formSearch: FormGroup;
  feature: string;

  page = 0;
  size = 50;

  selectedNodes: TreeNode[] = [];
  searchStockSupplier: SearchStockSupplier;

  seuils: any[] = [];

  localeFr = DATEPICKER_FR;

  sites: SiteDTO[] = [];

  zonesStockages: any[] = [];
  @ViewChild("treeViewZonesStockages", {static: false }) treeViewZonesStockages : DxTreeViewComponent;
  idsUdpZonesStockagesSelected: number[] = [];
  idsZonesStockagesSelected: number[] = [];

  idsSitesSelected: number[] = [];

  constructor(
    public utils: UtilsService,
    private route: ActivatedRoute,
    private stocksSvc: StocksService,
    private auth2Svc: Auth2Service,
    private produitDeclinaisonSvc: ProduitDeclinaisonService,
    private stockSvc: StocksService) {
    this.seuils = [{ value:"4", label:"Articles jamais commandés"}, {value:"0", label:"Articles commandés sans stocks"}, {value:"1", label:"Articles commandés avec stocks"}, {value:"3", label:"Tous"}];
  }

  ngOnInit() {
    this.initForm();
    // fonctionnalité du stock (inventaire, historique...)
    this.subscriptionFeature();
    // la pagination a changé (grille inventaire, matiere premiere ...)
    this.subscriptionPaginationSearchStock();
    this.onChangeSearchUpdateFilter();
    this.initSites();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subFeature);
    this.utils.unsubscribe(this.subPaginationSearchStock);
    this.utils.unsubscribe(this.subLotsDeleted);
  }

  initSites = (): void => {
    this.sites = this.auth2Svc.utilisateur.siteListLocaux;
    if (this.sites && this.sites.length) {
      this.sites = this.sites.sort((s1, s2) => s1.libelle.localeCompare(s2.libelle));
      this.idsSitesSelected.push(this.sites[0].id);
      this.updateUdpZds();
    }
  }

  onChangeSite = ($event): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.idsSitesSelected.filter(idSite => idSite === item.id).length) {
          this.idsSitesSelected.push(item.id);
        }
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.idsSitesSelected.filter(idSite => idSite === item.id).length) {
          this.idsSitesSelected = this.idsSitesSelected.filter(idSite => idSite !== item.id);
        }
      });
    }
    if (this.idsSitesSelected && this.idsSitesSelected.length) {
      this.updateUdpZds();
    }
  }

  initForm = () => {
    this.formSearch = new FormGroup({
      denree: new FormControl(''),
      codeDenree: new FormControl(''),
      denominationArticle: new FormControl(''),
      referenceInterneArticle: new FormControl(''),
      datePerime: new FormControl(new Date()),
      dateEntree: new FormControl(undefined),
      thresholdStocks: new FormControl('1'),
    });
  };

  subscriptionFeature = () => {
    this.subFeature = this.stocksSvc.feature$
      .pipe(
        map(value => {
          this.feature = value;
          this.searchStockSupplier = new SearchStockSupplier(this.formSearch.value, this.selectedNodes, this.feature, 0, 50);
        }),
        switchMap(value => this.searchStockSupplier?.feature === STOCKS_FEATURES.INVENTAIRE ? this.produitDeclinaisonSvc.searchInventaires(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected) : this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected)),
        map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
        catchError(err => this.utils.handleError(err)))
      .subscribe();
  };
  subscriptionPaginationSearchStock = () => {
    this.subPaginationSearchStock = this.produitDeclinaisonSvc.paginationSearchStock$.pipe(
      map(response => this.searchStockSupplier = new SearchStockSupplier(this.formSearch.value, this.selectedNodes, this.feature, response.page, response.size)),
      switchMap(response => this.searchStockSupplier?.feature === STOCKS_FEATURES.INVENTAIRE ? this.produitDeclinaisonSvc.searchInventaires(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected) : this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected)),
      map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    this.subRoute = this.route.data
      .subscribe((data: { stocksSupplier: StocksSupplier }) => {
        this.zonesStockages = data.stocksSupplier.tree;
        if (this.zonesStockages && this.zonesStockages.length) {
          this.zonesStockages.forEach(udp => {
            udp.zonesStockage.forEach(zds => zds["icon"] = "fas fa-home");
            udp.id = `udp-${udp.id}`;
            udp["icon"] = "fa-industry fas";
            udp["items"] = udp.zonesStockage.map(zds => ({ ...zds, id: `${udp.id}-${zds.id}-${zds.idUdpZds}-zds`}) );
            udp["expanded"] = true;
          });
        }
      });

    // lorqu'il y a eu une suppression de lot périmé, on réinit le filtre date "Périmé le" et on relance la recherhce
    this.subLotsDeleted = this.stockSvc.lotsDeleted$.pipe(
      tap(data =>this.formSearch.controls['datePerime'].setValue(undefined))
    ).subscribe();
  };

  onChangeSearchUpdateFilter = (): void => {
    this.formSearch.valueChanges.subscribe(e => this.formSearch.setValue(e, { emitEvent: false })); // Migrer à 10.0.4
    this.subFormSearch = this.formSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      map(values => this.searchStockSupplier = new SearchStockSupplier(values, this.selectedNodes, this.feature, 0, 50)),
      switchMap(values => this.searchStockSupplier?.feature === STOCKS_FEATURES.INVENTAIRE ? this.produitDeclinaisonSvc.searchInventaires(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected) : this.produitDeclinaisonSvc.searchInStock(this.searchStockSupplier, this.idsSitesSelected, this.idsZonesStockagesSelected, this.idsUdpZonesStockagesSelected)),
      map(response => this.produitDeclinaisonSvc.announceResultSearchStocks(response, this.searchStockSupplier)),
      catchError(err => this.utils.handleError(err)))
      .subscribe();
  };

  changeZoneStockage = ($event: any): void => {
    const keys = this.treeViewZonesStockages.instance.getSelectedNodeKeys().filter(key => key.includes("-zds"));
    this.idsUdpZonesStockagesSelected = keys.map(id => parseInt(id.split('-')[3]));
    this.idsZonesStockagesSelected = keys.map(id => parseInt(id.split('-')[2]));
    // déclencher la recherche avec un temps de latence debounceTiem du this.formSearch.valueChanges
    this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});
  };

  private updateUdpZds: () => void = () => {
    this.stocksSvc.prepareSearch(this.idsSitesSelected)
      .subscribe(res => {
        const udpsZdps = res.resultList;
        if (udpsZdps && udpsZdps.length) {
          udpsZdps.forEach(udp => {
            udp.zonesStockage.forEach(zds => zds["icon"] = "fas fa-home");
            udp.id = `udp-${udp.id}`;
            udp["icon"] = "fa-industry fas";
            udp["items"] = udp.zonesStockage.map(zds => ({...zds, id: `${udp.id}-${zds.id}-${zds.idUdpZds}-zds`}));
            udp["expanded"] = true;
          });
          this.zonesStockages = Object.assign([], udpsZdps);
        }
      });
    this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});
  };

}

