<ng-container *ngIf="!utils.isNullOrEmpty(client)">


  <p-card>
    <div class="card-size">
      <div class="row">
        <div class="col-md-6">
          <div class="row ">
            <div class="col-md-12">
              <!-- FORMULAIRE CLIENT -->
              <div class="card">
                <div class="card-header">
                  <h3 class="first">INFOS PRINCIPALES</h3>
                </div>
                <div class="card-body">
                  <form [formGroup]="formClient">
                    <ng-container *ngFor="let field of fieldsClient">
                      <yo-dynamic-form-field-bs [form]="formClient" [field]="field"
                                                [fields]="fieldsClient"></yo-dynamic-form-field-bs>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-12">
              <!--              FORMULAIRE ADRESSE-->
              <div class="card">
                <div class="card-header">
                  <h3 class="first">ADRESSE</h3>
                </div>
                <div class="card-body">
                  <form [formGroup]="formAdresseClient">
                    <ng-container *ngFor="let field of fieldsAdresseClient">
                      <yo-dynamic-form-field-bs [form]="formAdresseClient" [field]="field"
                                                [fields]="fieldsAdresseClient"></yo-dynamic-form-field-bs>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </div>


        </div>


        <div class="col-md-6">

          <div class="row ">
            <div class="col-md-12">
              <!--              FORMULAIRE CONTACT 1-->
              <div class="card">
                <div class="card-header">
                  <h3 class="first">Contact 1</h3>
                </div>
                <div class="card-body">
                  <form [formGroup]="formContact1">
                    <ng-container *ngFor="let field of fieldsContact1">
                      <yo-dynamic-form-field-bs [form]="formContact1" [field]="field"
                                                [fields]="fieldsContact1"></yo-dynamic-form-field-bs>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row mg-t-10">
            <div class="col-md-12">
              <!--              FORMULAIRE CONTACT 2-->
              <div class="card">
                <div class="card-header">
                  <h3 class="first">Contact 2</h3>
                </div>
                <div class="card-body">
                  <form [formGroup]="formContact1">
                    <ng-container *ngFor="let field of fieldsContact2">
                      <yo-dynamic-form-field-bs [form]="formContact2" [field]="field"
                                                [fields]="fieldsContact2"></yo-dynamic-form-field-bs>
                    </ng-container>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="d-flex justify-content-end">
        <span class="mg-r-5">
          <button pButton
                  title="Enregistrer"
                  type="submit"
                  icon="fas fa-save"
                  class="p-button-success"
                  (click)="save()"
                  [disabled]="!gds.canModify(client)">
          </button>
        </span>
    </div>
  </p-card>


</ng-container>
