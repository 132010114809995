<p-dialog
  [modal]="true"

  [dismissableMask]="false"
  (onHide)="closeDialog()"
  [style]="{'width':'700px',  'max-height': '80vh'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <label><i class="{{data?.logo}}"></i>&nbsp;{{data?.title}} </label>
    </div>
  </ng-template>

  <form [formGroup]="form" (ngSubmit)="saveContratMenuConviveEquipe()" novalidate>

    <div class="scrollLayout">

      <table class="table table-striped">
        <tbody>
        <tr>
          <td class="colLabel">
            <label class="label-select">Type de fabrication</label>
          </td>
          <td>
            <p-dropdown [options]="tacheFabricationList"
                        [disabled]="isTypeDeFabricationDisabled()"
                        placeholder="Type de fabrication affecté à l'équipe"
                        [style]="{'width':'300px'}"
                        [formControlName]="'typeFabrication'"
                        showClear="true"
                        optionLabel="libelle"
                        filter="true">
            </p-dropdown>

            <div class="error" *ngIf="form.controls['typeFabrication']?.dirty && form.controls['typeFabrication']?.hasError('required')">
              La sélection d'un type de fabrication est obligatoire.
            </div>
          </td>
        </tr>
        <tr>
          <td class="colLabel">
            <label class="label-select">Équipe</label>
          </td>
          <td>
            <p-dropdown [options]="udpEquipeList"
                        placeholder="Choisir l'équipe de production"
                        [style]="{'width':'400px'}"
                        [formControlName]="'udpEquipe'"
                        showClear="true"
                        optionLabel="equipeLibelle"
                        filter="true">
              <ng-template let-selectedItem pTemplate="selectedItem">
                <div *ngIf="selectedUdpEquipe">
                  <div>{{selectedItem.zoneDeProductionLibelle}} - {{selectedItem.equipeLibelle}}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div>{{item.zoneDeProductionLibelle}} - {{item.equipeLibelle}}</div>
              </ng-template>
            </p-dropdown>
            <div class="error" *ngIf="form.controls['udpEquipe']?.dirty && form.controls['udpEquipe']?.hasError('required')">
              La sélection d'une équipe de production est obligatoire.
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">

            <yo-cmc-equipes-jours [contratMenuConvive]="contratMenuConvive"
                                  [cmcEquipe]="cmcEquipe"
                                  [udpEquipeList]="udpEquipeList"></yo-cmc-equipes-jours>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <div class="d-flex justify-content-end">
      <button pButton
              title="Enregistrer"
              type="submit"
              icon="fas fa-save"
              class="p-button-success"
              [disabled]="!gds.canModify(cmcEquipe)"></button>
      <button
        class="mg-l-5 cta-inner-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times">
      </button>
    </div>
  </form>
</p-dialog>
