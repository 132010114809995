<div *ngIf="!utils.isCollectionNullOrEmpty(cmcdPlcList)" @triggerPeriode>


  <!--    GRILLE DES EFFECTIFS-->

  <p-table
    [scrollHeight]="utils.scrollHeightContent(340)"
    styleClass="p-datatable-sm"
    [scrollable]="true"
    [frozenWidth]="(colRepasWidth*4)+'px'"
    [value]="rows">

    <!--CAPTION-->
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">

        <div class="font-12">
          <i class="fas fa-map-pin mg-r-5 pn-border-color"></i>
          <span class="mg-r-5">
                {{pointDeLivraison.libelle | uppercase}}
            - <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(periode))"></span>
              </span>
          <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        </div>

        <div>
          <span class="mg-r-5">
            <p-menu appendTo="body" #editPlanning [popup]="true" [model]="itemsEditPlanning"></p-menu>
            <button type="button" pButton icon="fas fa-edit" class="mg-r-5"
                    (click)="editPlanning.toggle($event)"
                    pTooltip="Modification en masse des  effectifs, coûts et taux de prise théoriques"
                    showDelay="500">
            </button>
          </span>
          <span class="mg-r-5">
            <button pButton
                    title="Enregistrer"
                    type="submit"
                    icon="fas fa-save"
                    class="p-button-success"
                    (click)="savePlanning()"
                    [disabled]="!gds.canModify(contratMenuConvive)"></button>
          </span>
        </div>

      </div>
    </ng-template>

    <!--FROZEN COLUMNS HEADER-->
    <ng-template pTemplate="frozenheader">
      <tr>
        <th [style.width.px]="colRepasWidth">Jour</th>
        <th [style.width.px]="colRepasWidth">Repas</th>
        <th [style.width.px]="colRepasWidth" pTooltip="Effectif prévisionnel" showDelay="500">
          Effectif prév.
        </th>
        <th [style.width.px]="colRepasWidth" pTooltip="Coût prévisionnel" showDelay="500">
          Coût prév.
        </th>
      </tr>
    </ng-template>

    <!--FROZEN COLUMNS BODY-->
    <ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td [style.width.px]="colRepasWidth"
            [style.vertical-align]="'middle'"
            *ngIf="rowGroupMetadata[rowData.idJourSemaine].index === rowIndex"
            [attr.rowspan]="rowGroupMetadata[rowData.idJourSemaine].size">
          <div class="no-overflow" showDelay="500">
            <strong>{{utils.getJourSemaineById(rowData.idJourSemaine).viewValue | uppercase }}</strong>
          </div>
        </td>
        <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'"
            [style.height.px]="getTailleComposante(rowData)">
          <div class="no-overflow" showDelay="500"
               [pTooltip]="rowData.repasLibelle">
            <strong>{{rowData.repasLibelle}}</strong>
          </div>
        </td>

        <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'">
          <div class="no-overflow" showDelay="500">
            <!--                EFFECTIF PREVISIONNEL-->
            <ng-container *ngTemplateOutlet="tplEffPrev;context:{cmcrPlc:getCmcrPlc(rowData)}"></ng-container>
          </div>
        </td>

        <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'">
          <div class="no-overflow" showDelay="500">
            <!--                COUT PREVISIONNEL-->
            <ng-container *ngTemplateOutlet="tplCoutPrev;context:{cmcrPlc:getCmcrPlc(rowData)}"></ng-container>
          </div>
        </td>

      </tr>
    </ng-template>


    <!--HEADER-->
    <ng-template pTemplate="header">

      <tr>
        <ng-container *ngFor="let col of cols ">
          <th [style.width.px]="cellWidth">
            <div class="no-overflow" showDelay="500" [pTooltip]="col.libelle">
              {{col.libelle | uppercase}}
            </div>

          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!--BODY-->
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>

        <!--CELLULES-->
        <ng-container *ngFor="let col of cols ">
          <ng-container *ngTemplateOutlet="tplCell;context:{cmcr:rowData,cell:getCell(rowData,col)}">
          </ng-container>
        </ng-container>

      </tr>

    </ng-template>

  </p-table>

</div>


<!--TEMPLATE CELLULE-->
<ng-template #tplCell let-cell="cell" let-cmcr="cmcr">
  <td [style.width.px]="cellWidth" style="text-align: right;"
      [style.height.px]="getTailleComposante(cmcr)"
      [style.vertical-align]="'top'">


    <ng-container *ngFor="let slot of utils.getSlots(cell.nombreChoixMaximum); let i=index; ">

      <ng-container
        *ngTemplateOutlet="tplCmcdPlc;context:{cmcdPlc:getCmcdPlc(cell, i+1)}">
      </ng-container>

    </ng-container>


  </td>
</ng-template>

<!--TEMPLATE contrat_menu_convive_decoupage__plc INPUT CELL (saisie de l'effectif previsionnel en % pour un jour/repas/decoupage/plat)-->
<ng-template #tplCmcdPlc let-cmcdPlc="cmcdPlc">

  <ng-container *ngIf="!utils.isNullOrEmpty(cmcdPlc)">

    <div class="input-group input-group-sm mg-t-5">
      <div class="input-group-prepend ">
        <span class="input-group-text font-10">Plat {{cmcdPlc.ordre}}</span>
      </div>
      <input type="text" [style.max-width.px]="50" style="text-align: right;"
             placeholder=""
             pTooltip="Taux de prise"
             showDelay="500"
             (keydown)="utils.keydownNumber($event,cmcdPlc,'tauxDePrise',true)"
             [(ngModel)]="cmcdPlc.tauxDePrise"
             pKeyFilter="pint">

      <div class="input-group-append">
          <span class="input-group-text font-10">%
          </span>
      </div>

      <ng-container *ngIf="cmcdPlc.arrondiEffPrev">
        <i class="fas fa-exclamation-triangle danger-color cursor mg-l-5 mg-t-5"
           pTooltip="Arrondi réalisé pour que la somme des plats corresponde à l'effectif prévisionnel du jour / repas ."
           showDelay="500"></i>
      </ng-container>

    </div>

  </ng-container>


</ng-template>


<!--TEMPLATE EFFECTIF PREVISIONNEL-->
<ng-template #tplEffPrev let-cmcrPlc="cmcrPlc">

  <div class="input-group input-group-sm mg-t-5">
    <input type="text" [style.max-width.px]="50" style="text-align: right;"
           placeholder=""
           (keydown)="utils.keydownNumber($event,cmcrPlc,'effectifPrevisionnel',true)"
           [(ngModel)]="cmcrPlc.effectifPrevisionnel"
           pKeyFilter="pint">

    <div class="input-group-append">
          <span class="input-group-text font-10">pers.
          </span>
    </div>

  </div>


</ng-template>

<!--TEMPLATE COUT PREVISIONNEL-->
<ng-template #tplCoutPrev let-cmcrPlc="cmcrPlc">

  <div class="input-group input-group-sm mg-t-5">
    <input type="text" [style.max-width.px]="50" style="text-align: right;"
           placeholder=""
           (keydown)="utils.keydownNumber($event,cmcrPlc,'coutPrevisionnel',true)"
           [(ngModel)]="cmcrPlc.coutPrevisionnel"
           pKeyFilter="pint">

    <div class="input-group-append">
          <span class="input-group-text font-10">€
          </span>
    </div>

  </div>


</ng-template>


<!--DIALOG EFFECTIFS PREV-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'800px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayEffectifDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Modifier les effectifs prévisionnels
    </div>
  </ng-template>


  <!--CONTENT-->
  <div class="row">
    <div class="col-md-4">
      <h3 class="first">Jours ouvrés</h3>
      <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                 filter="filter" optionLabel="viewValue">
      </p-listbox>
    </div>
    <div class="col-md-4">
      <h3 class="first">Repas</h3>
      <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                 filter="filter" optionLabel="libelle">
      </p-listbox>
    </div>
    <div class="col-md-4">
      <h3 class="first">Effectif prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="text"
               pKeyFilter="pint" style="width: 60px;" [(ngModel)]="majEffectifPrev">
        <div class="input-group-append ">
          <span class="input-group-text">pers.</span>
        </div>
      </div>
    </div>
  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5"
                (click)="updateProperty(selectedJoursSemaine,selectedRepasList,majEffectifPrev,'effectifPrevisionnel')"></button>
        <button pButton icon="fas fa-times"
                (click)="displayEffectifDialog=false" class="cta-delete"></button>
      </div>
    </div>

  </ng-template>


</p-dialog>


<!--DIALOG COUT THEORIQUE-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'800px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayCoutDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Modifier les coûts prévisionnels
    </div>
  </ng-template>


  <!--CONTENT-->
  <div class="row">
    <div class="col-md-4">
      <h3 class="first">Jours ouvrés</h3>
      <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                 filter="filter" optionLabel="viewValue">
      </p-listbox>
    </div>
    <div class="col-md-4">
      <h3 class="first">Repas</h3>
      <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                 filter="filter" optionLabel="libelle">
      </p-listbox>
    </div>
    <div class="col-md-4">
      <h3 class="first">Coût Prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="number" pKeyFilter="pnum" style="width: 60px;" [(ngModel)]="majCoutPrev">
        <div class="input-group-append ">
          <span class="input-group-text">€</span>
        </div>
      </div>
    </div>
  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5"
                (click)="updateProperty(selectedJoursSemaine,selectedRepasList,majCoutPrev,'coutPrevisionnel')"></button>
        <button pButton icon="fas fa-times" (click)="displayCoutDialog=false"
                class="cta-delete"></button>
      </div>
    </div>

  </ng-template>


</p-dialog>


<!--DIALOG TAUX DE PRISE PREV-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'1200px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayTauxDePriseDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Modifier les taux de prise prévisionnels
    </div>
  </ng-template>


  <!--CONTENT-->
  <div class="row">
    <div class="col-md-2">
      <h3 class="first">Jours ouvrés</h3>
      <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                 optionLabel="viewValue">
      </p-listbox>
    </div>
    <div class="col-md-2">
      <h3 class="first">Repas</h3>
      <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                 optionLabel="libelle">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Composantes</h3>
      <p-listbox [options]="decoupageRepasList" [(ngModel)]="selectedDecoupageRepasList" multiple="multiple"
                 checkbox="checkbox"
                 optionLabel="libelle">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Plats</h3>
      <p-listbox [options]="platList" [(ngModel)]="selectedPlatList" multiple="multiple" checkbox="checkbox">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Taux de prise prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="number" pKeyFilter="pint" style="width: 60px;" [(ngModel)]="majTauxDePrisePrev">
        <div class="input-group-append ">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5"
                (click)="updateTauxDePrisePrev(selectedJoursSemaine,selectedRepasList,selectedDecoupageRepasList,selectedPlatList,majTauxDePrisePrev)"></button>
        <button pButton icon="fas fa-times" (click)="displayTauxDePriseDialog=false"
                class="cta-delete"></button>
      </div>
    </div>

  </ng-template>


</p-dialog>

