<p-tabView>
  <p-tabPanel *ngFor="let pMO of produitsModesOperatoires"
              class="tabpanel-custo"
              [header]="pMO.libelleDeclinaison"
              [tooltip]="pMO.codeProduitDeclinaison">
    <p-editor [(ngModel)]="pMO.modeOperatoire"
              [style]="{'height':'calc(67vh)'}"
              [readonly]="!canModify()">
    </p-editor>

  </p-tabPanel>
</p-tabView>

<div class="d-flex justify-content-between mg-b-10">
  <div>
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <label class="space"><strong>Produit {{yoProduit?.libelle | uppercase}}</strong></label>
  </div>
  <button pButton
          title="Enregistrer"
          type="submit"
          icon="fas fa-save"
          class="p-button-success"
          (click)="saveModeOperatoire()"
          [disabled]="!gds.canModify(yoProduit)"></button>
</div>




