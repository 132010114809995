import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import ConditionnementDTO from "../../../core/dtos/conditionnement/conditionnement-dto";
import VarianteDTO from "../../../core/dtos/conditionnement/variante-dto";
import {VariantesService} from "../../../core/services/conditionnements/variantes.service";
import {ConditionnementsService} from "../../../core/services/conditionnements/conditionnements.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {SiteDTO} from "../../../core/dtos/site-dto";

@Component({
  selector: 'yo-wizard-configuration-step-3',
  templateUrl: './wizard-configuration-step-3.component.html',
  styleUrls: ['./wizard-configuration-step-3.component.scss']
})
export class WizardConfigurationStepThreeComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number = 3;

  conditionnements: ConditionnementDTO[] = [];

  variantes: VarianteDTO[] = [];

  subVariantes: Subscription;

  subConditionnements: Subscription;

  currentSite: SiteDTO;

  constructor(private auth2Svc: Auth2Service,
              private udpSvc: UnitesDeProductionService,
              private utilsSvc: UtilsService,
              private wizardSvc: WizardConditionnementService,
              private variantesSvc: VariantesService,
              private conditionnementSvc: ConditionnementsService,
              private i8nSvc: InternationalizationService,
              private toastSvc: ToastService) {

  }

  ngOnInit() {
    this.initCurrentSite();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subVariantes);
    this.utilsSvc.unsubscribe(this.subConditionnements);
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep, conditionnementsLength: this.conditionnements.length, variantesLength: this.variantes.length });
  }

  findAllVariantes = (): void => {
    this.subVariantes = this.variantesSvc.getAll([this.currentSite.id])
      .subscribe(response => {
        this.variantes = response.resultList;
      });
  }

  findAllConditionnements = (): void => {
    this.subConditionnements = this.conditionnementSvc.getAll([this.currentSite.id])
      .subscribe(response => {
        this.conditionnements = response.resultList;
      });
  };

  initCurrentSite = (): void => {
    const dataStepOne: any = this.wizardSvc.getDataStepOne();
    const idSelectedSite = dataStepOne.idSelectedSite;
    this.currentSite = this.auth2Svc.utilisateur.siteListLocaux.find(site => site.id === idSelectedSite);
    this.findAllVariantes();
    this.findAllConditionnements();
  }

  onRowInsertedConditionnement = ($event): void => {
    const data: any = $event.data;
    const dataFromStepOne = this.wizardSvc.getDataStepOne(); // On utilise le site de l'étape 1 pour les référentiels
    const conditionnement: ConditionnementDTO = new ConditionnementDTO();
    conditionnement.site = { id: dataFromStepOne.idSelectedSite } as SiteDTO;
    conditionnement.libelle = data.libelle;
    conditionnement.code = undefined;
    conditionnement.abreviation = data.abreviation;
    conditionnement.poidsEnKg = data.poidsEnKg;
    this.conditionnementSvc.save(conditionnement)
      .subscribe(response => {
        $event.data.id = response.one.id;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le conditionnement a été ajouté avec succès`);
      });
  }

  onRowInsertedVariante = ($event): void => {
    const data: any = $event.data;
    const dataFromStepOne = this.wizardSvc.getDataStepOne(); // On utilise le site de l'étape 1 pour les référentiels
    const variante: VarianteDTO = new VarianteDTO();
    variante.site = { id: dataFromStepOne.idSelectedSite } as SiteDTO;
    variante.libelle = data.libelle;
    variante.code = undefined;
    this.variantesSvc.save(variante)
      .subscribe(response => {
        $event.data.id = response.one.id;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La variante a été ajoutée avec succès`);
      });
  }

  onRowUpdatedConditionnement = ($event): void => {
    const data: any = $event.data;
    const dataFromStepOne = this.wizardSvc.getDataStepOne(); // On utilise le site de l'étape 1 pour les référentiels
    const conditionnement: ConditionnementDTO = new ConditionnementDTO();
    conditionnement.id = data.id;
    conditionnement.site = { id: dataFromStepOne.idSelectedSite } as SiteDTO;
    conditionnement.libelle = data.libelle;
    conditionnement.code = undefined;
    conditionnement.abreviation = data.abreviation;
    conditionnement.poidsEnKg = data.poidsEnKg;
    this.conditionnementSvc.save(conditionnement)
      .subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le conditionnement a été modifié avec succès`);
      });
  }

  onRowUpdatedVariante = ($event): void => {
    const data: any = $event.data;
    const dataFromStepOne = this.wizardSvc.getDataStepOne(); // On utilise le site de l'étape 1 pour les référentiels
    const variante: VarianteDTO = new VarianteDTO();
    variante.id = data.id;
    variante.site = { id: dataFromStepOne.idSelectedSite } as SiteDTO;
    variante.libelle = data.libelle;
    variante.code = undefined;
    this.variantesSvc.save(variante)
      .subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La variante a été modifiée avec succès`);
      });
  }

  onRowInsertingConditionnement = ($event): void => {
    if (!Object.keys($event.data).length) {
      $event.cancel = true;
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode('EMPTY_LINE_TABLE', null));
    } else {
      if(isNaN(parseFloat($event.data?.poidsEnKg))) {
        $event.cancel = true;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode('WEIGHT_MUST_BE_NUMBER', null));
      }
    }
  }

  onRowInsertingVariante = ($event): void => {
    if (!Object.keys($event.data).length) {
      $event.cancel = true;
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, this.i8nSvc.getLabelFromCode('EMPTY_LINE_TABLE', null));
    }
  }


}
