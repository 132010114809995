<div class="d-flex justify-content-between mg-b-10">

  <div>
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <label><strong>Liste des apports nutritionnels</strong></label>
  </div>

  <div>
    <button
      pButton
      class="mg-r-5"
      icon="fa fa-download"
      [disabled]="!gds.canModify(yoProduit)"
      pTooltip="Import ciqual"
      (click)="openDialogNoParam()">
    </button>

    <button pButton
            title="Enregistrer"
            type="submit"
            icon="fas fa-save"
            class="p-button-success"
            (click)="save()"
            [disabled]="!gds.canModify(yoProduit)"></button>
  </div>

</div>

<div class="table-ciqual">
  <table class="table-striped table-bordered">
    <thead>
    <tr>

      <th rowspan="6" colspan="1" style="text-align: center">
        <!-- Toggle permettant de switcher entre l'affichage de TOUS les apports nutritionnels ou -->
        <!-- seulement ceux qui sont obligatoires -->
        <p-checkbox [binary]="true"
                    [ngModel]="!getExpandConstituants()"
                    (onChange)="onChangeToggle($event)"
                    [pTooltip]="getToggleTooltip()"
        ></p-checkbox>

        CONSTITUANTS({{selectedApportsNutritionnels.length}}) &nbsp;&nbsp;&nbsp;

      </th>


      <th [attr.colspan]="listProduitDeclinesDTO.length" style="text-align: center">
        DECLINAISONS
      </th>
    </tr>
    <tr>


      <th *ngFor="let pd of listProduitDeclinesDTO" style="text-align: center">
      <span *ngIf="!pd.fabrique; else plat">
        {{pd.libelle}}
      </span>
        <ng-template #plat>
          {{pd.declinaison.libelle}}
        </ng-template>
      </th>
    </tr>
    <tr>
      <th *ngFor="let pd of listProduitDeclinesDTO" style="text-align: center;font-size: 1em;">
    <tr>
      <td width="200px"> pour 100 g</td>
      <td width="200px">{{getHeaderParUnite(pd)}}</td>
    </tr>

    <!--  </th>-->

    <!--  </tr>-->
    </thead>
    <tbody class="autoscroll">
    <tr *ngFor="let apportNutritionnel of selectedApportsNutritionnels">

      <td class="cell">
        <div class="cell">
          <label [class.danger-color]="apportNutritionnel.obligatoire"
                 [style.margin-left]="getMarginLeftStyleApportNutritionnel(apportNutritionnel)">
            {{getLibelleApportNutritionnel(apportNutritionnel)}}
          </label>
        </div>
      </td>
      <td *ngFor="let pd of listProduitDeclinesDTO" style="text-align:center;vertical-align:middle;">

    <tr>
      <td width="200px" style="padding-left: 2px;padding-right: 2px;padding-top: 2px;">
        <input type="number" min="0" size="9" [disabled]="!canModify(yoProduit)"
               step="any"
               style="width: 120px;font-size: 1em;"
               [class.danger-color]="apportNutritionnel.obligatoire"
               [ngModel]="getTeneur(pd, apportNutritionnel)"
               [pTooltip]="tooltipTeneur(pd, apportNutritionnel)"
               matTooltipShowDelay="1500"
               (ngModelChange)="setTeneur(pd, apportNutritionnel, $event)">
        <label style="font-size: 1em;width: 30px;"
               [class.danger-color]="apportNutritionnel.obligatoire">&nbsp;{{getUnite(apportNutritionnel)}}&nbsp;</label>
      </td>

      <!--</div>-->
      <!--<div>-->
      <td width="200px" style="padding-left: 2px;padding-right: 2px;padding-top: 2px">
        <input type="number" min="0" size="9" [disabled]="!canModify(yoProduit)"
               step="any"
               style="width: 120px;font-size: 1em;"
               [class.danger-color]="apportNutritionnel.obligatoire"
               [ngModel]="getQuantite(pd, apportNutritionnel)"
               [pTooltip]="tooltipQuantite(pd, apportNutritionnel)"
               showDelay="1500"
               (ngModelChange)="setQuantite(pd, apportNutritionnel, $event)">
        <label style="font-size: 1em;width: 30px;"
               [class.danger-color]="apportNutritionnel.obligatoire">&nbsp;{{getUnite(apportNutritionnel)}}&nbsp;</label>
      </td>
    </tr>

    <!--  </td>-->
    <!--  </tr>-->
    </tbody>
  </table>
</div>


<!--DIALOGUE RECHERCHER UN ALIMENT DANS LE CIQUAL-->
<yo-ciqual-dialog-edit></yo-ciqual-dialog-edit>

