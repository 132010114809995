<dx-popup
  [showTitle]="true"
  title="Supprimer les lots périmés du stock"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
  (onDisposing)="closeDialog()"
  width="auto"
>
  <div class="row">
    <div class="col-lg-12">
      <dx-radio-group [(value)]="deleteOption" valueExpr="value" displayExpr="label" [items]="deleteOptions"></dx-radio-group>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-lg-12 text-right">
      <span class="mg-r-5">
        <button type="button" pButton icon="fas fa-trash" (click)="deletePerimes()" title="Supprimer les lots périmés"
              label="Supprimer"
              class="cta-inner-delete"
              showDelay="500"></button>
      </span>
      <span class="mg-r-5">
        <button type="button" pButton icon="fas fa-times" (click)="closeDialog()" title="Annuler" label="Annuler"
              class="cta-inner-delete"
              showDelay="500"></button>
      </span>
    </div>
  </div>
</dx-popup>
