// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.4-rc-9119(5516943242)-C07/03/2023-18:26:15-B07/03/2023-18:57:08' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.4-rc-9119(5516943242)-C07/03/2023-18:26:15-B07/03/2023-18:57:08",
  branch: "master",
  latestTag: "5.4-rc",
  revCount: "9119",
  shortHash: "5516943242",
  longHash: "5516943242dc3f1f45c35d3b6ddb4e99421cb68b",
  dateCommit: "07/03/2023-18:26:15",
  dateBuild: "07/03/2023-18:57:08",
  buildType: "Ansible",
  } ;
