<div class="row">

  <!--FILTRES-->
  <div class="col-md-auto">

    <h3 class="first">Familles GEMRCN</h3>
    <p-multiSelect [options]="famillesGemrcn"
                   [(ngModel)]="selectedFamillesGemrcn"
                   optionLabel="libelle"
                   selectedItemsLabel="{0} familles GEMRCN"
                   [displaySelectedLabel]="true"
                   (onChange)="refreshGrid($event)"
                   defaultLabel="Familles GEMRCN..."
                   [panelStyle]="{minWidth:'12em'}">
      <ng-template let-famille pTemplate="item">

        <div style="display:inline;position: relative;height: 25px;">
          <yo-couleur [hexColor]="famille.couleur.hexa" class="mg-r-5"
                      style="width:24px;position:absolute;top:1px;left:5px"></yo-couleur>
          <span style="font-size:14px;margin-top:4px;margin-left:30px;">{{famille.libelle}}</span>

          <span class="floatright"><yo-site [siteLibelleTooltip]="famille.site.libelle"
                                            [siteId]="famille.site.id"></yo-site></span>
        </div>

      </ng-template>
    </p-multiSelect>


    <h3 class="first">
          <span class="mg-r-5">
          <button pButton pTooltip="Ajouter une analyse GEMRCN" showDelay="500" icon="fas fa-plus"
                  class="p-button-secondary" (click)="addAnnalyseGemrcn()"></button>
          </span>
      Analyse GEMRCN
    </h3>
    <ng-container *ngFor="let groupe of groupesGemrcn">
      <div class="mg-t-10">
           <span class="mg-r-5">
             <button class="cta-delete" icon="fas fa-xs fa-trash" pButton pTooltip="Enlever l'analyse"
                     showDelay="500" (click)="removeGroupeAnalyseGemrcn(groupe)"></button>
           </span>
        <div
          style="display:inline-block;width:200px;vertical-align: top;">{{mnGroupeGemrcnSvc.getGroupeLibelle(groupe)}}
          <span
            showDelay="500"
            pTooltip="Fréquence repas = Nb Repas * Nb Semaines * Nb Jours Semaine">({{mnGroupeGemrcnSvc.getFrequenceRepas(groupe, nbSemaines, nbJoursSemaine)}}
            repas)</span></div>
      </div>
    </ng-container>

  </div>


  <!--GRILLE-->
  <div class="col">

    <ng-container *ngIf="!isGridDisplayable()">

      <div class="row">
        <div class="col-md-auto">
          <em class="font-12 genlabel-color">
            <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i> Aucune grille GEMRCN paramétrée.
            Au préalable, vérifiez que vous avez créer un planning.
          </em>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="isGridDisplayable()">
      <p-table
        [scrollHeight]="utils.scrollHeightContent(150)"
        styleClass="p-datatable-sm"
        [scrollable]="true"
        [value]="selectedFamillesGemrcn"
      >


        <!--HEADER-->
        <ng-template pTemplate="header">

          <tr>
            <th [style.width.px]="300" rowspan="2">Famille GEMRCN</th>
            <ng-container *ngFor="let groupeAnalyseGemrcn of groupesGemrcn">
              <th colspan="2">
                    <span>{{mnGroupeGemrcnSvc.getGroupeLibelle(groupeAnalyseGemrcn)}}
                      ({{mnGroupeGemrcnSvc.getFrequenceRepas(groupeAnalyseGemrcn, nbSemaines, nbJoursSemaine)}}
                      repas)</span>
                <span class="floatright">
                      <span class="mg-r-5">
                     <p-selectButton [options]="optionsChoixCalcul"
                                     showDelay="500"
                                     (ngModelChange)="changeGroupeGemrcnChoix($event,groupeAnalyseGemrcn)"
                                     [ngModel]="groupeAnalyseGemrcn.choix"></p-selectButton>
                      </span>


                    </span>
              </th>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let groupeAnalyseGemrcn of groupesGemrcn">
              <th>Règle recommandée</th>
              <th>{{mnGroupeGemrcnSvc.getTooltipChoixGermcn(groupeAnalyseGemrcn.choix)}}</th>
            </ng-container>

          </tr>

        </ng-template>


        <!--BODY-->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">

          <tr>

            <td [style.width.px]="300">
              <div class="no-overflow" [pTooltip]="rowData.libelle" showDelay="500">
                <yo-couleur [hexColor]="rowData.couleur.hexa"></yo-couleur>
                {{rowData.libelle}}
              </div>
            </td>


            <ng-container *ngFor="let groupeGemrcn of groupesGemrcn">
              <td>
                <!--FREQUENCES-->
                <div class="no-overflow">
                  <ng-container
                    *ngTemplateOutlet="tplFrequence;context:{
                        famille:rowData,
                        frequenceRepas:mnGroupeGemrcnSvc.getFrequenceRepas(groupeGemrcn,nbSemaines,nbJoursSemaine),
                        regle:getRegleGemrcn(rowData.id,groupeGemrcn.id),
                        groupeGemrcn : groupeGemrcn
                        }"></ng-container>
                </div>
              </td>

              <!--      CONTROLE GEMRCN-->
              <td>
                <ng-container
                  *ngTemplateOutlet="tplControleGemrcn;context:{
                        frequenceRepas:mnGroupeGemrcnSvc.getFrequenceRepas(groupeGemrcn,nbSemaines,nbJoursSemaine),
                        controle:getControleGemrcn(rowData.id,groupeGemrcn.id)
                        }"></ng-container>
              </td>
            </ng-container>


          </tr>

        </ng-template>

      </p-table>

    </ng-container>

  </div>

</div>


<!--TEMPLATE FREQUENCE REPAS-->
<ng-template #tplFrequence let-famille="famille" let-frequenceRepas="frequenceRepas" let-regle="regle"
             let-groupeGemrcn="groupeGemrcn">

  <ng-container *ngIf="regle">
    <div class="row">
      <div class="col-sm-auto cursor">

        <button pButton
                [label]="ggSvc.labelRegleGemrcn(regle,frequenceRepas)"
                (click)="openRegle($event,regle,famille,groupeGemrcn)"
                class="p-button-secondary"></button>
      </div>
    </div>
  </ng-container>

</ng-template>

<!--TEMPLATE CONTROLE GEMRCN (en choix dirigé ou multiple)-->
<ng-template #tplControleGemrcn let-frequenceRepas="frequenceRepas" let-controle="controle">

  <ng-container *ngIf="controle">

    <div class="row">
      <div class="col-md-12 text-right">
        <ng-container *ngIf="controle.modeleNutritionnelGroupeGemrcn.choix === CHOIX_GEMRCN.DIRIGE">
          {{controle.countChoixDirige  }} / {{controle.baseDenominator}}
          <yo-check [checked]="controle.choixDirigeOk"></yo-check>
        </ng-container>

        <ng-container *ngIf="controle.modeleNutritionnelGroupeGemrcn.choix === CHOIX_GEMRCN.MULTIPLE">
          {{controle.countChoixMultiple  }} / {{controle.baseDenominator}}
          <yo-check [checked]="controle.choixMultipleOk"></yo-check>
        </ng-container>
      </div>
    </div>


  </ng-container>

</ng-template>


<!--DIALOG DE CREATION / MODIFICATION DE REGLE GEMRCN-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'400px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayRegle">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <yo-couleur [hexColor]="selectedFamilleCouleurHexa"></yo-couleur>
      {{dialogTitleRegle}}
    </div>
  </ng-template>

  <ng-container *ngIf="selectedRegle">


    <!--CONTENT-->
    <div style="padding: 8px">

      <select style="display: inline;width:100px;" class="mg-r-10" [(ngModel)]="selectedRegle.operator">
        <option [ngValue]="'='">égal à</option>
        <option [ngValue]="'>'">minimum</option>
        <option [ngValue]="'<'">maximum</option>
        <option [ngValue]="'l'">libre</option>
      </select>

      <div style="display: inline-block;width:200px;">
        <ng-container *ngIf="selectedRegle.operator !='l'">
          <input type="number" [(ngModel)]="selectedRegle.numerator" pKeyFilter="pint"
                 style="width:40px;display: inline;" class="mg-r-5">
          <ng-container *ngIf="selectedRegle.numerator<=1">plat</ng-container>
          <ng-container *ngIf="selectedRegle.numerator>1">plats</ng-container>
          pour {{mnGroupeGemrcnSvc.getFrequenceRepas(selectedRegle.modeleNutritionnelGroupeGemrcn, nbSemaines, nbJoursSemaine)}}
          repas
        </ng-container>
      </div>


    </div>
  </ng-container>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5" (click)="updateRegle()"></button>
        <button pButton icon="fas fa-times" (click)="displayRegle=false" class="cta-delete"></button>
      </div>
    </div>

  </ng-template>


</p-dialog>

<!--dialog pour ajouter une combinaison de repas à faire en analyse GEMRCN-->
<p-dialog [modal]="true"
          [dismissableMask]="false"
          (onHide)="closeDialogAddAnalyseGemrcn()"
          [style]="{'width':'400px'}"
          positionTop="75"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="displayAddAnalyseGEMRCN">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Paramétrage de l'analyse GEMRCN
    </div>
  </ng-template>

  <ng-container *ngIf="!this.utils.isCollectionNullOrEmpty(repasOfModeleNutritionnelList)">
    <h3 class="first">Sélection des repas</h3>
    <div class="p-g" style="width:250px;margin-bottom:10px">

      <div class="p-g-12" *ngFor="let repas of repasOfModeleNutritionnelList">
        <p-checkbox name="group1" [value]="repas" [label]="repas.libelle"
                    [(ngModel)]="selectedRepasOfModeleNutritionnelList"></p-checkbox>
      </div>
    </div>

    <div class="p-g" style="width:250px;margin-bottom:10px"
         *ngIf="!utils.isCollectionNullOrEmpty(selectedRepasOfModeleNutritionnelList)" showDelay="500"
         pTooltip="Fréquence repas = Nb Repas * Nb Semaines * Nb Jours Semaine">
      Fréquence : {{selectedRepasOfModeleNutritionnelList?.length * nbSemaines * nbJoursSemaine}} repas
    </div>

  </ng-container>

  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <button pButton icon="fas fa-check" class="mg-r-5"
                (click)="saveGroupeGEMRCN()"></button>
        <button pButton icon="fas fa-times" (click)="closeDialogAddAnalyseGemrcn()"
                class="cta-delete"></button>
      </div>
    </div>

  </ng-template>

</p-dialog>
