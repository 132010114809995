<p-dialog
  [dismissableMask]="false"

  [showHeader]="true"
  (onHide)="closeDialog()"
  positionTop="300"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'width': '20vw'}"
  minY=400
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="far fa-file-pdf mg-r-5"></i> <span>Impression de l'inventaire</span>
    </div>
  </ng-template>
  <!--  CONTENT-->
  <ng-container>
    <label>Options d'impression :</label>
    <div class="d-flex justify-content-center">
      <div class="d-flex flex-column ">
        <p-checkbox name="group1" [value]="printOption.detailsByLot" label="Détail des lots"
                    [(ngModel)]="printOptions"></p-checkbox>
        <p-checkbox name="group1" [value]="printOption.detailsAccounting" label="Détail comptable"
                    [(ngModel)]="printOptions"></p-checkbox>
        <p-checkbox name="group1" [value]="printOption.showQuantite" label="Afficher la quantité"
                    [(ngModel)]="printOptions"></p-checkbox>
        <p-checkbox name="group1" [value]="printOption.hideEmpty" label="Exclure les éléments vides"
                    [(ngModel)]="printOptions"></p-checkbox>
      </div>
    </div>
  </ng-container>

  <ng-template pTemplate="footer">
    <span class="mg-r-5">
      <button type="button" pButton
              icon="fas fa-print"
              (click)="printInventaire()"
              title="Imprimer l'inventaire"
              label="Imprimer"></button>
    </span>
    <span class="mg-r-5">
      <button type="button" class="cta-inner-delete" pButton icon="fas fa-times" (click)="closeDialog()"></button>
    </span>


  </ng-template>

</p-dialog>
