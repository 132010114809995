<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  (onHide)="closeDialog()"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'height':'70vh', 'width':'90vw'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-lg fa-link mg-r-5"></i>Associer des articles à une denrée
    </div>
  </ng-template>

  <h5>
    <label class="badge badge-secondary mg-b-10">
      {{titleStep}}
    </label>
  </h5>

  <!--  DENREE SELECTION-->
  <div *ngIf="displayProduitDecliSelection" class="d-flex flex-row">
    <p-card [style]="{'margin-right': '30px'}">
      <div class="size-card-filter">

        <h3 class="first">Produit</h3>
        <input type="text"
               pInputText
               class="width-250"
               (input)="dt.filter($event.target.value,'produitsDeclinaison', 'contains')"/>

        <h3 class="first" pTooltip="Reference du produit" showDelay="500">Référence</h3>
        <input type="text"
               pInputText
               class="width-250"
               (input)="dt.filter($event.target.value,'reference', 'contains')"/>

        <h3 class="first" pTooltip="Code du produit" showDelay="500">Code</h3>
        <input type="text"
               pInputText
               class="width-250"
               (input)="dt.filter($event.target.value,'code', 'contains')"/>

      </div>
    </p-card>

    <p-table [columns]="colProduitDeclinaison"
             [value]="produitDeclinaisonList"
             selectionMode="single"
             [(selection)]="selectedProduitDeclinaison"
             [scrollable]="trueBoolean"
             [filterDelay]="1000"
             [rows]="20"
             [rowsPerPageOptions]="[5,10,15,20,50,100,200,500,1000,5000,10000]"
             [paginator]="trueBoolean"
             [scrollHeight]="utils.scrollHeightContent(550)"
             styleClass="p-datatable-sm"
             (onLazyLoad)="loadDataDenree($event)"
             [lazy]="trueBoolean"
             [totalRecords]="totalRecords"
             [loading]="loading"
             dataKey="id"
             #dt>

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
            <col *ngSwitchCase="'reference'" [style.width]="'90px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'libelle'" [style.width]="'250px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'code'" [style.width]="'100px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'actif'" [style.width]="'50px'" [style.text-align]="'left'">
          </ng-container>
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" style="height:34px">

          <ng-container *ngFor="let col of columns">

            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'reference'">
                <td style="text-align: left;">
                  {{rowData.reference}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'libelle'">
                <td style="text-align: left;">
                  {{rowData.libelle}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'code'">
                <td style="text-align: left;">
                  {{rowData.code}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'actif'">
                <td style="text-align: left;">
                  <p-checkbox
                    [disabled]="true"
                    [(ngModel)]="rowData.actif"
                    binary="true">
                  </p-checkbox>
                </td>
              </ng-container>

            </ng-container>
          </ng-container>

        </tr>
      </ng-template>

      <ng-template pTemplate="summary" let-rowData let-columns="columns">
        <tr style="height:34px">
          <td style="text-align: left;">
            {{utils.getTotalRecordsLabel('article', totalRecords)}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--  RESUME-->
  <div *ngIf="!displayProduitDecliSelection">

    <div class="d-flex flex-row align-items-center mg-b-10">
      <strong class="title mg-r-5">Site : </strong><label></label>
      <p-dropdown [options]="siteList"
                  placeholder="selection ..."
                  pTooltip="Site auquel seront associés les articles sélectionnés"
                  showDelay="500"
                  optionLabel="libelle"
                  filter="true"
                  (onChange)="onChangeSiteSelection($event)"
                  [(ngModel)]="selectedSite"></p-dropdown>
    </div>


    <div class="d-flex flex-row mg-t-10">
      <div class="mg-r-15"><strong class="title">Déclinaison : </strong>{{selectedProduitDeclinaison.libelle}}</div>
      <div class="mg-r-15"><strong>reference : </strong> {{selectedProduitDeclinaison.reference}}</div>
      <div class="mg-r-15"><strong>code : </strong> {{selectedProduitDeclinaison.code}}</div>
    </div>

    <div class="d-flex flex-row mg-t-10">
      <div class="mg-r-15">
        <strong class="title">Article : </strong>
        <input pInputText type="text" class="input-size-600" [readOnly]="isReadyOnlyProduitArticle"
               [(ngModel)]="selectedProduitArticle.libelle"/>
      </div>
      <div class="mg-r-15 mg-r-5">
        <strong>reference : </strong>
        <input pInputText type="text" class="input-size-400" [readOnly]="isReadyOnlyProduitArticle"
               [(ngModel)]="selectedProduitArticle.reference"/>
        <button
          class="mg-r-5"
          type="button"
          pButton
          [disabled]="selectedSite === undefined || isReadyOnlyProduitArticle"
          icon="fas fa-sync-alt"
          (click)="getUnicityProduitArticleReference(selectedSite)">
        </button>

      </div>
    </div>


    <div class="mg-t-30 mg-b-10">
      <p-table [columns]="colsArticle"
               [value]="articles"
               [scrollable]="trueBoolean"
               [filterDelay]="1000"
               [scrollHeight]="utils.scrollHeightContent(485)"
               styleClass="p-datatable-sm"
               dataKey="id"
               #dt>

        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
              <col *ngSwitchCase="'action'" [style.width]="'30px'" [style.text-align]="'left'">
              <col *ngSwitchCase="'codeApi'" [style.width]="'100px'" [style.text-align]="'left'">
              <col *ngSwitchCase="'libelle'" [style.width]="'130px'" [style.text-align]="'left'">
              <col *ngSwitchCase="'referenceArticle'" [style.width]="'130px'" [style.text-align]="'left'">

              <col *ngSwitchCase="'filialeLibelle'" [style.width]="'110px'" [style.text-align]="'left'">
              <col *ngSwitchCase="'prix'" [style.width]="'80px'" [style.text-align]="'right'">
            </ng-container>
          </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <!--BODY-->
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr style="height:34px">

            <ng-container *ngFor="let col of columns">

              <ng-container [ngSwitch]="col.field">

                <ng-container *ngSwitchCase="'action'">
                  <td style="text-align: center;">
                    <button
                      pButton
                      icon="fas fa-minus"
                      class="mg-r-5 cta-delete"
                      tooltipPosition="top"
                      pTooltip="Supprimer l'article"
                      (click)="deleteArticle(rowData)">
                    </button>
                  </td>
                </ng-container>


                <ng-container *ngSwitchCase="'codeApi'">
                  <td style="text-align: left;">
                    {{rowData.reference}}
                  </td>
                </ng-container>

                <ng-container *ngSwitchCase="'libelle'">
                  <td style="text-align: left;">
                    {{rowData.libelle}}
                  </td>
                </ng-container>

                <ng-container *ngSwitchCase="'referenceArticle'">
                  <td style="text-align: left;">
                    {{rowData.referenceArticle}}
                  </td>
                </ng-container>



                <ng-container *ngSwitchCase="'filialeLibelle'">
                  <td style="text-align: left;">
                    {{rowData.filialeLibelle}}
                  </td>
                </ng-container>


                <ng-container *ngSwitchCase="'prix'">
                  <td style="text-align: left;">
                    {{rowData.prix}}
                  </td>
                </ng-container>

              </ng-container>

            </ng-container>

          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>


  <div class="d-flex justify-content-end">

    <button
      class="mg-r-5"
      type="button"
      pButton
      label="Précédent"
      [disabled]="activeStep === StepEnum.produitDeclinaisonSelection"
      icon="fas fa-backward"
      (click)="onChangeStep(StepEnum.produitDeclinaisonSelection)">
    </button>

    <button
      class="mg-r-5"
      type="button"
      pButton label="Suivant"
      [disabled]="selectedProduitDeclinaison === undefined || activeStep === StepEnum.siteSeletion"
      icon="fas fa-forward"
      iconPos="right"
      (click)="onChangeStep(StepEnum.siteSeletion)">
    </button>

    <!--    *ngIf="displayAssociatedButton"-->
    <button
      class="mg-r-5 p-button-success"
      type="button"
      pButton
      label="Associer"
      [disabled]="isDisabledAssociatedBtn()"
      icon="fas fa-link"
      (click)="affectArticleNonAffectList()">
    </button>

  </div>
</p-dialog>


<!--SELECTION PRODUIT  ARTICLE-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  position="top"
  appendTo="body"
  [contentStyle]="{'overflow':'visible'}"
  [closeOnEscape]="false"
  [style]="{'height':'70vh', 'width':'60vw'}"
  [(visible)]="displayDialogProduitArticle">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-lg fa-link mg-r-5"></i>Associer à un article existant
    </div>
  </ng-template>

  <h5>
    <label class="badge badge-secondary mg-b-10">
      Sélectionner un article
    </label>
  </h5>


  <p-table [columns]="colsProduitArticle"
           [value]="produitArticleList"
           selectionMode="single"
           [(selection)]="selectedProduitArticle"
           [scrollable]="trueBoolean"
           [filterDelay]="1000"
           [scrollHeight]="utils.scrollHeightContent(485)"
           styleClass="p-datatable-sm"
           dataKey="id"
           #dt>

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
          <col *ngSwitchCase="'siteLibelle'" [style.width]="'80px'" [style.text-align]="'left'">
          <col *ngSwitchCase="'libelle'" [style.width]="'150px'" [style.text-align]="'left'">
          <col *ngSwitchCase="'reference'" [style.width]="'50px'" [style.text-align]="'left'">
          <col *ngSwitchCase="'marque'" [style.width]="'100px'" [style.text-align]="'left'">
          >
        </ng-container>
      </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" style="height:34px">

        <ng-container *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">

            <ng-container *ngSwitchCase="'siteLibelle'">
              <td style="text-align: left;">
                {{rowData.site.libelle}}
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'libelle'">
              <td style="text-align: left;">
                {{rowData.libelle}}
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'reference'">
              <td style="text-align: left;">
                {{rowData.reference}}
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="'marque'">
              <td style="text-align: left;">
                {{rowData.marqueCommerciale.libelle}}
              </td>
            </ng-container>


          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <div class="d-flex justify-content-end mg-t-10">

    <button
      class="mg-r-5 cta-delete"
      type="button"
      pButton
      label="Annuler"
      icon="fas fa-times"
      (click)="closeManualyDialogProduitArticle()">
    </button>

    <button
      class="mg-r-5 "
      type="button"
      pButton
      label="Créer"
      icon="fas fa-plus"
      (click)="createProduitArticle()">
    </button>


    <button
      class="mg-r-5 p-button-success"
      type="button"
      pButton
      label="Associer"
      [disabled]="selectedProduitArticle.id === 0"
      icon="fas fa-link"
      (click)="affectProduitArticle()">
    </button>
  </div>
  {{selectedProduitArticle}}
</p-dialog>
