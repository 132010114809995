<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>


      <button pButton
              class="mg-r-15"
              label="AJOUTER"
              pTooltip="Ajouter des conditionnements variante"
              icon="fa fa-plus"
              (click)="openDialogAjoutCondiVariantes()">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="udpConditionnementVariantesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="conditionnementVariante" caption="Conditionnement variante" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="conditionnementVarianteCellTemplate">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">

    <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoDisabled]="isDisabledDeleteBtn(cell.row.data)"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-trash'"
                    [pTooltip]="'Supprimer le conditionnemetn variant de la liste de l \'unté de production'"
                    tooltipPosition="right"
                    showDelay="500"
    ></yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'conditionnementVarianteCellTemplate'">
    {{cell.row.data.conditionnementVariante.conditionnement.libelle}} {{cell.row.data.conditionnementVariante.variante.libelle}}
  </div>
</dx-data-grid>

<yo-dialog-ajout-conditionnement></yo-dialog-ajout-conditionnement>
