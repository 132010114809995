import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs";
import {catchError} from "rxjs/operators";
import {UtilsService} from "../../utils/utils.service";
import {PrintCoutRevientPlatDTO} from "../../dtos/clients/print-cout-revient-plat-dto";
import {HttpService} from "../technique/http.service";
import {TYPE_EFFECTIF} from "../../constants";


export const URL_GET_PLC_TREE_NODE= `dolrest/gestion-clients/plc-tree-node`;
export const URL_PRINT_COUT_REVIENT_PLAT= `dolrest/gestion-clients/print-cout-revient-plat`;

@Injectable()
export class PrintCoutRevientPlatService {

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils : UtilsService){}

  private subjectDisplayDialogPrintCoutRevientPlat = new Subject();
  public displayDialogPrintCoutRevientPlat$ = this.subjectDisplayDialogPrintCoutRevientPlat.asObservable();

  public announceDisplayDialogPrintCoutRevientPlat = (displayDialog:boolean) => {
    this.subjectDisplayDialogPrintCoutRevientPlat.next(displayDialog);
  };

  public getPlcTreeNode = () => this.httpSvc.get(URL_GET_PLC_TREE_NODE);

  print = (printCoutRevientPlat : PrintCoutRevientPlatDTO) => {
    let url = `${URL_PRINT_COUT_REVIENT_PLAT}?mainReferenceBilling=${printCoutRevientPlat.typeEffectif === TYPE_EFFECTIF.facturation}`;
    if (printCoutRevientPlat.pointsLivraisonClients)
      url += `&idsPointsLivraisonClient=${printCoutRevientPlat.pointsLivraisonClients}`;
    if (printCoutRevientPlat.unitesProduction)
      url += `&idsUnitesProduction=${printCoutRevientPlat.unitesProduction}`;
    if (printCoutRevientPlat.dateProductionSelected)
      url += `&dateConsumption=${this.utils.convertYYYYMMdd(printCoutRevientPlat.dateProductionSelected.getTime())}`;
    if (printCoutRevientPlat.periode && printCoutRevientPlat.periode.length === 2)
      url += `&datePeriodStart=${this.utils.convertYYYYMMdd(printCoutRevientPlat.periode[0].getTime())}&datePeriodEnd=${this.utils.convertYYYYMMdd(printCoutRevientPlat.periode[1].getTime())}`;

    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/pdf')
    }).pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  buildTreeNodePlc = (nodes: any[]): any[] => {
    if (!nodes) return null;
    const treeFilters: any[] = [];
    nodes.forEach(nodeUdp => {
      let udp = ({ id: `${nodeUdp.id}_udp`, label: nodeUdp.label, icon: 'fa-industry fas', expanded: true, items: [] }) as any;
      let idxOffreAlim: number = 1; let idxCmc: number = 1; let idxRepas: number = 1; let idxPlc: number = 1; // DevExtreme ne veut pas de doublons d'identifiants
      nodeUdp.children.forEach(nodeOffreAlim => {
        let offreAlim = ({ id: `${nodeOffreAlim.id}_${idxOffreAlim++}_oa`, label: nodeOffreAlim.label, icon: 'fa-handshake fas', expanded: true, items: [] }) as any;
        udp.items.push(offreAlim);
        nodeOffreAlim.children.forEach(nodeCmc => {
          let cmc = ({ id: `${nodeCmc.id}_${idxCmc++}_cmc`, label: nodeCmc.label, icon: 'fa-users fas', expanded: false, items: [] }) as any;
          offreAlim.items.push(cmc);
          nodeCmc.children.forEach(nodeRepas => {
            let repas = ({ id: `${nodeCmc.id}-${nodeRepas.id}_${idxRepas++}_repas`, label: nodeRepas.label, icon: 'fa-hamburger fas', expanded: false, items: [] }) as any;
            cmc.items.push(repas);
            nodeRepas.children.forEach(nodePlc => {
              if (nodePlc) repas.items.push({
                id: `${nodeCmc.id}-${nodeRepas.id}-${nodePlc.id}_${idxPlc++}_plc-${nodeUdp.id}`,
                label: nodePlc.label,
                icon: 'fa-map-pin fas',
                expanded: false,
              });
            });
          });
        });
      });
      treeFilters.push(udp);
    });
    return treeFilters;
  }

}
