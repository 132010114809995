<div class="card ">
  <div class="card-header">

    <i class="fas fa-calendar-check mg-r-5"></i>{{srs?.workflowInstance?.libelle | uppercase}} (Site : {{srs?.workflowInstance?.site.libelle | uppercase}}), SÉLECTION DES
    MENUS
    <span class="floatright">
      <span class="mg-r-20">
      <button pButton
              class="p-button-success"
              type="submit"
              tabindex="-1"
              (click)="saveSelectionRepas()"
              pTooltip="Enregister les modifications"
              icon="fas fa-save"
              [disabled]="!workflowSvc.canModifyCurrentStep(srs.workflowInstance)">
      </button>
      </span>

      <!--PASSAGE À L'ETAPE SUIVANTE DU WORKFLOW-->
      <yo-button-next-steps [workflowInstance]="srs?.workflowInstance"
                            [play]="true"
                            (onError)="updateErrors($event)"
                            [runInput]="getRunInput()"></yo-button-next-steps>
    </span>
  </div>
  <div class="card-body" style="overflow: auto;max-height: 95vh;">
    <div class="row align-items-start no-gutters ">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">

            <p-checkbox class="mg-r-5" [(ngModel)]="expandTree" (onChange)="expandAll(expandTree)"
                        label="Déplier"
                        binary="true"></p-checkbox>
            <p-checkbox class="mg-r-40" [(ngModel)]="selectTree" (onChange)="selectAll()" label="Sélectionner"
                        binary="true"></p-checkbox>

            <p-calendar
              firstDayOfWeek="1"
              class="mg-r-40"
              dateFormat="dd/mm/yy"
              pTooltip="Sélection d'une période"
              tooltipPosition="bottom"
              showDelay="500"
              selectionMode="range"
              [readonlyInput]="true"
              [(ngModel)]="selectedDates"
              (onSelect)="updateTableDefinition(selectedNodes,selectedDates,false)"
              (onFocus)="getMonthDates($event,selectedNodes)"
              (onMonthChange)="getMonthDates($event,selectedNodes)"
              [disabledDays]="disabledDays"
              [locale]="localeFr"
              [showIcon]="true"
              [showWeek]="true">
              <ng-template pTemplate="date" let-date>
                <yo-date-dispo [date]="date" [dateCreationMenu]="isRepasDate(date,repasDates)"></yo-date-dispo>
              </ng-template>
            </p-calendar>
            <p-checkbox [(ngModel)]="selectAllPeriod"
                        (onChange)="changeSelectedPeriod($event,selectedRepas,dateCols)"
                        label="Sélectionner toute la période"
                        binary="true">
            </p-checkbox>

          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-auto">
                <div>
                  <p-tree [value]="srs.tree"
                          (selectionChange)="onChangeSelectionRepas($event)"
                          selectionMode="checkbox" [(selection)]="selectedNodes">
                  </p-tree>
                </div>
                <div>
                  <!--AIDE-->
                  <ng-container *ngTemplateOutlet="tplAide"></ng-container>
                </div>
              </div>
              <div class="col">
                <div style="max-width:65vw;overflow: auto">

                  <p-table [value]="selectedRepas"
                           [scrollable]="true"
                           scrollHeight="600px"
                           styleClass="p-datatable-sm"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="col-250">MENUS</th>
                        <ng-container *ngFor="let col of dateCols">
                          <th class="col-150">
                            <p-checkbox [(ngModel)]="col.colActive"
                                        (onChange)="activeCol($event,selectedRepas,dateCols,col)"
                                        pTooltip="Activer tous les repas du jour"
                                        showDelay="500"
                                        binary="true"></p-checkbox>
                            {{col.date | date:'E d LLL yyyy' | titlecase}}
                          </th>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr>
                        <td class="col-250">
                          <p-checkbox [(ngModel)]="rowData.data['rowActive']"
                                      (onChange)="activeRow($event,selectedRepas,dateCols,rowData)"
                                      pTooltip="Activer tous les jours du repas"
                                      showDelay="500"
                                      binary="true"></p-checkbox>
                          {{rowData.data['rowLabel']}}
                        </td>
                        <ng-container *ngFor="let col of dateCols">


                          <ng-container *ngTemplateOutlet="tplCheckedDay; context:{
                      col : col,
                      row : rowData,
                      jourOk: selectionRepasSvc.isJourRepasOkForContrat(col,rowData)
                    }">
                          </ng-container>

                        </ng-container>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <ng-container *ngTemplateOutlet="tplErreurs;context:{erreurs:srs.erreurs}"></ng-container>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--TEMPLATE ERREURS -->
<ng-template #tplErreurs let-erreurs="erreurs">

  <ng-container *ngIf="!utils.isCollectionNullOrEmpty(erreurs)">

    <div class="card mg-t-10" >
      <div class="card-header">
        <strong ><i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>ATTENTION, vous ne pouvez pas passer à l'étape suivante tant que les erreurs ne sont pas résolues.</strong>
      </div>
      <div class="card-body" >

        <ng-container *ngFor="let erreur of erreurs; let i=index;">
          <div>

          <span class="font-12">
            <i class="fas fa-times danger-color mg-r-5"></i>
            <strong>{{erreur.message}}</strong><br/>
            <em>{{erreur.detail}}</em>
          </span>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>

</ng-template>

<!--TEMPLATE AIDE -->
<ng-template #tplAide>

  <div class="card mg-t-10">
    <div class="card-header"><i class="fas fa-info-circle mg-r-5"></i>AIDE</div>
    <div class="card-body">
      <div class="font-12">
        <div>Niveau 1 = Unités de Production</div>
        <div>Niveau 2 = Offres alimentaires</div>
        <div>Niveau 3 = Prestations</div>
        <div>Niveau 4 = Repas</div>
      </div>
    </div>
  </div>

</ng-template>

<!--TEMPLATE DATE CALENDRIER-->
<ng-template #tplDateCalendrier let-date="date" let-isRepasDate="isRepasDate">

  <ng-container *ngIf="isRepasDate">
    <div class="creation-menus-day" pTooltip="Il existe des repas sur ce jour" showDelay="500">{{date.day}}</div>
  </ng-container>

  <ng-container *ngIf="!isRepasDate">
    <div>{{date.day}}</div>
  </ng-container>

</ng-template>

<!--TEMPLATE CELLULE -->
<ng-template #tplCheckedDay let-jourOk="jourOk" let-row="row" let-col="col">

  <ng-container *ngIf="!jourOk">
    <td class="col-150">
    <span class="font-12 cursor"
          pTooltip="'{{col.date | date:'E d LLL yyyy' | titlecase}}' non paramétré pour '{{row.data['rowLabel']}}'"
          showDelay="500">
      <i class="fas fa-ban danger-color mg-r-5"></i> Jour non paramétré.
    </span>
    </td>
  </ng-container>

  <ng-container *ngIf="jourOk">
    <ng-container
      *ngTemplateOutlet="tplJourOk;context:{
      isJourActif:isJourActif(selectionRepasMap,row,col),
      row:row,
      col:col,
      selectionRepasMap:selectionRepasMap
      }"></ng-container>
  </ng-container>

</ng-template>


<!--TEMPLATE CELLULE JOUR OK -->
<ng-template #tplJourOk let-isJourActif="isJourActif" let-row="row" let-col="col"
             let-selectionRepasMap="selectionRepasMap">
  <td
    (click)="setJourActif(selectionRepasMap,row,col)"
    class="col-150 cursor text-center"
    [escape]="false"
    pTooltip="{{isJourActif  ? 'ACTIF':'INACTIF'}} <br/> {{utils.getFrenchDate_ddd_ll(col.date)}} <br/> {{row.data['rowLabel']| uppercase}} "
    showDelay="500"
    [class.cellChecked]="isJourActif">


    <ng-container *ngIf="isJourActif">
      <i class="fa fa-check white"></i>
    </ng-container>

    <ng-container *ngIf="!isJourActif">
      <i class="fas fa-times  danger-color"></i>
    </ng-container>


  </td>
</ng-template>
