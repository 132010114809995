<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="openTypeUtilisateur(undefined)"
          [disabled]="!canCreateAndDelete()"
          pTooltip="Créer un type d'utilisateur">
        </button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <button
        pButton
        type="button"
        icon="fas fa-trash"
        class="cta-delete"
        (click)="deleteValues()"
        [disabled]="!canCreateAndDelete() || !selectedRows.length"
        pTooltip="Supprimer les éléments sélectionnés">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="typesUtilisateurList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="templateSite">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <yo-cell-button (yoNavigation)="openTypeUtilisateur(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoViewMode]="!canModify(cell.row.data)"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canModify(cell.row.data)?'Modifier le type d\'utilisateur':'Voir le type d\'utilisateur'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site [siteLibelle]="cell.row.data.site?.libelle"
             [siteId]="cell.row.data.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} type(s) d'utilisateur">
  </dxo-pager>

</dx-data-grid>

<yo-pms-type-utilisateur-dialog></yo-pms-type-utilisateur-dialog>


