import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Router} from '@angular/router';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {CatalogueAchatDTO} from '../../dtos/catalogue-achat-dto';
import {ProduitDTO} from '../../dtos/produit-dto';
import {sortBy as _sortBy, startCase as _startCase} from 'lodash'
import {ViewModelCatalogueAchat} from '../../../gestion-produits/produit/article-produit/catalogueachat-viewmodel';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldCurrencySupplier} from '../../suppliers/form-field-currency-supplier';
import {ProduitArticleDTO} from '../../dtos/produit-article-dto';
import {UnitedemesureDTO} from '../../dtos/unitedemesure-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {ObjectDTO} from '../../dtos/object-dto';
import {ImportRequestDTO} from '../../dtos/importexport/import-request-dto';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {FormFieldTableSupplier} from '../../suppliers/form-field-table-supplier';
import {AppellationDTO} from '../../dtos/appellations-dto';
import {AllergeneDTO} from '../../dtos/allergene-dto';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {ArticleSupplier} from '../../../gestion-fournisseurs/articles/catalogue-achat/catalogue-achat-resolver.service';
import {FournisseurDTO} from '../../dtos/fournisseur-d-t-o';
import {HttpService} from "../technique/http.service";


export const URL_SAVE_CATALOGUEACHAT = `dolrest/gestionarticles/produit/articles/saveOne`;
export const URL_DYNAMIC_SEARCH_CATALOGUEACHAT = `dolrest/gestionarticles/produit/catalogueachats/dynamic-search`;

export const URL_SAVE_CATALOGUEACHAT_LIST = `dolrest/gestionarticles/produit/articles/saveList`;
export const URL_DELETE_CATALOGUEACHAT = `dolrest/gestionarticles/produit/catalogueachats/deleteOne`;
export const URL_GET_FOURNISSEURS = `dolrest/gestionproduits/produit/articles/fournisseursbyudpid`;
export const URL_IMPORT_XSLX_PRIX_ACHATS = `dolrest/gestionarticles/import/xlsx/updatePrixAchats`;
export const URL_GET_PRODUIT_ARTICLE_APPELLATIONS = `dolrest/gestionarticles/produit/article/appellations`;
export const URL_GET_PRODUIT_ARTICLE_ALLERGENES = `dolrest/gestionarticles/produit/article/allergenes`;
export const URL_POST_SAVE_PRODUIT_ARTICLE_APPELLATIONS = `dolrest/gestionarticles/produit/article/appellations/save`;
export const URL_POST_SAVE_PRODUIT_ARTICLE_ALLERGENES = `dolrest/gestionarticles/produit/article/allergenes/save`;
export const URL_POST_ARTICLES_MAJ_ARTICLES = `dolrest/gestionarticles/get-catalogue-achat-by-id-udp-maj-articles`;
export const URL_POST_EXPORT_ARTICLES_TO_EXCEL_FILE = `dolrest/gestionarticles/export-excel-catalogue-achat-by-filters`;
export const URL_GET_ARTICLES_BY_ID_PD_ID_FOURNI_ID_UDP = `dolrest/gestionarticles/stocks/by-id-udp-id-pd-id-fournisseur`;
export const URL_GET_ARTICLE_PREFERE_BY_PRODUIT_DECLINAISON_AND_UDP = `dolrest/gestionarticles/produit/article/prefere/`;
export const URL_POST_UPDATE_PRIX_GACA = `dolrest/gestionarticles/update-prix-uf`;
export const URL_POST_REFRESH_ARTICLES_PREFERED = `dolrest/gestionarticles/produit/articles/refresh-article-prefere/`;


export enum FK_CatalogueAchat {
  produitArticleLibelle = 'produitArticle.libelle',
  referenceArticle = 'referenceArticle',
  prix = 'prix',
  uniteDeComandeLibelle = 'uniteDeCommande.libelle',
  codeApi = 'codeApi',
  fournisseurLibelle = 'fournisseur.libelle',
  denreeLibelle = 'denreeLibelle',
  familleProduit = 'libelleFamilleProduit'
}


@Injectable()
export class CataloguesAchatsService extends GenericHandler<CatalogueAchatDTO> {

  private subjectOpenDialogArticle = new Subject<CatalogueAchatDialogSupplier>();
  openDialogArticle$ = this.subjectOpenDialogArticle.asObservable();

  private subjectCloseDialogArticle = new Subject<CatalogueAchatDialogSupplier>();
  closeDialogArticle$ = this.subjectCloseDialogArticle.asObservable();

  private subjectArticleInfos = new BehaviorSubject(undefined);
  articleInfos$ = this.subjectArticleInfos.asObservable();

  private subjectArticleAllergenes = new BehaviorSubject(undefined);
  articleAllergenes$ = this.subjectArticleAllergenes.asObservable();

  private subjectArticleAppellations = new BehaviorSubject(undefined);
  articleAppellations$ = this.subjectArticleAppellations.asObservable();

  private subjectOpenMajCreateArticle = new Subject<ArticleSupplier>();
  openMajCreateArticle$ = this.subjectOpenMajCreateArticle.asObservable();

  private subjectDeleteArticle = new Subject<CatalogueAchatDTO>();
  deleteArticle$ = this.subjectDeleteArticle.asObservable();

  private subjectLoadingXlsProgressBar = new Subject<number>(); // Doit rester ici car spécifique aux CA
  loadingXlsProgressBar$ = this.subjectLoadingXlsProgressBar.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService,
              private httpSvc: HttpService,
              private udmSvc: UnitesDeMesuresService) {
    super(utils, auth2Svc, router, http, title);


  }

  announceLoadingXlsProgressBar = (id: number): void => {
    this.subjectLoadingXlsProgressBar.next(id);
  }


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  getSort = (): string[] => ['referenceArticle,asc'];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => undefined;

  getTitle = (): string => 'GESTION DES CATALOGUES ACHATS';

  getCreateNewObjectLabel = (): string => 'CRÉER UN CATALOGUE ACHAT';

  getEntityName = (): string => 'catalogueAchat';

  getFields = (dto: CatalogueAchatDTO): FormFieldBaseSupplier<any>[] => undefined;

  prepareFields = (dto: CatalogueAchatDTO, produit: ProduitDTO, uniteDeProduction: UniteDeProductionDTO) => {

    const fournisseurList$ = this.getFournisseursByUniteDeProduction(uniteDeProduction.id);
    let all$;

    all$ = combineLatest([fournisseurList$]);

    return all$.pipe(
      switchMap(data => {
        const arr = data as any[];
        const fournisseurList = arr[0].resultList;

        // si dto est vide, on init le dto avec un code api unique
        if (this.utils.isNullOrEmpty(dto)) {
          dto = this.createEmptyCatalogueAchatDTO(produit);
          dto.site = uniteDeProduction.site;
        }

        return of({
          catalogueAchat: dto,
          fields: this.getFieldsSpec(dto, produit, fournisseurList, uniteDeProduction)
        });
      }),
      catchError(err => this.utils.handleError(err))
    );
  };

  /**
   * Initialiser les champs catalogue achat
   * @param {CatalogueAchatDTO} dto
   * @param {ProduitDTO} produit
   * @returns {FormFieldBaseSupplier<any>[]}
   */
  private getFieldsSpec = (dto: CatalogueAchatDTO, produit: ProduitDTO, fournisseurList: FournisseurDTO[], selectedUdp: UniteDeProductionDTO): FormFieldBaseSupplier<any>[] => {

    const isReadOnly = this.utils.isNullOrEmpty(dto.caParentId) ? !this.canModify(dto): true;

    let valueRatioUcUf = 0;
    if (this.utils.isNumberGt0(dto.ratioUniteFacturationUniteBase) && this.utils.isNumberGt0(dto.ratioUniteCommandeUniteBase)) {
      valueRatioUcUf = this.utils.convertQuantiteUdm1ToQuantiteUdm2(1, dto.ratioUniteCommandeUniteBase, dto.ratioUniteFacturationUniteBase);
    }

    let valueRatioUsUt = 0;
    if (this.utils.isNumberGt0(dto.ratioUniteTechnique) && this.utils.isNumberGt0(dto.ratioUniteStockageUniteBase)) {
      valueRatioUsUt = this.utils.convertQuantiteUdm1ToQuantiteUdm2(1, dto.ratioUniteStockageUniteBase, dto.ratioUniteTechnique);
    }


    let formFieldFournisseurSecteur: FormFieldBaseSupplier<any>;
    let formFieldSite = new FormFieldTextboxSupplier({
      key: 'site',
      label: 'Site',
      readonly: true,
      value: dto.site,
      type: 'hidden',
      order: 1
    });

    // Une fois le catalogue achat créé on ne peut modifier : le fournisseur
    if (dto.id > 0) {
      formFieldFournisseurSecteur = new FormFieldTableSupplier({
        key: 'fournisseur',
        label: '',
        type: 'table',
        help: `Une fois l'article créé, la livraison n'est plus modifiable`,
        cols: [
          {header: 'Fournisseur', field: 'libelle'},
          {header: 'Code', field: 'code'}
        ],
        tableHeight: 100,
        readonly: true,
        value: [dto.fournisseur],
        order: 4
      });
    } else {

      formFieldFournisseurSecteur = new FormFieldTableSupplier({
        key: 'fournisseur',
        label: '',
        type: 'table',
        cols: [
          {header: 'Fournisseur', field: 'libelle'},
          {header: 'Code', field: 'code'}
        ],
        tableHeight: 250,
        readonly: isReadOnly,
        value: [dto.fournisseur],
        order: 4,
        required: true,
        options: fournisseurList,
      });

    }


    let formFields: FormFieldBaseSupplier<any>[] = [

      formFieldSite,
      formFieldFournisseurSecteur,

      new FormFieldTextboxSupplier({
        key: 'produitArticleLibelle',
        label: 'Libellé',
        help: `Dénomination de l'article dans datameal online`,
        readonly: isReadOnly,
        value: dto.produitArticle.libelle,
        minLength: 2,
        maxLength: 100,

        required: true,
        order: 5
      }),

      new FormFieldDropdownSupplier({
        key: 'produitDeclinaison',
        label: 'Produit déclinaison',
        readonly: isReadOnly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(produit.produitDeclinaisons, dto.produitArticle.produitDeclinaison),
        order: 6,
        required: true,
        options: produit.produitDeclinaisons,
        linkedFieldFn: this.onChangeProduitDeclinaison
      }),

      new FormFieldTextboxSupplier({
        key: 'codeApi',
        label: 'Code api',
        help: `Code api`,
        readonly: true,
        value: dto.codeApi,
        required: false,
        order: 7
      }),

      new FormFieldTextboxSupplier({
        key: 'produitArticleReference',
        label: 'Référence',
        help: `Référence de l'article dans datameal online`,
        readonly: isReadOnly,
        value: dto.produitArticle.reference,
        minLength: 3,
        maxLength: 50,
        required: true,
        order: 8
      }),

      new FormFieldTextboxSupplier({
        key: 'referenceArticle',
        label: 'Réf. Art. fournisseur',
        help: `Référence de l'article chez le fournisseur`,
        readonly: isReadOnly,
        value: dto.referenceArticle,
        maxLength: 50,
        required: false,
        order: 9
      }),

      new FormFieldDropdownSupplier({
        key: 'uniteDeFacturation',
        label: 'Unité de facturation',
        required: true,
        readonly: isReadOnly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.udmSvc.unitesDeMesureEnvironnement, dto.uniteDeFacturation),
        order: 10,
        linkedFieldFn: this.onChangeUniteFacturation,
        options: this.udmSvc.unitesDeMesureEnvironnement
      }),

      new FormFieldDropdownSupplier({
        key: 'uniteDeCommande',
        label: 'Unité de commande',
        readonly: isReadOnly,
        required: true,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.udmSvc.unitesDeMesureEnvironnement, dto.uniteDeCommande),
        order: 11,
        options: this.udmSvc.unitesDeMesureEnvironnement,
        linkedFieldFn: this.onChangeUC
      }),

      new FormFieldDropdownSupplier({
        key: 'uniteDeStockage',
        label: 'Unité de stockage',
        readonly: isReadOnly,
        required: true,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.udmSvc.unitesDeMesureEnvironnement, dto.uniteDeStockage),
        order: 12,
        options: this.udmSvc.unitesDeMesureEnvironnement,
        linkedFieldFn: this.onChangeUniteDeStockage
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioUniteFacturationUniteBase',
        label: 'Unité Factu. / KG',
        type: 'number',
        help: `Ratio unité de facturation / unité universelle (KG)`,
        readonly: isReadOnly,
        value: dto.ratioUniteFacturationUniteBase,
        min: 0.0001,
        required: true,
        prefix: `1 ${dto.uniteDeFacturation.libelle.toUpperCase()} équivaut à `,
        suffix: 'KG',
        width: 150,
        order: 13,
        linkedFieldFn: this.onChangeRatioUniteDeFactu
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioUniteCommandeUniteFacturation',
        label: 'Unité de Commande / Unité de Facturation',
        type: 'number',
        help: `Ratio unité de commande / unité de facturation`,
        readonly: isReadOnly,
        value: valueRatioUcUf,
        required: true,
        min: 0.0001,
        prefix: `1 ${dto.uniteDeCommande.libelle.toUpperCase()} équivaut à `,
        suffix: `${dto.uniteDeFacturation.libelle.toUpperCase()}`,
        width: 150,
        order: 15,
        linkedFieldFn: this.onChangeRatioUcUf
      }),


      new FormFieldTextboxSupplier({
        key: 'ratioUniteCommandeUniteBase',
        label: 'Unité Commande / KG',
        type: 'number',
        help: `Ratio unité de commande / unité universelle (KG)`,
        readonly: isReadOnly,
        value: dto.ratioUniteCommandeUniteBase,
        required: true,
        min: 0.0001,
        prefix: `1 ${dto.uniteDeCommande.libelle.toUpperCase()} équivaut à `,
        suffix: 'KG',
        width: 150,
        order: 14,
        linkedFieldFn: this.onChangeRatioUniteDeCommande
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioUniteStockageUniteBase',
        label: 'Unité de Stockage / KG',
        type: 'number',
        help: `Ratio unité de stockage / unité universelle (KG)`,
        readonly: isReadOnly,
        value: dto.ratioUniteStockageUniteBase,
        required: true,
        min: 0.0001,
        prefix: `1 ${dto.uniteDeStockage.libelle.toUpperCase()} équivaut à `,
        suffix: 'KG',
        width: 150,
        order: 15,
        linkedFieldFn: this.onChangeRatioUs
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioUniteStockageUniteTechnique',
        label: 'Unité de stockage / Unité Technique',
        type: 'number',
        help: `Ratio unité de stockage / unité technique`,
        readonly: isReadOnly,
        value: valueRatioUsUt,
        required: true,
        min: 0.0001,
        prefix: `1 ${dto.uniteDeStockage.libelle.toUpperCase()} équivaut à `,
        suffix: `${dto.uniteTechnique.libelle.toUpperCase()}`,
        width: 150,
        order: 15,
        linkedFieldFn: this.onChangeRatioUsUt
      }),

      new FormFieldTextboxSupplier({
        key: 'ratioUniteTechnique',
        type: 'hidden',
        value: dto.ratioUniteTechnique,
      }),


      new FormFieldTextboxSupplier({
        key: 'minimumDeCommande',
        label: 'Minimum à commander',
        help: 'Minimum à commander, affiché en unité de commande',
        type: 'number',
        readonly: isReadOnly,
        value: dto.minimumDeCommande,
        // Evolution #1463 ARTICLES : rendre le "Mini à commander" non obligatoire
        // required: true,
        // min: 0.0001,
        suffix: dto.uniteDeCommande ? dto.uniteDeCommande.libelle.toUpperCase() : undefined,
        width: 150,
        order: 16,
      }),

      new FormFieldCurrencySupplier({
        key: 'prixSingleMode',
        label: `Prix d'achat`,
        help: `Le prix d'achat se calcule en unité de facturation`,
        type: 'number',
        readonly: isReadOnly,
        value: dto.groupeAchatCaList.length > 0 ? dto.groupeAchatCaList[0].prixUF.value : 0,
        required: true,
        suffix: `€ par ${dto.uniteDeFacturation.libelle.toUpperCase()}`,
        min: 0.0001,
        width: 150,
        order: 18
      }),

      new FormFieldTableSupplier({
        key: 'prixMutlipleReadMode',
        label:`Prix d'achat`,
        type: 'table',
        help: `Le prix d'achat se calcule en unité de facturation`,
        cols: [
          {header: 'Prix', field: 'prixUF'},
          {header: 'Groupe', field: 'libelleGroupeAchat'}
        ],
        tableHeight: 150,
        readonly: true,
        value:dto.groupeAchatCaList,
        order: 18,
        options: dto.groupeAchatCaList
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: isReadOnly,
        value: dto.actif,
        required: false,
        order: 20
      }),

      new FormFieldCheckboxSupplier({
        key: 'produitCarne',
        label: 'Produit carné',
        readonly: isReadOnly,
        value: this.utils.isNullOrEmpty(dto.produitCarne) ? false : dto.produitCarne,
        required: false,
        order: 20
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 999
      }),

      new FormFieldTextboxSupplier({
        key: 'produitArticle',
        type: 'hidden',
        value: dto.produitArticle,
        order: 999
      }),


      new FormFieldTextboxSupplier({
        key: 'prixMoyen',
        type: 'hidden',
        value: dto.prixMoyen,
        order: 999
      }),


      new FormFieldTextboxSupplier({
        key: 'prixPrecedent',
        type: 'hidden',
        value: dto.prixPrecedent,
        order: 999
      })
    ];

    return formFields.sort((a, b) => a.order - b.order);
  };

  getPrice = (dto :CatalogueAchatDTO) => {
    dto.groupeAchatCaList.forEach( item =>{
        return item.prixUF.value;
    });
  };

  onChangeUniteFacturation = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const udm = object as UnitedemesureDTO;

      for (let field of fields) {
        if (field.key === 'prixSingleMode' || field.key === 'prixMutlipleReadMode') {
          field.suffix = '€ par ' + udm.libelle.toUpperCase();
        } else if (field.key === 'ratioUniteFacturationUniteBase') {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à `;
        } else if (field.key === 'ratioUniteCommandeUniteFacturation') {
          field.suffix = `${udm.libelle.toUpperCase()}`;
        }
      }
    }
  };

  onChangeUniteDeStockage = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const udm = object as UnitedemesureDTO;
      // console.log(fields);

      for (let field of fields) {
        if (field.key === 'ratioUniteStockageUniteBase') {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à `;
        } else if (field.key === 'ratioUniteStockageUniteTechnique') {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à`;
        }
      }
    }
  };

  onChangeUC = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const udm = object as UnitedemesureDTO;
      // console.log(fields);

      for (let field of fields) {
        if (field.key === 'minimumDeCommande') {
          field.suffix = udm.libelle.toUpperCase();
        } else if (field.key === 'ratioUniteCommandeUniteBase') {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à `;
        } else if (field.key === 'ratioUniteCommandeUniteFacturation') {
          field.prefix = `1 ${udm.libelle.toUpperCase()} équivaut à `;
        }
      }
    }
  };

  getAllFromEnvironnement = (): void => {
  };

  createEmptyCatalogueAchatDTO = (produit: ProduitDTO): CatalogueAchatDTO => {

    const catalogueAchat = new CatalogueAchatDTO();

    // 0 tell to the back, it s a creation
    catalogueAchat.id = 0;

    catalogueAchat.uniteDeFacturation = produit.produitDeclinaisons[0].uniteDeMesure;
    catalogueAchat.uniteDeCommande = produit.produitDeclinaisons[0].uniteDeMesure;
    catalogueAchat.uniteDeStockage = produit.produitDeclinaisons[0].uniteDeMesure;
    catalogueAchat.ratioUniteStockageUniteBase = produit.produitDeclinaisons[0].ratioUniteTechnique;
    catalogueAchat.referenceArticle = `REF${Math.floor(Math.random() * 10000000) + 1}`;
    catalogueAchat.actif = true;
    catalogueAchat.ratioUniteCommandeUniteBase = produit.produitDeclinaisons[0].ratioUniteTechnique;
    catalogueAchat.ratioUniteFacturationUniteBase = produit.produitDeclinaisons[0].ratioUniteTechnique;
    catalogueAchat.minimumDeCommande = 1;
    catalogueAchat.prix = 1;
    catalogueAchat.site = produit.site;
    catalogueAchat.codeApi = undefined;
    catalogueAchat.fournisseurOwnerLibelle = null;
    catalogueAchat.produitArticle = new ProduitArticleDTO();
    catalogueAchat.produitArticle.id = 0;
    catalogueAchat.produitArticle.site = produit.site;
    catalogueAchat.produitArticle.libelle = produit.libelle;
    catalogueAchat.produitArticle.reference = `REF${Math.floor(Math.random() * 10000000) + 1}`;

    catalogueAchat.produitArticle.produitDeclinaison = produit.produitDeclinaisons[0];
    catalogueAchat.uniteTechnique = produit.produitDeclinaisons[0].uniteDeMesure;
    catalogueAchat.ratioUniteTechnique = produit.produitDeclinaisons[0].ratioUniteTechnique;
    catalogueAchat.circuitCourt = false;
    catalogueAchat.bio = false;

    return catalogueAchat;
  };

  createEmptyDTO = (): CatalogueAchatDTO => new CatalogueAchatDTO();

  mapDtosToViewModels = (catalogueAchatList: CatalogueAchatDTO[]): ViewModelCatalogueAchat[] => {
    if (this.utils.isCollectionNullOrEmpty(catalogueAchatList)) {
      return [];
    }

    let viewModelCatalogueAchatList = catalogueAchatList.map(item => this.createViewModelCatalogueAchatFromCatalogueAchatDTO(item));
    viewModelCatalogueAchatList = _sortBy(viewModelCatalogueAchatList, ['declinaison']);

    return viewModelCatalogueAchatList;
  };

  /**
   * ViewModelCatalogueAchat est une classe utilisée pour le rendu dans la datagrid cela afin d'utiliser les filters primeng qui ne savent pas parcourir plusieurs niveua d'objet
   * @param {CatalogueAchatDTO} item
   * @returns {ViewModelCatalogueAchat}
   */
  createViewModelCatalogueAchatFromCatalogueAchatDTO = (item: CatalogueAchatDTO): ViewModelCatalogueAchat => {
    const vmCatalogueAchat = new ViewModelCatalogueAchat();

    vmCatalogueAchat.id = item.id;

    vmCatalogueAchat.actif = item.actif;
    vmCatalogueAchat.article = item.produitArticle.libelle;
    vmCatalogueAchat.ratioUniteDeCommande = item.ratioUniteCommandeUniteBase;
    vmCatalogueAchat.ratioUniteDeFacturation = item.ratioUniteFacturationUniteBase;
    vmCatalogueAchat.articleSiteId = item.site.id;
    vmCatalogueAchat.articleSiteLibelle = item.site.libelle;
    vmCatalogueAchat.declinaison = item.produitArticle.produitDeclinaison.declinaison.libelle;
    vmCatalogueAchat.produitDeclinaisonActif = item.produitDeclinaisonActif;
    vmCatalogueAchat.fournisseur = item.fournisseur.libelle;
    vmCatalogueAchat.fournisseurOwnerLibelle = item.fournisseurOwnerLibelle;
    vmCatalogueAchat.prixAchat = item.prix;
    vmCatalogueAchat.referenceArticle = item.produitArticle.reference;
    vmCatalogueAchat.referenceFournisseur = item.referenceArticle;
    vmCatalogueAchat.uniteDeCommande = item.uniteDeCommande.libelle;

    vmCatalogueAchat.uniteDeFacturation = item.uniteDeFacturation.libelle;

    vmCatalogueAchat.produitDeclinaisonLibelle = item.produitArticle.produitDeclinaison.libelle;
    vmCatalogueAchat.produitDeclinaisonId = item.produitArticle.produitDeclinaison.id;

    vmCatalogueAchat.uniteDeStockage = item.uniteDeStockage.libelle;
    vmCatalogueAchat.ratioUniteStockageUniteBase = item.ratioUniteStockageUniteBase;
    vmCatalogueAchat.codeApi = item.codeApi;
    vmCatalogueAchat.produitDeclinaisonCode = item.produitArticle.produitDeclinaison.code;

    vmCatalogueAchat.lmPdUpCa = item.lmPdUpCa;

    vmCatalogueAchat.groupeAchatCaList = item.groupeAchatCaList;

    vmCatalogueAchat.caSiteId = item.site.id

    return vmCatalogueAchat;
  };

  saveCatalogueAchat = (catalogueAchat: CatalogueAchatDTO): Observable<ResponseWrapper<CatalogueAchatDTO>> => {
    return this.http.post(URL_SAVE_CATALOGUEACHAT, catalogueAchat).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };

  dynamicSearchCatalogueAchat = (idsFournisseurs?: number[], onlyParent?: boolean, refProvider?: string, articleLabel?: string, refArticle?: string, libelleUniteCommande?: string,
                                 sortByArticleLabel?: string, sortByRefArticle?: string, sortByUniteCommandeLibelle?: string, sortByRefProvider?: string, page?: number, size?: number) => {
    let url = `${URL_DYNAMIC_SEARCH_CATALOGUEACHAT}?1=1`;
    if (idsFournisseurs && idsFournisseurs.length) url += `&idsFournisseurs=${idsFournisseurs}`;
    if (onlyParent !== null && onlyParent !== undefined) url += `&onlyParent=${onlyParent}`;
    if (refProvider) url += `&refProvider=${refProvider}`;
    if (articleLabel) url += `&articleLabel=${articleLabel}`;
    if (refArticle) url += `&refArticle=${refArticle}`;
    if (libelleUniteCommande) url += `&libelleUniteCommande=${libelleUniteCommande}`;
    if (sortByArticleLabel) url += `&sortByArticleLabel=${sortByArticleLabel}`;
    if (sortByRefArticle) url += `&sortByRefArticle=${sortByRefArticle}`;
    if (sortByUniteCommandeLibelle) url += `&sortByUniteCommandeLibelle=${sortByUniteCommandeLibelle}`;
    if (sortByRefProvider) url += `&sortByRefProvider=${sortByRefProvider}`;
    if (page !== null && page !== undefined) url += `&page=${page}`;
    if (size !== null && size !== undefined) url += `&size=${size}`;
    return this.httpSvc.get(url);
  }


  getEditObjectLabel = (data: ObjectDTO): string => '';

  saveCatalogueAchatList = (articles: CatalogueAchatDTO[]) => {

    return this.http.post(URL_SAVE_CATALOGUEACHAT_LIST, articles)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  updatePrixAchatsFromXlsx = (importRequest: ImportRequestDTO, file: File, doUpdate: boolean, idProgressStatus?: number) => {


    const fd = new FormData();
    fd.set('myJson', JSON.stringify(importRequest));
    fd.set('file', file);

    return this.http.post(`${URL_IMPORT_XSLX_PRIX_ACHATS}/${doUpdate}/${idProgressStatus || -1}`, fd, { headers: new HttpHeaders({ timeout: `${1200000}` }) })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };


  /**
   * Fonction appelée quand on change le ratio US / UT.
   * @param value
   * @param form
   * @param fields
   * @param object
   *
   */
  onChangeRatioUsUt = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    const ratioUt = form.controls['ratioUniteTechnique'].value;
    const ratioUs = form.controls['ratioUniteStockageUniteBase'].value;
    const ratioUsUt = form.controls['ratioUniteStockageUniteTechnique'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUt) && UtilsService.UTILS.isNumberGt0(ratioUsUt)) {

      const qte = ratioUsUt * ratioUt;
      form.controls['ratioUniteStockageUniteBase'].setValue(qte);
    }
  };

  onChangeRatioUs = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {


    const ratioUt = form.controls['ratioUniteTechnique'].value;
    const ratioUs = form.controls['ratioUniteStockageUniteBase'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUt) && UtilsService.UTILS.isNumberGt0(ratioUs)) {

      const qte = ratioUs / ratioUt;
      form.controls['ratioUniteStockageUniteTechnique'].setValue(qte);
    }

  };

  /**
   * Fonction appelée quand on change le ratio UC / UF.
   * @param value
   * @param form
   * @param fields
   * @param object
   *
   */
  onChangeRatioUcUf = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    // COMMANDE --------------------
    const formFacturation = form.parent.controls['formFacturation'];
    const ratioUfKg = formFacturation.controls['ratioUniteFacturationUniteBase'].value;
    const ratioUcUf = form.controls['ratioUniteCommandeUniteFacturation'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUfKg) && UtilsService.UTILS.isNumberGt0(ratioUcUf)) {

      const qte = ratioUfKg * ratioUcUf;
      form.controls['ratioUniteCommandeUniteBase'].setValue(qte);
    }
  };


  /**
   * Fonction appelée quand on change le ratio UF .
   * @param value
   * @param form
   * @param fields
   * @param object
   */
  onChangeRatioUniteDeFactu = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    // COMMANDE --------------------
    const formCommande = form.parent.controls['formCommande'];

    const ratioUfKg = form.controls['ratioUniteFacturationUniteBase'].value;
    const ratioUcUf = formCommande.controls['ratioUniteCommandeUniteFacturation'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUfKg) && UtilsService.UTILS.isNumberGt0(ratioUcUf)) {
      const qte = ratioUfKg * ratioUcUf;
      formCommande.controls['ratioUniteCommandeUniteBase'].setValue(qte);
    }
  };

  /**
   * Fonction appelée quand on change le ratio UC -> UB.
   * @param value
   * @param form
   * @param fields
   * @param object
   */
  onChangeRatioUniteDeCommande = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    const formFacturation = form.parent.controls['formFacturation'];

    const ratioUfKg = formFacturation.controls['ratioUniteFacturationUniteBase'].value;
    const ratioUcKg = form.controls['ratioUniteCommandeUniteBase'].value;

    if (UtilsService.UTILS.isNumberGt0(ratioUfKg) && UtilsService.UTILS.isNumberGt0(ratioUcKg)) {
      const ratioUcUf = ratioUcKg / ratioUfKg;
      form.controls['ratioUniteCommandeUniteFacturation'].setValue(ratioUcUf);
    }

  };

  /**
   * Sur le changement de produit declinaison, on doit recalculer le rapport Unite de Stockage / Unité Technique
   * @param value
   * @param form
   * @param fields
   * @param object
   */
  onChangeProduitDeclinaison = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const pd = object as ProduitDeclinaisonDTO;

      for (let field of fields) {
        if (field.key === 'ratioUniteStockageUniteTechnique') {
          field.suffix = `${pd.uniteDeMesure.libelle.toUpperCase()}`;

          const formStock = form.parent.controls['formStock'];
          const ratioUsUt = formStock.controls['ratioUniteStockageUniteTechnique'].value;

          const ratioUt = pd.ratioUniteTechnique;
          formStock.controls['ratioUniteTechnique'].setValue(ratioUt);

          if (UtilsService.UTILS.isNumberGt0(ratioUt) && UtilsService.UTILS.isNumberGt0(ratioUsUt)) {
            const qte = ratioUsUt * ratioUt;
            formStock.controls['ratioUniteStockageUniteBase'].setValue(qte);
          }


          break;
        }
      }
    }

  };

  /**
   * Recuperer le catalogue achat d'une produit par rapport à son unité de production
   * @param uniteProduictionId
   * @param int
   */
  getFournisseursByUniteDeProduction = (uniteProduictionId: number) => {
    return this.http.get(URL_GET_FOURNISSEURS, {
      params: new HttpParams()
        .set('idUniteDeProduction', uniteProduictionId + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };


  /**
   *  supprimer un catalogue achat
   * @param idCatalogueAchat
   * @param int
   */
  deleteCatalogueAchat = (idCatalogueAchat: number) => {
    return this.http.delete(URL_DELETE_CATALOGUEACHAT, {
      params: new HttpParams()
        .set('idCatalogueAchat', idCatalogueAchat + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };

  announceOpenDialogArticle = (cads: CatalogueAchatDialogSupplier) => {
    this.subjectOpenDialogArticle.next(cads);
  };

  announceCloseDialogArticle = (cads: CatalogueAchatDialogSupplier) => {
    this.subjectCloseDialogArticle.next(cads);
  };

  announceDeleteCatalogueAchat = (ca: CatalogueAchatDTO) => {
    this.subjectDeleteArticle.next(ca);
  };

  announceArticleInfos = (data: CatalogueAchatDialogSupplier) => {
    this.subjectArticleInfos.next(data);
  };

  announceArticleAppellations = (data: CatalogueAchatDialogSupplier) => {
    this.subjectArticleAppellations.next(data);
  };

  announceArticleAllergenes = (data: CatalogueAchatDialogSupplier) => {
    this.subjectArticleAllergenes.next(data);
  };

  /**
   * Le content est le body sans le footer (la ligne des boutons)
   * @param cads
   */
  getMaxContentHeightDialogArticle = (cads: CatalogueAchatDialogSupplier) => {

    if (cads) {

      // 140 = hauteur entete dialog + hauteur footer dialog
      return cads.maxBodyHeight - 140;
    }

    return 760;
  };

  getProduitArticleAppellations = (idProduitArticle: number) => {
    return this.http.get(URL_GET_PRODUIT_ARTICLE_APPELLATIONS, {
      params: new HttpParams()
        .set('idProduitArticle', idProduitArticle + '')
    }).pipe(
      catchError(error => this.utils.handleError(error))
    );

  };

  getProduitArticleAllergenes = (idProduitArticle: number) => {
    return this.http.get(URL_GET_PRODUIT_ARTICLE_ALLERGENES, {
      params: new HttpParams()
        .set('idProduitArticle', idProduitArticle + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );

  };

  saveProduitArticleAppellations = (idProduitArticle: number, appellationList: AppellationDTO[]) => {

    return this.http.post(URL_POST_SAVE_PRODUIT_ARTICLE_APPELLATIONS, appellationList, {
      params: new HttpParams()
        .set('idProduitArticle', idProduitArticle + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };

  saveProduitArticleAllergenes = (idProduitArticle: number, allergeneList: AllergeneDTO[]) => {
    return this.http.post(URL_POST_SAVE_PRODUIT_ARTICLE_ALLERGENES, allergeneList, {
      params: new HttpParams()
        .set('idProduitArticle', idProduitArticle + '')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };

  getCatalogueAchatByFilters = (filters: any) => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.httpSvc.get(`${URL_POST_ARTICLES_MAJ_ARTICLES}?${args}`);
  };

  exportCatalogueAchatByFilters = (filters: any) => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.http.get(`${URL_POST_EXPORT_ARTICLES_TO_EXCEL_FILE}?${args}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    }).pipe(
      catchError(error => this.utils.handleError(error)),
    );
  };


  getCatalogueAchatByIdAndIdPdAndIdFournisseur = (idUdp: number, idProduitDeclinaison : number, idFournisseur: number) => {
    return this.httpSvc.get(`${URL_GET_ARTICLES_BY_ID_PD_ID_FOURNI_ID_UDP}?idUdp=${idUdp}&idProduitDeclinaison=${idProduitDeclinaison}&idFournisseur=${idFournisseur}`);
  };

  updatePrixGroupeAchatCatalogueAchat = (newValue: any, idCatalogueAchat: number, idUdp: number, idFournisseur: number) => {
    let params = new HttpParams()
      .set('newValue', newValue + '')
      .set('idCatalogueAchat', idCatalogueAchat + '')
      .set('idUdp', idUdp + '')
      .set('idFournisseur', idFournisseur + '');
    return this.httpSvc.post(URL_POST_UPDATE_PRIX_GACA, params);
  };

  findArticlePrefere = (idProduitDeclinaison: number, idUdp: number): Observable<ResponseWrapper<any>> => {
    return this.httpSvc.get(`${URL_GET_ARTICLE_PREFERE_BY_PRODUIT_DECLINAISON_AND_UDP + idProduitDeclinaison}/${idUdp}`, null);
  }

  announceOpenMajCreatArticle = (value: ArticleSupplier) => {
    this.subjectOpenMajCreateArticle.next(value);
  };

  refreshArticlesPreferesAfterDeletion = (catalogueAchat: CatalogueAchatDTO) => this.httpSvc.post(`${URL_POST_REFRESH_ARTICLES_PREFERED}true`, catalogueAchat);

}

export interface CatalogueAchatDialogSupplier {
  catalogueAchat: CatalogueAchatDTO;
  produit: ProduitDTO;
  uniteDeProduction: UniteDeProductionDTO;
  route: string;
  rootRoute: any[];
  isNew: boolean;
  bodyHeight: number;
  maxBodyHeight?: number;
  routeProvenance: PROVENANCE;
  allProduitsDeclines: ProduitDeclinaisonDTO[];
}

export enum PROVENANCE {
  PRODUIT,
  FOURNISSEUR
}


