<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button pButton
              label="CRÉER"
              icon="fa fa-plus"
              (click)="openEditObject(undefined)"
              [disabled]="!canCreate()"
              pTooltip="Créer un fournisseur">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-popup
  [width]="700"
  [height]="'auto'"
  [showTitle]="true"
  [title]="deleteDialogTitle"
  [(visible)]="popupVisible"
  [resizeEnabled]="true"
  (onHiding)="closeDeleteDialog()"
>
  <div>
    <p>
      Êtes-vous sûr de vouloir supprimer ce fournisseur ?
    </p>
    <p>
      Cela entraînera également :
    </p>
    <ul>
      <li>les commandes de ce fournisseur</li>
      <li>les lignes des listes de besoins</li>
      <li>les stocks liés à ce fournisseur</li>
      <li>les articles liés à ce fournisseur</li>
      <li>l'éventuelle liaison avec les lots marchés</li>
      <li>les fournisseurs fils et toutes les données qui leurs sont rattachées</li>
    </ul>
    <p>
      Cette action est irréversible.
    </p>
  </div>
  <div class="p-2 d-flex justify-content-end">
    <button
      class="btn btn-primary"
      type="button"
      (click)="deleteObject()">
      Confirmer
    </button>
  </div>
</dx-popup>

<p-table
  [scrollable]="true"
  [scrollHeight]="utils.scrollHeightContent(285)"
  styleClass="p-datatable-sm"
  [rows]="50"
  [rowsPerPageOptions]="[5,10,15,20,50,100]"
  [paginator]="true"
  [value]="fournisseursList"
  (onFilter)="onFilterdata($event)"
  [columns]="cols"
  #dt>

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">

        <col *ngSwitchCase="'actions'" [style.text-align]="'left'">
        <col *ngSwitchCase="'site'" [style.text-align]="'left'">
        <col *ngSwitchCase="'libelle'" [style.text-align]="'left'">
        <col *ngSwitchCase="'code'" [style.text-align]="'left'">
        <col *ngSwitchCase="'adresse'" [style.text-align]="'left'">
        <col *ngSwitchCase="'filiales'" [style.text-align]="'left'">
        <col *ngSwitchCase="'actif'" [style.text-align]="'left'">

      </ng-container>
    </colgroup>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">

        <ng-container *ngSwitchCase="'actions'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'site'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'libelle'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'code'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'adresse'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'filiales'">
          <th>
            {{col.header}}
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'actif'">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>

      </ng-container>

    </tr>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy:utils.trackByFn" [ngSwitch]="col.field">

        <ng-container *ngSwitchCase="'site'">
          <th>
            <input pInputText placeholder="Tous les sites" type="text"
                   (input)="dt.filter($event.target.value, 'site.libelle', 'contains')"
                   style="width: 100% !important; ">
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'libelle'">
          <th>
            <input pInputText placeholder="Tous les fournisseurs" type="text"
                   (input)="dt.filter($event.target.value, col.field, 'contains')" style="width: 100% !important; ">
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="'code'">
          <th>
            <input pInputText placeholder="Tous les codes" type="text"
                   (input)="dt.filter($event.target.value, col.field, 'contains')" style="width: 100% !important; ">
          </th>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <th>
          </th>
        </ng-container>


      </ng-container>

    </tr>
  </ng-template>


  <!--BODY-->
  <ng-template pTemplate="body" let-columns="columns" let-rowData let-rowIndex="rowIndex">
    <tr>
      <td *ngFor="let col of columns;  trackBy:utils.trackByFn">

        <ng-container [ngSwitch]="col.field">

          <ng-container *ngSwitchCase="'actions'">
            <yo-cell-button (yoNavigation)="openEditObject(rowData)"
                            [yoViewMode]="!gds.canModify(rowData)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="45"
                            yoTextAlign="center"
                            yoIconClass="fa fa-edit"
                            yoTooltip="Modifier le fournisseur"
            ></yo-cell-button>
            <yo-cell-button (yoNavigation)="showDeletePopup(rowData)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="45"
                            [yoDisabled]="!gds.canModify(rowData)"
                            yoTextAlign="center"
                            yoIconClass="fa fa-trash"
                            yoTooltip="Supprimer le fournisseur"
            ></yo-cell-button>
          </ng-container>


          <ng-container *ngSwitchCase="'site'">
            <yo-site [siteLibelle]="rowData.site.libelle" [siteId]="rowData.site.id"></yo-site>
          </ng-container>


          <ng-container *ngSwitchCase="'libelle'">
            {{rowData.libelle | uppercase}}
          </ng-container>

          <ng-container *ngSwitchCase="'code'">
            {{rowData.code | uppercase}}
          </ng-container>

          <ng-container *ngSwitchCase="'adresse'">
            {{rowData.adresse.codePostal}} {{rowData.adresse.ville}}
          </ng-container>

          <ng-container *ngSwitchCase="'filiales'">
            <table>
              <tr>
                <td [style.width.px]="60" style="vertical-align: top;padding:4px;" pTooltip="Ajouter une filiale"
                    showDelay="500"
                    tooltipPosition="left">

                  <yo-cell-button (yoNavigation)="openFiliale(rowData,undefined)"
                                  [yoTooltipShowDelay]="1500"
                                  [yoMaxWidth]="true"
                                  [yoWidthPercent]="45"
                                  yoTextAlign="center"
                                  yoIconClass="fa fa-plus"
                  ></yo-cell-button>
                </td>
                <td style="vertical-align: top;padding:4px;">
                  <ng-container *ngFor="let filiale of rowData.children">

                    <p-menu appendTo="body" #menuActions [model]="filialeActions" [popup]="true"></p-menu>

                    <div (click)="chooseFilialeAction(menuActions,$event,filiale)" class="filiale"
                         [pTooltip]="getFilialeInfos(filiale)" [escape]="false"
                         showDelay="500">

                      <span class="genlabel cursor">{{filiale.libelle | uppercase}} ({{filiale.code}})</span>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </table>
          </ng-container>

          <ng-container *ngSwitchCase="'actif'">
            <p-checkbox [disabled]="true" [(ngModel)]="rowData.actif"
                        binary="true"></p-checkbox>
          </ng-container>

        </ng-container>
      </td>
    </tr>
  </ng-template>

  <!--SUMMARY-->
  <ng-template pTemplate="summary">
    <tr>
      <td style="text-align:left;">
        {{utils.getTotalRecordsLabel('fournisseur', fournisseursList.length)}}
      </td>
    </tr>
  </ng-template>

</p-table>


<!--DIALOG DE MISE À JOUR DU CATALOGUE ACHAT D'UN SECTEUR FOURNISSEUR-->
<yo-catalogue-achat-filiale></yo-catalogue-achat-filiale>

<!--DIALOG-EDITION FILIALE-->
<yo-filiale-form-dialog
  [displayDialog]="displayFormDialog"
  (onClose)="onCloseFormDialog()"
></yo-filiale-form-dialog>
