<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Choix de la cible</h1>
    <div class="item-wizard">
      <p class="required-field">Quel sera le site gestionnaire du futur modèle de conditionnement de vos plats ? </p>
      <dx-lookup
        [dataSource]="findAllLocalSites()"
        [value]="idSelectedSite"
        valueExpr="id"
        displayExpr="libelle"
        [searchEnabled]="true"
        [placeholder]="sitePlaceholder"
        (onSelectionChanged)="onChangeSite($event)">
        <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
      </dx-lookup>
    </div>
    <div class="item-wizard" id="section-udps">
      <p class="required-field">Pour quelles unités de production souhaitez-vous appliquer ce modèle ? </p>
      <dx-list
        #list
        [dataSource]="dataSource"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        searchExpr="libelle"
        searchMode="contains"
        [selectedItemKeys]="unitesDeProductionSelected"
        (onSelectionChanged)="onChangeUniteProduction($event)"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par unité de production..."
        >
        </dxo-search-editor-options>
      </dx-list>
      <div class="mg-t-10 text-center">
        <button  title="Sélectionner"
                 class="mg-r-5"
                 type="button"
                 (click)="selectAllUdp()">
          Tout sélectionner <i class="far fa-square-check"></i>
        </button>
        <button  title="Désélectionner"
                 class="mg-r-5"
                 type="button"
                 (click)="unselectAllUdp()">
          Tout désélectionner <i class="far fa-square"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
            class="mg-r-5"
            type="button"
            (click)="next()">
       Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>
