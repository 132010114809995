<div class="d-flex justify-content-between">
  <div>

    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <strong>
      <span  class="mg-r-15">Déclinaisons à substituer</span>
    </strong>

    <button
      pButton
      icon="fa fa-plus"
      class="mg-r-5"
      label="AJOUTER"
      (click)="showAddDialog()"
      pTooltip="Ajouter une denrée interdite">
    </button>
  </div>
</div>
<router-outlet></router-outlet>

<yo-dialog-ajout-denree-interdite></yo-dialog-ajout-denree-interdite>
<yo-dialog-modifier-denree-interdite></yo-dialog-modifier-denree-interdite>

