import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {SortiePourProductionDTO} from '../../dtos/sortie-pour-production-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {HttpService} from "../technique/http.service";

export const URL_VALIDER_SORTIES_POUR_PRODUCTION = `dolrest/gestion-sorties-pour-production/valider-sorties/`;

@Injectable({
  providedIn: 'root'
})
export class SortiePourProductionService extends GenericHandler<SortiePourProductionDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);

  }

  createEmptyDTO = (): SortiePourProductionDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: ObjectDTO): string => '';

  getEntityName = (): string => 'SortiePourProduction';

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => '';

  getTotalRecordsLabel = (): string => '';

  validerSorties = (idPlanProduction: number, sortiesAValider: any[], viewSelected: string) => {
    return this.httpSvc.post(URL_VALIDER_SORTIES_POUR_PRODUCTION + idPlanProduction, { sortiesAValider, viewSelected });
  };

}
