import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../core/utils/utils.service';
import {
  BCF_MAIL_STATUT,
  BCF_STATUT,
  DATEPICKER_FR,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  TYPE_LIST_UDP
} from '../../../core/constants';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {MenuItem} from 'primeng/api';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {switchMap} from 'rxjs/operators';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {cloneDeep as _cloneDeep, uniqBy as _uniqBy} from 'lodash'
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {ImpressionService} from '../../../core/services/impression.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {ValueDTO} from '../../../core/dtos/value-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {of, Subscription} from 'rxjs';
import {MailHistoService} from '../../../core/services/entities/mail-histo.service';
import {BoncfDetailService} from '../../../core/services/entities/boncf-detail.service';
import {BesoinsService} from "../../../core/services/gestion-commandes/besoins.service";
import {DxDataGridComponent} from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-bc-propositions',
  templateUrl: './bc-propositions.component.html',
  styleUrls: ['./bc-propositions.component.scss']
})
export class BcPropositionsComponent implements OnInit, OnDestroy {

  subBonCf: Subscription;
  subMailHistoBonCf: Subscription;
  subRefreshGridCommandeProposition: Subscription;

  createCommandeOptionList: MenuItem[] = [];

  propositionsCommandes: BonCfDTO[];
  totalRecords = 0;
  loadingTable = false;
  displayEditDialog = false;
  dialogTitle = 'Détails';
  bonCf: BonCfDTO;
  selectedBonCfs: BonCfDTO[];
  lignesBonCf: BonCfDetailDTO[];

  BONCF_MAIL_STATUT = BCF_MAIL_STATUT;

  // afficher le dialog por créer une nouvelle proposition de commande
  displayAddDialog = false;
  uniteDeProductionList: UniteDeProductionDTO[];

  // items pour le filtre de sélection des listes de besoin
  filterItemsListeBesoin: ValueDTO[];
  // items pour le filtre de sélection des unités de production
  filterItemsUdp: ValueDTO[];
  // items pour le filtre de sélection des fournisseurs
  filterItemsFournisseur: ValueDTO[];

  filterDatesLivraisons: Date[];

  fr: any;

  subMailsBonCfEnvoyes: Subscription;

  nextSteps: MenuItem[] = [
    {
      label: `Passer les propositions sélectionnées à l'étape suivante :`,
      items: [
        {
          label: 'À valider',
          icon: 'fas fa-angle-double-right',
          command: (event) => this.updateToCommandeConfirmees()
        },
        {
          label: 'Refusées',
          icon: 'fas fa-times',
          command: (event) => this.updateToCommandeRefusees()
        },
      ]
    },
  ];

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  listesBesoinsIdsSelected: number[] = [];
  udpIdsSelected: number[] = [];
  fournisseursIdsSelected: number[] = [];

  isActionDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/proposition-commande';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cd: ChangeDetectorRef,
              private routeMapSvc: RoutemapService,
              private impressionSvc: ImpressionService,
              public auth2Svc: Auth2Service,
              private bonCfSvc: BoncfService,
              private besoinSvc : BesoinsService,
              public commandesSvc: CommandesService,
              private bonCfDetailSvc: BoncfDetailService,
              private gds: GenericDatagridService,
              private udpSvc: UnitesDeProductionService,
              public mailHistoSvc: MailHistoService,
              public wkfSvc: WorkflowsService,
              public utils: UtilsService,
              public domSanitizer: DomSanitizer,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }


  ngOnInit() {
    this.loadingTable = true;
    this.fr = DATEPICKER_FR;

    this.mailHistoBonCfSubscription();
    this.bonCfSubscription();
    this.mailsBonCfEnvoyesSubscription();
    this.initCreateCommandeOption()
    this.subcriptionRefreshGrid();
    this.initCustomStore();
    this.initFilters();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subBonCf);
    this.utils.unsubscribe(this.subMailHistoBonCf);
    this.utils.unsubscribe(this.subMailsBonCfEnvoyes);
    this.utils.unsubscribe(this.subRefreshGridCommandeProposition);
  }

  initCreateCommandeOption = (): void => {
    this.createCommandeOptionList = [
      {
        label: `Passer une commande`,
        items: [
          {
            label: 'Par fournisseur',
            icon: 'fas fa-truck',
            command: (event) => this.showAddDialog()
          },
          {
            label: 'Par denrée',
            icon: 'fas fa-carrot',
            command: (event) => this.showAddDialogByDenree()
          }
        ]
      }
    ];
  };

  initFilters = (): void => {
    this.commandesSvc.getFilterItemsListeBesoin(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsListeBesoin = response.resultList;
    });

    this.commandesSvc.getFilterItemsUdp(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsUdp = response.resultList;
    });

    this.commandesSvc.getFilterItemsFfs(BCF_STATUT.PROPOSITION_COMMANDE).subscribe(response => {
      this.filterItemsFournisseur = response.resultList;
    });
  }

  onPropositionAdded = async (): Promise<void> => {
    await this.grid.instance.refresh();
  }

  onChangeFilterDateLivraison = async (): Promise<void> => {
    if (this.grid)
      await this.grid.instance.refresh();
  };

  onChangeFilterFournisseurs = async ($event): Promise<void> => {
    if (this.grid) {
      this.fournisseursIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  onChangeFilterUdps = async ($event): Promise<void> => {
    if (this.grid) {
      this.udpIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  onChangeFilterListesBesoins = async ($event): Promise<void> => {
    if (this.grid) {
      this.listesBesoinsIdsSelected = $event.value;
      await this.grid.instance.refresh();
    }
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const ssWrapper = new SearchSupplierWrapper();
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        ssWrapper.filtersMap['statutCode'] = new SearchSupplier(BCF_STATUT.PROPOSITION_COMMANDE);
        if (!this.utils.isCollectionNullOrEmpty(this.filterDatesLivraisons)) {
          const startDate = this.filterDatesLivraisons[0].getTime();
          let stopDate = _cloneDeep(startDate);
          if (this.filterDatesLivraisons[1]) {
            stopDate = this.filterDatesLivraisons[1].getTime();
          }
          ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
          ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
        }

        if (this.listesBesoinsIdsSelected && this.listesBesoinsIdsSelected.length)
          ssWrapper.filtersMap['listesBesoins'] = new SearchSupplier(undefined, this.listesBesoinsIdsSelected);
        if (this.udpIdsSelected && this.udpIdsSelected.length)
          ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, this.udpIdsSelected);
        if (this.fournisseursIdsSelected && this.fournisseursIdsSelected.length)
          ssWrapper.filtersMap['ffs'] = new SearchSupplier(undefined, this.fournisseursIdsSelected);

        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.commandesSvc.searchCommandes(ssWrapper, urlPaginationParams).toPromise().then(response => {
          this.propositionsCommandes = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  /**
   * Abonnement à l'ouverture de l'historique des mail pour mettre à jour le statut mail du bonCf
   */
  mailHistoBonCfSubscription = (): void => {
    this.subMailHistoBonCf = this.bonCfSvc.mailHistoBonCf$.subscribe(async () => {
      await this.grid.instance.refresh();
    });
  };

  sendEmail = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(async rowsSelected => {
        const result = confirm(`Confirmez-vous l'envoi des commandes sélectionnées aux fournisseurs ?`, `Confirmation`);
        const isConfirmed: boolean = await result;
        if(isConfirmed) {
          const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.propositionsCommandes.find(p => id === p.id));
          this.commandesSvc.sendMailToFournisseurs(selectedBonCfs);
        }
      });
  };

  subcriptionRefreshGrid = (): void => {
    this.subRefreshGridCommandeProposition = this.commandesSvc.refreshGridCommandeProposition$.subscribe( async () =>{
      await this.grid.instance.refresh();
    });
  }

  /**
   * Abonnement à la modification  d'un bon de commande, notamment pour mettre à jour le montant HT
   */
  bonCfSubscription = (): void => {
    this.subBonCf = this.bonCfSvc.bonCf$.subscribe(async () => {
      await this.grid.instance.refresh();
    });
  };

  /**
   * Abonnement à l'envoi d'un ou plusieurs bons de commandes par email afin de mettre à jour le statut du bon de commande.
   */
  mailsBonCfEnvoyesSubscription = (): void => {
    this.subMailsBonCfEnvoyes = this.commandesSvc.mailsBonCfEnvoyes$
      .pipe(
        switchMap(bonsCfDTO => {
          return this.gds.search(this.commandesSvc.filterStatusMails(bonsCfDTO));
        }),
        switchMap(response => {
          this.commandesSvc.updateStatusBonsCommandesFromRefresh(response.resultList, this.propositionsCommandes, true);
          return of(response.resultList);
        }),
        switchMap( (mailsEnvoyes) => this.commandesSvc.refreshStatusMailsPeriodically(mailsEnvoyes, this.propositionsCommandes))
      )
      .subscribe(async (response) => {
        await this.grid.instance.refresh();
        const mails = _uniqBy(response.resultList, mail => mail.extraInfos);
        this.commandesSvc.updateStatusBonsCommandesFromRefresh(mails, this.propositionsCommandes, false);
      });
  };

  printPDFCommandes = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.propositionsCommandes.find(p => id === p.id));
        this.commandesSvc.printPDFCommandes(selectedBonCfs).subscribe(response => {
          const blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, 'propositions.pdf');
        });
      });
  };

  updateToCommandeRefusees = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_REFUSEE).subscribe(async (response) => {
          if (!this.utils.isResponseSupplierError(response)) {
            await this.grid.instance.refresh();
          }
        });
      });
  };


  updateToCommandeConfirmees = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.commandesSvc.updateCommandesStatut(rowsSelected, BCF_STATUT.COMMANDE_A_VALIDER).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
  };


  openEditFournisseurDialog = (bonCf: BonCfDTO, openAddArticlesFournisseurDialog: boolean): void => {


    this.bonCf = undefined;

    this.commandesSvc.getLignesCommandeFournisseur(bonCf.id).subscribe(response => {

      this.lignesBonCf = [];

      if (!this.utils.isResponseSupplierError(response)) {
        this.displayEditDialog = true;
        this.dialogTitle = this.commandesSvc.getCfTitle(bonCf);
        this.lignesBonCf = response.resultList;
        this.bonCf = _cloneDeep(bonCf);

        if (openAddArticlesFournisseurDialog) {
          this.bonCfDetailSvc.announceAvailableArticles(this.bonCf);
        }
      }
    });
  };

  deleteBonCfs = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.propositionsCommandes.find(p => id === p.id));
    // on ne doit pas supprimer les bons de commandes des sites secondaires
    const bonCfsToDelete = selectedBonCfs.filter(boncf => this.auth2Svc.isSiteLocal(boncf.site.id));
    const bonCfsToNoNotDelete = selectedBonCfs.filter(boncf => !this.auth2Svc.isSiteLocal(boncf.site.id));

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToNoNotDelete))
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Vous ne pouvez pas supprimer la/les commande(s) ${bonCfsToNoNotDelete.map(item => item.numeroBcf).join(', ')}. La/Les commande(s) appartiennent à un site référent.`);

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToDelete)) {
      this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
        this.commandesSvc.delete(bonCfsToDelete).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
    }
  };

  showAddDialogByDenree = (): void => {
    this.besoinSvc.announceOpenPropositionDialogDenree(true);
  };

  showAddDialog = (): void => {

    this.udpSvc.getUniteDeProductionlist(TYPE_LIST_UDP.OnlyLocal).subscribe(response => {

      this.displayAddDialog = true;

      // TODO gestion site principaux multiples
      this.uniteDeProductionList = response.resultList as UniteDeProductionDTO[];
      if (this.utils.isCollectionNullOrEmpty(this.uniteDeProductionList)) {
        this.uniteDeProductionList = [];
      }
      // this.uniteDeProductionList = this.uniteDeProductionList.filter(udp => udp.site.id === this.auth2Svc.utilisateur.sitePrincipal.id);
      this.uniteDeProductionList = this.uniteDeProductionList.filter(udp => this.auth2Svc.isSiteLocal(udp.site.id));
    });
  };


  newPropositionCommande = (bonCf: BonCfDTO): void => {
    this.displayAddDialog = false;
    if (this.utils.isCollectionNullOrEmpty(this.propositionsCommandes)) {
      this.propositionsCommandes = [];
    }

    this.propositionsCommandes.unshift(bonCf);
    this.selectedBonCfs = [];
    this.selectedBonCfs.push(bonCf);

    // on incremente de 1 le nombre total d'éléments
    this.totalRecords = this.totalRecords + 1;

    // on ouvre les lignes de la proposition de commande.
    this.openEditFournisseurDialog(bonCf, true);
  };

  /**
   * Indique si le BonCfDTO est modifiable.
   * @param bonCF
   */
  canModify = (bonCF: BonCfDTO): boolean => this.gds.canModify(bonCF);

  help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Gérer les commandes fournisseur`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    const p1: Paragraphe = new Paragraphe();
    p1.title = ` Création d’une <b>proposition de commande</b>`;
    p1.lines = [
      `Après avoir initié une création de commandes vous devrez renseigner les rubriques indiquées pour générer une proposition de commande.`,
      `Vous pourrez alors ajouter des articles à cette commande en précisant les quantités souhaitées en Unités de Commandes, voire ajouter une remarque pour un article donné ou encore une remarque d’ordre général concernant la commande.`,
      `N’oubliez pas d’enregistrer votre commande avant de quitter une fenêtre.`,
    ];

    dms.content = {
      intro: `Les <b>commandes fournisseur</b> fonctionnent selon une logique de « workflow » : cela signifie qu’une commande passera par une succession d’états pour aller d’une proposition de commandes qui constitue le 1er état jusqu’à un bon à facturer qui est le dernier état.
      <ul>
<li>Proposition de commande</li>
<li>Commande à valider</li>
<li>Commande à réceptionner</li>
<li>Commande réceptionnée</li>
<li>Commande facturée</li>
</ul>
      Après avoir sélectionné une ou plusieurs commandes fournisseurs, vous pourrez les faire passer à l’état suivant.`,
      paragraphes: [p1]
    };
    return dms;
  };

  openHistoriqueMails = (bonCf: BonCfDTO) => {
    this.bonCfSvc.announceHistoriqueMailBonCf(bonCf);
  };
}
