import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {ProduitArticleDTO} from '../../../core/dtos/produit-article-dto';
import {ActivatedRoute} from '@angular/router';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {Subscription} from 'rxjs';
import {LazyLoadEvent} from 'primeng/api';
import {ModelViewMatierePremiere} from './model-view-matiere-premiere';
import {StockDTO} from '../../../core/dtos/stock-dto';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from '../../../core/constants';
import {ToastService} from "../../../core/services/technique/toast.service";
import {DATAGRID_ROW_TYPES} from "../../../core/services/technique/devextreme.service";

@Component({
  selector: 'yo-matieres-premieres',
  templateUrl: './matieres-premieres.component.html',
  styleUrls: ['./matieres-premieres.component.scss']
})
export class MatieresPremieresComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  subResultSearchStocks: Subscription;
  subLoadStocks:Subscription;


  articles: ProduitArticleDTO[] = [];
  matieresPremieres: ModelViewMatierePremiere[] = [];
  selectedMatierePremiere: ModelViewMatierePremiere;
  totalDenrees = 0;
  stocks:StockDTO[]=[];

  // criteres de la recherche du panneau gauche
  filters : any;

  cols: any[] = [
    {field: 'action', header: 'Actions'},
    {field: 'libelle', header: 'Déclinaison'},
    {field: 'code', header: 'Code'},
    {field: 'quantiteEnKilo', header: 'Qté en Kilo'},
    {field: 'pump', header: 'P.U.M.P'},
    {field: 'totalHT', header: 'Total HT'},
  ];


  constructor(public utils: UtilsService,
              private stocksSvc: StocksService,
              private produitDeclinaisonSvc:ProduitDeclinaisonService,
              private route: ActivatedRoute,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subscritionRouteData();
    // s'abonner à la recherche des articles dans le stock
    this.subscritionResultSearchStocks();
    // declencher la recuperation des lignes de stock d'une matiere premiere
    this.subscritionLoadStocks();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchStocks);
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subLoadStocks);
  }

  subscritionResultSearchStocks = () => {
    this.subResultSearchStocks = this.produitDeclinaisonSvc.resultSearchStocksForMatieresPremieres$
      .subscribe(response => {
        this.stocks=[];
        this.matieresPremieres = response.resultList;
        this.totalDenrees = response.totalElements;
        this.filters = response.additionalProperties['filters'];

        // recharger les lignes de lot si une matiere premiere est selectionnée et toujours visible
        this.selectedMatierePremiere = this.utils.preSelectSingleList(this.matieresPremieres,this.selectedMatierePremiere);

        if(!this.utils.isNullOrEmpty(this.selectedMatierePremiere)){
          this.stocksSvc.announceLoadStocks(this.selectedMatierePremiere)
        }
      });
  };

  subscritionRouteData = () => {
    this.subRoute = this.route.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature);

    });
  };

  subscritionLoadStocks = () => {
    this.stocksSvc.loadStocks$.subscribe(selectedMatierePremiere => {
      this.stocks = selectedMatierePremiere.children;
      this.selectedMatierePremiere = selectedMatierePremiere;
    });
  };

  lazyLoad = ($event: LazyLoadEvent) => {
    this.stocks=[];
    this.produitDeclinaisonSvc.lazyLoadStocks($event);
  };

  onRowSelect = ($event: any) => {
    if($event.rowType === 'data') {
      this.stocks = [];
      if ($event.data && !$event.data.noStock) {
        this.stocksSvc.announceLoadStocks($event.data);
      }
    }
  };

  openDialogPerimes = () => {
    if(this.utils.isNullOrEmpty(this.filters) || this.utils.isNullOrEmpty(this.filters['datePeremption'] )){
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Le champ de recherche "Périmé le" doit être renseigné pour activer la suppression des matières premières périmées`);
    }else{
      let lotsArticlesLoaded = this.matieresPremieres.reduce((results, item) => {
        item.children.forEach(child => results.push(child.lotArticle));
        return results;
        },
        []);
      this.stocksSvc.announceOpenDialogPerimes(lotsArticlesLoaded, this.selectedMatierePremiere);
    }
  };

  openDialogAjoutLot = (selectedMatierePremiere : ModelViewMatierePremiere) => {
    this.selectedMatierePremiere = selectedMatierePremiere;
    this.produitDeclinaisonSvc.announceOpenDialogAjoutLot(this.selectedMatierePremiere, null);
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      if (event.data?.noStock) {
        event.rowElement.style.backgroundColor = '#ffdbcd';
        event.rowElement.style.fontWeight = 'bold';
      } else {
        event.rowElement.style.backgroundColor = '#a8e8ad';
        event.rowElement.style.fontWeight = 'bold';
        event.rowElement.style.cursor = 'pointer';
      }
    }
  };

}
