<div [style.max-width.px]="720" [style.max-height.px]="420" class="overflow-scroll">
  <!--hack pour eviter un autofocus sur les p-radioButton-->
  <input style="width:0px !important;height:0px !important;border:none !important;outline:none !important;">
  <table id="articlesDispos">
    <thead class="header">
    <tr>
      <th [style.width.px]="250">Article</th>
      <th [style.width.px]="100">Prix unitaire</th>
      <th [style.width.px]="250">Fournisseur</th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngFor="let ca of _articlesDisponibles">
      <ng-container [ngSwitch]="ca.groupeAchatCaList.length">

        <!--    1 seul groupement d'achat pour l'article-->
        <ng-container *ngSwitchCase="1">
          <ng-container
            *ngTemplateOutlet="tpl1GroupeAchat;context:{ca:ca, gaca:ca.groupeAchatCaList[0]}"></ng-container>
        </ng-container>

        <!--      plusieurs groupe achat pour l'article-->
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="tplPlusieursGroupesAchats;context:{ca:ca}"></ng-container>
        </ng-container>

      </ng-container>
    </ng-container>


    </tbody>
  </table>
</div>

<!--TEMPLATE FOURNISSEUR PREFERE-->
<ng-template #tplFournisseurPrefere let-ca="ca">
  <div class="d-flex">
    <div class="width-20">
      <i title="fournisseur préféré"
         class="fab fa-first-order fa-xs prefere-color mg-l-5"
         *ngIf="isFournisseurPrefere(ca.fournisseur.libelle)"></i>
    </div>
    <div>
      {{ca.fournisseur.libelle}}
    </div>
  </div>
</ng-template>

<!--TEMPLATE 1 GROUPE ACHAT-->
<ng-template #tpl1GroupeAchat let-ca="ca" let-gaca="gaca">
  <tr>
    <td [style.width.px]="250">
      {{ca.produitArticle.libelle}}
    </td>
    <td>

      <div class="d-flex pg-4">
        <!-- selectionner le prix-->
        <div class="width-25">
          <p-radioButton [name]="'groupname'"
                         [value]="gaca"
                         [(ngModel)]="_selectedGacaViewModel"
                         (ngModelChange)="updateGacaViewModel($event)"
                         binary="true">
          </p-radioButton>
        </div>

        <!-- afficher le prefere-->
        <div class="width-22">
          <i *ngIf="gaca.prefere" class="mg-r-5 fas fa-star cursor rotate-1-turn" pTooltip="Article préféré"></i>
          <i *ngIf="!gaca.prefere" title="Cliquez ici pour le considérer en tant qu'article préféré" (click)="updateItemPrefered($event, gaca)" class="mg-r-5 far fa-star cursor rotate-1-turn" pTooltip="Article préféré"></i>
        </div>

        <!-- prix en uf-->
        <div class="width-120">
          {{gaca.prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
          / {{ca.uniteDeFacturation.libelle | slice:0:5}}
        </div>

      </div>

    </td>
    <td [style.width.px]="250">
      <ng-container *ngTemplateOutlet="tplFournisseurPrefere;context:{ca:ca}"></ng-container>
    </td>
  </tr>
</ng-template>


<!--TEMPLATE PLUSIEURS GROUPE ACHAT-->
<ng-template #tplPlusieursGroupesAchats let-ca="ca">

  <tr>

    <td [style.width.px]="250">
      {{ca.produitArticle.libelle}}
    </td>

    <td>
      <ng-container *ngFor="let gaca of ca.groupeAchatCaList">
        <div class="d-flex pg-4">

          <!-- selectionner le prix-->
          <div class="width-25">
            <p-radioButton [name]="'groupname'+unicityIdForGroupeName"
                           [value]="gaca"
                           [(ngModel)]="_selectedGacaViewModel"
                           (ngModelChange)="updateGacaViewModel($event)"></p-radioButton>
          </div>

          <!-- afficher le prefere-->
          <div class="width-22">
            <i *ngIf="gaca.prefere" class="mg-r-5 fas fa-star cursor rotate-1-turn" pTooltip="Article préféré"></i>
            <i *ngIf="!gaca.prefere" title="Cliquez ici pour le considérer en tant qu'article préféré" (click)="updateItemPrefered($event, gaca)" class="mg-r-5 far fa-star cursor rotate-1-turn" pTooltip="Article préféré"></i>
          </div>

          <!-- prix en uf-->
          <div class="width-120">
            {{gaca.prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
            / {{ca.uniteDeFacturation.libelle | slice:0:5}}
          </div>

          <!-- groupement d'achat-->
          <div class="width-40 font-14 cursor" pTooltip="Groupement d'achat">
            ({{gaca.libelleGroupeAchat}})
          </div>
        </div>
      </ng-container>
    </td>

    <td [style.width.px]="250">
      <ng-container *ngTemplateOutlet="tplFournisseurPrefere;context:{ca:ca}"></ng-container>
    </td>


  </tr>

</ng-template>


