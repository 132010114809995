<dx-toolbar class="yo-toolbar p-mb-4">
  <dxi-item location="before">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <button
      pButton label="CRÉER"
      title="Créer un plan de production"
      [disabled]="!canCreatePlanProduction()"
      icon="fa fa-plus"
      type="button"
      (click)="openInitProcessus()">
    </button>
  </dxi-item>
  <dxi-item location="after">
    <button
      pButton
      icon="fa fa-trash"
      (click)="deletePlanProdItems()"
      [disabled]="isActionDisabled"
      class="cta-delete"
      title="Supprimer les plans de production sélectionnés">
    </button>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="planProductionItems"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onSelectionChanged)="checkDisabledButtons()"
  columnResizingMode="widget"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="250" cellTemplate="templateActions" [allowSorting]="false" alignement="left" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" cellTemplate="templateSite" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Plan de production" [width]="'auto'" cellTemplate="templateLibelle" [setCellValue]="changeValueLibelle" [allowEditing]="true"></dxi-column>
  <dxi-column dataField="date" caption="Date de création" cellTemplate="templateDate" [allowEditing]="false" alignment="left"></dxi-column>
  <dxi-column dataField="code" caption="Code" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="currentStep" caption="Avancement" cellTemplate="templateAvancement" [allowSorting]="false" [allowEditing]="false" alignment="left"></dxi-column>
  <div *dxTemplate="let cell of 'templateActions'">
    <td>
      <button pButton icon="fas fa-edit"
              class="mg-l-5 cta-edit-or-watch mg-r-15"
              title="Visualiser le plan de production"
              showDelay="500"
              (click)="editCurrentLine(cell.row.data.id)"
              [disabled]="!canEditProductionPlan(cell.row.data)">
      </button>
      <dx-drop-down-button
        [splitButton]="false"
        [useSelectMode]="false"
        text="Accéder à une étape"
        keyExpr="id"
        displayExpr="label"
        [dropDownOptions]="{ width: 230 }"
        [items]="data"
        (onItemClick)="onClickStepForDirectAccess($event, cell.row.data.id, cell.row.data.currentStep)"
      >
      </dx-drop-down-button>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site [siteLibelle]="cell.row.data.site.libelle"
             [siteId]="cell.row.data.site.id"></yo-site>
  </div>
  <div *dxTemplate="let cell of 'templateLibelle'">
    <strong>{{ cell.value }}</strong>
  </div>
  <div *dxTemplate="let cell of 'templateDate'">
    <td style="text-align: left;">
      {{cell.row.data.dateCreation | date:'E d LLL yyyy' | titlecase}}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateAvancement'">
    <td (click)="openStep(cell.row.data.id)" [class.hover-background-grey]="cell.row.data.currentStep !== 'STEP_END'">
      <div>
        <i [class]="getClassFromCurrentStep(cell.row.data.id) + ' fas fa-square fa-lg mg-r-10'"></i>
        <label class="cursor">{{ getLabelFromCurrentStep(cell.row.data.id)  | uppercase}}</label>
      </div>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} plan(s) de production">
  </dxo-pager>

</dx-data-grid>

<yo-init-plan-de-production></yo-init-plan-de-production>

