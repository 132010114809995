import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestiongemrcnRoutingModule} from './gestiongemrcn-routing.module';
import {GestiongemrcnRootComponent} from './gestiongemrcn-root/gestiongemrcn-root.component';
import {GrilleModelesNutrisComponent} from './grille-modeles-nutris/grille-modeles-nutris.component';
import {ModeleNutriComponent} from './plan-alimentaire/modele-nutri/modele-nutri.component';
import {GrilleFrequencesGemrcnComponent} from './grille-frequences-gemrcn/grille-frequences-gemrcn.component';
import {SharedModule} from '../shared/shared.module';
import {PlanAlimentaireComponent} from './plan-alimentaire/plan-alimentaire.component';
import {FicheIdentiteComponent} from './plan-alimentaire/fiche-identite/fiche-identite.component';
import {PlanningComponent} from './plan-alimentaire/planning/planning.component';
import {FamillesPlatsComponent} from './plan-alimentaire/familles-plats/familles-plats.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TabMenuModule} from 'primeng/tabmenu';
import {MultiSelectModule} from 'primeng/multiselect';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {DxDataGridModule, DxToolbarModule} from "devextreme-angular";


@NgModule({
    imports: [
        CommonModule,
        GestiongemrcnRoutingModule,
        SharedModule,
        CoreModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,

        TableModule,
        CheckboxModule,
        CardModule,
        DropdownModule,
        OverlayPanelModule,
        TabMenuModule,
        MultiSelectModule,
        DialogModule,
        ListboxModule,
        SelectButtonModule,
        TooltipModule,
        ButtonModule,
        DxDataGridModule,
        DxToolbarModule
    ],
  declarations: [
    GestiongemrcnRootComponent,
    GrilleModelesNutrisComponent,
    GrilleFrequencesGemrcnComponent,
    PlanAlimentaireComponent,
    FicheIdentiteComponent,
    PlanningComponent,
    FamillesPlatsComponent,
    ModeleNutriComponent
  ],
  exports: [
    GrilleFrequencesGemrcnComponent
  ],
  entryComponents: [],
  providers: []
})
export class GestiongemrcnModule {
}
