<div class="d-flex flex-row align-items-center">
  <h5 class="mg-b-0 mg-r-15">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <label class="badge badge-secondary">
      {{'plan de production' | uppercase}}
    </label>
  </h5>
  <div><strong>"{{planProduction?.libelle | uppercase}}"</strong>
    <yo-site class="mg-l-15" [siteLibelle]="planProduction?.site?.libelle" [siteId]="planProduction?.site?.id"></yo-site>
    <span [title]="currentEffectif" class="type-effectif mg-l-10">(C'est l'{{currentEffectif}} qui est utilsé pour le calcul du plan de production)</span>
  </div>
</div>

<div class="d-flex flex-row justify-content-between mg-b-10">
  <div>
    <button
      pButton
      title="Appliquer les modifications aux ligne(s) selectionnée(s)"
      label="Traitement en masse"
      icon="fas fa-edit"
      [disabled] = "selectedDetails.length === 0 || (!valueConsumptionDate || !this.valueMeal || !this.valuePlc || !this.valueTeam)"
      (click)="openBulkEditionDetailDialog()">
    </button>
  </div>
  <div>
    <span class="mg-r-5">
      <button
        pButton
        title="Options d'affichage"
        icon="fa fa-desktop"
        (click)="openOptionsDisplayDialog()">
      </button>
    </span>
    <span class="mg-r-5" title="Générer une liste de besoins à partir du plan de production courant">
      <button
        pButton
        title="Générer une liste de besoins à partir du plan de production courant"
        icon="fas fa-box"
        (click)="genererListeBesoins()">
      </button>
    </span>
    <span class="mg-r-5" title="Lancer le calcul du conditionnement">
      <button
        pButton
        title="Lancer le calcul du conditionnement"
        icon="fas fa-boxes"
        (click)="openPreparationConditionnement()">
      </button>
    </span>
    <span class="mg-r-5" title="Rafraîchir le calcul du conditionnement">
      <button
        pButton
        title="Rafraîchir le calcul du conditionnement"
        icon="fas fa-cogs"
        (click)="openPreparationConditionnement(true)">
      </button>
    </span>

    <span class="mg-r-5" title="Sorties de production">
      <button
        pButton
        title="Sorties de production"
        icon="fas fa-sign-out-alt"
        (click)="generateSortiesPlanProduction()">
      </button>
    </span>

    <button
      type="button"
      pButton
      icon="fas fa-print"
      (click)="openPrintSettingsFichesTechniques()"
      title="Sélectionner une impression">
    </button>
  </div>
</div>

<dx-data-grid
  id="gridDetailPlanProd"
  (onCellPrepared)="onCellPrepared($event)"
  (onEditingStart)="onEditingStart($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  [dataSource]="planProduction?.rows"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [height]="utils.getWindowAvailableHeight(250)"
  columnResizingMode="widget"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxi-column alignment="right" dataField="atelier.libelle" caption="Atelier" [allowEditing]="false" [groupIndex]="0"></dxi-column>
  <dxi-column alignment="right"
              [dataField]="columnNameEquipe"
              caption="Équipe"
              cellTemplate="equipeCellTemplate"
              headerCellTemplate="updateHeaderCellTemplate"
              editCellTemplate="equipeEditCellTemplate"
              [visible]="isColumnTeamVisible()"
              [allowEditing]="isEditable()">
  </dxi-column>
  <dxi-column alignment="right" dataField="uniteDeProduction.libelle" caption="Unité de Production" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="produitDeclinaison.libelle" cellTemplate="produitDeclinaisonCellTemplate" [width]="250" caption="Nom de la fiche technique"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="produitDeclinaison.declinaison.libelle" caption="Déclinaison" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="offreAlimentairePrestation.libelle" caption="Prestation" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="pointDeLivraisonClient.libelle" caption="Point de livraison" [visible]="isColumnPlcVisible()" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="repas.libelle" caption="Repas" [visible]="isColumnMealVisible()" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" [dataField]="columnNameNbParts" caption="Nb. portions" cellTemplate="effCellTemplate" [allowEditing]="isEditable()"></dxi-column>
  <dxi-column alignment="right" dataField="poidsNetUT" caption="Poids net total" cellTemplate="weightCellTemplate" [allowEditing]="false"></dxi-column>

  <dxi-column alignment="right" [dataField]="columnNameDateFab" caption="Date de fab." headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateFab" [allowEditing]="isEditable()"></dxi-column>
  <dxi-column alignment="right" dataField="dateConso" caption="Date de conso." [visible]="isColumnConsumptionDateVisible()" [allowEditing]="false" dataType="date"
              cellTemplate="date"></dxi-column>
  <dxi-column alignment="right" [dataField]="columnNameDateDlc" caption="Date limite de conso." headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateDlc" [allowEditing]="isEditable()"></dxi-column>

  <dxo-summary>
    <dxi-total-item
      column="id"
      displayFormat="{0} éléments"
      showInColumn="produitDeclinaison.declinaison.libelle"
      summaryType="count">
    </dxi-total-item>
  </dxo-summary>

  <dxo-search-panel [visible]="true"></dxo-search-panel>

  <dxo-editing mode="cell" [allowUpdating]="isEditable()" [confirmDelete]="false"></dxo-editing>
  <dxo-selection
    [selectAllMode]="'true'"
    [showCheckBoxesMode]="'always'"
    mode="multiple">
  </dxo-selection>
  <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
    <div [title]="'Colonne modifiable : ' + cell.column.caption">
      {{cell.column.caption}}
    </div>
  </div>

  <div *dxTemplate="let cell of 'date'">
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>

  <div *dxTemplate="let cell of 'effCellTemplate'">
    <i *ngIf="cell.row.data.nbParts == 0" class="fas fa-exclamation-triangle space yoni-color"
       [title]="getToolTipEffectifsZero(cell)">
    </i>
    {{ cell.value }} {{ cell.data.produitDeclinaison.uniteDeMesure.abreviation }}
  </div>

  <div *dxTemplate="let cell of 'weightCellTemplate'">
    {{ cell.value }} g
  </div>

  <div *dxTemplate="let cell of 'dateDlc'">
    <i *ngIf="dlcLessThanDateConsommation(cell.row.data)" class="fas fa-exclamation-triangle space yoni-color"
       [title]="getToolTipDlc(cell)">
    </i>
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>

  <div *dxTemplate="let cell of 'dateFab'">
    {{cell.value | date:'E d LLL yyyy' | titlecase}}
  </div>

  <div *dxTemplate="let cell of 'equipeGroupCellTemplate'">
    Équipe: {{cell.data.items[0].equipeLibelle}}
  </div>

  <div *dxTemplate="let cell of 'produitDeclinaisonCellTemplate'">
    <a (click)="displayParentsDishes(cell.data.produitDeclinaison?.id, cell.data.parent.id)" *ngIf="cell.data.parent && cell.data.parent.id && cell.data.parent.produitDeclinaison" title="Obtenir la liste des plats parents utilisant ce sous-plat" showDelay="500">
      <i (click)="displayParentsDishes(cell.data.produitDeclinaison?.id, cell.data.parent.id)" class="fa fa-bars mg-r-10 cursor" aria-hidden="true"></i>
    </a>
    <a [title]="'Aller sur la fiche technique ' + cell.value + (cell.data.parent && cell.data.parent.id && cell.data.produitDeclinaison ? ' (' + cell.data.produitDeclinaison.produit?.typeProduit?.libelle + ')' : '') " showDelay="500"
       (click)="goToProductPage(cell.data.produitDeclinaison?.produit?.id)">
      <span class="cursor save-color">{{cell.value}} <span class="subrecipe-color" title="Regroupé">{{ cell.data.parent && cell.data.parent.id && cell.data.produitDeclinaison ? '(' + cell.data.produitDeclinaison.produit?.typeProduit?.libelle + ')' : '' }}</span></span>
    </a>
  </div>

  <div *dxTemplate="let cell of 'equipeCellTemplate'">
    {{cell.value}}
  </div>
  <div *dxTemplate="let cell of 'equipeEditCellTemplate'">
    <dx-lookup (onSelectionChanged)="changeTeam(cell.value?.id, cell.data?.id)" [(value)]="cell.value" [dataSource]="dataSourceTeams" [grouped]="true" displayExpr="equipeLibelle" itemTemplate="itemTpl" groupTemplate="groupTpl">
      <dxo-drop-down-options [showTitle]="false">
        <div *dxTemplate="let item of 'groupTpl'">
          <span [title]="item.key"><i class="fas fa-store mg-r-5"></i> {{ item.key }}</span>
        </div>
        <div *dxTemplate="let item of 'itemTpl'">
          <span [title]="item.equipeLibelle"><i class="fas fa-users mg-r-5"></i> {{ item.equipeLibelle }}</span>
        </div>
      </dxo-drop-down-options>
    </dx-lookup>
  </div>

</dx-data-grid>

<div class="d-flex mg-t-15 text-center" id="footer-step">
  <div class="col-lg-12 text-center">
    <button
      (click)="previousStep()"
      pButton
      iconPos="left"
      type="button"
      class="p-button-primary"
      label="Précédent"
      title="Génération du plan de production"
      showDelay="800"
      icon="fas fa-angle-left">
    </button>
  </div>
</div>

<yo-print-settings
  [displayDialog]="displayPrintSettingsFichesTechniques"
  [datesConso]="datesConso"
  [datesFab]="datesFab"
  [equipes]="teams"
  [planProd]="planProduction" (onClose)="displayPrintSettingsFichesTechniques=$event">
</yo-print-settings>

<yo-bulk-edition-details-dialog></yo-bulk-edition-details-dialog>

<dx-popup
  title="Options d'affichage"
  [showTitle]="true"
  [height]="'auto'"
  [width]="550"
  (onHiding)="closeOptionsDisplayDialog()"
  [(visible)]="displayOptionsDisplayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col-md-12">
      <label>Détail des lignes par : </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueTeam" text="Equipe"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valuePlc" text="Point de livraison client"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueMeal" text="Repas"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueConsumptionDate" text="Date de consommation"></dx-check-box>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <label>Affichage sous-recettes : </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <dx-check-box [(ngModel)]="displayOnlyMainRecipes" text="Ne pas afficher les sous-recettes"></dx-check-box>
    </div>
  </div>
  <div class="row float-right mg-t-15">
    <div class="col-md-12">
      <button class="p-button-success" pButton label="Enregistrer" icon="fas fa-save" (click)="applyGrouping()"></button>
    </div>
  </div>
</dx-popup>

<dx-popup
  title="Liste des plats parents : "
  [showTitle]="true"
  [height]="'auto'"
  [width]="'50%'"
  (onHiding)="closeParentsDishes()"
  [(visible)]="displayParentsDishesDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <dx-data-grid
    id="gridDishesParents"
    [dataSource]="dishesParents"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    columnResizingMode="widget"
    #grid>
    <dxi-column alignment="right" dataField="produitDeclinaison.libelle" caption="Fiche technique" [allowEditing]="false"></dxi-column>
    <dxi-column alignment="right" dataField="formattedDateFab" caption="Date de fabrication" [allowEditing]="false"></dxi-column>
    <dxi-column alignment="right" dataField="nbParts" cellTemplate="nbPartsTemplate" caption="Nombre de parts" [allowEditing]="false"></dxi-column>
    <div *dxTemplate="let cell of 'nbPartsTemplate'">
      {{cell.value}} {{ cell.data.produitDeclinaison?.uniteDeMesure?.abreviation }}
    </div>
  </dx-data-grid>
</dx-popup>

<dx-popup
  title="Génération d'une liste de besoin"
  [showTitle]="true"
  [height]="'auto'"
  [width]="650"
  (onHiding)="closeOptionsGenerationListeBesoin()"
  [(visible)]="displayOptionsGenerationListeBesoinDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col-md-12">
      <label>Il est possible de filtrer les denrées de votre future liste de besoins directement à partir des filtres proposés ci-dessous, cette possibilité
      est facultative, vous pouvez donc générer directement votre liste de besoins sans filtres en appuyant directement sur le bouton Générer.
        L'attribution d'un nom pour la future liste de besoins est également facultative, si ce dernier n'est pas renseigné, l'application le fera à votre place
        de manière automatique.
      </label>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <dx-text-box placeholder="Nom de la liste de besoins" [(value)]="newLabelListeBesoin"></dx-text-box>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <dx-tree-view
        #treeViewFamillePlat
        [dataSource]="famillesPlats"
        [height]="150"
        dataStructure="plain"
        keyExpr="id"
        parentIdExpr="parent.id"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        [selectNodesRecursive]="true"
        displayExpr="libelle"
        [selectByClick]="true"
        (onItemSelectionChanged)="onChangeParentFamilleProduitSelection($event)"
        [searchEnabled]="true"
        searchMode="contains"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par famille de produit..."
        ></dxo-search-editor-options>
      </dx-tree-view>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <dx-list
        #list
        [dataSource]="filtersLbde?.fournisseurs"
        [width]="'auto'"
        [height]="150"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        [selectedItemKeys]="idsFournisseursSelected"
        (onSelectionChanged)="onChangeFournisseur($event)"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        searchExpr="libelle"
        searchMode="contains"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par fournisseur..."
        ></dxo-search-editor-options>
      </dx-list>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <dx-list
        #list
        [dataSource]="filtersLbde?.taches"
        [width]="'auto'"
        [height]="150"
        selectionMode="multiple"
        selectAllMode="allPages"
        displayExpr="libelle"
        valueExpr="id"
        [selectedItemKeys]="idsTachesSelected"
        (onSelectionChanged)="onChangeTache($event)"
        [showSelectionControls]="true"
        [searchEnabled]="true"
        searchExpr="libelle"
        searchMode="contains"
      >
        <dxo-search-editor-options
          placeholder="Filtrer par type d'approvisionnement..."
        ></dxo-search-editor-options>
      </dx-list>
    </div>
  </div>
  <div class="row float-right mg-t-15">
    <div class="col-md-12">
      <button pButton label="Générer" icon="fas fa-box" (click)="applyFiltersForGenerateListeBesoins()"></button>
    </div>
  </div>
</dx-popup>
