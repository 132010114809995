<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Modèle de conditionnement plat <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="mcpList"
                     [multiple]="true"
                     [style.width]="'100%'"
                     checkbox="true"
                     formControlName="models"
                     optionLabel="libelle">
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'models',label:'Modèle de conditionnement plat'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end" style="margin-top: 15px">
      <button
        pButton type="submit"
        icon="fas fa-save"
        class="p-button-success"
        label="Enregistrer"
        [disabled]="!canSave()"
        [class.disable]="!canSave()">
      </button>
      <button
        class="mg-l-5 cta-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
