<p-dialog
  [(visible)]="displayDialog"
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [style]="{'width':'1100px'}"
  [contentStyle]="{'overflow':'visible'}">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-search mg-r-5"></i>
      <span class="mg-l-5">RECHECHE DE DENRÉES</span>
    </div>
  </ng-template>

  <div [ngClass]="selectedDenreeList.length === 0 ? 'mg-b-10' : ''">
    <p-autoComplete
      #autocompleteSearch
      inputId="autocompleteSearch"
      placeholder="Recherche..."
      [autofocus]="true"
      [(ngModel)]="denree"
      field="libelle"
      (completeMethod)="search($event)">
    </p-autoComplete>
  </div>

  <div *ngIf="selectedDenreeList.length>0" class="search-element d-flex flex-row align-items-center"
       [style]="{'margin-bottom':'10px','margin-top':'10px'}">
    <i class="fas fa-apple-alt mg-r-5"></i> <strong class="mg-r-5 inline">Contenu panier : </strong>
    <div [style.width.px]="utils.getWindowAvailableWidth(415)">
      <p-chips #basketDenree
               (keydown)="utils.onChangeDisabledInputChips($event)"
               [(ngModel)]="selectedDenreeList"
               [disabled]="isDisabled()"
               (onRemove)="onRemoveChipDenree($event)">
        <ng-template let-item pTemplate="item">
          <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle"> {{item.libelle}}</i>
        </ng-template>
      </p-chips>
    </div>
  </div>

  <dx-data-grid
    [dataSource]="denreeList"
    keyExpr="id"
    [(selectedRowKeys)]="selectedRowKeysDenree"
    [height]="utils.getWindowAvailableHeight(400)"
    width="100%"
    [hoverStateEnabled]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    #gridUdp>
    <dxi-column dataField="id" alignment="center" caption="Actions" [width]="80"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="libelle" alignment="left" caption="Déclinaison" [width]="300"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="code" alignment="left" caption="Code" [width]="300"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="site" alignment="left" caption="Site" [width]="200"
                [allowFiltering]="true"
                cellTemplate="siteCellTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'siteCellTemplate'">
      {{cell.row.data.site.libelle}}
    </div>


    <!--    TEMPLATE-->
    <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <yo-cell-button (yoNavigation)="addDenreeToBasket(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoDisabled]="isDisabled()"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fas fa-plus'"
                      pTooltip="ajouter la denrée à la selection"
                      tooltipPosition="right"
                      showDelay="500">
      </yo-cell-button>
    </div>

    <!--    OPTION-->
    <dxo-pager
      [showPageSizeSelector]="false"
      [showNavigationButtons]="true"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Déclinaison(s)">
    </dxo-pager>
  </dx-data-grid>


  <ng-template pTemplate="footer">
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Completez les 3 onglets pour
          enregister
          et passer à la selection des articles </label>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-plus" (click)="sendDenreeBasket()"
                [disabled]="selectedDenreeList.length === 0" pTooltip="Affection du contenu du panier"
                tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="cta-inner-delete">
        </button>
      </div>
    </div>
  </ng-template>

</p-dialog>
