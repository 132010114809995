<p-dialog header="Gérer la grille"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'780px'}"
          [(visible)]="openDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="fiche-id-size">
      <p-panel header="Associations modèles conditionnements plats (conditionnements variantes) / prestations" [toggleable]="true"
               [style]="{'margin-bottom': '10px'}">

        <div class="row">
          <div class="col-md-6">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="mcpCvList"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'350px'}"
                         [filter]="true"
                         [multiple]="true"
                         [checkbox]="true"
                         [group]="true"
                         formControlName="mcpCv"
                         filterPlaceHolder="Conditionnements *"
              >
              </p-listbox>
            </div>
          </div>

            <div class="col-md-6">
              <div class="p-inputgroup input-group-sm ">
                <p-listbox [options]="prestationsList"
                           [style.width]="'100%'"
                           [listStyle]="{'height':'350px'}"
                           [filter]="true"
                           [multiple]="true"
                           [checkbox]="true"
                           formControlName="prestations"
                           filterPlaceHolder="Prestations *"
                           optionLabel="libelle">
                </p-listbox>
              </div>
            </div>
        </div>

        <div class="d-flex justify-content-between mg-t-10">
          <div>
            <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
          </div>
          <button pButton
                  title="Enregistrer"
                  type="submit"
                  icon="fas fa-save"
                  class="p-button-success"
                  [disabled]="!canModify"></button>
        </div>

      </p-panel>

    </div>
  </form>

</p-dialog>
