import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {PrintCoutRevientPlatService} from '../../../core/services/gestion-plc/print-cout-revient-plat.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {of, Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {DATEPICKER_FR, MIME_TYPE, TYPE_EFFECTIF} from '../../../core/constants';
import {FlatNode} from '../../../core/models/flatNode';
import {SelectionRepasPlcsService} from '../../../core/services/selection-plcs/selection-repas-plcs.service';
import {debounceTime} from 'rxjs/operators';
import {PrintCoutRevientPlatDTO} from '../../../core/dtos/clients/print-cout-revient-plat-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {FrontStorageService} from '../../../core/services/storage/front-storage.service';
import {DxTreeViewComponent} from "devextreme-angular";

@Component({
  selector: 'yo-print-cout-revient-plat',
  templateUrl: './print-cout-revient-plat.component.html',
  styleUrls: ['./print-cout-revient-plat.component.scss']
})
export class PrintCoutRevientPlatComponent implements OnInit, OnDestroy {

  localeFr = DATEPICKER_FR;

  displayDialog: boolean;
  treePlc: any[] = [];
  selectedPlcs: any[] = [];
  selectedUdps: any[] = [];
  typeEffectif: string = TYPE_EFFECTIF.facturation;

  datesSaisieEffectifs: Date[] = [];
  dateCreationMenu: Date[] = [];
  dateMin: Date;
  userMessage: string;
  dateProductionSelected: Date = new Date();
  periodeSelected: Date[] = [];
  newMonth: any;

  TYPE_EFFECTIF = TYPE_EFFECTIF;

  @ViewChild('tree') treeView: DxTreeViewComponent;

  subDisplayDialog: Subscription;
  subChangeMonths: Subscription;


  constructor(private printCrpSvc: PrintCoutRevientPlatService,
              private selectionRepasPlcsSvc: SelectionRepasPlcsService,
              private storageSvc:FrontStorageService,
              public utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initOpenDialog();
    this.initStepperEvent();
    this.getCalendarValidDates();
    let startDate: Date = new Date();
    startDate = new Date(startDate.getFullYear(), 0, 1);
    let endDate: Date = new Date();
    this.periodeSelected = [startDate, endDate];
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayDialog);
    this.utils.unsubscribe(this.subChangeMonths);
  }

  initStepperEvent = () => {
    this.printCrpSvc.getPlcTreeNode().subscribe((response: ResponseWrapper<any>) => {
      this.treePlc = this.printCrpSvc.buildTreeNodePlc(response.resultList);
    });

    this.subChangeMonths = this.selectionRepasPlcsSvc.changeMonths$.pipe(
      debounceTime(500)).subscribe(event => {
      this.newMonth = event;
      this.getCalendarValidDates();
    });

  };

  onChangeSelectionRepas = event => {
    const idsSelected: string[] = this.treeView.instance.getSelectedNodeKeys().filter(key => key.includes("_plc"));
    idsSelected.map(idSelected => {
      let tmp: number[] = idSelected.split('_')[0].split('-').map(id => parseInt(id));
      this.selectedPlcs.push(tmp[2]);

      let idUdpSelected: number = parseInt(idSelected.split('plc-')[1]);
      this.selectedUdps.push(idUdpSelected);
    });
  };

  initOpenDialog = () => {
    this.subDisplayDialog = this.printCrpSvc.displayDialogPrintCoutRevientPlat$.subscribe((response: boolean) => {
      this.displayDialog = response;
    });
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  printCoutRevient = () => {
    let printCoutRevientPlat: PrintCoutRevientPlatDTO = new PrintCoutRevientPlatDTO();
    printCoutRevientPlat.pointsLivraisonClients = [... new Set(this.selectedPlcs) ];
    printCoutRevientPlat.unitesProduction = [... new Set(this.selectedUdps) ];
    printCoutRevientPlat.periode = this.periodeSelected;
    printCoutRevientPlat.dateProductionSelected = this.dateProductionSelected;
    printCoutRevientPlat.typeEffectif = this.typeEffectif;

    this.printCrpSvc.print(printCoutRevientPlat).subscribe(response => {
      let reportName = 'cout_revient_plat';

      let blob = new Blob([response], {
        type: MIME_TYPE.PDF
      });

      fs_saveAs(blob, reportName);
      return of(blob);
    });

  };

  getCalendarValidDates = () => {
    if (this.selectedPlcs && this.selectedPlcs.length) {
      let startDate = new Date();

      if (this.selectedPlcs.length > 0) {

        const plcSpecNodes: TreeNode[] = this.utils.selectedNodesLevel(this.selectedPlcs, 5);
        const flatNodeList: FlatNode[] = FlatNode.treeNodeToFlatNodeList(plcSpecNodes);
        this.selectionRepasPlcsSvc.changeMonthsHttpRequest(this.newMonth, flatNodeList, startDate, 2).subscribe(response => {
          if (response) {

            this.datesSaisieEffectifs = response.additionalProperties['datesSaisieEffectifs'];
            this.dateCreationMenu = response.additionalProperties['dateCreationMenu'];
            this.dateMin = response.additionalProperties['dateMin'];
            this.userMessage = response.additionalProperties['userMessage'];

            this.dateMin = this.utils.convertNumberDateToDate(this.dateMin);

            if (!this.utils.isCollectionNullOrEmpty(this.datesSaisieEffectifs)) {
              this.datesSaisieEffectifs = this.datesSaisieEffectifs.map(item => this.utils.convertNumberDateToDate(item));
            }

            if (!this.utils.isCollectionNullOrEmpty(this.dateCreationMenu)) {
              this.dateCreationMenu = this.dateCreationMenu.map(item => this.utils.convertNumberDateToDate(item));
            }
          }
        });
      }
    }
  };

  changeDates = (event: any) => {
    this.selectionRepasPlcsSvc.announceChangeMonths(event);
  };

  canPrint = (): boolean => this.selectedPlcs && this.selectedPlcs.length && this.dateProductionSelected && this.datesSaisieEffectifs && this.typeEffectif !== undefined

}
