<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'width':'600px'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="far fa-lg fa-file-excel mg-r-5"></i> Impression coût matière
    </div>
  </ng-template>

  <div class="d-flex flex-column justify-content-center mg-b-5 mg-t-7">
    <div class="d-flex flex-row mg-b-5 ">
      <div>
        <label class="mg-r-41 mg-l-5">Du : </label>
        <p-calendar pTooltip="Selectionner la date de début"
                    placeholder="date de début"
                    [showOnFocus]="false"
                    [(ngModel)]="startDate"
                    [showIcon]="true"
                    dateFormat="dd/mm/yy"
                    [readonlyInput]="false"
                    firstDayOfWeek="1"
                    [showWeek]="true"
                    [inputStyle]="{'max-width':'140px','width':'140px'}"
                    [locale]="fr"
                    appendTo="body">
        </p-calendar>
      </div>
      <div>
        <label class="mg-l-45 mg-r-30"> au : </label>
        <p-calendar pTooltip="Selectionner la date de fin"
                    placeholder="date de fin"
                    [showOnFocus]="false"
                    [(ngModel)]="endDate"
                    [showIcon]="true"
                    dateFormat="dd/mm/yy"
                    [readonlyInput]="false"
                    [showWeek]="true"
                    firstDayOfWeek="1"
                    [inputStyle]="{'max-width':'140px','width':'140px'}"
                    [locale]="fr"
                    appendTo="body">
        </p-calendar>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center mg-b-5">
      <label class="mg-l-5 mg-b-0 mg-r-5">Régimes :</label>
      <p-dropdown [options]="regimeOptionList"
                  [(ngModel)]="selectedRegimeId"
                  placeholder="Choisir un régime"
                  appendTo="body"
                  filter="true"
                  [showClear]="true"></p-dropdown>


      <label class="mg-b-0 mg-l-44 mg-r-5">Repas :</label>
      <p-multiSelect [options]="repasOptionlist"
                     [(ngModel)]="repasListResult"
                     defaultLabel="Selection..."
                     [style]="{'width':'172px','max-width':'172px', 'margin-top':'0px'}"
                     appendTo="body"></p-multiSelect>
    </div>
  </div>


  <div class="d-flex justify-content-end ">
    <button type="button" pButton label="Imprimer" [disabled]="this.isValidForm()" icon="fas fa-print"
            class="save-button" (click)="printCoutMatiere()">
    </button>

    <button type="button" pButton class="cta-inner-delete close-button" icon="fas fa-times" (click)="closeDialog()">
    </button>
  </div>
</p-dialog>
