import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {StockDTO} from '../../dtos/stock-dto';
import {UtilsService} from '../../utils/utils.service';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {UniteDeProduction__zoneDeStockageService} from '../entities/unite-de-production__zone-de-stockage.service';
import {Observable, Subject} from 'rxjs';
import {ModelViewInventaire} from '../../../gestion-stocks/stocks/inventaire/model-view-inventaire';
import {ModelViewMatierePremiere} from '../../../gestion-stocks/stocks/matieres-premieres/model-view-matiere-premiere';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {FormFieldCurrencySupplier} from "../../suppliers/form-field-currency-supplier";
import {FormFieldCalendarSupplier} from "../../suppliers/form-field-calendar-supplier";
import {HttpService} from "../technique/http.service";
import {DialogStockPerimesSupplier, URL_POST_DELETE_LOTS} from "../entities/produit-declinaison.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";
import {catchError} from "rxjs/operators";

export const URL_GET_PREPARE_SEARCH = `dolrest/gestion-stocks/prepare-search`;
export const URL_POST_SAVE_INVENTAIRE = `dolrest/gestion-stocks/save/inventaire`;
export const URL_POST_SAVE_STOCK = `dolrest/gestion-stocks/ajout/lot`;
export const URL_POST_UPDATE_STOCK = `dolrest/gestion-stocks/update/lot`;


@Injectable({
  providedIn: 'root'
})
export class StocksService extends GenericHandler<StockDTO> {

  private subjectFeature = new Subject<string>();
  public feature$: Observable<string> = this.subjectFeature.asObservable();

  private subjectLoadStocks = new Subject<ModelViewMatierePremiere>();
  public loadStocks$: Observable<ModelViewMatierePremiere> = this.subjectLoadStocks.asObservable();

  private subjectOpenDialogSortie = new Subject<DialogStockSortieSupplier>();
  public openDialogSortie$: Observable<DialogStockSortieSupplier> = this.subjectOpenDialogSortie.asObservable();

  private subjectIsKeepItClose = new Subject<boolean>();
  public isKeepItClose$: Observable<boolean> = this.subjectIsKeepItClose.asObservable();

  private subjectIsConfirmCancelled = new Subject<boolean>();
  public isConfirmCancelled$: Observable<boolean> = this.subjectIsConfirmCancelled.asObservable();

  private subjectOpenDialogEditLot = new Subject<DialogStockSortieSupplier>();
  public openDialogEditLot$: Observable<DialogStockSortieSupplier> = this.subjectOpenDialogEditLot.asObservable();

  private subjectLotsDeleted = new Subject();
  lotsDeleted$ = this.subjectLotsDeleted.asObservable();

  private subjectOpenDialogPerimes = new Subject<DialogStockPerimesSupplier>();
  openDialogPerimes$ = this.subjectOpenDialogPerimes.asObservable();

  constructor(utils: UtilsService,
              private gds: GenericDatagridService, private udpZdsSvc: UniteDeProduction__zoneDeStockageService,
              auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);


  }

  announceLotsDeleted = () => {
    this.subjectLotsDeleted.next();
  };

  announceOpenDialogPerimes = (lotsArticlesLoaded: string[], selectedMatierePremiere: ModelViewMatierePremiere) => {
    const dialogPerimeSupplier = new DialogStockPerimesSupplier(selectedMatierePremiere, lotsArticlesLoaded);
    this.subjectOpenDialogPerimes.next(dialogPerimeSupplier);
  };

  deleteLotsPerimes = (lotsToDisable: string[]) => this.httpSvc.delete(`${URL_POST_DELETE_LOTS}?lotsToDisable=${lotsToDisable}`);

  createEmptyDTO = (): StockDTO => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: StockDTO): string => '';

  getEntityName = (): string => 'stock';

  getFields = (dto: any): FormFieldBaseSupplier<any>[] => {
    if (!dto) {
      return [];
    }
    const udpZdsList = this.initUdpZdsList(dto.idUdp);
    const udpZdsSelected = udpZdsList.find(udpZds => udpZds.id === dto.idUdpZoneDeStockage);
    const fields: FormFieldBaseSupplier<any>[] = [
      new FormFieldDropdownSupplier({
        key: 'unitesProductionZonesDeProduction',
        label: 'Zone de stockage',
        optionLabel: 'libelleZoneDeStockage',
        readonly: !this.canModify(dto),
        type: 'singleListBox',
        value: this.utils.preSelectSingleList(udpZdsList, udpZdsSelected),
        order: 1,
        options: udpZdsList,
        width: 300,
        required: true,
      }),

      new FormFieldCurrencySupplier({
        key: 'quantite',
        label: 'Quantité',
        width: 150,
        type: 'number',
        suffix: dto.udmStockLibelle,
        value: dto.quantite,
        required: true,
        order: 2
      }),

      new FormFieldCurrencySupplier({
        key: 'prixUnitaire',
        label: 'PU H.T en UF',
        width: 150,
        type: 'number',
        suffix: '€/' + (dto.udmFacturationLibelle ? dto.udmFacturationLibelle : dto.uniteFacturation),
        value: dto.prixUnitaire,
        required: true,
        order: 3
      }),

      new FormFieldCalendarSupplier({
        key: 'dateEntree',
        label: 'Date Entrée',
        value: new Date(dto.dateEntree),
        dateFormat: 'dd/mm/yy',
        required: true,
        showTime: false,
        order: 4
      })

    ];
    return fields;
  };

  initUdpZdsList = (idUdp: number): UniteDeProduction__ZoneDeStockageDTO[] => {
    const udpZdsList = this.udpZdsSvc.udpZdsListEnvironnement;
    if (this.utils.isCollectionNullOrEmpty(udpZdsList)) {
      return [];
    }
    return udpZdsList.filter(item => item.idUniteDeProduction === idUdp);
  };

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => 'GESTION DES STOCKS';

  getTotalRecordsLabel = (): string => '';

  prepareSearch = (idsSites: number[]) => {
    let args: string = '';
    if (idsSites && idsSites.length) args += `&idsSites=${idsSites}`;
    return this.httpSvc.get(`${URL_GET_PREPARE_SEARCH}?1=1${args}`);
  };


  announceFeature = (feature: string) => {
    this.subjectFeature.next(feature);
  };

  public announceIsKeepItClose = (isKeepItClose: boolean) => {
    this.subjectIsKeepItClose.next(isKeepItClose);
  };

  public annonceIsConfirmCancelled = (IsConfirmCancelled: boolean) => {
    this.subjectIsConfirmCancelled.next(IsConfirmCancelled);
  };

  public announceOpenDialogEditLot = (selectedMatierePremiere: ModelViewMatierePremiere, stockDTO: any) => {
    this.subjectOpenDialogEditLot.next(new DialogStockSortieSupplier(stockDTO, null, selectedMatierePremiere));
  };


  saveInventaire = (lignesInventaire: ModelViewInventaire[]) => {
    return this.httpSvc.post(URL_POST_SAVE_INVENTAIRE, lignesInventaire);
  };

  saveStock = (stock: StockDTO) => {
    return this.httpSvc.post(URL_POST_SAVE_STOCK, stock);
  };

  updateStock = (stock: StockDTO) => {
    return this.httpSvc.post(URL_POST_UPDATE_STOCK, stock);
  };


  announceOpenDialogSortie = (filters: any, selectedStock: StockDTO, matierePremiere: ModelViewMatierePremiere) => {
    const dsss = new DialogStockSortieSupplier(selectedStock, filters, matierePremiere);
    this.subjectOpenDialogSortie.next(dsss);
  };

  announceLoadStocks = (matierePremiere: ModelViewMatierePremiere) => {
    this.subjectLoadStocks.next(matierePremiere);
  };


  /**
   * si champ de recherhce 'Périmé le ' renseigné, on prend cette date
   * sinon on prend la date du jour pour considerer que le stock est périmé
   * @param dlc
   */
  isPerime = (dlc: number, filters: any) => {
    let datePerime = new Date();

    if (filters && filters.datePeremption)
      datePerime = new Date(filters.datePeremption);

    let dlcDate = new Date(dlc);
    let dlcTime = new Date(dlcDate.getFullYear(), dlcDate.getMonth(), dlcDate.getDate()).getTime();
    let datePerimeTime = new Date(datePerime.getFullYear(), datePerime.getMonth(), datePerime.getDate()).getTime();

    return dlcTime < datePerimeTime;
  };

}

export class DialogStockSortieSupplier {

  selectedStock: any;
  filters: any;
  matierePremiere: ModelViewMatierePremiere;


  constructor(selectedStock: any, filters: any, matierePremiere: ModelViewMatierePremiere) {
    this.selectedStock = selectedStock;
    this.filters = filters;
    this.matierePremiere = matierePremiere;
  }
}
