import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FP_ROUTES, OUTLET_SECONDARY, SECURITY} from "../core/constants";
import {Auth2Guard} from "../core/services/security/auth2.guard";
import {GestionProductionRootComponent} from "./gestion-production-root/gestion-production-root.component";
import {PlansDeProductionComponent} from "./pp/plans-de-production/plans-de-production.component";
import {PpComponent} from "./pp/pp.component";
import {SelectionFiltersResolverService} from "./pp/plans-de-production/selection-filters/selection-filters-resolver.service";
import {SelectionFiltersPlanProductionComponent} from "./pp/plans-de-production/selection-filters/selection-filters-plan-production.component";
import {PlanProductionComponent} from "./pp/plans-de-production/plan-production/plan-production.component";
import {SortiesProductionComponent} from "./pp/sorties-production/sorties-production.component";

const routes: Routes = [
  {
    path: FP_ROUTES.GESTION_PRODUCTION,
    component: GestionProductionRootComponent,
    canActivate: [Auth2Guard],
    canActivateChild: [Auth2Guard],
    runGuardsAndResolvers: 'always',
    data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    children: [
      {
        path: 'pp',
        component: PpComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
        children: [
          {
            path: 'plans-de-production',
            runGuardsAndResolvers: 'always',
            component: PlansDeProductionComponent,
            data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION}
          }
        ]
      }
    ]
  },
  {
    path: 'steps',
    component: SelectionFiltersPlanProductionComponent,
    runGuardsAndResolvers: 'always',
    outlet: OUTLET_SECONDARY,
    data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    children: [
      {
        path: 'selection-filters/:idPlanProduction',
        component: SelectionFiltersPlanProductionComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
        resolve: {
          selectionFiltersPlanProductionSupplier: SelectionFiltersResolverService
        }
      }
    ]
  },
  {
    path: 'steps',
    component: PlanProductionComponent,
    runGuardsAndResolvers: 'always',
    outlet: OUTLET_SECONDARY,
    data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    children: [
      {
        path: 'plan-production/:idPlanProduction',
        component: PlanProductionComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION}
      }
    ]
  },
  {
    path: 'steps',
    component: SortiesProductionComponent,
    runGuardsAndResolvers: 'always',
    outlet: OUTLET_SECONDARY,
    data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION},
    children: [
      {
        path: 'sorties-production/:idPlanProduction',
        component: SortiesProductionComponent,
        runGuardsAndResolvers: 'always',
        data: {role: SECURITY.ROLES.ROLE_PRODUCTION, licence: SECURITY.FEATURES.GESTION_PRODUCTION}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProductionRoutingModule { }
