<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <!--      POINTS DE LIVRAISON-->
      <span class="mg-r-5 ">
          <p-dropdown [options]="mplc.plcList"
                      showDelay="500"
                      placeholder="Sélectionner un point de livraison..."
                      [style]="{'width':'300px'}"
                      (onChange)="changePlc($event)"
                      [(ngModel)]="selectedPlc"
                      showClear="true"
                      optionLabel="libelle"
                      filter="true">
          </p-dropdown>
        </span>

      <!--        CALENDRIER-->
      <span class="mg-r-5">
      <p-calendar
        firstDayOfWeek="1"
        dateFormat="'{{getWeekLabel(week)}}'"
        tooltipPosition="bottom"
        showDelay="500"
        [numberOfMonths]="2"
        [style]="{'width':'280px'}"
        (onSelect)="changeWeek($event)"
        (onFocus)="getMonthDates($event)"
        (onMonthChange)="getMonthDates($event)"
        (onYearChange)="getMonthDates($event)"
        [readonlyInput]="true"
        [(ngModel)]="selectedDate"
        [locale]="localeFr"
        [showIcon]="true"
        [showWeek]="true"
        [disabledDays]="disabledDays"
        [minDate]="dateMin"
      >
            <ng-template pTemplate="date" let-date>
              <!--TEMPLATE DU CALENDRIER POUR LE HIGHLIGHT DES DATES QUI ONT DES MENUS COMPOS-->
              <yo-date-dispo [date]="date" [dateSaisieEffectif]="utils.isDateInList(date,datesSaisieEffectifs)"
                             [dateCreationMenu]="utils.isDateInList(date,datesCreationMenus)"></yo-date-dispo>
            </ng-template>
          </p-calendar>
        </span>

      <!--        TYPE D'EFFECTIF-->
      <span class="mg-r-5 ">
          <p-selectButton [options]="modes" (onChange)="changeMode($event)"
                          [(ngModel)]="selectedMode"></p-selectButton>
        </span>

      <!--    IMPRESSION-->
      <span class="mg-r-1">
      <p-menu appendTo="body" #printMenu [popup]="true" [model]="itemsPrintMenu"></p-menu>
      <button type="button" pButton
              icon="fas fa-print"
              class="mg-r-5 va-top"
              (click)="printMenu.toggle($event)"
              pTooltip="Impression"
              showDelay="500">
      </button>
    </span>
      <span>
      <button type="button" pButton
              icon="fas fa-copy"
              class="mg-r-5 va-top"
              (click)="openDialogDuplicateEffectivesPrevisionnals()"
              pTooltip="Dupliquer les effectifs prévisionnels"
              showDelay="500">
      </button>
    </span>

    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
    </div>
  </dxi-item>
</dx-toolbar>

<div class="d-flex flex-wrap mg-t-14"
     style="width: calc( 100vw - 70px); height: calc( 100vh - 150px ); max-height: calc( 100vh - 150px ); overflow: auto;">


  <div class="max-width-900 min-width-900"
       style="height: calc( 100vh - 150px ); max-height: calc( 100vh - 150px ); overflow: auto;">
    <yo-prestations-semaine></yo-prestations-semaine>
    <div class="card-footer mg-t-14">
    <span class="font-weight-normal font-12">
      <div class="text-left">
        <i class="fas fa-square save-color mg-r-5 border"></i>Effectifs en cours de saisie
      </div>
      <div class="text-left">
        <i class="fas fa-square site-secondaire-color mg-r-5 border"></i>Menus en cours de saisie
      </div>
      <div class="text-left">
        <i class="fas fa-square checked-color mg-r-5 border"></i>Menus validés = Saisie des effectifs possible
      </div>
      <div class="text-left">
        <i class="fas fa-square white mg-r-5 border"></i>Aucun menu
      </div>
    </span>
    </div>
  </div>
  <div class="mg-l-20">
    <yo-repas></yo-repas>
  </div>


</div>

<!--IMPRESSION DES EFFECTIFS-->
<yo-print-effectif></yo-print-effectif>
<yo-print-cout-revient-plat></yo-print-cout-revient-plat>
<yo-print-effectif-totaux></yo-print-effectif-totaux>

<dx-popup
  [showTitle]="true"
  width="450px"
  height="170px"
  title="Dupliquer les effectifs prévisionnels"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="popupDuplicateEffectivesVisible"
>
  <form id="form-duplicate-effectives">
    <div>
      <input id="checkbox-fabrication" type="checkbox" [(ngModel)]="duplicateToManufacturing"
             [ngModelOptions]="{standalone: true}"/>
      <label for="checkbox-fabrication" style="margin-left: 5px;">Fabrication</label>
    </div>
    <div>
      <input id="checkbox-facturation" type="checkbox" [(ngModel)]="duplicateToBilling"
             [ngModelOptions]="{standalone: true}"/>
      <label for="checkbox-facturation" style="margin-left: 5px;">Facturation</label>
    </div>
    <div class="text-right" id="form-duplicate-effectives-cta">
      <button type="button" pButton
              label="Dupliquer"
              icon="fas fa-copy"
              class="mg-r-5 va-top"
              (click)="duplicateEffectivesPrevisionnals()"
              pTooltip="Copier"
              showDelay="500">
      </button>
      <button type="button" pButton
              label="Fermer"
              icon="fas fa-times"
              class="cta-inner-delete"
              (click)="closeDialogDuplicateEffectivesPrevisionnals()"
              pTooltip="Dupliquer les effectifs prévisionnels"
              showDelay="500">
      </button>
    </div>
  </form>
</dx-popup>


