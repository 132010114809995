import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {WizardConditionnementService} from "../../../core/services/conditionnements/conditionnement-wizard.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {FichetechniqueService} from "../../../core/services/gestionproduits/fichetechnique.service";

@Component({
  selector: 'yo-wizard-configuration-step-7',
  templateUrl: './wizard-configuration-step-7.component.html',
  styleUrls: ['./wizard-configuration-step-7.component.scss']
})
export class WizardConfigurationStepSevenComponent implements OnInit, OnDestroy {

  @Output() onEmitPreviousStep = new EventEmitter<any>();

  @Output() onEmitNextStep = new EventEmitter<any>();

  private currentStep: number = 7;

  private dataPreviousStep: any;

  produits: any[] = [];

  declinaisons: any[] = [];

  private subProducts: Subscription;
  private timer: any;

  constructor(private graphQlSvc: GraphQLService,
              private wizardSvc: WizardConditionnementService,
              private ftSvc: FichetechniqueService,
              private utilsSvc: UtilsService) {
    this.dataPreviousStep = this.wizardSvc.getDataStepSix();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subProducts);
  }

  ngOnInit(): void {
    this.initProducts();
  }

  initProducts = (): void => {
    this.subProducts = this.graphQlSvc.sendQuery(`{
      allProduits(filters: { ids: [${this.dataPreviousStep?.idsProducts}] }) {
          id,
          libelle,
          code,
          produitsDeclinaisons {
            id,
            totalPoidsNetRecetteUT
            declinaison {
              id,
              libelle
            }
          }
        }
      }`).subscribe((response: any) => {
      const data: any = this.wizardSvc.getDataStepFive();
      this.produits = response.allProduits;
      this.declinaisons = this.produits.filter(p => p.produitsDeclinaisons.filter(pd => data.declinaisonsSelected.includes(pd.declinaison.id) ))
                                       .map(p => p.produitsDeclinaisons.map(pd => pd.declinaison) )
                                       .reduce((item, res) => { res.push(...item); return res; }, []);
      this.declinaisons = this.declinaisons.filter((declinaison, idx) => this.declinaisons.findIndex((d) => d.id === declinaison.id) === idx);
      this.declinaisons = this.declinaisons.filter(d => data.declinaisonsSelected.find(dd => dd === d.id))
    });
  }

  findTotalNetUT = (idProduit: number, idDeclinaison: number): number => {
    const produit: any = this.produits
      .find(produit => produit.id === idProduit);
    const produitDecline = produit.produitsDeclinaisons.map(pd => pd)
      .find(pd => pd.declinaison.id === idDeclinaison)
    return produitDecline ? produitDecline.totalPoidsNetRecetteUT : null;
  }

  updateTotalNetUT = ($event, idProduit: number, idDeclinaison: number): void => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.produits.forEach(produit => {
        if (produit.id === idProduit) {
          produit.produitsDeclinaisons.forEach(pd => {
            if (pd.declinaison.id === idDeclinaison) {
              pd.totalPoidsNetRecetteUT = parseFloat($event.target.value);
              this.ftSvc.updatePoidsNetFT(pd.id, $event.target.value)
                .subscribe(() => {});
            }
          });
        }
      });
    }, 1000);
  }

  previous = (): void => {
    this.onEmitPreviousStep.emit({ currentStep: this.currentStep });
  }

  next = (): void => {
    this.onEmitNextStep.emit({ currentStep: this.currentStep });
  }


}
