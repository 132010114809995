<dx-popup
    [showTitle]="true"
    [title]="titleDialog"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    container=".dx-viewport"
    [(visible)]="displayDialog"
    [fullScreen]="true"
>

  <!--CONTENU-->
  <div [style.height.px]="utils.getWindowAvailableHeight(200)">

    <div class="form-group row">
      <div class="genlabel col-sm-2 col-form-label">Mode de recherche</div>
      <div class="col-sm-4">
        <p-selectButton id="searchMode" [options]="searchModes" [(ngModel)]="searchMode"
                        (ngModelChange)="changeSearchMode($event)"></p-selectButton>
      </div>
      <div class="col-sm-6 text-right ">
        <label class="genlabel mg-r-5" [pTooltip]="planAlimentaire?.libelle | uppercase" showDelay="500">Plan
          alimentaire</label> <strong class="mg-r-20">{{planAlimentaire?.libelle | uppercase}}</strong>
        <label class="genlabel mg-r-5">Semaine</label> <strong
        class="mg-r-20">{{numeroSemainePlanAlimentaire}}</strong>
        <label class="genlabel mg-r-5">Régime</label> <strong
        class="mg-r-20">{{searchSupplier?.cmcContrainteAlim?.libelle | uppercase}}</strong>
      </div>
    </div>

    <div class="row margin-bottom-filters">
      <div class="col-md-2">
        <dx-tag-box #inputSites
          [dataSource]="sites" placeholder="Filtrer par site..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
          [multiline]="false" [showSelectionControls]="true" (onValueChanged)="onChangeSite($event)" [value]="selectedSites">
        </dx-tag-box>
      </div>
      <div class="col-md-2">
        <dx-text-box #inputLibellePlat valueChangeEvent="keyup" (onValueChanged)="announceLibelleSearch($event)" placeholder="Filtrer par libellé..."></dx-text-box>
      </div>
      <div class="col-md-3">
        <dx-tag-box #inputDeclinaisons
          [dataSource]="declinaisons" placeholder="Filtrer par déclinaison..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
          [multiline]="false" [showSelectionControls]="true" (onValueChanged)="onChangeDeclinaison($event)" [value]="selectedDeclinaisons">
        </dx-tag-box>
      </div>
      <div class="col-md-2">
        <dx-tag-box #inputTypes
          [dataSource]="types" placeholder="Filtrer par type..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
          [multiline]="false" [showSelectionControls]="true" (onValueChanged)="onChangeType($event)" [value]="selectedTypesProduit">
        </dx-tag-box>
      </div>
      <div class="col-md-3">
        <dx-drop-down-box
          #inputFamilleProduit
          [(value)]="treeBoxValue"
          valueExpr="id"
          displayExpr="name"
          placeholder="Filtrer par famille de produit..."
          [showClearButton]="true"
          [dataSource]="treeDataSource"
        >
          <div *dxTemplate="let data of 'content'">
            <dx-tree-view
              #treeViewFamillePlat
              [dataSource]="famillesPlats"
              [height]="150"
              dataStructure="plain"
              keyExpr="id"
              parentIdExpr="parent.id"
              selectionMode="multiple"
              showCheckBoxesMode="normal"
              [selectNodesRecursive]="true"
              displayExpr="libelle"
              [selectByClick]="true"
              (onItemSelectionChanged)="onChangeParentFamilleProduitSelection($event)"
              [searchEnabled]="true"
              searchMode="contains"
            >
            </dx-tree-view>
          </div>
        </dx-drop-down-box>
      </div>
    </div>
    <div class="row margin-bottom-filters">
      <div class="col-md-3">
        <dx-tag-box
          #inputFamilleGemrcn
          itemTemplate="templateInputFamDiet"
          [dataSource]="famillesGemrcn" placeholder="Filtrer par famille diététique..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
          [multiline]="false" [showSelectionControls]="true" (onValueChanged)="onChangeFamilleGemrcn($event)" [value]="selectedFamillesGemrcn">
          <div *dxTemplate="let famille of 'templateInputFamDiet'">
            <div>
              <yo-couleur style="display: inline-block;" [hexColor]="famille.couleur.hexa" class="mg-r-5"></yo-couleur>
              <span [pTooltip]="famille.libelle" showDelay="500">
                {{famille.libelle}}
              </span>
            </div>
          </div>
        </dx-tag-box>
      </div>
      <div class="col-md-3">
        <dx-tag-box
          #inputAppellations
          itemTemplate="templateInputAppellations"
          [dataSource]="appellations" placeholder="Filtrer par appellation..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true"
          [multiline]="false" [showSelectionControls]="true" (onValueChanged)="onChangeAppellationGemrcn($event)" [value]="selectedAppellations">
          <div *dxTemplate="let appellation of 'templateInputAppellations'">
            <div style="display: inline">
              <yo-img-entity class="mg-r-5"
                             [displayNoImage]="false"
                             [ordre]="1"
                             [entityName]="appellationsSvc.getEntityName()"
                             [height]="22"
                             [refresh]="1"
                             [openable]="false"
                             format="thumb"
                             [entityId]="appellation.id"
                             [title]="appellation.libelle"></yo-img-entity>
            </div>
            <span style="font-size:14px;margin-top:4px">{{appellation.libelle}}</span>
          </div>
        </dx-tag-box>
      </div>
      <div class="col-md-3">
        <dx-number-box #inputPrixMin placeholder="Filtrer par prix d'achat minimum..." (onValueChanged)="announcePrixMinSearch($event)" [showSpinButtons]="true" [showClearButton]="true" [value]="null"></dx-number-box>
      </div>
      <div class="col-md-3">
        <dx-number-box #inputPrixMax placeholder="Filtrer par prix d'achat maximum..." (onValueChanged)="announcePrixMaxSearch($event)" [showSpinButtons]="true" [showClearButton]="true" [value]="null"></dx-number-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 font-10">

        <dx-data-grid
          [dataSource]="dataSource"
          [remoteOperations]="true"
          keyExpr="id"
          [height]="utils.getWindowAvailableHeight(382)"
          width="100%"
          [allowColumnResizing]="true"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #grid>
          <dxi-column dataField="id" caption="Action(s)" cellTemplate="templateActions" [width]="100"></dxi-column>
          <dxi-column dataField="site" caption="Site" cellTemplate="templateSite"></dxi-column>
          <dxi-column width="auto" dataField="libelle" caption="Plat" cellTemplate="templateLabel"></dxi-column>
          <dxi-column dataField="declinaison.libelle" caption="Déclinaison"></dxi-column>
          <dxi-column dataField="typeProduitLibelle" caption="Type"></dxi-column>
          <dxi-column dataField="familleProduitLibelle" caption="Fam. plat"></dxi-column>
          <dxi-column dataField="familleGemrcnLibelle" cellTemplate="templateFamilleGemrcn" caption="Fam. diet."></dxi-column>
          <dxi-column dataField="appellation" cellTemplate="templateAppellation" caption="Appellation" cellTemplate="templateAppellation"></dxi-column>
          <dxi-column dataField="dlc" cellTemplate="templateDlc" caption="DLC"></dxi-column>
          <dxi-column dataField="prixAchat" cellTemplate="templatePrixAchat" caption="Prix d'achat"></dxi-column>

          <div *dxTemplate="let cell of 'templateActions'">
            <yo-cell-button (click)="updateCart(cell.row.data)"
                            [class.hidden]="isPresentInCart(cell.row.data) || canHideEcartIcon()"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="30"
                            [yoTextAlign]="'center'"
                            class="space"
                            [yoIconClass]="'fas fa-cart-plus'"
                            [pTooltip]="'Ajouter un article'"
                            tooltipPosition="right"
                            showDelay="500"
            ></yo-cell-button>
          </div>
          <div class="no-overflow font-12" style="width:100%;" *dxTemplate="let cell of 'templateSite'">
            <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle"
                     [siteLibelleTooltip]="cell.row.data.site.libelle"></yo-site>
          </div>
          <div *dxTemplate="let cell of 'templateLabel'" class="d-flex flex-row">
            <div class="no-overflow" [pTooltip]="cell.row.data.libelle" showDelay="500">
              <yo-icon-produitfab [fabrique]="cell.row.data.fabrique"></yo-icon-produitfab>
              {{ cell.row.data.libelle }}
            </div>
            <i *ngIf="cell.row.data.erreurPrix"
               class="fas  danger-color mg-r-5 mg-l-5 align-self-center"
               [pTooltip]="cell.row.data.messageErreurPrix">
            </i>
          </div>
          <ng-container *dxTemplate="let cell of 'templateFamilleGemrcn'">
            <ng-container *ngFor="let fnp of cell.row.data.famillesNutritionnellesProduits">
              <div class="no-overflow max-width-150" [pTooltip]="fnp.familleNutritionnelleLibelle"
                   showDelay="500">
                <yo-couleur class="mg-r-5"
                            [hexColor]="fnp.familleNutritionnelleCouleurHexa"></yo-couleur>
                {{fnp.familleNutritionnelleLibelle}}
              </div>
            </ng-container>
          </ng-container>

          <ng-container *dxTemplate="let cell of 'templateAppellation'">
            <ng-container *ngFor="let pa of cell.row.data.produitAppellationList">
              <ng-container *ngIf="pa.actif">
                <yo-img-entity class="mg-r-5"
                               [displayNoImage]="false"
                               [ordre]="1"
                               [entityName]="appellationsSvc.getEntityName()"
                               [height]="22"
                               [refresh]="1"
                               [openable]="true"
                               format="thumb"
                               [entityId]="pa?.appellationId"
                               [title]="pa?.appellationLibelle"></yo-img-entity>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *dxTemplate="let cell of 'templateDlc'">
            <span class="text-right" pTooltip="dlc en jours" showDelay="500">{{cell.row.data.dlc}}j</span>
          </ng-container>

          <ng-container *dxTemplate="let cell of 'templateAllergene'">
            {{getProduitAllergeneList(cell.row.data.produitAllergeneList, 'allergeneLibelle')}}
          </ng-container>

          <ng-container *dxTemplate="let cell of 'templatePrixAchat'">
            <div style="text-align: right;">{{cell.row.data.udpPrixAchat   | currency:'EUR':'symbol':'1.4-4'}}
              / {{cell.row.data.uniteDeMesure.libelle}}</div>
          </ng-container>

          <dxo-sorting mode="single"></dxo-sorting>
          <dxo-pager
            [showPageSizeSelector]="true"
            [showNavigationButtons]="false"
            [allowedPageSizes]="[20, 50, 100, 200]"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} plat(s)">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </div>

    <div class="d-flex mg-t-3">
      <div class="mr-auto"></div>
      <div class="mg-r-20">
        <!--voir mon panier-->
        <button pButton (click)="showCart()"
                [disabled]="utils.isCollectionNullOrEmpty(produitsDeclinaisonsInCart)"
                label="{{produitsDeclinaisonsInCart.length}}" icon="fas fa-shopping-basket"></button>
      </div>
      <div class="mg-r-5">
        <!--ajouter mes plats-->
        <button [disabled]="disableAddPlats() || this.utils.isCollectionNullOrEmpty(this.produitsDeclinaisonsInCart)" pButton
                label="Ajouter mes {{searchSupplier?.decoupageRepas?.libelle}}  ({{searchSupplier?.maxItemsPanier}} max)"
                icon="fas fa-plus"
                (click)="addProduitsDeclinaisons()"></button>
      </div>
      <div>
        <!--fermer-->
        <button pButton (click)="onHideAjoutComposants()"
                class="cta-inner-delete"
                icon="fas fa-times" label="Fermer"></button>
      </div>
    </div>
  </div>

</dx-popup>


<!--DIALOG PANIER-->
<dx-popup
    [showTitle]="true"
    title="Mon panier"
    [dragEnabled]="false"
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    container=".dx-viewport"
    [(visible)]="displayCart"
    [height]="'auto'"
    [width]="'auto'"
>
  <div>
    <div class="list-plats" *ngFor="let pd of getProductsSelected()">
      <i class="fas fa-times  mg-r-20 cursor" pTooltip="Enlever" showDelay="500"
         (click)="removeProduitDeclinaisonInCart(pd)"></i>
      <yo-icon-produitfab [fabrique]="pd.fabrique"></yo-icon-produitfab>
      <span class="mg-r-20">{{pd.libelle}}</span>
      <em class="genlabel-color" style="font-size: 12px;"> {{pd.declinaison.libelle}}</em>
    </div>
    <div class="cta-list-plats">
      <button pButton (click)="eraseCart()" icon="fas fa-trash" class="cta-inner-delete" label="Vider le panier"></button>
      <button pButton (click)="onHideCart()" icon="fas fa-times"
              class="cta-inner-delete"
              label="Fermer"></button>
    </div>
  </div>

</dx-popup>
