<ng-container *ngIf="!utils.isNullOrEmpty(pes)">

  <div class="mg-b-10">
    <span class="mg-r-5">Planning des équipes {{this.getEquipeTypeTache(pes.typeTache)}},</span>
    <span><strong>les jours représentent les jours de {{this.getTypeJour(pes.typeTache)}}.</strong></span>
  </div>

  <dx-data-grid
    [allowColumnResizing]="true"
    [dataSource]="pes.rows"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(350)"
    (onCellPrepared)="onCellPrepared($event)"
    (onCellClick)="onCellClick($event)"
    (onRowPrepared)="onRowPrepared($event)"
    [showBorders]="true"
    #planningEquipesTable>

    <dxi-column dataField="zoneDeProductionLibelle" caption="Atelier" [groupIndex]="0"></dxi-column>

    <dxi-column dataField="equipeLibelle" caption="Équipe" cellTemplate="equipeCellTemplate"></dxi-column>

    <dxi-column [dataField]="pes.cols[0]" [caption]="pes.cols[0].jourSemaine"
                cellTemplate="lundiCellTemplate"
                headerCellTemplate="lundiHeaderCellTemplate"
    ></dxi-column>

    <dxi-column [dataField]="pes.cols[1]" [caption]="pes.cols[1].jourSemaine"
                cellTemplate="mardiCellTemplate"
                headerCellTemplate="mardiHeaderCellTemplate"
    ></dxi-column>

    <dxi-column [dataField]="pes.cols[2]" [caption]="pes.cols[2].jourSemaine"
                cellTemplate="mercrediCellTemplate"
                headerCellTemplate="mercrediHeaderCellTemplate"></dxi-column>

    <dxi-column [dataField]="pes.cols[3]" [caption]="pes.cols[3].jourSemaine"
                cellTemplate="jeudiCellTemplate"
                headerCellTemplate="jeudiHeaderCellTemplate"></dxi-column>

    <dxi-column [dataField]="pes.cols[4]" [caption]="pes.cols[4].jourSemaine"
                cellTemplate="vendrediCellTemplate"
                headerCellTemplate="vendrediHeaderCellTemplate"></dxi-column>

    <dxi-column [dataField]="pes.cols[5]" [caption]="pes.cols[5].jourSemaine"
                cellTemplate="samediCellTemplate"
                headerCellTemplate="samediHeaderCellTemplate"></dxi-column>

    <dxi-column [dataField]="pes.cols[6]" [caption]="pes.cols[6].jourSemaine"
                cellTemplate="dimancheCellTemplate"
                headerCellTemplate="dimancheHeaderCellTemplate"></dxi-column>

    <div *dxTemplate="let cell of 'equipeCellTemplate'" class="mg-t-20">
      {{cell.data.equipeLibelle}}
    </div>


    <div *dxTemplate="let cell of 'lundiHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[0],cells:pes.cells}">
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'lundiCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[0],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'mardiHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[1],cells:pes.cells}">
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'mardiCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[1],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'mercrediHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[2],cells:pes.cells}">
      </ng-container>
    </div>


    <div *dxTemplate="let cell of 'mercrediCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[2],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'jeudiHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[3],cells:pes.cells}">
      </ng-container>
    </div>


    <div *dxTemplate="let cell of 'jeudiCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[3],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'vendrediHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[4],cells:pes.cells}">
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'vendrediCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[4],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'samediHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[5],cells:pes.cells}">
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'samediCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[5],pes.typeTache)}"></ng-container>
    </div>

    <div *dxTemplate="let cell of 'dimancheHeaderCellTemplate'">
      <ng-container *ngTemplateOutlet="tplHeaderJourSemaine;context:{col:pes.cols[6],cells:pes.cells}">
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'dimancheCellTemplate'">
      <ng-container
        *ngTemplateOutlet="tplCell;context:{cell:getCell(cell.data,pes.cols[6],pes.typeTache)}"></ng-container>
    </div>

  </dx-data-grid>

  <yo-legend-grid-dev-extreme
    [modificationManuelle]="false"
    [celluleActive]="true"
    [celluleInactive]="true"
    [colonneModifiable]="false"
    [colonneInformations]="false"
    [colonneObligatoire]="false">
  </yo-legend-grid-dev-extreme>

  <hr>
  <div class="d-flex mg-t-20">
    <div class="mr-auto">
      <yo-help class="mg-r-10" lang="fr" [file]="pathFile" [dialogMsgSupplier]="getHelp(pes.typeTache)" [width]="800"></yo-help>
      <span class="font-12">
      Affecter les équipes {{getEquipeTypeTache(pes.typeTache)}} au planning.
    </span>
    </div>
    <div>
      <button pButton icon="fas fa-save" class="p-button-success" [pTooltip]="getTooltipSavePlanning(pes.typeTache)"
              (click)="savePlanning()"></button>
    </div>
  </div>
</ng-container>

<!--TEMPLATE CELL-->
<ng-template #tplCell let-cell="cell">

  <div class="text-right cursor line-height-20" (click)="cellActive(cell,pes.cells)">&nbsp;</div>
  <div>

    <yo-jours-decalage
      (onChangeEmitter)="changeJoursDecalage(cell,pes.cells,$event)"
      [joursDecalageSupplier]="getJoursDecalageSupplier(cell.jourSemaine,cell.joursDecalage)">
    </yo-jours-decalage>

  </div>

</ng-template>

<!--TEMPLATE HEADER COL-->
<ng-template #tplHeaderJourSemaine let-col="col" let-cells="cells">
  <div class="d-flex">
    <div class="mr-auto">{{col.jourSemaine}}</div>
  </div>
</ng-template>
