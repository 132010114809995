<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [style]="{'width':'1000px'}"
  [modal]="true"
  [breakpoint]="640"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt mg-r-5"></i> Modifier une denrée interdite
    </div>
  </ng-template>

  <ng-container *ngIf="selectedUdpDenreeInterdite">

    <div class="row">

      <div class="col-md-4">
        <label class="mg-r-5"><strong>Déclinaison interdite</strong></label>
        <div>{{selectedUdpDenreeInterdite.denreeInterditeLibelle}}</div>
      </div>

      <div class="col-md-4">
        <label class="mg-r-5"><strong>Déclinaison de substitution</strong></label>
        <div>{{selectedUdpDenreeInterdite.denreeSubstitutionLibelle}}</div>
      </div>

      <div class="col-md-4" style="margin: auto;">
        <label class="mg-r-5"><strong>Pourcentage de substitution</strong></label>
        <div class="p-inputgroup">
          <input matInput type="number" [(ngModel)]="selectedUdpDenreeInterdite.ratio">
          <span class="p-inputgroup-addon">%</span>
        </div>
        <div>
          <strong>1 {{selectedUdpDenreeInterdite.denreeInterditeUniteDeMesure}}</strong> de la denrée interdite donnera
          <strong>{{getMesureDenreeSubstitution() | number : '1.0-4'}} {{selectedUdpDenreeInterdite.denreeSubstitutionUniteDeMesure}}</strong>
          de la denrée de substitution
        </div>
      </div>

    </div>

  </ng-container>

  <ng-template pTemplate="footer">

    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-sign-out-alt" (click)="modifierDenreeDeSubstitution()"
              pTooltip="Modifier la denrée de substitution"
              label="Modifier une denrée de substitution"
              showDelay="500"
      ></button>
    </span>
    <span class="mg-r-5">
      <button type="button" pButton icon="fas fa-times" (click)="closeDialog()" pTooltip="Annuler" label="Annuler"
              class="cta-delete"
              showDelay="500"></button>
    </span>

  </ng-template>

</p-dialog>

