<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button type="button"
              pButton
              icon="fa fa-plus"
              label="CRÉER"
              (click)="openNewSite()"
              [pTooltip]="'Créer un site'">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="sitesForGrille"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>

  <dxi-column dataField="id" caption="Actions" cellTemplate="templateActions" [width]="100"
              [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
  <dxi-column dataField="reference" caption="Code"></dxi-column>
  <dxi-column dataField="adresse1" caption="Adresse"></dxi-column>
  <dxi-column dataField="ville" caption="Ville"></dxi-column>
  <dxi-column dataField="telephone" caption="Téléphone"></dxi-column>
  <dxi-column dataField="actif" caption="Actif"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'">
    <yo-cell-button (yoNavigation)="openSite(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-edit'"
                    [yoTooltip]="'Modifier le site ' + cell.row.data.libelle"
    >
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="confirmDelete(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fa fa-trash'"
                    [yoTooltip]="'Supprimer le site '+ cell.row.data.libelle"
    >
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} site(s)">
  </dxo-pager>

</dx-data-grid>



