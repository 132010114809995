import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {WorkflowDto} from '../../dtos/workflow-dto';
import {GenericRequestSupplier} from '../../suppliers/generics/generic-request-supplier';
import {WorkflowEtapeDto} from '../../dtos/workflow-etape-dto';
import {ListeBesoinsDto} from '../../dtos/liste-besoins-dto';
import {find as _find, sortBy as _sortBy, startCase as _startCase} from 'lodash';
import {catchError} from 'rxjs/operators';
import mermaid from "mermaid";
import {FP_ROUTES, WORKFLOW_TASKS} from '../../constants';
import {CommandeProduitDTO} from '../../dtos/commande-produit-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {Observable, Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {RunStepDTO} from '../../dtos/run-step-dto';
import {RoutemapService} from '../routemap.service';
import {PreferencesUtilisateurService} from '../preferences-utilisateur.service';
import {HttpService} from '../technique/http.service';
import {InitProcessusSupplier} from "../../suppliers/gestion-processus/init-processus-supplier";

export const URL_POST_GO_TO_PREVIOUS_STEP = `dolrest/gestion-listes-besoins/liste-besoins/goToPreviousStep`;
export const URL_GET_PROGRESS_CALCUL_BESOIN = `dolrest/gestion-listes-besoins/liste-besoins/getProgressCalculBesoin`;

export const URL_GET_WORKFLOWS = `dolrest/workflows/getWorkflows`;
export const URL_POST_START_WORKFLOW = `dolrest/workflows/startWorkflow`;
export const URL_POST_RUN_STEP = `dolrest/workflows/runStep`;
export const URL_POST_NEXT_STEP = `dolrest/workflows/nextStep`;
export const URL_GET_HAS_NEXT_STEP = `dolrest/workflows/hasNextStep`;
export const URL_GET_WORKFLOW_INSTANCE_BY_ID = `dolrest/workflows/getWorkflowbyId`;

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class WorkflowsService extends GenericHandler<WorkflowDto> {

  private subjectWorkflowInstanceSaved = new Subject<WorkflowInstanceDTO>();
  workflowInstanceSaved$ = this.subjectWorkflowInstanceSaved.asObservable();

  private subjectWorkflowInstance = new Subject<WorkflowInstanceDTO>();
  workflowInstance$ = this.subjectWorkflowInstance.asObservable();

  private subjectOpenDialogInitProcessus = new Subject<InitProcessusSupplier>();
  openDialogInitProcessus$ = this.subjectOpenDialogInitProcessus.asObservable();


  constructor(
    private routeMapSvc: RoutemapService,
    private prefUsrSvc: PreferencesUtilisateurService,
    private httpSvc: HttpService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router,
    http: HttpClient, title: Title, private gds: GenericDatagridService) {
    super(utils, auth2Svc, router, http, title);

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  createEmptyDTO = (): WorkflowDto => undefined;

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEntityName = (): string => 'workflow';

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => undefined;

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => undefined;

  getTitle = (): string => '';

  /**
   * Récupérer les workflows
   * @param {GenericRequestSupplier} genericRequest
   * @deprecated
   */
  getWorkflowsDeprecated = (genericRequest: GenericRequestSupplier) => this.gds.search(genericRequest).pipe(
    catchError(error => this.utils.handleError(error))
  );

  getWorkflows = (stepsRequired: string[]): Observable<ResponseWrapper<WorkflowDto>> => {

    let joinStepsRequired = stepsRequired ? stepsRequired.join(',') : '';
    const params = new HttpParams().set('requiredSteps', joinStepsRequired);

    return this.httpSvc.get(URL_GET_WORKFLOWS, params);
  };

  getWorkflowInstanceById = idWorkflowInstance => {
    const params = new HttpParams().set('idWorkflowInstance', idWorkflowInstance);
    return this.httpSvc.get(URL_GET_WORKFLOW_INSTANCE_BY_ID, params);
  };

  /**
   * Déclencher une étape du workflow
   * @param runStep
   */
  runStep = (runStep: RunStepDTO): Observable<ResponseWrapper<WorkflowInstanceDTO>> => this.httpSvc.post(URL_POST_RUN_STEP, runStep);


  /**
   * Si commande renseigné, on affiche l'étape courante de la commande fournsseur
   * @param mermaidDiv
   * @param {WorkflowDto} wkf
   * @param {ListeBesoinsDto} commande
   */
  showMermaidFlow = (mermaidDiv: any, wkf: WorkflowDto, commande?: ListeBesoinsDto,) => {

    if (!this.utils.isNullOrEmpty(wkf)) {

      const config = {
        theme: 'dark',
        flowchart:{
          width: '50%',
          height: '50%'
        }
      };

      mermaid.initialize(config);

      const element: any = mermaidDiv.nativeElement;

      let defGraph = '';
      let graphId = `graphId${wkf.id}`;
      if (!this.utils.isNullOrEmpty(commande)) {
        defGraph = this.getMermaidGraphDefinitionCurrentStep(commande, wkf);
        graphId += `${commande.id}`;
      } else {
        defGraph = this.getMermaidGraphDefinition(wkf);
      }

      mermaid.render(graphId, defGraph, (svgCode, bindFunctions) => {

        element.innerHTML = svgCode;

      });
    }
  };

  /**
   * Rendering graphique du workflow
   * @param {ListeBesoinsDto} listeBesoin
   * @param {WorkflowDto} workflow
   * @returns {string}
   */
  getMermaidGraphDefinition = (workflow: WorkflowDto): string => {

    // graph top to bottom = TB
    let graphDefinition = `graph TB\n`;

    if (!this.utils.isNullOrEmpty(workflow)) {
      let etapes = workflow.workflowEtapeList;
      if (!this.utils.isCollectionNullOrEmpty(etapes)) {

        etapes = _sortBy(etapes, ['niveau', 'id']);

        etapes.map(etape => {

          // graphDefinition +=`subgraph ${etape.niveau}\n`;

          if (!this.utils.isCollectionNullOrEmpty(etape.wLienSourceList)) {
            etape.wLienSourceList.map(lien => {

              const etapeDest = _find(etapes, {'id': lien.idEtapeDest});

              graphDefinition += `${etape.id}("fa:${etape.workflowTypeStatutIcone} ${etape.workflowStatutLibelle.toUpperCase()}") --> ${etapeDest.id}("fa:${etapeDest.workflowTypeStatutIcone} ${etapeDest.workflowStatutLibelle.toUpperCase()}") \n`;
            });
          }

        });


        // -----------------
        // Styling
        // -----------------

        graphDefinition += `classDef startStop fill:#fff,stroke:#333,stroke-width:2px\n`;

        graphDefinition += `classDef tasks fill:#fff,stroke:#CB247A,stroke-width:2px\n`;

        graphDefinition += `class ${etapes
          .filter(etape => etape.workflowStatutCode != 'start' && etape.workflowStatutCode != 'end')
          .map(etape => etape.id)
          .join(',')} tasks\n`;

        const etapeStart = _find(etapes, {'workflowStatutCode': 'start'});
        const etapeStop = _find(etapes, {'workflowStatutCode': 'end'});
        graphDefinition += `class ${etapeStart.id},${etapeStop.id} startStop\n`;


      }
    }

    return graphDefinition;
  };

  /**
   * Rendering graphique du workflow avec l'étape courante
   * @param {ListeBesoinsDto} listeBesoin
   * @param {WorkflowDto} workflow
   * @returns {string}
   */
  getMermaidGraphDefinitionCurrentStep = (listeBesoin: ListeBesoinsDto, workflow: WorkflowDto): string => {

    let graphDefinition = this.getMermaidGraphDefinition(workflow);

    graphDefinition += `classDef current fill:#fff,stroke:#4CAF50,stroke-width:4px\n`;
    graphDefinition += `class ${listeBesoin.workflowEtape.id} current\n`;

    return graphDefinition;
  };


  /**
   * Récupérer la premiere etape du workflow
   * @param {ListeBesoinsDto} listeBesoin
   * @returns {string}
   */
  getFirstStep = (workflow: WorkflowDto): WorkflowEtapeDto => {

    let firstStep;

    if (!this.utils.isNullOrEmpty(workflow)) {
      if (!this.utils.isCollectionNullOrEmpty(workflow.workflowEtapeList)) {

        const etapes = workflow.workflowEtapeList;

        let firstStep = this.utils.getFirstElement(etapes.filter(item => item.workflowStatutCode.toLowerCase() === 'start'));

        if (!this.utils.isNullOrEmpty(firstStep)) {
          return firstStep;
        }

      }
    }

    return firstStep;
  };

  /**
   * Récupérer les acteurs d'une étape de listeBesoin
   * @param {ListeBesoinsDto} listeBesoin
   * @returns {string[]}
   */
  getActeurs = (listeBesoin: ListeBesoinsDto) => {

    const acteurs: string[] = listeBesoin.workflowEtape.workflowEtape__utilisateurList.map(user => this.utils.capitalize(user.prenom) + ' ' + user.nom.toUpperCase());

    return acteurs;

  };


  /**
   * Passer à l'étape suivante du workflow
   * @param
   * @param {WorkflowEtapeDto} etape
   * @returns {Observable<any>}
   */
  goToNextStep = (runStep: RunStepDTO): Observable<ResponseWrapper<WorkflowInstanceDTO>> => this.httpSvc.post(URL_POST_NEXT_STEP, runStep);

  getProgessCalculProduction = (idListeBesoin: number) => this.http.get<ResponseWrapper<CommandeProduitDTO>>(URL_GET_PROGRESS_CALCUL_BESOIN, {
    params: new HttpParams().set('idListeBesoin', idListeBesoin + '')
  })
    .pipe(catchError(error => this.utils.handleError(error)));

  goToPreviousStep = (listeBesoin: ListeBesoinsDto, onError: boolean = false, msg: string = '') => {

    const params = new HttpParams()
      .set('onError', onError + '')
      .set('message', msg);

    return this.httpSvc.post(URL_POST_GO_TO_PREVIOUS_STEP, listeBesoin, params);

  };

  startWorkflow = (form: FormGroup): Observable<ResponseWrapper<WorkflowInstanceDTO>> => {

    const fd = new FormData();
    let site = form.controls['sites'].value;
    fd.set('idSite', site.id + '');
    fd.set('idWorkflow', form.controls['workflow'].value.id);
    fd.set('libelle', form.controls['libelle'].value);

    return this.httpSvc.post(URL_POST_START_WORKFLOW, fd);
  };

  /**
   * Est ce que l'utilisateur connecté peut créer la liste de besoin ?
   * Pour cela, on regarde s'il fait partie de la liste des acteurs de la premiere etape
   * @param {WorkflowDto} workflow
   */
  canStartWorkflow = (workflow: WorkflowDto): boolean => {

    if (!this.utils.isNullOrEmpty(workflow)) {

      const firstStep = this.getFirstStep(workflow);

      if (!this.utils.isNullOrEmpty(firstStep)) {

        const actors = firstStep.workflowEtape__utilisateurList;

        if (!this.utils.isCollectionNullOrEmpty(actors)) {

          const exists = !!this.utils.getFirstElement(actors.filter(actor => actor.idUtilisateur === this.auth2Svc.utilisateur.id))

          return exists;
        }
      }
    }

    return false;
  };

  /**
   * Est ce que l'utilisateur connecté peut modifier l'instance
   * @param
   * @returns {boolean}
   */
  canModifyCurrentStep = (wi: WorkflowInstanceDTO): boolean => {

    if (!this.utils.isNullOrEmpty(wi)) {

      const actorList = wi.actors;
      if (!this.utils.isCollectionNullOrEmpty(actorList)) {

        const exists = !!this.utils.getFirstElement(actorList.filter(actor => actor.idUtilisateur === this.auth2Svc.utilisateur.id));

        return exists;

      }
    }

    return false;
  };

  announceWorkflowInstance = (one: WorkflowInstanceDTO) => {
    this.subjectWorkflowInstance.next(one);
  };

  announceWorkflowInstanceSaved = (one: WorkflowInstanceDTO) => {
    this.subjectWorkflowInstanceSaved.next(one);
  };

  announceOpenDialogInitProcessus = (ips: InitProcessusSupplier) => {
    this.subjectOpenDialogInitProcessus.next(ips);
  };

  private _openProductionListPlatsTask = (idWorkflow: number) => {
    const localWorkflowInstance : WorkflowInstanceDTO = {
      id: idWorkflow,
      codeWorkflowStatut: WORKFLOW_TASKS.PRODUCTION_LISTE_PLATS
    } as WorkflowInstanceDTO;
    this.openTask(localWorkflowInstance);
  }

  openTask = (workflowInstance: WorkflowInstanceDTO) => {

    // console.log('open task');

    const workflowStatut = workflowInstance.codeWorkflowStatut.toLowerCase();

    switch (workflowStatut) {

      case WORKFLOW_TASKS.PRODUCTION_LISTE_PLATS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'pp-details']);
        break;

      case WORKFLOW_TASKS.SELECTION_MENUS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;

      case WORKFLOW_TASKS.SELECTION_PLCS :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;

      case WORKFLOW_TASKS.BESOINS :
        this.openVueBesoins(workflowInstance);
        break;

      case WORKFLOW_TASKS.SORTIES_PRODUCTION :
        this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, workflowInstance.codeWorkflowStatut])
        break;


      case WORKFLOW_TASKS.COMMANDES_FOURNISSEURS :
        this.routeMapSvc.goToPrimaryRoute([FP_ROUTES.GESTION_COMMANDES_FOURNISSEURS, 'bc', 'propositions']);
        break;
    }

  };

  /**
   * La liste de besoins à 2 vues : Denrees et Articles.
   * On selectionne la vue à partir de la preference utilisateur
   * @param workflowInstance
   */
  openVueBesoins = (workflowInstance: WorkflowInstanceDTO) => {

    this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'lb-details', 'articles']);


  };

  /**
   * Est ce que c'est une tache qui a besoin dêtre recalculée ?
   * Par exemple la selection des menus doit etre recalculee (enregistree) avant le passage en production
   * @param workflowStatutCode
   */
  isTaskToRecompute = (workflowStatutCode: string) => {

    if (workflowStatutCode.toLowerCase() === WORKFLOW_TASKS.SELECTION_MENUS || workflowStatutCode.toLowerCase() === WORKFLOW_TASKS.SELECTION_PLCS) {
      return true;
    }

    return false;
  };

  getEditObjectLabel = (data: ObjectDTO): string => `MODIFIER LE PROCESSUS '${data.libelle.toUpperCase()}'`;


  goToListeBesoins = (workflowInstance: WorkflowInstanceDTO) => {
    if (!this.utils.isNullOrEmpty(workflowInstance)) {
      this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'lb-details'])
    }
  };

  goToListeBesoinsDetails = (workflowInstance: WorkflowInstanceDTO) => {
    if (!this.utils.isNullOrEmpty(workflowInstance)) {
      this.routeMapSvc.goToSecondaryRoute(['gestion-processus', workflowInstance.id, 'lb-details', 'articles'])
    }
  };

  hasNextStep = (idWorkflowInstance: number, codeWorkflowStatut: string) => {

    const params = new HttpParams()
      .set('idWorkflowInstance', idWorkflowInstance + '')
      .set('codeWorkflowStatut', codeWorkflowStatut);

    return this.httpSvc.get(URL_GET_HAS_NEXT_STEP, params);


  };
}
