<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-cubes mg-r-5 mg-l-15"></i> INVENTAIRE
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <button pButton icon="fas fa-print"
              class="mg-r-5"
              (click)="printInventaireService.announceDisplayDialogPrintInventaire(true)"
              showDelay="1000"
              title="Extraction de l'inventaire">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<p-table
  [value]="lignesInventaire"
  [columns]="cols"
  [responsive]="true"
  [scrollable]="true"
  [rows]="50"
  [rowsPerPageOptions]="[5,10,15,20,50,100,200,500]"
  [paginator]="true"
  [scrollHeight]="utils.scrollHeightContent(305)"
  styleClass="p-datatable-sm"
  [totalRecords]="totalDenrees"
  (onLazyLoad)="lazyLoadInventaire($event)"
  [lazy]="true"
  [loading]="loading"
  selectionMode="single"
  [(selection)]="selectedInventaire"
  dataKey="referenceInterne"
  #dt>

  <!--COLGROUP-->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
        <col *ngSwitchCase="'denree'">
        <col *ngSwitchCase="'zdsLibelle'">
        <col *ngSwitchCase="'dateEntree'">
        <col *ngSwitchCase="'dlc'">
        <col *ngSwitchCase="'uniteDeStockage'">
        <col *ngSwitchCase="'quantite'">
        <col *ngSwitchCase="'quantiteReaj'">
      </ng-container>
    </colgroup>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns; trackBy: utils.trackByFn;" [ngSwitch]="col.field">
        <ng-container *ngSwitchCase="'denree'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'zdsLibelle'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'dateEntree'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'dlc'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'uniteDeStockage'">
          <th title="Unité de stockage" showDelay="500">
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'quantite'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
        <ng-container *ngSwitchCase="'quantiteReaj'">
          <th>
            {{col.header}}
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr *ngIf="rowData.ligneArticle" [pSelectableRow]="rowData">
      <td [attr.colspan]="columns.length-1" [class]="rowData.noStock ? 'bg-secondary-header no-stock' : 'bg-secondary-header'">
                   <span class="mg-r-5">
                    <button pButton icon="fas fa-plus" showDelay="1000" class="p-button-secondary"
                            title="Ajout d'un nouveau lot" (click)="openDialogAjoutLot(rowData)"></button>
                  </span>
        <span class="mg-r-5 bold"> {{rowData.produitDeclinaisonCode}}
          - {{rowData.produitDeclinaisonLibelle}} <i *ngIf="rowData.noStock" class="fas fa-ban danger-color mg-r-5"></i> <span *ngIf="rowData.noStock">Pas de stock</span>
                </span>
        <span class="floatright"
              *ngIf="rowData.totalHT">TOTAL HT = {{rowData.totalHT | currency:'EUR':'symbol':'1.2-4'}}
          , TOTAL TTC = {{rowData.totalTTC | currency:'EUR':'symbol':'1.2-4'}} </span>
      </td>
    </tr>
    <tr *ngIf="!rowData.ligneArticle">
      <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
        <td *ngSwitchCase="'denree'" style="text-align: left">
          {{rowData.lotArticle}}
        </td>
        <td *ngSwitchCase="'zdsLibelle'" style="text-align: left">
          <div>
            <i class="fas fa-industry mg-r-5"></i>{{rowData.udpLibelle}}
          </div>
          <div class="mg-l-10 mg-t-5">
            <i class="fas fa-cubes mg-r-5"></i>{{rowData.zdsLibelle}}
          </div>
        </td>
        <td *ngSwitchCase="'dlc'" style="text-align: left">
          {{rowData.dlc | date:'dd/MM/yy'}}
        </td>
        <td *ngSwitchCase="'dateEntree'" style="text-align: left">
          {{rowData.dateEntree | date:'dd/MM/yy'}}
        </td>
        <td *ngSwitchCase="'uniteDeStockage'" style="text-align: left">
          {{rowData.uniteDeStockage}}
        </td>
        <td *ngSwitchCase="'quantite'" style="text-align: right">
          {{rowData.quantite  | number:'1.4-4'}}
        </td>
        <td
          [pEditableColumn]="rowData" [pEditableColumnField]="'quantiteReaj'"
          *ngSwitchCase="'quantiteReaj'" style="text-align: right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="p-g p-fluid">
                <div class="p-g-12 p-md-12">
                  <div class="p-inputgroup">
                    <input pInputText pKeyFilter="pnum"
                           [style.width.px]="100"
                           class="text-right"
                           min="0"
                           (input)="updateQuantityReaj($event, rowData.lotArticle)"
                           (keydown)="utils.keydownNumber($event,rowData,'quantiteReaj',true)"
                           [(ngModel)]="rowData.quantiteReaj">
                    <span class="p-inputgroup-addon">{{rowData.uniteDeStockage}}</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
                          <span
                            class="space cursor save-color">{{rowData.quantiteReaj | number:'1.4-4'}}  </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>

<yo-dialog-lot-ajout></yo-dialog-lot-ajout>
<yo-dialog-print-inventaire [searchSupplierWrapper]="ssw"></yo-dialog-print-inventaire>
