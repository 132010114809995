<dx-popup
  [width]="1200"
  [height]="'auto'"
  [maxHeight]="600"
  [title]="dialogTitle"
  [showTitle]="true"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Modèle point de livraison (Modèle PLC) <span class="danger-color">*</span>
      </div>
      <div class="col-md-8">
        <dx-lookup
          [value]="mcPlcSelected?.id"
          [dataSource]="modelsDataSource"
          formControlName="mcPlc"
          placeholder="Sélectionner un modèle"
          valueExpr="id"
          displayExpr="libelle"
          [searchEnabled]="false"
        >
        </dx-lookup>
        <ng-container
          *ngTemplateOutlet="tplError;context:{form:form,property:'mcPlc',label:'Modèle'}"></ng-container>
      </div>
    </div>

    <div class="p-2 d-flex justify-content-end">
      <button
        class="btn p-button-success"
        type="submit"
        pButton
        [disabled]="!canSave()"
        [class.disable]="!canSave()">
        <i class="fas fa-save mg-r-5"></i> Enregistrer
      </button>
      <button
        class="ml-3 btn  cta-inner-delete"
        type="button"
        (click)="closeDialog()"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
  </form>
</dx-popup>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>

  </div>

</ng-template>
