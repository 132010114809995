<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog()"
>
  <div *dxTemplate="let data of 'title'">
    <div style="padding: 10px 0">
      <i class="fas fa-print mg-r-5"></i> {{ dialogTitle }} <span class="float-right cursor" (click)="closeDialog()"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <form novalidate>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Sélection de la période <span class="danger-color">*</span> </div>
      <div class="col-md-4">
        <dx-date-box [value]="dateFrom" type="date" placeholder="Date de début..." (onValueChanged)="onChangeStartDate($event)"></dx-date-box>
      </div>
      <div class="col-md-4">
        <dx-date-box [value]="dateTo" type="date" placeholder="Date de fin..." (onValueChanged)="onChangeEndDate($event)"></dx-date-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Types d'effectifs <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-tag-box
          #tagEffs
          [multiline]="true"
          [items]="typesEffectives"
          placeholder="Filtrer par type d'effectifs à exporter..."
          displayExpr="label"
          valueExpr="value"
          [searchEnabled]="true"
          [value]="typesEffectifsToExport"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeTypeEffective($event)">
        </dx-tag-box>
      </div>
    </div>
   <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Repas </div>
      <div class="col-md-8">
        <dx-tag-box
          #tagRepas
          [multiline]="true"
          [items]="repas"
          placeholder="Filtrer par repas..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRepas($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Régimes </div>
      <div class="col-md-8">
         <dx-tag-box
           #tagRegimes
          [multiline]="true"
          [items]="regimes"
          placeholder="Filtrer par régimes..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeRegimes($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Clients </div>
      <div class="col-md-8">
         <dx-tag-box
           #tagClients
          [multiline]="true"
          [items]="clients"
          placeholder="Filtrer par client..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeClient($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" pTooltip="Libellé">Points de livraison </div>
      <div class="col-md-8">
        <dx-tag-box
          #tagPlcs
          [multiline]="true"
          [dataSource]="pointsLivraisonsClient"
          placeholder="Filtrer par point de livraison..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangePlc($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10">
      <div class="col-md-4 genlabel">Sites </div>
      <div class="col-md-8">
        <dx-tag-box
          #tagSites
          [multiline]="true"
          [items]="sitesLocaux"
          placeholder="Filtrer par site..."
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeSite($event)">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-10 float-right">
      <div class="col-md-12">
        <button type="button" pButton
                icon="fas fa-print"
                (click)="print()"
                pTooltip="Imprimer les effectifs totaux"
                label="Imprimer"
                showDelay="500"
        ></button>
        <span class="mg-l-5">
          <button type="button" pButton
                  icon="fas fa-times"
                  class="cta-inner-delete"
                  (click)="closeDialog()"
                  pTooltip="Fermer la boîte de dialogue"
                  label="Fermer"
                  showDelay="500"
          ></button>
        </span>
      </div>
    </div>
  </form>
</dx-popup>
