<dx-popup
  [showTitle]="true"
  [title]="'Détails de la facture'"
  [(visible)]="displayInvoice"
  [width]="850"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeInvoiceDialog()"
  [fullScreen]="fullScreenInvoice"
>
  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreenInvoice
        }">
  </dxi-toolbar-item>

  <dx-scroll-view
    width="100%"
    height="100%">
    <div class="row mg-b-15 text-right" *ngIf="auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
      <div class="col-lg-12">
        <button
          pButton
          type="button"
          icon="fas fa-print"
          class="mg-r-15"
          (click)="printInvoice()"
          title="Imprimer la facture"
        >
        </button>
        <button
          pButton
          type="button"
          icon="fas fa-check"
          class="mg-r-15"
          (click)="validInvoice()"
          title="Valider la facture">
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <table class="invoice-table">
          <thead>
          <tr>
            <th>Numéro de facture</th>
            <th>Fournisseur</th>
            <th class="modifiable">Date d'émission</th>
            <th>Total HT</th>
            <th>Frais de port</th>
            <th class="modifiable">Frais de port ajustés</th>
            <th>Total TTC</th>
            <th>Auteur (création)</th>
            <th>Date de création</th>
            <th>Auteur (modification)</th>
            <th>Date de modification</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ currentInvoice?.number }}</td>
            <td>{{ currentInvoice?.provider?.libelle }}</td>
            <td>
              <ng-container *ngIf="auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
                <span *ngIf="!chooseDate" class="pointer italic" (click)="chooseDateInput()">{{ !currentInvoice?.dateBill ? 'Cliquez ici pour choisir une date' : utilsSvc.formatToDDMMYYYY(currentInvoice?.dateBill) }}</span>
                <ng-container *ngIf="chooseDate">
                  <dx-date-box
                    (onValueChanged)="switchChooseDate($event)"
                  >
                  </dx-date-box>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!auth2Svc.isSiteLocal(currentInvoice?.site?.id)">
                <span>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateBill) }}</span>
              </ng-container>
            </td>
            <td>{{ currentInvoice?.totalHT?.value }} {{ currentInvoice?.totalHT?.uniteMonetaire }}</td>
            <td>{{ currentInvoice?.shippingFees?.value }} {{ currentInvoice?.shippingFees?.uniteMonetaire }}</td>
            <td [class]="currentInvoice?.shippingFeesModifiedManually ? 'pointer italic cell-changed' : 'pointer italic'">
              <ng-container *ngIf="!changeShippingFeesEnabled">
                <span (click)="toggleShippingFeesState()">{{ currentInvoice?.realShippingFees?.value }} {{ currentInvoice?.realShippingFees?.uniteMonetaire }}</span>
              </ng-container>
              <ng-container *ngIf="changeShippingFeesEnabled">
                <dx-text-box (onValueChanged)="updateInvoice($event, 'realShippingFees.value', currentInvoice?.id)" [(value)]="currentInvoice?.realShippingFees.value" ></dx-text-box>
              </ng-container>
            </td>
            <td>{{ currentInvoice?.totalTTC?.value }} {{ currentInvoice?.totalTTC?.uniteMonetaire }}</td>
            <td>{{ currentInvoice?.authorCreation }}</td>
            <td>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateCreation) }}</td>
            <td>{{ currentInvoice?.authorModification}}</td>
            <td>{{ utilsSvc.formatToDDMMYYYY(currentInvoice?.dateModification) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row mg-t-15" *ngIf="currentInvoice?.rows.length">
      <div class="col-lg-12">
        <dx-data-grid
          (onCellPrepared)="onCellPreparedDetails($event)"
          [dataSource]="currentInvoice?.rows"
          keyExpr="id"
          [height]="'auto'"
          width="100%"
          [hoverStateEnabled]="true"
          [allowColumnResizing]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #gridDetails>
          <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>
          <dxi-column dataField="bonReceptionLigne.numBonReception" caption="Bon réception" cellTemplate="numBonReceptionTemplate"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'">
          </dxi-column>
          <dxi-column dataField="bonReceptionLigne.lotArticle.lotArticle" caption="Lot article"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"></dxi-column>
          <dxi-column dataField="bonReceptionLigne.denominationArticle" caption="Article"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"></dxi-column>
          <dxi-column dataField="quantityInvoiced" caption="Quantité facturée"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"
                      cellTemplate="quantityCellTemplate">
          </dxi-column>
          <dxi-column dataField="realQuantityInvoiced.value" caption="Quantité facturée ajustée"
                      [allowEditing]="true"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"
                      editCellTemplate="editQuantityAjusteTemplate"
                      cellTemplate="realQuantityCellTemplate">
          </dxi-column>
          <dxi-column dataField="unitPrice" caption="Prix unitaire UF"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"
                      cellTemplate="unitPriceCellTemplate"></dxi-column>
          <dxi-column dataField="realUnitPrice.value" caption="Prix unitaire UF ajusté"
                      [allowEditing]="true"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      editCellTemplate="editPuAjusteTemplate"
                      [alignment]="'left'"
                      cellTemplate="realUnitPriceCellTemplate"></dxi-column>
          <dxi-column dataField="realTotalInvoiced" caption="Total HT"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"
                      cellTemplate="realTotalInvoicedTemplate"></dxi-column>
          <dxi-column dataField="tva.value" caption="TVA" [width]="100"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'"
                      cellTemplate="tvaTemplate"></dxi-column>
          <dxi-column dataField="bonReceptionLigne.bonReceptionLigneMotifNonConfoList" caption="Motifs de non conformité" cellTemplate="motifsNonConformiteTemplate"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'">
          </dxi-column>
          <dxi-column dataField="bonReceptionLigne.bonReceptionLigneLitigeList" caption="Litiges" cellTemplate="litigesTemplate"
                      [allowEditing]="false"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      [alignment]="'left'">
          </dxi-column>
          <div *dxTemplate="let cell of 'numBonReceptionTemplate'">
            {{ cell?.row.data.bonReceptionLigne.numBonReception }}
          </div>
          <div class="total" *dxTemplate="let cell of 'unitPriceCellTemplate'">
            {{ cell?.row.data.unitPrice.value }} {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
          </div>
          <div class="total" *dxTemplate="let cell of 'realUnitPriceCellTemplate'">
            {{ cell?.row.data.realUnitPrice.value }} {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
          </div>
          <div class="total" *dxTemplate="let cell of 'quantityCellTemplate'">
            {{ cell?.row.data.quantityInvoiced.value }} {{ cell?.row.data.bonReceptionLigne.uniteDeFacturationAbreviation }}
          </div>
          <div class="total" *dxTemplate="let cell of 'realQuantityCellTemplate'">
            {{ cell?.row.data.realQuantityInvoiced.value }} {{ cell?.row.data.bonReceptionLigne.uniteDeFacturationAbreviation }}
          </div>
          <div class="total" *dxTemplate="let cell of 'realTotalInvoicedTemplate'">
            {{ cell?.row.data.realTotalInvoiced.value }} {{ cell?.row.data.realTotalInvoiced.uniteMonetaire }}
          </div>
          <div class="total" *dxTemplate="let cell of 'tvaTemplate'">
            {{ cell?.row.data.tva.valeur }} %
          </div>
          <div class="total" *dxTemplate="let cell of 'priceCellTemplate'">
            {{ cell?.row.data.realUnitPrice.uniteMonetaire }}
          </div>

          <div *dxTemplate="let cell of 'editPuAjusteTemplate'">
            <dx-text-box (onValueChanged)="updateInvoice($event, 'realUnitPrice.value', cell?.row.data.id)" [(value)]="cell?.row.data.realUnitPrice.value" ></dx-text-box>
          </div>
          <div *dxTemplate="let cell of 'editQuantityAjusteTemplate'">
            <dx-text-box (onValueChanged)="updateInvoice($event, 'realQuantityInvoiced.value', cell?.row.data.id)" [(value)]="cell?.row.data.realQuantityInvoiced.value" ></dx-text-box>
          </div>
          <div *dxTemplate="let cell of 'motifsNonConformiteTemplate'">
            {{ joinLitigesOrMotifs(cell?.row.data.bonReceptionLigne.bonReceptionLigneMotifNonConfoList, true) }}
          </div>
          <div *dxTemplate="let cell of 'litigesTemplate'">
            {{ joinLitigesOrMotifs(cell?.row.data.bonReceptionLigne.bonReceptionLigneLitigeList, false) }}
          </div>

          <dxo-pager
            [showPageSizeSelector]="true"
            [showNavigationButtons]="false"
            [allowedPageSizes]="[20, 50, 100, 200]"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} ligne(s)">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
