<p-dialog [header]="dialogTitle"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'890px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="udpMcpList"
                     filterPlaceHolder="Unité de production / Modèle de conditionnement plat *"
                     [filter]="true"
                     (onChange)="changeUdpModel($event)"
                     [style.width]="'100%'"
                     [listStyle]="{'height':'200px'}"
                     optionLabel="libelleUniteDeProduction"
                     formControlName="udpMcp"
                     >
            <ng-template let-item pTemplate="item">
              <div class="item">
                <div>{{ item.libelleUniteDeProduction + ' / ' +  item.libelleModeleConditionnementPlat }}</div>
              </div>
            </ng-template>
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'udpMcp',label:'Unité de production / Modèle de conditionnement plat'}"></ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="p-inputgroup input-group-sm ">
          <p-listbox [options]="unitesList"
                     filterPlaceHolder="Pour un(e) *"
                     [filter]="true"
                     (onChange)="changeUniteAConditionner($event)"
                     [style.width]="'100%'"
                     [listStyle]="{'height':'200px'}"
                     optionLabel="libelle"
                     formControlName="uniteAConditionner">
          </p-listbox>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'uniteAConditionner',label:'Pour un(e)'}"></ng-container>
        </div>
      </div>
      <div class="col-md-4">
        <div class="p-inputgroup input-group-sm ">
          <textarea id="textRemarque" rows="2" cols="50" style="width: 100%; height: 255px;" placeholder="Remarque(s) "
                    formControlName="remarques" pInputTextarea></textarea>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'remarques',label:'>Remarque(s)'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-12">
        Instructions  <i id="add-instruction" class="p-button-primary fas fa-plus" title="Ajouter une instruction" (click)="addRowInstruction()"></i>
      </div>
      <div class="col-md-12">
        <div class="p-inputgroup input-group-sm ">
          <dx-data-grid
            [dataSource]="instructionsByDeclinaison"
            height="150px"
            width="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [showRowLines]="true"
            [showBorders]="true"

            #gridInstructions>
            <dxi-column width="80px" caption="Action(s)" cellTemplate="actionsTemplate"></dxi-column>
            <dxi-column caption="Instruction" datafield="libelle" cellTemplate="libelleTemplate"></dxi-column>
            <dxi-column *ngFor="let declinaison of declinaisons"
                        cellTemplate="instructionTemplate"
                        dataField="valuesByDeclinaison"
                        [name]="declinaison.libelle"
                        [caption]="declinaison.libelle">
            </dxi-column>

            <div *dxTemplate="let cell of 'actionsTemplate'" class="text-center">

              <yo-cell-button (yoNavigation)="deleteRowInstruction($event, cell.rowIndex)"
                              [yoTooltipShowDelay]="1500"
                              [yoMaxWidth]="true"
                              [yoWidthPercent]="30"
                              [yoTextAlign]="'center'"
                              [yoIconClass]="'fas fa-trash'"
                              [pTooltip]="'Supprimer l\'instruction'"
                              tooltipPosition="right"
                              showDelay="500">
              </yo-cell-button>
            </div>
            <div *dxTemplate="let cell of 'libelleTemplate'">
              <dx-text-box *ngIf="instructionsByDeclinaison.length" [value]="cell.row.data.libelle" (onValueChanged)="changeLibelleInstruction($event, cell.rowIndex)"  placeholder="Libellé de l'instruction"></dx-text-box>
            </div>
            <div *dxTemplate="let cell of 'instructionTemplate'">
              <dx-number-box [value]="cell.row.data.values[cell.columnIndex - 2]" (onValueChanged)="changeValueInstruction($event, cell.rowIndex, cell.columnIndex - 2)"></dx-number-box>
            </div>

          </dx-data-grid>
        </div>
      </div>
    </div>

    <ng-container *ngIf="displayFormNonNourri">

      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" pTooltip="Libellé">Poids net (Fiche Technique) en g <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm ">
            <dx-data-grid
              [dataSource]="[declinaisons]"
              height="100px"
              width="100%"
              [hoverStateEnabled]="true"
              [allowColumnResizing]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              #grid>
              <dxi-column *ngFor="let declinaison of declinaisons"
                          cellTemplate="poidsNetTemplate"
                          [width]="300"
                          [caption]="declinaison.libelle">
              </dxi-column>
              <div *dxTemplate="let cell of 'poidsNetTemplate'">
                <dx-number-box (onValueChanged)="changePoidsNetFicheTechnique($event, cell)" [value]="declinaisonsPoidsFTList[cell.columnIndex].poidsNet"></dx-number-box>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>

      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" pTooltip="Libellé">Nombre de pièce(s) à servir au convive <span class="danger-color">*</span></div>
        <div class="col-md-8">
          <div class="p-inputgroup input-group-sm ">
            <dx-data-grid
              [dataSource]="[declinaisons]"
              height="100px"
              width="100%"
              [hoverStateEnabled]="true"
              [allowColumnResizing]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              #grid>
              <dxi-column *ngFor="let declinaison of declinaisons"
                          cellTemplate="poidsNetTemplate"
                          [width]="300"
                          [caption]="declinaison.libelle">
              </dxi-column>
              <div *dxTemplate="let cell of 'poidsNetTemplate'">
                <dx-number-box (onValueChanged)="changeNbUc($event, cell)" [value]="declinaisonsNbUCList[cell.columnIndex].nbUc"></dx-number-box>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>

      <div class="row mg-t-10">
        <div class="col-md-4 genlabel required" pTooltip="Libellé">Poids net d'un(e) {{ uniteAConditionner ? uniteAConditionner.libelle : "unité à conditionner" }} en Kg <span class="danger-color">*</span></div>
        <div class="col-md-1">
          <div class="p-inputgroup input-group-sm ">
            <input type="number" id="poidsUc" style="width: 100%" formControlName="poidsUc" pInputTextarea />
            <ng-container
              *ngTemplateOutlet="tplError;context:{form:form,property:'poidsUc',label:'>Poids net d\'une unité à conditionner en Kg'}"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="d-flex justify-content-end" style="margin-top: 15px">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        class="p-button-success"
        [disabled]="!canSave()"
        [class.disable]="!canSave()">
      </button>
      <button
        class="mg-l-5 cta-inner-delete"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>
</p-dialog>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
