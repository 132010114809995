<dx-popup
  [showTitle]="true"
  title="Modifier le lot"
  [(visible)]="displayDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHiding)="closeDialog"
  width="1100"
>
  <ng-container *ngIf="lotSelected">
    <div class="row no-margin" id="form-edit-lot-storage-areas">
      <div class="col-xs-12">
        <dx-list
          [dataSource]="dataSource"
          [height]="500"
          selectionMode="single"
          selectAllMode="allPages"
          displayExpr="libelleZoneDeStockage"
          valueExpr="id"
          keyExpr="id"
          [showSelectionControls]="true"
          [searchEnabled]="true"
          [selectedItemKeys]="selectedUdpZdsKeys"
          (onSelectionChanged)="onChangeUdpZds($event)"
          searchExpr="libelleZoneDeStockage"
          searchMode="contains"
        >
          <dxo-search-editor-options
            placeholder="Filtrer par zone de stockage..."
          >
          </dxo-search-editor-options>
        </dx-list>
      </div>
    </div>
    <div class="row mg-b-15" id="form-edit-lot-prices-quantities">
      <div class="col-xs-3 mg-l-15 mg-r-5">
        <label for="quantite-stock" class="genlabel no-overflow" class="font-10">
          <span class="danger-color">*</span> {{ 'Quantité' | uppercase }}
        </label>
        <div class="input-group input-group-sm ">
          <input type="text"
                 id="quantite-stock"
                 class="form-control form-control-sm "
                 placeholder="Quantité"
                 [(ngModel)]="quantite">
          <div class="input-group-append ">
            <span class="input-group-text">{{ lotSelected?.uniteDeStockage }}</span>
          </div>
        </div>
      </div>
      <div class="col-xs-3 mg-l-15 mg-r-5">
        <label for="quantite-stock" class="genlabel no-overflow" class="font-10">
          <span class="danger-color">*</span> PU H.T EN UF
        </label>
        <div class="input-group input-group-sm ">
          <input type="text"
                 class="form-control form-control-sm "
                 placeholder="Prix unitaire Hors Taxe en unité de facturation"
                 [(ngModel)]="prixUnitaireHt">
          <div class="input-group-append ">
            <span class="input-group-text">€/{{ lotSelected.udmFacturationLibelle ? lotSelected.udmFacturationLibelle : lotSelected.uniteFacturation }}</span>
          </div>
        </div>
      </div>
      <div class="col-xs-3 mg-l-15 mg-r-5">
        <label for="quantite-stock" class="genlabel no-overflow" class="font-10">
          <span class="danger-color">*</span> {{ 'Date entrée' | uppercase }}
        </label>
        <div class="input-group input-group-sm ">
          <dx-date-box [value]="dateEntree" type="date" placeholder="Date entrée" (onValueChanged)="onChangeDateEntree($event)"></dx-date-box>
        </div>
      </div>
      <div class="col-xs-3 mg-l-15 mg-r-5">
        <label for="quantite-stock" class="genlabel no-overflow" class="font-10">
          <span class="danger-color">*</span> DLC
        </label>
        <div class="input-group input-group-sm ">
          <dx-date-box [value]="dlc" type="date" placeholder="DLC" (onValueChanged)="onChangeDlc($event)"></dx-date-box>
        </div>
      </div>
    </div>
  </ng-container>

  <div id="footer" class="d-flex justify-content-end">
    <div class="d-flex">
      <div class="ml-auto">
        <button type="button" pButton  class="mg-r-15" class="p-button-success"  icon="fas fa-save" (click)="updateLot()" [title]="labelSubmit" [label]="labelSubmit" showDelay="500"></button>
        <button type="button" pButton icon="fas fa-times" class="cta-inner-delete" [title]="labelCancel" [label]="labelCancel" showDelay="500"
                (click)="closeDialog()"></button>
      </div>
    </div>
  </div>
</dx-popup>

