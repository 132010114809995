<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"

  [style]="{'min-width':'500px'}"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="mg-r-5 fas fa-industry yoni-color"></i>{{uniteDeProduction?.libelle}} Sélection des fournisseurs
      préférées
    </div>
  </ng-template>

  <ng-container *ngIf="!utils.isCollectionNullOrEmpty(udpSfList)">

    <p-listbox [options]="udpSfList"
               [(ngModel)]="selectedUdpSfList"
               [style]="{'width':'450px'}"
               [listStyle]="{'max-height':'600px'}"
               multiple="multiple"
               checkbox="checkbox"
               filter="filter" optionLabel="fournisseurLibelle">
      <ng-template pTemplate="header">
        <i class="mg-r-5 fas fa-truck"></i>
        Fournisseurs préférées
      </ng-template>
      <ng-template let-item pTemplate="item">
        {{item.fournisseurLibelle}} - {{item.fournisseurCode}} (Secteur {{ item.secteurLibelle }})
      </ng-template>
    </p-listbox>

  </ng-container>

  <ng-template pTemplate="footer">
    <span class="mg-r-5">
    <button type="button" class="p-button-success" pButton icon="fas fa-save" (click)="saveFilialesPreferees()"></button>
    </span>
    <span class="mg-r-5">
    <button type="button" pButton icon="fas fa-times" class="cta-delete" (click)="closeDialog()"></button>
    </span>
  </ng-template>


</p-dialog>

