<h5>
  <label class="badge badge-secondary">
    <i class="fa fa-user yoni-color  mg-r-5"></i>
    <ng-container *ngIf="yoUtilisateur.id === 0; else editMode">
      {{'Créer un utilisateur'}}
    </ng-container>
    <ng-template #editMode>
      {{yoUtilisateur.nom | uppercase}}  {{yoUtilisateur.prenom | titlecase}}
    </ng-template>
  </label>
</h5>

<div class="d-flex flex-column">
  <div [style.height.px]="utils.getWindowAvailableHeight(110)" style="overflow-y: auto;">
    <form [formGroup]="formGroupCtrl" autocomplete="off">


      <p-panel header="Informations générales" [style]="{'margin-bottom': '10px'}">
        <div formGroupName="utilisateurGroup">

          <div class="d-flex flex-row">
            <div class="mg-r-50">
              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Nom <span class="danger-color">*</span> : </label>
                <input
                  formControlName="nom"
                  class="size-input"
                  [required]="true"
                  [(ngModel)]="yoUtilisateur.nom"
                  showDelay="1500"
                  onfocus="removeAttribute('readonly')"
                  pInputText/>
              </div>

              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Prénom <span class="danger-color">*</span> : </label>
                <input
                  formControlName="prenom"
                  class="size-input"
                  [required]="true"
                  [(ngModel)]="yoUtilisateur.prenom"
                  showDelay="1500"
                  onfocus="removeAttribute('readonly')"
                  pInputText/>
              </div>

              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Email <span class="danger-color">*</span> : </label>
                <input
                  formControlName="email"
                  class="size-input"
                  [required]="true"
                  [(ngModel)]="yoUtilisateur.email"
                  showDelay="1500"
                  onfocus="removeAttribute('readonly')"
                  pInputText/>
              </div>

              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Code <span class="danger-color">*</span> : </label>
                <input
                  formControlName="code"
                  class="size-input"
                  [required]="true"
                  [(ngModel)]="yoUtilisateur.code"
                  showDelay="1500"
                  onfocus="removeAttribute('readonly')"
                  pInputText/>
              </div>
            </div>

            <div>
              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Login <span class="danger-color">*</span> : </label>
                <input
                  formControlName="login"
                  class="size-input"
                  [required]="true"
                  [disabled]="yoUtilisateur.id > 0"
                  [(ngModel)]="yoUtilisateur.login"
                  showDelay="1500"
                  onfocus="removeAttribute('readonly')"
                  pInputText/>
              </div>

              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Mot de passe <span class="danger-color">*</span> : </label>
                <input
                  formControlName="motDePasse"
                  class="size-input"
                  type="password"
                  [(ngModel)]="motDePasse"
                  pInputText/>
              </div>
              <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
                <label>Confirmation <span class="danger-color">*</span> : </label>
                <input
                  formControlName="motDePasseConfirmation"
                  class="size-input"
                  type="password"
                  [(ngModel)]="motDePasseConfirmation"
                  pInputText/>
              </div>

              <p-checkbox
                class="mg-b-10"
                formControlName="actif"
                binary="true"
                value="actif"
                label="Actif"
                [(ngModel)]="yoUtilisateur.actif">
              </p-checkbox>
            </div>
          </div>

        </div>
      </p-panel>
    </form>

    <p-tabMenu [activeItem]="tabMenuUser[0]" [model]="tabMenuUser"></p-tabMenu>

    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.profil">
      <h3 class="first">Liste des profils <span class="danger-color">*</span></h3>
      <!-- PROFILS-->
      <div class="d-flex flex-row">
        <div class="mg-r-15">

          <p-listbox
            filter="true"
            appendTo="body"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            [options]="arrayAllProfilDTO"
            (onChange)="changeProfil($event)"
            [(ngModel)]="selectedProfil"
            [ngModelOptions]="{standalone: true}"
            optionLabel="libelle"
          >
            <ng-template let-item pTemplate="item">
              {{item.libelle}}
            </ng-template>
          </p-listbox>
        </div>


        <div
          *ngIf="!utils.isNullOrEmpty(selectedProfil) && !utils.isCollectionNullOrEmpty(selectedProfil.roleList)">
          <p-table
            [value]="selectedProfil.roleList"
            [scrollable]="true"
            [scrollHeight]="utils.scrollHeightContent(506)"
            styleClass="p-datatable-sm"
          >

            <ng-template pTemplate="header">
              <tr>
                <th style="width:300px">Rôle</th>
                <th>Description</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="width:300px">{{rowData.libelle}}</td>
                <td>{{rowData.description}}</td>
              </tr>
            </ng-template>

          </p-table>
        </div>

      </div>
    </div>

    <!--ENVIRONNEMENT-->
    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.environnement">
      <h3 class="first">Liste des environnements <span class="danger-color">*</span></h3>
      <div class="flex-row flex-row">
        <div class="mg-t-10">
          <p-listbox
            multiple="multiple"
            filter="true"
            [checkbox]="true"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            defaultLabel="Selectionner..."
            [options]="environnementList"
            optionLabel="libelle"
            [(ngModel)]="environmentsSelected"
          >
          </p-listbox>
        </div>
      </div>
    </div>

    <!--ENVIRONNEMENT PLC-->
    <div *ngIf="tabVisibility === TAB_FICHE_UTILISATEUR.envPlc">
      <h3 class="first">Liste des env. point de livraison <span class="danger-color">*</span></h3>
      <!-- PROFILS-->
      <div class="d-flex flex-row">
        <div class="mg-r-15">

          <p-listbox
            filter="true"
            appendTo="body"
            [listStyle]="{'min-height':'280px','max-height':'280px'}"
            [style]="{'width':'300px'}"
            [options]="envPointDeLivraison"
            [(ngModel)]="envPointDeLivraisonSelected"
            [ngModelOptions]="{standalone: true}"
            optionLabel="libelle">
            <ng-template let-item pTemplate="item">
              {{item.libelle}}
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row mg-t-10">
      <div class="mr-auto"></div>
      <div>
        <button pButton
                [title]="yoUtilisateur.id === 0 ?'Enregistrer le nouvel utilisateur':'Sauvegarde des modifications'"
                type="submit"
                icon="fas fa-save"
                class="p-button-success"
                (click)="save()">
        </button>
      </div>
    </div>
  </div>
</div>

