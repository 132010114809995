import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {InternationalizationService} from "../i8n/i8n.service";
import {
  GestionUniteProduction__ModeleConditionnementPlatService
} from "../gestion-unites-productions/unite-production__modele-conditionnement-plat.service";

@Injectable({
  providedIn: 'root'
})
export class WizardConditionnementService {

  private subjectOpenDialogWizard = new Subject<any>();
  openDialogWizard$ = this.subjectOpenDialogWizard.asObservable();

  constructor(private i8nSvc: InternationalizationService,
              private udpMcpSvc: GestionUniteProduction__ModeleConditionnementPlatService) {
  }

  announceOpenDialogWizardConditionnement = () => {
    this.subjectOpenDialogWizard.next(null);
  };

  saveStepOne = (dataStepOne: any): void => {
    const errors: string[] = [];
    if(!dataStepOne.idSelectedSite)
      errors.push(this.i8nSvc.getLabelFromCode('SITE_MANDATORY', null));
    if(!dataStepOne.unitesDeProductionSelected || !dataStepOne.unitesDeProductionSelected.length)
      errors.push(this.i8nSvc.getLabelFromCode('UDPS_MUST_BE_SELECTED', null));
    if (errors.length) throw errors;
    localStorage.setItem('idSiteForModelePlat', `${dataStepOne.idSelectedSite}`);
    localStorage.setItem('idsUdpForModelePlat', `${dataStepOne.unitesDeProductionSelected}`);
  }

  getDataStepOne = (): any => {
    const idSelectedSite: number = localStorage.getItem('idSiteForModelePlat') ? parseInt(localStorage.getItem('idSiteForModelePlat')) : undefined;
    const unitesDeProductionSelected: number[] = localStorage.getItem('idsUdpForModelePlat') ? localStorage.getItem('idsUdpForModelePlat').split(",").map(id => parseInt(id)) : [];
    return { idSelectedSite, unitesDeProductionSelected };
  }

  saveStepTwo = (dataStepTwo: any, unitesDeProductionSelected: number[]): Observable<any> => {
    const errors: string[] = [];
    if(!dataStepTwo.modeModelePlatSelected)
      errors.push(this.i8nSvc.getLabelFromCode('MODE_MCP_MANDATORY', null));
    if(!dataStepTwo.idUniteMesureSelected)
      errors.push(this.i8nSvc.getLabelFromCode('UNITE_CONDITIONNEMENT_MANDATORY', null));
    if(!dataStepTwo.labelModelPlat)
      errors.push(this.i8nSvc.getLabelFromCode('LABEL_MANDATORY', null));
    if (errors.length) throw errors;
    localStorage.setItem('modeModelePlat', `${dataStepTwo.modeModelePlatSelected}`);
    localStorage.setItem('idUniteAConditionner', `${dataStepTwo.idUniteMesureSelected}`);
    localStorage.setItem('labelModelPlat', `${dataStepTwo.labelModelPlat}`);
    localStorage.setItem('idModelPlat', `${dataStepTwo.id}`);
    return this.udpMcpSvc.save(dataStepTwo.id, unitesDeProductionSelected);
  }

  getDataStepTwo = (): any => {
    const modeModelePlatSelected: string = localStorage.getItem('modeModelePlat');
    const idUniteMesureSelected: number = localStorage.getItem('idUniteAConditionner') ? parseInt(localStorage.getItem('idUniteAConditionner')) : undefined;
    const labelModelPlat: string = localStorage.getItem('labelModelPlat');
    const id: number = localStorage.getItem('idModelPlat') ? parseInt(localStorage.getItem('idModelPlat')) : undefined;
    return { id, modeModelePlatSelected, idUniteMesureSelected, labelModelPlat };
  }

  saveDataStepFive = (declinaisonsSelected): void => {
    localStorage.setItem("declinaisonsSelected", declinaisonsSelected);
  }

  getDataStepFive = (): any => {
    const declinaisonsSelected: number[] = localStorage.getItem("declinaisonsSelected")
      ? localStorage.getItem("declinaisonsSelected").split(",").map(id => parseInt(id))
      : [];
    return { declinaisonsSelected };
  }

  saveStepSix = (dataStepSix: any): any => {
    localStorage.setItem("idsProducts", dataStepSix.idsProducts);
  }

  getDataStepSix = (): any => {
     const idsProducts: number[] = localStorage.getItem("idsProducts")
       ? localStorage.getItem("idsProducts").split(",").map(id => parseInt(id))
       : [];
     return { idsProducts };
   }

  clear = (): void => {
    localStorage.removeItem('idSiteForModelePlat');
    localStorage.removeItem('idsUdpForModelePlat');
    localStorage.removeItem('modeModelePlat');
    localStorage.removeItem('idUniteAConditionner');
    localStorage.removeItem('labelModelPlat');
    localStorage.removeItem('idModelPlat');
    localStorage.removeItem("idsProducts");
    localStorage.removeItem("declinaisonsSelected");
  }

}
