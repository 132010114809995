<form [formGroup]="form" (ngSubmit)="saveFicheIdentite()" novalidate>
  <div class="card ">
    <div class="card-header">
      <label class="mg-r-5 bold">Prestation {{contratMenuConvive?.convive?.libelle | uppercase}}</label>
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <span class="floatright">
          <button pButton
                      title="Enregistrer"
                      type="submit"
                      icon="fas fa-save"
                      class="mg-r-5 p-button-success"
                      [disabled]="!gds.canModify(contratMenuConvive)"></button>
          <button *ngIf="contratMenuConvive?.id !== 0" pButton icon="fas fa-trash"
                (click)="openDeleteDialog(contratMenuConvive)"
                [disabled]="!canDelete(contratMenuConvive)"
                  class="cta-inner-delete"
                pTooltip="Supprimer" tooltipPosition="top"
                showDelay="500" class="p-button-danger">
          </button>
      </span>
    </div>
    <div class="card-body" style="overflow: auto;max-height:85vh;">

      <ng-container *ngFor="let field of fieldsInfosGenerales">
        <yo-dynamic-form-field-bs [fields]="fieldsInfosGenerales" [field]="field" [form]="formInfoGenerales"></yo-dynamic-form-field-bs>
      </ng-container>

    </div>
  </div>
</form>

