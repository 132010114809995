<div class="row part-content">
  <div class="col-lg-3 border-left-wizard"></div>
  <div class="col-lg-9 pg-t-15 text-justify">
    <h1>Sélection des plats à conditionner</h1>
    <div class="item-wizard">
      <p>
        Nous vous proposons de sélectionner à travers cette étape les plats que vous désirez conditionner. Le tableau de gauche représente la liste
        des plats à conditionner compatibles avec votre modèle de plats tandis que le tableau de droite représente votre sélection dans lequel vous
        pouvez supprimer des lignes en cas d'erreur.
        Chaque plat sélectionné sera associé au modèle de conditionnement plat actuel ainsi que les unités de production associés à ce dernier.
      </p>
      <div class="row mg-t-12">
        <div class="col-lg-12 container-table">
          <dx-data-grid
            [dataSource]="produits"
            keyExpr="id"
            width="100%"
            height="100%"
            [hoverStateEnabled]="true"
            [rowAlternationEnabled]="true"
            [allowColumnResizing]="true"
            [showRowLines]="true"
            [showBorders]="true">
            <dxi-column dataField="libelle" caption="Nom du plat" [allowFiltering]="false"></dxi-column>
            <dxi-column *ngFor="let d of declinaisons"
                        cellTemplate="dCellTemplate"
                        [width]="300"
                        [dataField]="d.id"
                        [caption]="d?.libelle">
            </dxi-column>
            <div class="flex-wrap" *dxTemplate="let cell of 'dCellTemplate'">
              <input *ngIf="findTotalNetUT(cell.row.data.id, cell.column.dataField)" type="number" (change)="updateTotalNetUT($event, cell.row.data.id, cell.column.dataField)" [value]="findTotalNetUT(cell.row.data.id, cell.column.dataField)" />
            </div>

            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[20, 50, 100]"
              [showNavigationButtons]="false"
              [visible]="true"
              [showInfo]="true"
              infoText="{2} plat(s)">
            </dxo-pager>

          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row part-footer">
  <div class="col-lg-12 text-center">
    <button title="Passer à l'étape précédente"
            class="mg-r-5"
            type="button"
            (click)="previous()">
      <i class="fas fa-chevron-left"></i> Précédent
    </button>
    <button  title="Passer à l'étape suivante"
             class="mg-r-5"
             type="button"
             (click)="next()">
      Suivant <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

