import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import ModeleConditionnementPlatDTO from '../../dtos/conditionnement/modele-conditionnement-plat-dto';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ResteDTO from '../../dtos/conditionnement/reste-dto';
import {Subject} from "rxjs/index";
import {DeclinaisonDTO} from "../../dtos/declinaison-dto";
import {RowParametrageModel} from "../../models/gestion-conditionnements/row-parametrage-model";
import {ConditionnementVarianteForListBoxDTO} from "../../../gestion-conditionnements/modeles-plats/dialog/parametrage/dialog/mp-add-cv-decli.component";
import ConditionnementVarianteDTO from "../../dtos/conditionnement/conditionnement-variante-dto";

export const URL_GET_ALL_MODELES_PLATS = `dolrest/conditionnements/modeles-conditionnements-plats/list`;
export const URL_GET_ALL_MODES = `dolrest/conditionnements/modeles-conditionnements-plats/modes`;
export const URL_GET_MODELE_PLAT = `dolrest/conditionnements/modeles-conditionnements-plats/findById`;
export const SAVE_PARAMETRAGE_MODELE_PLAT = `dolrest/conditionnements/modeles-conditionnements-plats/saveParametrage`;
export const URL_SAVE = `dolrest/conditionnements/modeles-conditionnements-plats/save`;
export const URL_SAVE_ROW_PARAMETRAGE = `dolrest/conditionnements/modeles-conditionnements-plats/saveRowParametrage`;
export const URL_DUPLICATE_MODELE = `dolrest/conditionnements/modeles-conditionnements-plats/duplicate`;

@Injectable({
  providedIn: 'root'
})
export class ModelesPlatsService {

  private subMcpSaved = new Subject<MpcSavedSupplier>();
  mcpSaved$ = this.subMcpSaved.asObservable();

  private subCreationRowParametrageAsked = new Subject<ModeleConditionnementPlatDTO>();
  creationRowParametrageAsked$ = this.subCreationRowParametrageAsked.asObservable();

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles de conditionnement pour des plats
   * @returns {Observable<ModeleConditionnementPlatDTO>}
   */
  getAllModelesConditionnementsPlats = (): Observable<ResponseWrapper<ModeleConditionnementPlatDTO>> => this.httpSvc.get(URL_GET_ALL_MODELES_PLATS, null);

  /**
   * Récupérer tous les modèles de conditionnement pour des plats
   * @returns {Observable<ModeleConditionnementPlatDTO>}
   */
  getAllModes = (): Observable<ResponseWrapper<string>> => this.httpSvc.get(URL_GET_ALL_MODES, null);

  findById = (id: number): Observable<ResponseWrapper<ModeleConditionnementPlatDTO>> => this.httpSvc.get(`${URL_GET_MODELE_PLAT}/${id}`, null);

  formatDataParametrage = (grid: any, mappingColumnsDeclinaisons: Map<number, number>, idMcp: number, mode: string, queuesConfiguration: any): ModelePlatParametrage => {
    grid.forEach(g => {
      g.data = [];
      Object.keys(g.declinaisons).forEach(col => {
        let idDeclinaison = null;
        const column = g.declinaisons[col];
        const idCol = parseInt(col, 10);
        Object.keys(column).forEach(c => {
          const idRow = parseInt(c, 10);
          idDeclinaison = mappingColumnsDeclinaisons.get(idCol);
          Object.keys(column[idRow]).forEach(rowcol => {
            const info = g.declinaisons[idCol][idRow];
            const { contient, effectifNourri, poidsNet, mcp__did, mcp__cvid  } = info;
            if (g.data.findIndex(i => i.idDeclinaison === idDeclinaison && i.idCvd === info.cvdid) < 0) {
              g.data.push({idDeclinaison, idCvd: info.cvdid, contient, effectifNourri, poidsNet, mcp__did, mcp__cvid });
            }
          });
        });
      });
      delete g.declinaisons;
    });

    const data: ModelePlatParametrage = {
      idMcp,
      grid,
      queues: [],
      mode
    };

    Object.keys(queuesConfiguration).forEach(idCol => {
      data.queues.push({
        idDeclinaison: mappingColumnsDeclinaisons.get(parseInt(idCol, 10)),
        reste: queuesConfiguration[idCol].reste,
        idmcpd: queuesConfiguration[idCol].idmcpd
      });
    });

    return data;
  }

  /**
   * Enregistre le paramétrage du modèle de plat
   * @param data paramétrage
   */
  saveParametrage = (data: ModelePlatParametrage) => this.httpSvc.post(SAVE_PARAMETRAGE_MODELE_PLAT, data);

  /**
   * Enregistre un modèle de plat
   * @param data modèle de plat
   */
  save = (data: ModeleConditionnementPlatDTO) => this.httpSvc.post(URL_SAVE, data);

  duplicateModele = (modeleIdToDuplicate: number, duplicationSiteSelectedId: number, duplicateMcPlcData: boolean) => this.httpSvc.post(`${URL_DUPLICATE_MODELE}/${modeleIdToDuplicate}/${duplicationSiteSelectedId}/${duplicateMcPlcData}`, null);

  /**
   * Enregistre une association modèle de plat avec d'une part des déclinaisons et d'autre part
   * des conditionnements variantes
   * @param mcp
   * @param conditionnementsVariantesList
   * @param declinaisonsList
   */
  saveRowParametrage = (mcp: ModeleConditionnementPlatDTO, conditionnementsVariantesList: ConditionnementVarianteForListBoxDTO[],
                        declinaisonsList: DeclinaisonDTO[]) => {
    const declinaisonsWithoutSite: DeclinaisonDTO[] = declinaisonsList.map(d => ({ id: d.id, libelle: d.libelle }) as DeclinaisonDTO);
    const conditionnementsVariantesSimpleList: ConditionnementVarianteDTO[] = conditionnementsVariantesList
      .map(cv => ({ id: cv.id, conditionnement: cv.conditionnement, variante: cv.variante }) as ConditionnementVarianteDTO);
    return this.httpSvc.post(URL_SAVE_ROW_PARAMETRAGE, {
      mcpId: mcp.id,
      conditionnementsVariantesList: conditionnementsVariantesSimpleList,
      declinaisonsList: declinaisonsWithoutSite
    } as RowParametrageModel);
  };

  saveRowParametrageModern = (mcp: ModeleConditionnementPlatDTO, conditionnementsVariantesList: ConditionnementVarianteDTO[],
                        declinaisonsList: DeclinaisonDTO[]) => {
    const declinaisonsWithoutSite: DeclinaisonDTO[] = declinaisonsList.map(d => ({ id: d.id, libelle: d.libelle }) as DeclinaisonDTO);
    return this.httpSvc.post(URL_SAVE_ROW_PARAMETRAGE, {
      mcpId: mcp.id,
      conditionnementsVariantesList,
      declinaisonsList: declinaisonsWithoutSite
    } as RowParametrageModel);
  };

  /**
   * Annonce l'enregistrement d'un modèle de plat
   * @param mcp modèle de plat enregistré
   * @param isUpdate
   */
  announceMcpSaved = (mpc: ModeleConditionnementPlatDTO, isUpdate: boolean = false) => {
    this.subMcpSaved.next({ mpc, isUpdate } as MpcSavedSupplier);
  };

  /**
   * Annonce la création d'une ligne de paramétrage
   * @param mpc (Ne doit jamais être renseigné si on respecte le cas général)
   */
  announceCreationRowParametrageAsked = (mpc?: ModeleConditionnementPlatDTO) => {
    this.subCreationRowParametrageAsked.next(mpc);
  };

}

export interface MpcSavedSupplier {
  isUpdate: boolean;
  mpc: ModeleConditionnementPlatDTO;
}

export interface ModelePlatParametrage {
  grid: any; // GridModelePlatParametrage
  idMcp: number;
  mode: string;
  queues: QueueModelePlatParametrage[];
}

export interface QueueModelePlatParametrage {
  idDeclinaison: number;
  idmcpd: number;
  reste: ResteDTO;
}

export interface GridModelePlatParametrage {
  conditionnementLabel: string;
  varianteLabel: string;
  idtache: number;
  idmcpcv: number;
  id: number; // conditionnement variante
  data: DataGridModelePlatParametrage[];
}

export interface DataGridModelePlatParametrage {
  idDeclinaison: number;
  idCvd: number;
  mcp__did: number;
  mcp__cvid: number;
  contient: number;
  effectifNourri: number;
  poidsNet: number;
}

