<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button
        pButton
        class="mg-r-10"
        icon="fa fa-plus"
        label="CRÉER"
        pTooltip="Créer un article"
        [disabled]="!canCreateArticle()"
        (click)="openEditObject(undefined)">
      </button>
      <p-dropdown
        [filter]="true"
        [options]="uniteDeProductionList"
        [style]="{'max-width':'200px','width':'200px'}"
        optionLabel="libelle"
        (onChange)="onChangeSelectedUniteDeProduction($event)"
        [(ngModel)]="uniteDeProductionSelected">
      </p-dropdown>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  id="gridContainer"
  [dataSource]="viewModelCatalogueAchatList"
  [height]="utils.getWindowAvailableHeight(220)"
  [showBorders]="true"
  [showRowLines]="true"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [rowAlternationEnabled]="true"
  keyExpr="id"
  #grid>
  <dxi-column dataField="id" caption="Actions"
              [allowFiltering]="false"
              [allowGrouping]="false"
              [alignment]="'left'"
              cellTemplate="actionsCellTemplate"></dxi-column>
  <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison"
              [allowFiltering]="false"
              cellTemplate="declinaisonCellTemplate"></dxi-column>
  <dxi-column dataField="article" caption="Article"
              cellTemplate="articleCellTemplate"
              [width]="200"></dxi-column>
  <dxi-column dataField="fournisseur" caption="Fournisseur" cellTemplate="fournisseurCellTemplate"></dxi-column>
  <dxi-column dataField="groupeAchatCaList[0].prixUF.value"
              cellTemplate="groupeAchatCaCellTemplate"
              caption="Prix d'achat en UF"
              [alignment]="'left'"
              [width]="250">
  </dxi-column>
  <dxi-column dataField="codeApi" caption="Code Api"></dxi-column>
  <dxi-column dataField="referenceArticle" caption="Réf. art." pTooltip="Référence article" [width]="100"></dxi-column>
  <dxi-column dataField="referenceFournisseur" caption="Réf. art. fourn."
              pTooltip="Référence article chez le fournisseur"></dxi-column>
  <dxi-column dataField="uniteFacturation" caption="UF (Unité de Facturation)"
              [allowFiltering]="false"
              [allowGrouping]="false"
              [allowSorting]="false"
              cellTemplate="ufCellTemplate"></dxi-column>
  <dxi-column dataField="uniteCommande" caption="UC (Unité de Commande)"
              [allowFiltering]="false"
              [allowGrouping]="false"
              [allowSorting]="false"
              cellTemplate="ucCellTemplate"></dxi-column>
  <dxi-column dataField="uniteDeStockage" caption="US (Unité de Stockage)" cellTemplate="usCellTemplate"
              [allowFiltering]="false"
              [allowGrouping]="false"
              [allowSorting]="false"
              [width]="150"></dxi-column>
  <dxi-column dataField="actif" caption="Actif" [width]="100"></dxi-column>

  <!--  COLUMN CHOOSER-->
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

  <!--  EXCEL EXPORT-->
  <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>


  <!--  RECHERCHE FRONT-->
  <dxo-search-panel [visible]="true"></dxo-search-panel>

  <!--  FILTERING-->
  <dxo-header-filter
    [visible]="true"></dxo-header-filter>

  <!--  SORTING-->
  <dxo-sorting mode="multiple"></dxo-sorting>

  <!--  GROUPING-->
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

  <!-- PRIX ACHAT CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'groupeAchatCaCellTemplate'">
    <div *ngFor="let groupeAchatCa of cell.row.data?.groupeAchatCaList" class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center width-25">
        <ng-container [ngSwitch]="groupeAchatCa.prefere">

          <ng-container *ngSwitchCase="true">
            <i
              [class]="groupeAchatCa.modificationManuelle ? 'mg-r-5 fas fa-star cursor modification-manuelle' : 'mg-r-5 fas fa-star cursor'"
              pTooltip="Article préféré"></i>
          </ng-container>

          <ng-container *ngSwitchCase="false">
            <i *ngIf="cell.row.data.actif" class="mg-r-5 far fa-star cursor rotate-1-turn"
               pTooltip="Rendre l'article comme préféré"
               (click)="updatePrefere(groupeAchatCa,viewModelCatalogueAchatList,cell.row.data,true)"></i>
          </ng-container>

          <ng-container *ngSwitchCase="undefined">
            <!--                // DO NOTHING-->
          </ng-container>
        </ng-container>
        <div>{{groupeAchatCa.prixUF.value | currency:'EUR':'symbol':'1.4-4'}}
          / {{cell.row.data.uniteDeFacturation | slice:0:5}}
          <span
            [pTooltip]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.row.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
        </div>
      </div>
    </div>
  </div>

  <!--  UNITÉ DE FACTURATION CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'ufCellTemplate'">
    1 {{cell.row.data.uniteDeFacturation}} = {{cell.row.data.ratioUniteDeFacturation}} KG
  </div>


  <!-- UNITE DE COMMANDE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'ucCellTemplate'">
    1 {{cell.row.data.uniteDeCommande}} = {{cell.row.data.ratioUniteDeCommande}} KG
  </div>

  <!--  UNITE DE STOCKAGE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'usCellTemplate'">
    1 {{cell.row.data.uniteDeStockage}} = {{cell.row.data.ratioUniteStockageUniteBase}} KG
  </div>


  <!--  ARTICLE CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'articleCellTemplate'">
    <div class="no-overflow">
      <div *ngIf="!cell.row.data.actif" class="width-25">
        <i pTooltip="Attention, article inactif !"
           class="fas fa-exclamation-triangle danger-color cursor"></i>
      </div>
      <label class="font-12" pTooltip="{{cell.row.data.article}}">
        {{cell.row.data.article | uppercase}}
      </label>
    </div>
    <div>
      <yo-info-lotmarche-article *ngIf="!utils.isNullOrEmpty(uniteDeProductionSelected)"
                                 [uniteDeProductionId]="uniteDeProductionSelected?.id"
                                 [lotMarchePdLumpCa]="cell.row.data.lmPdUpCa"></yo-info-lotmarche-article>
    </div>
  </div>


  <!-- FOURNISSEUR -->
  <div class="d-flex justify-content-between" *dxTemplate="let cell of 'fournisseurCellTemplate'">
    <label class="font-12">
      {{cell.row.data.fournisseur | uppercase}}
    </label>
    <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
         [pTooltip]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
      <span class="fa-stack fa-2x">
        <i class="fas fa-circle fa-stack-2x"></i>
        <strong class="fa-stack-1x custom-letter">H</strong>
      </span>
    </div>
  </div>

  <!--  DECLINAISON CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'declinaisonCellTemplate'">

    <div class="d-flex">

      <div *ngIf="!cell.row.data.produitDeclinaisonActif" class="width-25">
        <i pTooltip="Attention, déclinaison inactive !"
           class="fas fa-exclamation-triangle danger-color cursor"></i>
      </div>

      <div class="no-overflow">
        <label class="font-12" pTooltip="{{cell.row.data.produitDeclinaisonLibelle}}">
          {{cell.row.data.produitDeclinaisonLibelle | uppercase}}
        </label><br/>
        <span class="font-10" pTooltip="Code de la denrée">
          {{cell.row.data.produitDeclinaisonCode | uppercase}}
        </span>
      </div>

    </div>

  </div>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <ng-container *ngIf="canModify(cell.row.data)">
      <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="canEdit(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                      [pTooltip]="canEdit(cell.row.data)?'Modifier l\'article':'Voir l\'article'"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>

      <yo-cell-button (yoNavigation)="openDeleteObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoDisabled]="isDisabledDeleteBtn(cell.row.data)"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-trash'"
                      [pTooltip]="isDisabledDeleteBtn(cell.row.data)? 'Cet article ne peut être supprimer car il est hérité du fournisseur '+ cell.row.data.fournisseurOwnerLibelle :'Supprimer l\'article'"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </ng-container>

    <ng-container *ngIf="!canModify(cell.row.data)">
      <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-eye'"
                      pTooltip="Voir l'article"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </ng-container>
  </div>

</dx-data-grid>


<!--DIALOG D'EDITION D'UN ARTICLE-->
<yo-dialog></yo-dialog>

