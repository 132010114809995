import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {MenuItem, SelectItem} from 'primeng/api';
import {ProduitDTO} from '../../core/dtos/produit-dto';
import {cloneDeep as _cloneDeep} from 'lodash'
import {Auth2Service} from '../../core/services/security/auth2.service';
import {catchError, switchMap} from 'rxjs/operators';
import {Page, SearchService} from '../../core/services/search.service';
import {SearchSupplierWrapper} from '../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../core/suppliers/search-supplier';
import {RoutemapService} from '../../core/services/routemap.service';
import {DialogMsgSupplier} from '../../core/suppliers/dialog-msg-supplier';
import {ProduitsService} from '../../core/services/entities/produits.service';
import {GenericFormService} from '../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ProduitDeclinaisonDTO} from '../../core/dtos/produit-declinaison-dto';
import {
  DialogProduitDeclinaisonSupplier,
  ProduitDeclinaisonService
} from '../../core/services/entities/produit-declinaison.service';
import {ResponseWrapper} from '../../core/suppliers/wrappers/response-wrapper';
import {FamillesProduitService} from '../../core/services/entities/familles-produit.service';
import {FamilleProduitDTO} from '../../core/dtos/famille-produit-dto';
import {TypeProduitDTO} from '../../core/dtos/type-produit-dto';
import {TypesProduitService} from '../../core/services/entities/types-produit.service';
import {SiteDTO} from '../../core/dtos/site-dto';
import {DeclinaisonsService} from '../../core/services/entities/declinaisons.service';
import {DeclinaisonDTO} from '../../core/dtos/declinaison-dto';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {ProduitContrainteAlimService} from '../../core/services/entities/produit-contrainte-alim.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../core/suppliers/generics/generic-request-supplier";
import {DevextremeService, FilterItem} from "../../core/services/technique/devextreme.service";
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from 'devextreme/ui/dialog';
import {RegimeAlimentaireDTO} from "../../core/dtos/regime-alimentaire-dto";
import {RegimeAlimentaireService} from "../../core/services/entities/regime-alimentaire.service";
import {debounceTime} from "rxjs/internal/operators";
import {ToastService} from "../../core/services/technique/toast.service";
import {TextMessageService} from "../../shared/ui/text-messages/textMessage.service";
import {GraphQLService} from "../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-grilleproduits',
  templateUrl: './grilleproduits.component.html',
  styleUrls: ['./grilleproduits.component.scss']
})
export class GrilleproduitsComponent implements OnInit, OnDestroy {

  // SUBSCRIPTIONS
  subProduitSubject: Subscription;
  subProduitSubjectToDelete: Subscription;
  subAuth2: Subscription;
  subDeleteProduit: Subscription;
  subGrilleProduits: Subscription;
  subSaveProduitDeclinaison: Subscription;
  subDeleteProduitDeclinaison: Subscription;

  /**
   * Souscription à la récupération des {@link FamilleProduitDTO}s.
   */
  subFamillesProduits: Subscription;

  /**
   * Souscription à la récupération des {@link TypeProduitDTO}s.
   */
  subTypesProduits: Subscription;

  /**
   * Souscription à la récupération des {@link DeclinaisonDTO}s.
   */
  subDeclinaisons: Subscription;

  subRegimes: Subscription;

  @ViewChild('grid') grid: DxDataGridComponent;

  /**
   * La p-overlayPanel pour la duplication de produit
   */
  @ViewChild('opDuplication') opDuplication: OverlayPanel;

  pathFile: string = HELP_FOLDERS.PRODUITS + '/produits';

  openProduitDeclinaison = (produit: ProduitDTO, produitDeclinaison: ProduitDeclinaisonDTO): void => {

    // Probleme dans les cycle de vie Angular RXJS a deja envoyer affecter une valeur a l observable avant une le composant et le temps de s'y abonner
    // d'ou le setTime et le then le dialog existe dans DeclinaisonsProduitComponent (routing necessaire)
    this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', produit.typeProduit.fabrique, produit.id, 'produit-declinaisons']).then(data => {
      setTimeout(() => {
        this.produitsDeclinaisonSvc.announceOpenDialogProduitDeclinaison(<DialogProduitDeclinaisonSupplier>{
          produit: produit,
          produitDeclinaison: produitDeclinaison
        });
      }, 800);
    });
  };

  help = (): DialogMsgSupplier => undefined;

  /**
   * Contient l'intégralité des {@link FamilleProduitDTO}s de premier niveau (cf. {@link FamillesProduitService#removeChildren()}.
   */
  allFamillesProduits: FamilleProduitDTO[];

  /**
   * Les {@link SelectItem}s représentant les {@link FamilleProduitDTO}s.
   */
  allFamillesProduitsItems: FamilleProduitDTO[];

  idsFamillesProduitsSelected: number[] = [];

  allRegimesItems: RegimeAlimentaireDTO[] = [];

  idsRegimesSelected: number[] = [];

  /**
   * Contient l'intégralité des types de produits ({@link TypeProduitDTO}.
   */
  allTypesProduits: TypeProduitDTO[];

  /**
   * Les {@link SelectItem}s représentant les {@link TypeProduitDTO}s.
   */
  allTypesProduitsItems: TypeProduitDTO[];

  idsTypeProduitSelected: number[] = [];

  globalFilterWrote: string;

  /**
   * Contient la liste des sites visibles par l'utilisateur connecté.
   */
  sitesUtilisateurConnecte: SiteDTO[];

  /**
   * Les {@link SelectItem}s représentant les sites visibles par l'utilisateur connecté.
   */
  sitesUtilisateurConnecteItems: SiteDTO[];

  idsSiteUtilisateurConnecteSelected: number[] = [];

  /**
   * Les {@link SelectItem}s représentant les sites visibles par l'utilisateur connecté.
   */

  allDeclinaisonsItems: DeclinaisonDTO[];

  idsDeclinaisonsSelected: number[] = [];

  /**
   * site selectionné pour la duplication d'un produit
   */
  duplicationSiteSelected: SiteDTO;

  produitToDuplicate: ProduitDTO;

  loading: boolean;
  start: boolean = true;
  hasgestionproduitsiapro: boolean;
  hasgestionproduitsiprod: boolean;

  produitsForGrille: ProduitDTO[] = [];

  totalRecords: number;

  itemsProduitMenu: MenuItem[] = [];

  dataSource: CustomStore;

  subGlobalSearch: Subscription;
  subjectGlobalSearch: Subject<string> = new Subject<string>();
  globalSearch$ = this.subjectGlobalSearch.asObservable();

  constructor(private produitsService: ProduitsService,
              private produitsDeclinaisonSvc: ProduitDeclinaisonService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private regimesSvc: RegimeAlimentaireService,
              private cd: ChangeDetectorRef,
              private familleProduitSvc: FamillesProduitService,
              private typesProduitSvc: TypesProduitService,
              public produitsSvc: ProduitsService,
              private routeMapSvc: RoutemapService,
              private searchService: SearchService,
              public auth2Svc: Auth2Service,
              public utils: UtilsService,
              public produitContrainteAlimSvc: ProduitContrainteAlimService,
              private declinaisonSvc: DeclinaisonsService,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService,
              private textMessageService: TextMessageService,
              private graphQlSvc: GraphQLService) {
  }


  ngOnInit() {
    this.subscriptionGlobalFilterSearch();
    this.initItemsProduitMenu();
    this.initDroits();
    // un produit a été supprimé, on doit rafraichir la grille
    this.produitDeletedSubscription();
    // un produit  a été enregistré ou modifié, on doit rafraichir la grille
    this.produitSaveSubscription();
    // un produit declinaison a été enregistré ou modifié, on doit rafraichir la grille
    this.produitDeclinaisonSaveSubscription();
    // un produit declinaison a été supprimé, on doit rafraichir la grille
    this.produitDeclinaisonDeleteSubscription();
    // mode iappro
    this.gestionProduitIApproSubscription();
    // pre renseigner le filtre des familles produits
    this.loadFilterFamillesProduitsSubscription();
    // pre renseigner le filtre des déclinaisons
    this.loadFilterDeclinaisonsSubscription();
    // pre renseigner le filtre des sites
    this.loadFilterSitesSubscription();
    this.loadRegimesSubscription();
    this.initCustomStore();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subGrilleProduits);
    this.utils.unsubscribe(this.subProduitSubject);
    this.utils.unsubscribe(this.subProduitSubjectToDelete);
    this.utils.unsubscribe(this.subAuth2);
    this.utils.unsubscribe(this.subDeleteProduit);
    this.utils.unsubscribe(this.subSaveProduitDeclinaison);
    this.utils.unsubscribe(this.subDeleteProduitDeclinaison);
    this.utils.unsubscribe(this.subFamillesProduits);
    this.utils.unsubscribe(this.subTypesProduits);
    this.utils.unsubscribe(this.subDeclinaisons);
    this.utils.unsubscribe(this.subRegimes);
    this.utils.unsubscribe(this.subGlobalSearch);
  }

  initDroits = (): void => {
    const droitsGestionProduits$ = combineLatest([this.auth2Svc.hasGestionProduitsIprod$, this.auth2Svc.hasGestionProduitsIapro$]);
    droitsGestionProduits$.subscribe(res => {
      this.hasgestionproduitsiprod = res[0];
      this.hasgestionproduitsiapro = res[1];
    });

  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        const pagination: string = this.searchService.createPageUrlParam(new Page(page, pageSize));
        const sort: string = (sorts && sorts.length && sorts[0] && sorts[0].dir && sorts[0].path) ? `sort=${sorts[0].path},${sorts[0].dir}` : '';
        const urlParams: string = `${pagination}&${sort}`;

        const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
        ssw.filtersMap['sites'] = new SearchSupplier(null, this.auth2Svc.utilisateur.sites.map(item => item.id), null, null);
        ssw.filtersMap['fullTextProduit'] = new SearchSupplier(this.globalFilterWrote || '', undefined, null, null);

        if (this.idsSiteUtilisateurConnecteSelected && this.idsSiteUtilisateurConnecteSelected.length)
          ssw.filtersMap['sites'] = new SearchSupplier(null, this.idsSiteUtilisateurConnecteSelected, null, null);

        const filterCode = filters.find(filter => filter.column === 'code');
        if (filterCode)
          ssw.filtersMap['code'] = new SearchSupplier(filterCode.value, undefined, null, null);

        const filterProduit = filters.find(filter => filter.column === 'libelle');
        if (filterProduit)
          ssw.filtersMap['produit'] = new SearchSupplier(filterProduit.value, undefined, null, null);

        const filterActif = filters.find(filter => filter.column === 'actif');
        if (filterActif)
          ssw.filtersMap['actif'] = new SearchSupplier(filterActif.value, undefined, null, null);

        if (this.idsTypeProduitSelected && this.idsTypeProduitSelected.length)
          ssw.filtersMap['idsTypeProduit'] = new SearchSupplier(null, this.idsTypeProduitSelected, null, null);

        if (this.idsDeclinaisonsSelected && this.idsDeclinaisonsSelected.length)
          ssw.filtersMap['idsDeclinaison'] = new SearchSupplier(null, this.idsDeclinaisonsSelected, null, null);

        if (this.idsFamillesProduitsSelected && this.idsFamillesProduitsSelected.length)
          ssw.filtersMap['idsFamilleProduit'] = new SearchSupplier(null, this.idsFamillesProduitsSelected, null, null);

        if (this.idsRegimesSelected && this.idsRegimesSelected.length)
          ssw.filtersMap['idsRegimes'] = new SearchSupplier(null, this.idsRegimesSelected, null, null);

        this.grid.instance.columnOption('produitContrainteAlimList', 'visible', false);

        return this.produitsService.search(urlParams, ssw, !this.hasgestionproduitsiprod && this.hasgestionproduitsiapro)
          .toPromise().then(result => {
            this.produitsForGrille = result.resultList;
            return {
              data: result.resultList,
              totalCount: result.totalElements
            }
          });
      },
      update: (key, values) => {
        return null;
      }
    });
  }

  announceGlobalFilterSearch = (e: any): void => {
    this.subjectGlobalSearch.next(e.value);
  }

  subscriptionGlobalFilterSearch = (): void => {
    this.subGlobalSearch = this.globalSearch$
      .pipe(debounceTime(500))
      .subscribe(searchValue => {
        this.globalFilterWrote = searchValue;
        this.grid.instance.refresh();
      });
  }

  onChangeSitesFilter = (event): void => {
    this.idsSiteUtilisateurConnecteSelected = event.value;
    this.grid.instance.refresh();
  }

  onChangeTypesProduitFilter = (event): void => {
    this.idsTypeProduitSelected = event.value;
    this.grid.instance.refresh();
  }

  onChangeFamillesProduitFilter = (event): void => {
    this.idsFamillesProduitsSelected = event.value;
    this.grid.instance.refresh();
  }

  onChangeDeclinaisonsFilter = (event): void => {
    this.idsDeclinaisonsSelected = event.value;
    this.grid.instance.refresh();
  }

  onChangeRegimesFilter = (event): void => {
    this.idsRegimesSelected = event.value;
    this.grid.instance.refresh();
  }

  /**
   * un produit  a été enregistré ou modifié, on doit rafraichir la grille
   */
  produitSaveSubscription = (): void => {
    this.subProduitSubject = this.produitsSvc.savedDto$.subscribe(() => {
      setTimeout(() => this.grid.instance.refresh(), 5000);
    });
  };

  /**
   * un produit declinaison a été supprimé, on doit rafraichir la grille
   */
  produitDeclinaisonDeleteSubscription = (): void => {
    this.subDeleteProduitDeclinaison = this.gfs.deletedId$.subscribe(() => this.grid.instance.refresh());
  };

  /**
   * abonnement à l'ajout/modification d'une declinaison dans la grille des produits
   */
  produitDeclinaisonSaveSubscription = (): void => {
    this.subSaveProduitDeclinaison = this.produitsDeclinaisonSvc.savedDto$.subscribe(() => this.grid.instance.refresh());
  };

  /**
   * Pré renseigner le filtre des déclinaisons
   */
  loadFilterDeclinaisonsSubscription = (): void => {
    this.subDeclinaisons = this.gds.getAll(this.declinaisonSvc.getEntityName(), this.declinaisonSvc.getSort(), true).subscribe(response => {
      this.allDeclinaisonsItems = response.resultList
        .map(declinaison => ({libelle: declinaison.libelle, id: declinaison.id}));
    });
  };

  loadFilterSitesSubscription = (): void => {
    this.sitesUtilisateurConnecteItems = this.auth2Svc.utilisateur.sites.map(site => ({
      libelle: site.libelle,
      id: site.id
    }));
  };

  /**
   * Pré renseigner le filtre des familles produits
   */
  loadFilterFamillesProduitsSubscription = (): void => {
    this.subFamillesProduits = this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true).subscribe(response => {
      this.allFamillesProduits = response.resultList;
      this.allFamillesProduits = this.familleProduitSvc.removeChildren(this.allFamillesProduits);
      this.allFamillesProduitsItems = this.allFamillesProduits.map(familleProduit => ({
        libelle: familleProduit.libelle,
        id: familleProduit.id
      } as FamilleProduitDTO));
    });
  };

  loadRegimesSubscription = (): void => {
    this.subRegimes = this.getRegimesAlimentaires()
      .subscribe(response => this.allRegimesItems = response.allRegimesAlimentaires);
  }

  private getRegimesAlimentaires = (): Observable<any> => {
    return this.graphQlSvc.sendQuery(`
    {
      allRegimesAlimentaires {
        id,
        libelle,
      }
    }
    `);
  }

  /**
   * Un produit a été supprimé, on doit rafraichir la grille
   */
  produitDeletedSubscription = (): void => {
    this.subProduitSubjectToDelete = this.produitsService.produitSubjectToDelete$.subscribe(() => this.grid.instance.refresh());
  };

  /**
   * En mode iappro, one ne doit voir que les denrees
   */
  gestionProduitIApproSubscription = (): void => {
    this.subAuth2 = this.auth2Svc.hasGestionProduitsIapro$.pipe(
      switchMap(data => {
        this.hasgestionproduitsiapro = data;
        return this.auth2Svc.hasGestionProduitsIprod$;
      }),
      switchMap(data => {
        this.hasgestionproduitsiprod = data;
        return this.gds.getAll(this.typesProduitSvc.getEntityName(), this.typesProduitSvc.getSort(), true);
      }),
      switchMap(response => {
        if (!this.hasgestionproduitsiprod && this.hasgestionproduitsiapro)
          this.allTypesProduits = this.typesProduitSvc.getTypesProduits(response.resultList, false);
        else
          this.allTypesProduits = response.resultList;
        this.allTypesProduitsItems = this.allTypesProduits.map(typeProduit => ({
          libelle: typeProduit.libelle,
          id: typeProduit.id
        } as TypeProduitDTO));
        return of(null);
      }),
      catchError(err => this.utils.handleError(err))
    ).subscribe();
  };

  initItemsProduitMenu = (): void => {
    this.itemsProduitMenu = [
      {
        disabled: this.hasgestionproduitsiprod || this.hasgestionproduitsiapro,
        label: 'Créer une denrée',
        icon: 'fas fa-apple-alt',
        command: (event) => this.openNewProduit(false)
      },
      {
        disabled: this.hasgestionproduitsiprod,
        label: 'Créer un plat',
        icon: 'fas fa-utensils',
        command: (event) => this.openNewProduit(true)
      }
    ];
  };

  openFicheIdentite = (produit: ProduitDTO): void => {
    this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', produit.typeProduit.fabrique, produit.id, 'ficheidentite']);
  };

  confirmDelete = async (produit: ProduitDTO): Promise<void> => {
    const message = this.textMessageService.getProduitDeletionMessage(produit)
    const result = confirm(message, 'Suppression du produit');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.produitsService.deleteProduit(produit)
        .subscribe(() => {
          this.grid.instance.refresh();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le produit ${produit?.libelle} a été supprimé avec succès`);
        });
    }
  };

  openNewProduit = (isFabrique: boolean): void => {
    this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', isFabrique, 0, 'ficheidentite']);
  };

  canCreate = (): boolean => this.auth2Svc.hasSitesLocaux();

  tooltipCreationDeclinaison = (): string => {
    if (!this.canCreate())
      return '';
    return 'Ajouter une déclinaison';
  };


  /**
   * ouverture p-overlayPanel pour la duplication de produit
   * @param $event
   * @param rowData
   */
  openPanelDuplication = ($event, rowData: ProduitDTO): void => {
    this.duplicationSiteSelected = this.auth2Svc.utilisateur.siteListLocaux.length === 1 ? this.auth2Svc.utilisateur.siteListLocaux[0] : undefined;
    this.produitToDuplicate = _cloneDeep(rowData);
    this.opDuplication.toggle($event);
  };

  /**
   * Appel le service pour dupliquer le produits en base
   * @param produitToDuplicate
   * @param duplicationSite
   */
  duplicateProduit = (): void => {
    this.produitsService.duplicateProduitPlat(this.produitToDuplicate, this.duplicationSiteSelected).subscribe((response: ResponseWrapper<ProduitDTO>) => {
      const msg = `Produit dupliqué avec succès.`;
      this.grid.instance.refresh();
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, msg);
      this.produitsService.announceSavedDTO(response);
      this.opDuplication.hide();
      this.openFicheIdentite(response.one);
    });
  };
}
