<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-cubes mg-r-5 mg-t-10 mg-l-15"></i> MOUVEMENTS
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <button pButton icon="fas fa-print"
              class="mg-r-15"
              (click)="exportMouvementsDetails()"
              showDelay="1000"
              title="Extraction des mouvements">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="mouvements"
  keyExpr="id"
  [height]="400"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="false"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  #grid
>
  <dxi-column dataField="id" alignment="center" caption="Actions" [width]="80"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate"></dxi-column>
  <dxi-column dataField="entreeSortie" alignment="left" caption="E/S"
              [allowGrouping]="false" [allowFiltering]="false" cellTemplate="esTemplate"></dxi-column>
  <dxi-column dataField="stockMouvementStatutCode" alignment="left" caption="Motif"
              [allowGrouping]="false" [allowFiltering]="false" cellTemplate="stTemplate"></dxi-column>
  <dxi-column dataField="dateMouvement" alignment="left" caption="Date du mouvement" dataType="date"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="zdsLibelle" alignment="left" caption="Emplacement" cellTemplate="emplacementTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="lotArticleFournisseur" alignment="left" caption="Lot Fournisseur"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="denominationArticle" alignment="left" caption="Dénomination" cellTemplate="denominationArticleTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" alignment="left" caption="Fournisseur filiale"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="quantite" alignment="right" caption="Quantité" cellTemplate="quantiteTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="prix" alignment="right" caption="Prix unitaire" cellTemplate="prixTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="tva" alignment="right" caption="TVA" cellTemplate="tvaTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="prixTotalHT" alignment="right" caption="HT" cellTemplate="prixTotalHTTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="prixTotalTTC" alignment="right" caption="TTC" cellTemplate="prixTotalTTCTemplate"
              [allowGrouping]="false" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <yo-cell-button (click)="openModification(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-edit'"
                      title="modifier le mouvement"
                      [yoDisabled]="!auth2Svc.isSiteLocal(cell.row.data.site?.id)"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </div>
    <div *dxTemplate="let cell of 'esTemplate'">
      <ng-container *ngIf="cell.row.data.entreeSortie">
        <i class="fas fa-arrow-right bold mg-l-5 mg-r-5 new-color" title="Entrée de stock"
           showDelay="500"></i>
      </ng-container>
      <ng-container *ngIf="!cell.row.data.entreeSortie">
        <i class="fas fa-arrow-left bold  mg-l-5 mg-r-5 danger-color" title="Sortie de stock"
           showDelay="500"></i>
      </ng-container>
    </div>
    <div *dxTemplate="let cell of 'stTemplate'">
      <span [title]="cell.row.data.stockMouvementStatutLibelle" showDelay="500">{{cell.row.data.stockMouvementStatutLibelle}}</span>
    </div>
    <div *dxTemplate="let cell of 'emplacementTemplate'">
      <div>
        <i class="fas fa-industry mg-r-5"></i>{{cell.row.data.udpLibelle}}
      </div>
      <div class="mg-l-10 mg-t-5">
        <i class="fas fa-cubes mg-r-5"></i>{{cell.row.data.zdsLibelle}}
      </div>
    </div>
    <div *dxTemplate="let cell of 'denominationArticleTemplate'">
      {{cell.row.data.denominationArticle}}
      <span class="mg-r-5" *ngIf="cell.row.data.ratioUS === 0"><i
        class="fas fa-exclamation-triangle danger-color space"
        title="Le ratio d'unité de stockage de cet article est égal à 0"></i>
                </span>
    </div>
    <div *dxTemplate="let cell of 'quantiteTemplate'">
      <span title="Quantité en unité de facturation : {{cell.row.data.quantiteEnUF}} {{cell.row.data.uniteDeFacturation}} "
            showDelay="500">{{cell.row.data.quantite | number:'1.4-4'}}   {{cell.row.data.uniteDeStockage}}</span>
    </div>
    <div *dxTemplate="let cell of 'prixTemplate'">
      {{cell.row.data.prixUnitaire  | currency:'EUR':'symbol':'1.4-4' }} / {{cell.row.data.uniteDeFacturation}}
    </div>
    <div *dxTemplate="let cell of 'tvaTemplate'">
      {{cell.row.data.tva  | number:'1.2-2' }}%
    </div>
    <div *dxTemplate="let cell of 'prixTotalHTTemplate'">
      {{cell.row.data.prixTotalHT  | currency:'EUR':'symbol':'1.4-4' }}
    </div>
    <div *dxTemplate="let cell of 'prixTotalTTCTemplate'">
      {{cell.row.data.prixTotalTTC | currency:'EUR':'symbol':'1.4-4' }}
    </div>
</dx-data-grid>

<div class="row mg-t-20" [style.width.px]="utils.getScreenAvailableWidth(DELTA_WIDTH_MOUVEMENTS)">
  <div class="col-md-2">
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Entrées</span>
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5 new-color">  {{balanceEntreesHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Sorties</span>
      </div>
      <div class="col-md-2">
        <strong
          class="mg-r-5 danger-color">  {{balanceSortiesHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
      <div class="col-md-2 text-right">
        <span class="bold genlabel">Balance</span>
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5">  {{balanceHT | currency:'EUR':'symbol':'1.4-4' }}</strong>HT
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5 new-color">  {{balanceEntreesTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong
          class="mg-r-5 danger-color">  {{balanceSortiesTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <strong class="mg-r-5">  {{balanceTTC | currency:'EUR':'symbol':'1.4-4' }}</strong>TTC
      </div>
    </div>
  </div>
</div>

<yo-dialog-modification-mouvement></yo-dialog-modification-mouvement>
