<div class="d-flex flex-column " [style.max-width.px]="1000">

  <div [style.max-height.px]="utils.getWindowAvailableHeight(200)" style="overflow-y:auto;overflow-x:hidden">
    <yo-form [groups]="groups" [form]="form" [options]="{'width':'980px'}">
    </yo-form>
  </div>

  <div>
    <hr>
    <div class="d-flex">
      <div class="mr-auto">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        ️<em class="mg-r-5"> N'oubliez pas d'enregistrer votre travail.</em>
      </div>
      <div class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="saveFicheIdentite()" class="p-button-success"
                [disabled]="!canModify()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500"></button>
      </div>
      <div>
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="utils.sidenav=false"
                class="cta-inner-delete"></button>
      </div>
    </div>
  </div>

</div>






