import {Component, OnDestroy, OnInit} from "@angular/core";
import {WizardConditionnementService} from "../../core/services/conditionnements/conditionnement-wizard.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {ModelesPlatsService} from "../../core/services/conditionnements/modeles-plats.service";
import ModeleConditionnementPlatDTO from "../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {SiteDTO} from "../../core/dtos/site-dto";
import {UnitedemesureDTO} from "../../core/dtos/unitedemesure-dto";
import {BindPlatsMcpModel} from "../../core/models/gestion-conditionnements/bind-plats-mcp-model";
import {
  ImplementationModelesConditionnementsPlatsService
} from "../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";

@Component({
  selector: 'yo-wizard-configuration',
  templateUrl: './wizard-configuration.component.html',
  styleUrls: ['./wizard-configuration.component.scss']
})
export class WizardConfigurationComponent implements OnInit, OnDestroy {

  display: boolean = false;

  fullScreen: boolean = false;

  title: string = "Assistant de configuration d'un conditionnement";

  currentStep: number = 0;

  subOpenDialog: Subscription;

  STEP_INITIALIZATION: number = 0;

  STEP_TARGET_MODEL_DISH: number = 1;

  STEP_TYPO_CONDITIONNEMENT: number = 2;

  STEP_CONFIG_REFERENTIEL: number = 3;

  STEP_CONFIG_REFERENTIEL_COMBINAISONS: number = 4;

  STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT: number = 5;

  STEP_CHOICE_MEALS: number = 6;

  STEP_CHECK_RECIPES: number = 7;

  constructor(private wizardSvc: WizardConditionnementService,
              private utilsSvc: UtilsService,
              private toastSvc: ToastService,
              private mpcSvc: ModelesPlatsService) {
  }


  ngOnInit(): void {
    this.currentStep = 0;
    this.wizardSvc.clear(); // Dans le cas où l'utilisateur a réalisé une actualisation de la page accidentellement
    this.initOpenDialogSubscription();
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.wizardSvc.openDialogWizard$
      .subscribe(() => this.display = true);
  }

  toggleFullScreen = (): void => {
    this.fullScreen = !this.fullScreen;
  }

  onInitPopup = ($event): void => {
    $event.component.registerKeyHandler("escape", function (arg) {
      arg.stopPropagation();
    });
  }

  start = (): void =>  { this.currentStep = this.STEP_TARGET_MODEL_DISH; }

  clear = (): void => { this.currentStep = this.STEP_INITIALIZATION; this.wizardSvc.clear(); }

  handlePreviousStep = (data: any) => {
    if (data.currentStep === this.STEP_TARGET_MODEL_DISH) {
      this.currentStep = this.STEP_INITIALIZATION;
    } else if (data.currentStep === this.STEP_TYPO_CONDITIONNEMENT) {
      this.currentStep = this.STEP_TARGET_MODEL_DISH;
    } else if (data.currentStep === this.STEP_CONFIG_REFERENTIEL) {
      this.currentStep = this.STEP_TYPO_CONDITIONNEMENT;
    } else if (data.currentStep === this.STEP_CONFIG_REFERENTIEL_COMBINAISONS) {
      this.currentStep = this.STEP_CONFIG_REFERENTIEL;
    } else if (data.currentStep === this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT) {
      this.currentStep = this.STEP_CONFIG_REFERENTIEL_COMBINAISONS;
    } else if (data.currentStep === this.STEP_CHOICE_MEALS) {
      this.currentStep = this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT;
    } else if (data.currentStep === this.STEP_CHECK_RECIPES) {
      this.currentStep = this.STEP_CHOICE_MEALS;
    }
  }

  handleNextStep = (data: any) => {
    if (data.currentStep === this.STEP_TARGET_MODEL_DISH) {
      try {
        this.wizardSvc.saveStepOne(data);
        this.currentStep = this.STEP_TYPO_CONDITIONNEMENT;
      } catch (errors) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, errors.join(","));
      }
    } else if (data.currentStep === this.STEP_TYPO_CONDITIONNEMENT) {
      try {
        const dataStepOne: any = this.wizardSvc.getDataStepOne();
        const modelePlat: ModeleConditionnementPlatDTO = {
          id: data.id,
          site: { id: dataStepOne.idSelectedSite } as SiteDTO,
          libelle: data.labelModelPlat,
          code: undefined,
          mode: data.modeModelePlatSelected,
          uniteAConditionner: { id: data.idUniteMesureSelected } as UnitedemesureDTO
        } as ModeleConditionnementPlatDTO;
        this.mpcSvc.save(modelePlat).subscribe(response => {
          const mpc: ModeleConditionnementPlatDTO = response.one;
          data.id = mpc.id;
          this.wizardSvc.saveStepTwo(data, dataStepOne.unitesDeProductionSelected)
            .subscribe((response) => {
              this.currentStep = this.STEP_CONFIG_REFERENTIEL;
            });
        });
      } catch (errors) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, errors.join(","));
      }
    } else if (data.currentStep === this.STEP_CONFIG_REFERENTIEL) {
      if (!data.conditionnementsLength || !data.variantesLength)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez renseigner au minimum un conditionnement et une variante`);
      else
        this.currentStep = this.STEP_CONFIG_REFERENTIEL_COMBINAISONS;
    } else if (data.currentStep === this.STEP_CONFIG_REFERENTIEL_COMBINAISONS) {
      if (!data.conditionnementsVariantes || !data.conditionnementsVariantes.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez combiner au minimum un conditionnement avec une variante`);
      else
        this.currentStep = this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT;
    } else if (data.currentStep === this.STEP_CONFIG_BINDINGS_GRID_MODELE_PLAT) {
      if (!data.modelePlatParametrage || !data.modelePlatParametrage.grid.length || !data.modelePlatParametrage.queues.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez associer vos conditionnements/variantes avec des déclinaisons puis configurer la grille avant de poursuivre`);
      else {
        this.wizardSvc.saveDataStepFive(data.declinaisonsSelected);
        this.mpcSvc.saveParametrage(data.modelePlatParametrage)
          .subscribe(() => {
            this.currentStep = this.STEP_CHOICE_MEALS;
          });
      }
    } else if (data.currentStep = this.STEP_CHOICE_MEALS) {
      if (!data.idsProducts.length) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner au minimum un plat à conditionner`);
      } else {
        this.currentStep = this.STEP_CHECK_RECIPES;
      }
    }
    else {
      alert("Not implemented yet");
    }
  }

}
