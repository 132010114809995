import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UtilsService} from "../../core/utils/utils.service";
import {Subscription} from "rxjs";
import CustomStore from "devextreme/data/custom_store";
import {DATAGRID_ROW_TYPES, DevextremeService} from "../../core/services/technique/devextreme.service";
import {DxDataGridComponent} from "devextreme-angular";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../core/constants";
import {FacturationService} from "../../core/services/entities/facturation.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {confirm} from "devextreme/ui/dialog";

@Component({
  templateUrl: './factures-fournisseurs.component.html',
  styleUrls: ['./factures-fournisseurs.component.scss']
})
export class FacturesFournisseursComponent implements OnInit, OnDestroy {

  datasource: any;

  bills: any[] = [];

  subDatasource: Subscription;

  subFiltersSearch: Subscription;

  subRefresh: Subscription;

  @ViewChild("grid") grid: DxDataGridComponent;

  @ViewChild("gridDetails") gridDetails: DxDataGridComponent;

  allMode: string;
  checkBoxesMode: string;

  sitesSelected: number[] = [];

  status: any[] = [{id: 'EN_ATTENTE_VALIDATION', value: 'En attente de validation'}, {id: 'SOLDEE', value: 'Soldée'}];
  statusSelected: string[] = [this.status[0].id];

  providersSelected: number[] = [];

  numbersBillSelected: string[] = [];

  filtersSearch: any;
  isActionDisabled: boolean = true;

  displayComments: boolean = false;

  displayLitigesMotifs: boolean = false;

  currentInvoice: any;

  pathFile: string = HELP_FOLDERS.FACTURATION + '/factures-fournisseurs';

  constructor(public utilsSvc: UtilsService,
              private facturationSvc: FacturationService,
              private dxSvc: DevextremeService,
              public auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subDatasource);
    this.utilsSvc.unsubscribe(this.subFiltersSearch);
    this.utilsSvc.unsubscribe(this.subRefresh);
  }

  ngOnInit(): void {
    this.initFilters();
    this.initDataSource();
    this.initRefresh();
  }

  refreshDataSource = (): void => {
    this.grid.instance.refresh();
  }

  refreshDataSourceDetails = (): void => {
    this.gridDetails?.instance?.refresh();
  }

  initFilters = (): void => {
    this.subFiltersSearch = this.facturationSvc.fetchFiltersSearch()
      .subscribe(response => this.filtersSearch = response.one);
  }

  initRefresh = (): void => {
    this.subRefresh = this.facturationSvc.refreshInvoices$
      .subscribe(response => this.refreshDataSource());
  }

  initDataSource = (): void => {
    this.datasource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        let size: number = loadOptions.take || this.grid.instance.pageSize();
        let page: number = this.grid.instance.pageIndex() + 1;

        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          page = null;
          size = null;
        }

        return this.facturationSvc.fetchInvoicesByFilters(this.sitesSelected, this.numbersBillSelected, this.providersSelected, this.statusSelected, page, size).toPromise().then(response => {
          this.bills = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(null, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  onEditorPreparing = ($event: any): void => {
    const currentBill: any = $event.row?.data;
      if ($event.dataField === "dateBill" && $event.parentType === "dataRow" && $event.editorName !== 'dxCheckBox') {
        const self = this;
        $event.editorOptions.onValueChanged = function (args: any) {
          if (self.auth2Svc.isSiteLocal(currentBill.site.id)) {
            currentBill.dateBill = new Date(args.value).getTime();
            self.facturationSvc.update(currentBill)
              .subscribe((response) => self.refreshDataSource());
          }
          else
            self.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Vous n'avez pas le droit de sélectionner une date d'émission`);
        }
    }
  };

  updateRealShippingFees = ($event, currentBill: any): void => {
    if (this.auth2Svc.isSiteLocal(currentBill.site.id)) {
      currentBill.realShippingFees.value = $event.value;
      currentBill.shippingFeesModifiedManually = true;
      this.facturationSvc.update(currentBill)
        .subscribe((response) => this.refreshDataSource());
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Vous n'avez pas le droit de modifier les frais de port`);
    }
  }

  onCellPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      if (event.column.allowEditing === true) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }

    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.column.dataField === 'realShippingFees' && event.data?.shippingFeesModifiedManually) event.cellElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  printInvoices = async(): Promise<void> => {
    const rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    const invoicesSelected: any[] = this.bills.filter(b => rowsSelected.find(id => id === b.id));
    const invoicesToPrint = invoicesSelected.filter(invoice => this.auth2Svc.isSiteLocal(invoice.site?.id));
    const invoicesCannotBePrinted = invoicesSelected.filter(invoice => !this.auth2Svc.isSiteLocal(invoice.site?.id));
    if (invoicesCannotBePrinted && invoicesCannotBePrinted.length)
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le(s) facture(s) ${invoicesCannotBePrinted.map(item => item.number).join(', ')} ne ser(ont) pas imprimé(s) car elle(s) appartienne(nt) à un site qui n'appartient pas à votre environnement.`);
    if (invoicesToPrint && invoicesToPrint.length)
      this.facturationSvc.printInvoices(invoicesToPrint.map(it => it.id));
  }

  validBills = async(): Promise<void> => {
    const rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    const question: string = rowsSelected.length > 1 ? 'Voulez-vous vraiment valider les factures sélectionnées ?' : 'Voulez-vous vraiment valider la facture sélectionnée ?';
    const response = await confirm(question, 'Validation de facture(s)');
    if (response) {
      const invoicesSelected: any[] = this.bills.filter(b => rowsSelected.find(id => id === b.id));
      const invoicesToValid = invoicesSelected.filter(invoice => this.auth2Svc.isSiteLocal(invoice.site?.id));
      const invoicesCannotBeValid = invoicesSelected.filter(invoice => !this.auth2Svc.isSiteLocal(invoice.site?.id));
      if (invoicesCannotBeValid && invoicesCannotBeValid.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le(s) facture(s) ${invoicesCannotBeValid.map(item => item.number).join(', ')} ne ser(ont) pas validé(s) car elle(s) appartienne(nt) à un site qui n'appartient pas à votre environnement.`);
      if (invoicesToValid && invoicesToValid.length)
        this.facturationSvc.validInvoices(invoicesToValid.map(it => it.id)).subscribe((response) => {
          this.refreshDataSource();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'La validation a été effectuée avec succès');
        });
    }
  }

  deleteValues = async (): Promise<void> => {
    const rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    const question: string = rowsSelected.length > 1 ? 'Voulez-vous vraiment supprimer les factures sélectionnées ?' : 'Voulez-vous vraiment supprimer la facture sélectionnée ?';
    const response = await confirm(question, 'Validation de facture(s)');
    if (response) {
      const invoicesSelected: any[] = this.bills.filter(b => rowsSelected.find(id => id === b.id));
      const invoicesToDelete = invoicesSelected.filter(invoice => invoice.status !== 'SOLDEE' && this.auth2Svc.isSiteLocal(invoice.site?.id));
      const invoicesCannotBeDelete = invoicesSelected.filter(invoice => invoice.status === 'SOLDEE' || !this.auth2Svc.isSiteLocal(invoice.site?.id));
      if (invoicesCannotBeDelete && invoicesCannotBeDelete.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Le(s) facture(s) ${invoicesCannotBeDelete.map(item => item.number).join(', ')} ne ser(ont) pas supprimé(es) car elle(s) sont soldée(s) ou elle(s) appartienne(nt) à un site qui n'appartient pas à votre environnement.`);
      if (invoicesToDelete && invoicesToDelete.length) {
        this.facturationSvc.deleteInvoices(invoicesToDelete.map(it => it.id))
          .subscribe((response) => {
            this.refreshDataSource();
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'La suppression a été effectuée avec succès');
          });
      }
    }
  }

  containsLitigesOrMotifsNonConformes = (invoice: any): boolean => invoice.litiges?.length || invoice.motifsNonConformes?.length

  containsComments = (invoice: any): boolean => invoice.comments?.length

  displayCommentsDialog = (currentInvoice: any): void => {
    this.displayComments = true;
    this.currentInvoice = currentInvoice;
  }

  closeCommentsDialog = (): void => {
    this.displayComments = false;
    this.currentInvoice = undefined;
  }

  displayLitigesMotifsDialog = (currentInvoice: any): void => {
    this.displayLitigesMotifs = true;
    this.currentInvoice = currentInvoice;
  }

  closeLitigesMotifsDialog = (): void => {
    this.displayLitigesMotifs = false;
    this.currentInvoice = undefined;
  }

  displayInvoiceDialog = (currentInvoice: any): void => {
    this.facturationSvc.announceOpenDialogDetailsInvoice(currentInvoice);
  }

  joinBonReception = (invoice: any): void => this.facturationSvc.announceOpenDialogJoinBonReception(invoice);

}
