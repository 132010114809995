<p-dialog
  [dismissableMask]="false"
  [showHeader]="true"
  (onHide)="closeDialog()"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayDialog"
  [responsive]="true"
  [modal]="true"
  [style]="{'width': '22vw'}">
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-clipboard mg-r-5"></i> Remarque permanente pour les commandes de la
      filiale {{selectedUdpSecteurFournisseur.fournisseurLibelle}} ({{selectedUdpSecteurFournisseur.secteurLibelle}}).
    </div>
  </ng-template>

  <textarea id="textRemarque" maxlength="250"
            [(ngModel)]="selectedUdpSecteurFournisseur.remarqueCommande" pInputTextarea></textarea>


  <div class="d-flex justify-content-end mg-t-10">
    <div>
        <span class="mg-r-5">
          <button pButton type="button" class="p-button-success" (click)="saveRemarque()" icon="fas fa-save"></button>
        </span>
      <button type="button" pButton icon="fas fa-times"
              class="cta-delete"
              (click)="closeDialog()">
      </button>

    </div>
  </div>
</p-dialog>
