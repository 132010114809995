<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <!--<div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="openTache(undefined)"
          [disabled]="!canCreateAndDelete()"
          pTooltip="Créer une tâche">
        </button>
      </div>-->
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <button
        pButton
        type="button"
        icon="fas fa-trash"
        class="cta-delete"
        (click)="deleteValues()"
        [disabled]="!canCreateAndDelete() || !selectedRows.length"
        pTooltip="Supprimer les éléments sélectionnés">
      </button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="tachesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="templateSite">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="icone" caption="Icône" [width]="100"
              [filterOperations]="['contains']"
              cellTemplate="cellTemplate"
              [allowFiltering]="false">
  </dxi-column>
  <dxi-column dataField="couleur" caption="Couleur" [width]="150"
              [filterOperations]="['contains']"
              cellTemplate="cellColor"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="ordre" caption="Ordre" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="typeTache.libelle" caption="Type de tâche" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="typeEquipement.libelle" caption="Type d'équipement" [width]="200"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <yo-cell-button (yoNavigation)="openTache(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoViewMode]="!canModify(cell.row.data)"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canModify(cell.row.data)?'Modifier la tâche':'Voir la tâche'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'templateSite'">
    <yo-site *ngIf="cell.row.data.site"
             [siteLibelle]="cell.row.data.site?.libelle"
             [siteId]="cell.row.data.site?.id"></yo-site>
  </div>

  <div *dxTemplate="let data of 'cellTemplate'">
    <img style="width: 25px;" [src]="'/assets/logo/' + data.value"/>
  </div>

  <div *dxTemplate="let data of 'cellColor'">
    <dx-color-box style="border-style: none; background: none" [readOnly]="true" [value]="data.value"
                  [text]=""></dx-color-box>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} tâche(s)">
  </dxo-pager>

</dx-data-grid>

<yo-pms-tache-dialog></yo-pms-tache-dialog>


