<p-dialog
  [dismissableMask]="false"
  [closable]="true"
  [closeOnEscape]="true"
  (onHide)="closeDialog()"
  [(visible)]="displayDialog"
  showEffect="fade"
  [style]="{'width':'1010px','height':utils.getWindowAvailableHeight(100)+'px'}"
  [contentStyle]="{'overflow':'hidden'}"
  [modal]="true"
  #changeUniteDialog>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      Changement d'unité technique pour la denrée {{denree?.libelle}}
    </div>
  </ng-template>


  <div [style.height.px]="utils.getWindowAvailableHeight(240)" style="overflow-y:auto;overflow-x: hidden;">

    <dx-data-grid
      [dataSource]="uniteDeMesureList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(570)"
      columnResizingMode="widget"
      [cacheEnabled]="false"
      [allowColumnReordering]="true"
      (onSelectionChanged)="onSelectionChanged($event)"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="site.libelle" alignment="center" caption="Site"
                  [allowFiltering]="false"
                  cellTemplate="siteCellTemplate">
      </dxi-column>
      <dxi-column dataField="libelle" alignment="center" caption="Libelle"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="code" alignment="center" caption="Code"
                  [allowFiltering]="true">
      </dxi-column>


      <div *dxTemplate="let cell of 'siteCellTemplate'">
        <yo-site [siteLibelle]="cell.data.site.libelle"
                 [siteId]="cell.data.site.id">
        </yo-site>
      </div>

      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'onClick'"
        mode="single">
      </dxo-selection>
      <dxo-paging [pageSize]="50"></dxo-paging>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Unité de mesure">
      </dxo-pager>

      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>

    </dx-data-grid>

    <div class="mg-t-10 d-flex justify-content-center">
      <p-card>
        <p-header class="d-flex justify-content-center">
          <strong class="mg-t-10">Unité technique utilisée</strong>
        </p-header>
        <div class="card-body">{{denree?.uniteDeMesure?.libelle}}</div>
      </p-card>

      <i class="mg-l-30 mg-r-30 fa-3x fas fa-long-arrow-alt-right align-self-center"></i>

      <p-card>
        <p-header class="d-flex justify-content-center">
          <strong class="mg-t-10">Unité technique de substitution</strong>
        </p-header>
        <div class="card-udm-body">{{uniteTechniqueSubstitution?.libelle}}</div>
      </p-card>
    </div>

    <div *ngIf="!this.utils.isNullOrEmpty(uniteTechniqueSubstitution)" class="d-flex mg-t-40 justify-content-center">
      <div class="mg-l-30 d-flex" *ngIf="uniteTechniqueSubstitution">
        <div class="d-flex align-items-center">
          <strong> 1 {{denree.uniteDeMesure.code}} </strong>
          <p class="mg-l-5" style="margin: inherit;"> fait {{getMesureForOldUniteDeMesure() | number : '1.0-4'}} Kilogramme </p>
        </div>
        <i class="mg-l-30 mg-r-30 fa-3x fas fa-long-arrow-alt-right align-self-center"></i>
        <div class="d-flex align-items-center">
          <strong> 1 {{uniteTechniqueSubstitution.code}} </strong>
          <p class="mg-l-5 d-flex" style="place-items: center; margin: inherit"> fait
            <dx-number-box
              style="margin: 1rem"
              [width]="'150px'"
              [(ngModel)]="qteNewUniteDeMesure"
              [showSpinButtons]="true"
              [showClearButton]="true"
            ></dx-number-box>
            Kilogramme
          </p>
        </div>
      </div>
    </div>
  </div>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <!--      <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>-->
      <!--      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Completez les 3 onglets pour avoir-->
      <!--        accés à l'onglet suivant "Articles" </label></div>-->
    </div>


  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex">
      <div class="mr-auto">
      </div>
      <div>
        <button pButton icon="fas fa-save" (click)="saveUniteTechnique()"
                pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </div>

      <div>
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="cta-inner-delete">
        </button>
      </div>
    </div>
  </ng-template>

</p-dialog>
