import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DATEPICKER_FR, STOCKS_FEATURES} from '../../../core/constants';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UniteDeProduction__zoneDeStockageService} from '../../../core/services/entities/unite-de-production__zone-de-stockage.service';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {CataloguesAchatsService} from '../../../core/services/entities/catalogues-achats.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {StocksSupplier} from '../../stocks/stocks-resolver.service';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {StockMouvementStatutDTO} from '../../../core/dtos/stock-mouvement-statut-dto';
import {StockMouvementService} from '../../../core/services/gestion-stock/stock-mouvement.service';
import * as moment from 'moment';
import {StockMouvementStatutService} from '../../../core/services/gestion-stock/stock-mouvement-statut.service';
import {DxTreeViewComponent} from "devextreme-angular";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";

@Component({
  selector: 'yo-search-panel-mouvements',
  templateUrl: './search-panel-mouvements.component.html',
  styleUrls: ['./search-panel-mouvements.component.scss']
})
export class SearchPanelMouvementsComponent implements OnInit, OnDestroy {

  subFormSearch: Subscription;
  subFormSearchBalances: Subscription;
  subFeature:Subscription;
  subSearchBalanceMouvement:Subscription;
  subPaginationSearchMouvement:Subscription;
  subExportMouvement:Subscription;

  subRoute: Subscription;
  formSearch: FormGroup;
  feature:string;

  zonesStockages: any[] = [];
  idsZonesStockagesSelected: number[] = [];

  @ViewChild("treeViewZonesStockages", {static: false }) treeViewZonesStockages : DxTreeViewComponent;

  mouvementsStatut: StockMouvementStatutDTO[];

  filterEntree: boolean;
  filterSortie: boolean;

  localeFr = DATEPICKER_FR;

  sites: SiteDTO[] = [];
  idsSitesSelected: number[] = [];

  idsTypesMouvSelected: number[] = [];

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private udpZdsSvc: UniteDeProduction__zoneDeStockageService,
              private stocksSvc: StocksService,
              private catalogueAchatSvc: CataloguesAchatsService,
              private stocksMvtSvc:StockMouvementService,
              private stockMvtStatutSvc: StockMouvementStatutService,
              private auth2Svc: Auth2Service) {
  }

  initForm = () => {

    const dateDebMonth = moment().startOf('month').toDate();
    const dateEndMonth = moment().endOf('month').toDate();

    this.formSearch = new FormGroup({
      denominationArticle: new FormControl(''),
      referenceInterneArticle: new FormControl(''),
      lotArticle: new FormControl(''),
      lotArticleFournisseur: new FormControl(undefined),
      mouvements: new FormControl([]),
      periode: new FormControl([dateDebMonth, dateEndMonth]),
      filterEntree: new FormControl(),
      filterSortie: new FormControl()
    });
  };

  initSites = (): void => {
    this.sites = this.auth2Svc.utilisateur.siteListLocaux;
    if (this.sites && this.sites.length) {
      this.sites = this.sites.sort((s1, s2) => s1.libelle.localeCompare(s2.libelle));
      this.idsSitesSelected.push(this.sites[0].id);
      this.updateUdpZds();
    }
  }

  private updateUdpZds: () => void = () => {
    this.stocksSvc.prepareSearch(this.idsSitesSelected)
      .subscribe(res => {
        const udpsZdps = res.resultList;
        if (udpsZdps && udpsZdps.length) {
          udpsZdps.forEach(udp => {
            udp.zonesStockage.forEach(zds => zds["icon"] = "fas fa-home");
            udp.id = `udp-${udp.id}`;
            udp["icon"] = "fa-industry fas";
            udp["items"] = udp.zonesStockage.map(zds => ({...zds, id: `${udp.id}-${zds.idUdpZds}-zds`}));
            udp["expanded"] = true;
          });
          this.zonesStockages = Object.assign([], udpsZdps);
          this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});
        }
      });
  };

  onChangeTypeMouv = ($event): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.idsTypesMouvSelected.filter(id => id === item.id).length) {
          this.idsTypesMouvSelected.push(item.id);
        }
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.idsTypesMouvSelected.filter(id => id === item.id).length) {
          this.idsTypesMouvSelected = this.idsTypesMouvSelected.filter(id => id !== item.id);
        }
      });
    }
    this.formSearch.controls['mouvements'].setValue(this.idsTypesMouvSelected);
  }

  onChangeSite = ($event): void => {
    if ($event.addedItems?.length) {
      $event.addedItems.forEach(item => {
        if (!this.idsSitesSelected.filter(idSite => idSite === item.id).length) {
          this.idsSitesSelected.push(item.id);
        }
      });
    }
    if ($event.removedItems?.length) {
      $event.removedItems.forEach(item => {
        if (this.idsSitesSelected.filter(idSite => idSite === item.id).length) {
          this.idsSitesSelected = this.idsSitesSelected.filter(idSite => idSite !== item.id);
        }
      });
    }
    if (this.idsSitesSelected && this.idsSitesSelected.length) {
      this.updateUdpZds();
    }
  }

  ngOnInit() {
    this.initSites();
    this.initForm();
    this._allSubscription();
    this.subscriptionFormSearch();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
    this.utils.unsubscribe(this.subFeature);
    this.utils.unsubscribe(this.subFormSearch);
    this.utils.unsubscribe(this.subFormSearchBalances);
    this.utils.unsubscribe(this.subSearchBalanceMouvement);
    this.utils.unsubscribe(this.subPaginationSearchMouvement);
    this.utils.unsubscribe(this.subExportMouvement);
  }

  private _allSubscription = () => {
    this.subFeature = this.stocksSvc.feature$
      .pipe(
        filter(feature=> feature===STOCKS_FEATURES.MOUVEMENTS),
        switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected,0,50)),
        map(response => this.stocksMvtSvc.announceResultSearchMouvements(response, this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected)),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe();

    // la pagination a changé
    this.subPaginationSearchMouvement = this.stocksMvtSvc.paginationSearchMouvement$.pipe(
      switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected,values.page,values.size)),
      map(response => this.stocksMvtSvc.announceResultSearchMouvements(response, this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected)),
      catchError(err => this.utils.handleError(err))
    ).subscribe();

    this.subRoute = this.route.data
      .subscribe((data: { stocksSupplier: StocksSupplier }) => {
        this.zonesStockages = data.stocksSupplier.tree;
        this.mouvementsStatut = data.stocksSupplier.mouvementsStatuts;

        if (this.zonesStockages && this.zonesStockages.length) {
          this.zonesStockages.forEach(udp => {
            udp.zonesStockage.forEach(zds => zds["icon"] = "fas fa-home");
            udp.id = `udp-${udp.id}`;
            udp["icon"] = "fa-industry fas";
            udp["items"] = udp.zonesStockage.map(zds => ({...zds, id: `${udp.id}-${zds.idUdpZds}-zds`}));
            udp["expanded"] = true;
          });
        }
      });
  };

  filterTypeMouvements = ($event: any, typeMouvement: string): void => {
    if(typeMouvement === 'E') {
      this.filterEntree = $event.value;
      this.formSearch.controls['filterEntree'].setValue($event.value);
    } else {
      this.filterSortie = $event.value;
      this.formSearch.controls['filterSortie'].setValue($event.value);
    }
    if(!this.filterEntree && !this.filterSortie) {
      this.filterEntree = null;
      this.filterSortie = null;
    }
    this.stockMvtStatutSvc.getAll(this.filterSortie, this.filterEntree)
      .subscribe(res => this.mouvementsStatut = res.resultList);
  };

  changeZoneStockage = ($event: any): void => {
    this.idsZonesStockagesSelected = this.treeViewZonesStockages.instance.getSelectedNodeKeys().filter(key => key.includes("-zds")).map(id => parseInt(id.split('-')[2]));
    // déclencher la recherche avec un temps de latence debounceTiem du this.formSearch.valueChanges
    this.formSearch.updateValueAndValidity({onlySelf: false, emitEvent: true});

  };

  subscriptionFormSearch = (): void => {
    this.subFormSearch = this.formSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(values => this.stocksMvtSvc.searchInMouvements(this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected,0,50)),
      map(response => this.stocksMvtSvc.announceResultSearchMouvements(response, this.formSearch.value, this.idsSitesSelected, this.idsZonesStockagesSelected)),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe();
  };

}
