import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangelogsComponent} from './changelogs.component';
import {SharedModule} from '../shared/shared.module';
import {CoreModule} from '../core/core.module';
import {ButtonModule} from 'primeng/button';
import {PanelModule} from "primeng/panel";
import {MarkdownModule} from "ngx-markdown";
import {ChangelogsRoutingModule} from "./changelogs-routing.module";
import {NumberVersionComponent} from "./number-version/number-version.component";
import {DetailedVersionComponent} from "./detailed-version/detailed-version.component";


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ButtonModule,
    PanelModule,
    MarkdownModule,
    ChangelogsRoutingModule
  ],
  declarations: [ChangelogsComponent, NumberVersionComponent, DetailedVersionComponent],
  exports: [ChangelogsComponent]
})
export class ChangelogsModule { }
