import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreModule} from '../core/core.module';
import {GestionProductionRoutingModule} from './gestion-production-routing.module';
import {GestionProductionRootComponent} from './gestion-production-root/gestion-production-root.component';
import {PpComponent} from './pp/pp.component';
import {SharedModule} from '../shared/shared.module';
import {PlansDeProductionComponent} from './pp/plans-de-production/plans-de-production.component';
import {PrintSettingsComponent} from './pp/plans-de-production/print-settings/print-settings.component';
import {GestionProcessusModule} from '../gestion-processus/gestion-processus.module';
import {InitProcessusComponent} from '../gestion-processus/init-processus/init-processus.component';
import {BulkEditionDetailsDialogComponent} from './pp/plans-de-production/grid-details-pp/bulk-edition-details-dialog/bulk-edition-details-dialog.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortiesProductionComponent} from './pp/sorties-production/sorties-production.component';
import {
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxListModule,
  DxLookupModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule
} from 'devextreme-angular';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InitializationPlanDeProductionComponent} from "./pp/plans-de-production/initialization/initialization-plan-de-production.component";
import {SelectionFiltersPlanProductionComponent} from "./pp/plans-de-production/selection-filters/selection-filters-plan-production.component";
import {PlanProductionComponent} from "./pp/plans-de-production/plan-production/plan-production.component";


@NgModule({
  imports: [
    CommonModule,
    GestionProductionRoutingModule,
    SharedModule,
    GestionProcessusModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    MenuModule,
    SelectButtonModule,

    DxCheckBoxModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxPopupModule,
    DxLookupModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxDateBoxModule,
    DxTabPanelModule,
    DxToolbarModule,
    DxListModule,
    DxDropDownButtonModule
  ],
  entryComponents:[
    InitProcessusComponent
  ],
  declarations: [
    GestionProductionRootComponent,
    PpComponent,
    PlansDeProductionComponent,
    PrintSettingsComponent,
    SortiesProductionComponent,
    BulkEditionDetailsDialogComponent,
    InitializationPlanDeProductionComponent,
    SelectionFiltersPlanProductionComponent,
    PlanProductionComponent
  ]
})
export class GestionProductionModule { }
