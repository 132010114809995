<form [formGroup]="formGroupCtrl">


    <div class="card-size">
      <h5>
      <label class="badge badge-secondary">
        <i class="fa fa-map-marker yoni-color  mg-r-5"></i>
         <ng-container *ngIf="yoSite.id === 0; else editMode">
           {{'Création d\'un site' | uppercase }}
         </ng-container>
         <ng-template #editMode>
           {{yoSite.libelle | uppercase}}
         </ng-template>
      </label>
      </h5>


      <div class="d-flex justify-content-end mg-b-10">
        <button pButton
                title="Enregistrer le site"
                type="submit"
                icon="fas fa-save"
                class="p-button-success"
                (click)="save()">
        </button>
      </div>


      <p-panel header="INFORMATIONS GÉNÉRALES" [style]="{'margin-bottom': '10px'}">

        <div class="d-flex flex-row align-items-center" formGroupName="siteGroup">
          <div class="size-input-group d-flex align-items-center justify-content-between mg-r-50 mg-b-10">
            <label>Libellé <span class="danger-color">*</span> : </label>
            <input
              formControlName="libelle"
              class="size-input"
              [required]="true"
              [(ngModel)]="yoSite.libelle"
              showDelay="1500"
              onfocus="removeAttribute('readonly')"
              pInputText/>
          </div>

          <div class="size-input-group d-flex align-items-center justify-content-between mg-r-15 mg-b-10">
            <label>Code <span class="danger-color">*</span> : </label>
            <input
              formControlName="reference"
              class="size-input"
              [required]="true"
              [(ngModel)]="yoSite.reference"
              showDelay="1500"
              onfocus="removeAttribute('readonly')"
              pInputText/>
          </div>

          <p-checkbox
            formControlName="actif"
            binary="true"
            value="actif"
            label="Actif"
            [(ngModel)]="yoSite.actif">
          </p-checkbox>
        </div>

      </p-panel>

      <p-panel header="ADRESSE">
        <div class="d-flex flex-row align-items-start" formGroupName="adressGroup">

          <div class="d-flex flex-column mg-r-50">
            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Adresse 1 : </label>
              <input
                formControlName="adresse1"
                class="size-input"
                [(ngModel)]="yoSite.adresse1"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Adresse 2 : </label>
              <input
                formControlName="adresse2"
                class="size-input"
                [(ngModel)]="yoSite.adresse2"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Adresse 3 : </label>
              <input
                formControlName="adresse3"
                class="size-input"
                [(ngModel)]="yoSite.adresse3"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Code postal : </label>
              <input
                formControlName="codePostal"
                class="size-input"
                [(ngModel)]="yoSite.codePostal"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Ville : </label>
              <input
                formControlName="ville"
                class="size-input"
                [(ngModel)]="yoSite.ville"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

          </div>

          <div class="d-flex flex-column align-items-start mg-r-50">
            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Téléphone : </label>
              <input
                formControlName="telephone"
                class="size-input"
                [(ngModel)]="yoSite.telephone"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Fax : </label>
              <input
                formControlName="fax"
                class="size-input"
                [(ngModel)]="yoSite.fax"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>

            <div class="size-input-group d-flex align-items-center justify-content-between mg-b-10">
              <label>Email : </label>
              <input
                formControlName="email"
                class="size-input"
                [(ngModel)]="yoSite.email"
                showDelay="1500"
                onfocus="removeAttribute('readonly')"
                pInputText/>
            </div>
          </div>

        </div>
      </p-panel>

    </div>

</form>
