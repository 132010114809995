<div id="stats-menus-egalim" class="card mg-b-10 card-size" *ngIf="canDisplay()">
  <div class="card-header">
    <div class="d-flex">
      <div class="flex-grow-1">
        <span class="mg-r-5">Analyse Egalim pour la prestation</span>
        <strong>{{mp2Svc?.planningMenus?.contratMenuConviveList[0]?.contratMenuLibelle}}
          - {{mp2Svc?.planningMenus?.contratMenuConviveList[0]?.libelle}}</strong>
      </div>

      <div class="mg-r-5">
        <p-calendar
          firstDayOfWeek="1"
          dateFormat="dd/mm/yy"
          tooltipPosition="bottom"
          showDelay="500"
          selectionMode="range"
          [numberOfMonths]="2"
          [readonlyInput]="true"
          [(ngModel)]="selectedPeriode"
          (onSelect)="changeSelectedPeriode()"
          [locale]="localeFr"
          [inputStyle]="{'width':'250px'}"
          [showIcon]="true"
          [showWeek]="true"
        >
        </p-calendar>
      </div>

    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf="!dataGraphic">
      <p>Veuillez sélectionner une période et saisir un effectif</p>
    </ng-container>
    <ng-container *ngIf="dataGraphic">
      <dx-bar-gauge
        id="gauge"
        [startValue]="0"
        [endValue]="100"
        [palette]="['#2196f3', '#42a84c']"
        [values]="[dataGraphic.pourcentageDurable, dataGraphic.pourcentageBio]"
      >
        <dxo-label [visible]="true" [customizeText]="customizeTextLabel"></dxo-label>
        <dxo-tooltip
          [enabled]="true"
          [customizeTooltip]="customizeTooltip"
        ></dxo-tooltip>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-title [text]="dataGraphic.egalim ? 'Conforme à la loi Egalim' : 'Non conforme à la loi Egalim'">
          <dxo-font [size]="28" [color]="dataGraphic.egalim ? 'green' : 'red'"></dxo-font>
        </dxo-title>
        <dxo-legend
          [visible]="true"
          verticalAlignment="bottom"
          horizontalAlignment="center"
          [customizeText]="customizeText"
        >
        </dxo-legend>
      </dx-bar-gauge>
    </ng-container>
  </div>

</div>
