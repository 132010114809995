import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {UnitesDeMesuresService} from '../entities/unites-de-mesures.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {StockMouvementStatutDTO} from '../../dtos/stock-mouvement-statut-dto';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE} from "../../constants";
import {Subject} from "rxjs/index";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {HttpService} from "../technique/http.service";
import {SearchSupplierWrapper} from "../../suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../suppliers/search-supplier";
import {StockDTO} from "../../dtos/stock-dto";

export const URL_POST_SAVE_SORTIE=`dolrest/gestion-stocks/save/sortie`;
export const URL_FIND_ALL_BY=`dolrest/gestion-stocks/findBy/stock-mouvement-statut`;
export const URL_DELETE=`dolrest/gestion-stocks/delete/stock-mouvement-statut`;
export const URL_SAVE=`dolrest/gestion-stocks/save/stock-mouvement-statut`

@Injectable({
  providedIn: 'root'
})
export class StockMouvementStatutService extends GenericHandler<StockMouvementStatutDTO> {

  stockMouvementStatutListEnvironnement: StockMouvementStatutDTO[] = [];

  private subjectOpenDialogStatut = new Subject<StockMouvementStatutDTO>();
  openDialogStatut$ = this.subjectOpenDialogStatut.asObservable();

  private subjectStatutSaved = new Subject<StatutSavedSupplier>();
  statutSaved$ = this.subjectStatutSaved.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService,
              private udmSvc: UnitesDeMesuresService,
              private httpSvc: HttpService
  ) {
    super(utils, auth2Svc, router, http, title);

    this.getAllFromEnvironnement();

    this.environnement$.subscribe(() => {
      this.getAllFromEnvironnement();
    });

  }

  announceOpenDialogStatut(param: StockMouvementStatutDTO) {
    this.subjectOpenDialogStatut.next(param);
  }

  announceStatutSaved(statut: StockMouvementStatutDTO, isUpdate: boolean = false) {
    this.subjectStatutSaved.next({ isUpdate, statut } as StatutSavedSupplier);
  }

  saveSpecific(statut: StockMouvementStatutDTO): Observable<ResponseWrapper<StockMouvementStatutDTO>> {
    return this.httpSvc.post(URL_SAVE, statut);
  }

  /**
   * Supprime un ou plusieurs statut(s) en fonction de
   * leurs identifiants.
   * @param idsToDelete
   */
  deleteByIds(idsToDelete: number[]): Observable<any> {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsToDelete.join(','));
    return this.httpSvc.delete(URL_DELETE, params);
  }

  /**
   * Récupérer toutes les valeurs
   * @returns {Observable<StockMouvementStatutDTO>}
   */
  getAll(isSortie?: boolean, isEntree?: boolean): Observable<ResponseWrapper<StockMouvementStatutDTO>> {
    const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
    if (isEntree !== undefined && isEntree !== null) {
      ssw.filtersMap['entree'] = new SearchSupplier(isEntree);
    }
    if (isSortie !== undefined && isSortie !== null) {
      ssw.filtersMap['sortie'] = new SearchSupplier(isSortie);
    }
    return this.httpSvc.post(`${URL_FIND_ALL_BY}?page=0&size=999`, ssw);
  }

  createEmptyDTO(): StockMouvementStatutDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {

    console.log("StockMouvementStatutService#getAllFromEnvironnement()");

    this.gds.search(this.filterAll()).pipe(
      shareReplay(),
      map(response => this.stockMouvementStatutListEnvironnement = response.resultList),
      tap(response => this.announceObjects(this.stockMouvementStatutListEnvironnement)),
      catchError(err => this.utils.handleError(err)))
      .subscribe();

  }


  filterAll(): GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.getEntityName());

    const search = new Search();

    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = `${this.getEntityName().toLowerCase()}.id`;
    predicat1.operator = PREDICAT_OPERATOR.GreaterThan;
    predicat1.type =  PREDICAT_TYPE.Integer;
    predicat1.value = '0';

    search.predicats.push(predicat1);

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Ascendant;
    sort.path = `${this.getEntityName().toLowerCase()}.libelle`;
    search.sorts = [sort];

    grs.search = search;


    return grs;
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: StockMouvementStatutDTO): string {
    return "";
  }

  getEntityName(): string {
    return "StockMouvementStatut";
  }

  getFields(dto: StockMouvementStatutDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES MOUVEMENTS DE STOCK";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

  saveSortie: (stock: StockDTO) => Observable<any> = (stock: StockDTO) => this.httpSvc.post(URL_POST_SAVE_SORTIE, stock);

}

export interface StatutSavedSupplier {
  isUpdate: boolean;
  statut: StockMouvementStatutDTO;
}

