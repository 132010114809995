import {Component, OnDestroy, OnInit} from '@angular/core';
import {StocksService} from '../../../core/services/gestion-stock/stocks.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {ModelViewInventaire} from './model-view-inventaire';
import {ProduitDeclinaisonService} from '../../../core/services/entities/produit-declinaison.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {PrintInventaireService} from '../../../core/services/gestion-stock/print-inventaire.service';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-inventaire',
  templateUrl: './inventaire.component.html',
  styleUrls: ['./inventaire.component.scss']
})
export class InventaireComponent implements OnInit, OnDestroy {

  subResultSearchStocks: Subscription;
  subRoute: Subscription;
  lignesInventaire: ModelViewInventaire[];
  totalDenrees = 0;
  totalLots = 0;
  selectedInventaire: ModelViewInventaire;

  loading = false;
  isSaveDisabled = true;

  // criteres de recherhce du panneau gauche (module gestion de stocks)
  ssw: SearchSupplierWrapper;

  rowGroupMetadata: any;

  timer: any;

  cols: any[] = [
    {field: 'denree', header: 'Lot'},
    {field: 'code', header: 'Code'},
    {field: 'zdsLibelle', header: 'Zone de stockage'},
    {field: 'dateEntree', header: 'Réceptionné le'},
    {field: 'dlc', header: 'DLC'},
    {field: 'uniteDeStockage', header: 'U.S'},
    {field: 'quantite', header: 'Qté'},
    {field: 'quantiteReaj', header: 'Qté Réaj'}
  ];

  private isKeepCloseDialogConfirmSave : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private stocksSvc: StocksService,
    private produitDeclinaisonSvc:ProduitDeclinaisonService,
    public utils: UtilsService,
    public printInventaireService: PrintInventaireService,
    private toastSvc: ToastService) {
  }


  ngOnInit() {

    // notifier le composant de recherche qu'on ait sur la fonctionnalité
    this.subRoute = this.route.url.subscribe(response => {
      const feature = response[0].path;
      this.stocksSvc.announceFeature(feature);

    });

    // s'abonner à la recherche des articles dans le stock
     this.subResultSearchStocks = this.produitDeclinaisonSvc.resultSearchStocksForInventaire$
      .subscribe(response => {

        this.lignesInventaire = response.resultList;
        this.ssw = new SearchSupplierWrapper();
        this.ssw.filtersMap = response.additionalProperties['filters'];
        this.totalDenrees= response.additionalProperties['totalDenrees'] ? response.additionalProperties['totalDenrees'] : 0;
        this.totalLots = response.additionalProperties['totalLots'] ? response.additionalProperties['totalLots'] : 0;
      });

     // dialog confirm keep openable
    this.stocksSvc.isKeepItClose$.subscribe( (response:boolean) =>{
      this.isKeepCloseDialogConfirmSave = response;
    });

    // si l utilsateur annule son save le btn save reste clickable
    this.stocksSvc.isConfirmCancelled$.subscribe((reponse:boolean)=>{
      this.isSaveDisabled = !reponse;
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subResultSearchStocks);
    this.utils.unsubscribe(this.subRoute);
  }


  lazyLoadInventaire = ($event: LazyLoadEvent) => {

    // declencher la requete back de pagination
    this.produitDeclinaisonSvc.lazyLoadStocks($event);
  };

  openDialogAjoutLot = (selectedInventaire : ModelViewInventaire) => {
    this.produitDeclinaisonSvc.announceOpenDialogAjoutLot(null, selectedInventaire);
  };

  updateQuantityReaj = ($event: any, lotArticle: string): void => {
    clearTimeout(this.timer);
    console.log('updateQuantityReaj ', this.lignesInventaire.filter(li => li.lotArticle === lotArticle));
    this.timer = setTimeout(() => {
      this.stocksSvc.saveInventaire(this.lignesInventaire.filter(li => li.lotArticle === lotArticle)).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les modifications ont été enregistrées avec succès`);
          this.isSaveDisabled = true;
        }
      });
    }, 1500);
  };

}
