import {Injectable} from '@angular/core';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericHandler} from '../generics/generic-handler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {EquipeDTO} from '../../dtos/equipe-dto';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {Observable, Subject} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {ImportReferentielResultDTO} from "../../dtos/ImportReferentielResultDTO";
import {HttpService} from "../technique/http.service";
import {GraphQLService} from "../technique/graphql.service";

export const URL_SAVE_GEMRCN = `dolrest/referentiel/production/equipes/save`;
export const URL_EXPORT_XLSX_EQUIPES = `dolrest/referentiel/production/equipes/export`;
export const URL_IMPORT_XLSX_EQUIPES = `dolrest/referentiel/production/equipes/import`;
export const URL_DELETE_EQUIPE = `dolrest/referentiel/production/equipes/delete`;

@Injectable()
export class EquipesService extends GenericHandler<EquipeDTO> {

  private subjectOpenDialog = new Subject<EquipeDTO>();
  openDialogEdition$ = this.subjectOpenDialog.asObservable();

  private subjectEquipeSaved = new Subject<EquipeSavedSupplier>();
  equipeSaved$ = this.subjectEquipeSaved.asObservable();

  exportEquipeByFilters = (rowsToExport?: number[]) => {
    let params: string = '?1=1';
    if (rowsToExport && rowsToExport.length) params += `&ids=${rowsToExport.join(',')}`
    return this.http.get(`${URL_EXPORT_XLSX_EQUIPES}?${params}`, {
      responseType: 'blob',
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/vnd.ms-excel')
    });
  };

  importEquipeFromXlsx = (file: File): Observable<ResponseWrapper<ImportReferentielResultDTO>> => {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpSvc.post(URL_IMPORT_XLSX_EQUIPES, formData, null);
  };

  deleteEquipeByFilters = (rowsToDelete?: number[]) => {
    let params: string = '?1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.http.delete(`${URL_DELETE_EQUIPE}?${params}`);
  };

  announceOpenDialogEdition = (equipe: EquipeDTO) => {
    this.subjectOpenDialog.next(equipe);
  };

  announceEquipeSaved = (equipe: EquipeDTO, isUpdate: boolean = false) => {
    this.subjectEquipeSaved.next({isUpdate, equipe} as EquipeSavedSupplier);
  }


  save = (equipe: EquipeDTO): Observable<ResponseWrapper<EquipeDTO>> => this.httpSvc.post(URL_SAVE_GEMRCN, equipe);

  canModify(element: ObjectDTO): boolean {
    if (this.utils.isNullOrEmpty(element?.site?.id) || element.site.id == 0)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  //region GENERIQUE
  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['code,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES ÉQUIPES';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE ÉQUIPE';
  }


  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private httpSvc: HttpService,
              private gds: GenericDatagridService,
              private graphQlSvc: GraphQLService) {
    super(utils, auth2Svc, router, http, title);
  }

  getEntityName(): string {
    return 'equipe';
  }

  getFields(dto: EquipeDTO): FormFieldBaseSupplier<any>[] {

    // Par défaut une Equipe est active.
    if (this.utils.isNullOrEmpty(dto) || dto.id == 0 || dto.id == undefined) {
      dto = new EquipeDTO();
      dto.actif = true;
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      this.createFormFieldBaseSupplierForSite(dto, 1),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        minLength: 2,
        maxLength: 100,
        value: dto.libelle,
        required: true,
        excelType: 'string',
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: !this.canModify(dto),
        value: dto.code,
        maxLength: 50,
        required: true,
        excelType: 'string',
        order: 3
      }),

      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: !this.canModify(dto),
        value: dto.actif,
        required: false,
        excelType: 'boolean',
        order: 5
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 6
      })

    ];

    return formFields.sort((a, b) => a.order - b.order);
  }


  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): EquipeDTO {
    return new EquipeDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER L'ÉQUIPE '${data.libelle.toUpperCase()}'`;
  }

  getAll(): Observable<any> {
    throw new Error('To delete');
  }

  //endregion

}

export interface EquipeSavedSupplier {
  isUpdate: boolean;
  equipe: EquipeDTO;
}
