import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {ObjectDTO} from '../../../core/dtos/object-dto';

@Component({
  selector: 'yo-cell-button',
  template: `

    <ng-container *ngIf="!yoHiddenMode">
    <button pButton
            [icon]="yoViewMode ?'fa fa-eye': yoIconClass"
            [class]="'mg-r-5 mg-b-2 ' + (yoIconClass.includes('trash') ? ' cta-delete ' : '  ') +  (yoIconClass.includes('save') ? ' p-button-success ' : '  ') "
            [class.cta-edit-or-watch]="yoViewMode || yoIconClass.includes('fa-edit') || yoIconClass.includes('fa-eye')"
            [class.cta-secondary]="!yoViewMode && !yoIconClass.includes('fa-search') && !yoIconClass.includes('fa-edit') && !yoIconClass.includes('fa-eye') && !yoIconClass.includes('trash') && !yoIconClass.includes('save') "
            [pTooltip]="yoTooltip"
            [style.text-align]="yoTextAlign"
            [class.small]="yoMaxWidth"
            [class.bg-danger]="utils.isBg(yoBackgroundColor,'danger')"
            [class.bg-settings]="utils.isBg(yoBackgroundColor,'settings')"
            [class.bg-new]="utils.isBg(yoBackgroundColor,'new')"
            [class.bg-white]="utils.isBg(yoBackgroundColor,'white')"
            [class.bg-yoni]="utils.isBg(yoBackgroundColor,'yoni')"
            [class.bg-save]="utils.isBg(yoBackgroundColor,'save')"
            [class.cursor]="'pointer'"
            (click)="onClickYoButton($event)"
            [class.white]="yoWhite"
            [style.width]="yoWidthPercent+'%'"
            [class.red]="yoRed"
            [class.disable]="yoDisabled"
            type="button">


    </button>
    </ng-container>

  `,
  styleUrls: ['./cell-button.component.scss']
})
export class CellButtonComponent implements OnInit {

  @Input() yoTooltipShowDelay;
  @Input() yoTooltip;
  @Input() yoBackgroundColor;
  @Input() yoLabel;
  @Input() yoWhite: boolean;
  @Input() yoRed: boolean;
  @Input() yoIconClass;
  @Input() yoMaxWidth: boolean;
  @Input() yoWidthPercent: number = 95;
  @Input() yoTextAlign: string = 'left';
  @Input() yoDisabled: boolean;
  @Input() yoViewMode: boolean;
  @Input() yoHiddenMode: boolean = false;

  /**
   * Objet venant du back, sers à savoir si on peut editer ou pas : s'il appartient au site principal ou pas
   */
  @Input() yoElement: ObjectDTO;

  @Output() yoNavigation = new EventEmitter();

  constructor(public utils: UtilsService, public gds: GenericDatagridService) {
  }

  ngOnInit() {

  }


  onClickYoButton($event) {
    if (!this.yoDisabled)
      this.yoNavigation.emit($event);
  }


}
