<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"

  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <span class="mg-r-5"><i [class]="logo"></i></span>
      {{title}}
    </div>
  </ng-template>

  <div class="d-flex flex-grow-1" [style.height]="'calc(68vh)'">
    <div class="d-flex flex-column">

      <div *ngIf="isVisibleForm" class="flex-grow-1">
        <yo-form [groups]="groups" [form]="form" [options]="{'width':utils.getMaxWidthBody()}">
        </yo-form>
      </div>
    </div>
  </div>
  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>
    </div>
    <div>
    <span class="mg-r-5">
      <button pButton icon="fas fa-save" (click)="saveOrigine()"
              [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
              showDelay="500" class="p-button-success">
      </button>
    </span>
      <span>
      <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
              class="cta-delete">
      </button>
    </span>
    </div>
  </div>

</p-dialog>
